import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {computeDiscountPrice} from '../../../shared/helpers';
import { Currency } from '../../../interfaces/currency';

@Component({
    selector: 'app-product-price',
    templateUrl: `./product-price.component.html`,
    styleUrls: ['./product-price.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductPriceComponent {

    @Input() priceRange: {min?: number, max?: number, originalMin?: number, originalMax?: number};

    @Input() price = 0;

    @Input() discount = 0;

    @Input() originalPrice = 0;

    @Input() orientation = 'vertical'; // vertical | horizontal

    @Input() color = 'primary'; // primary (black) | secondary (gold)

    @Input() fontSize = ''; // normal (24px) | small (20px) |  tiny (16px)

    @Input() showBothPrices = true;  // show original and discount price or only discounted or original

    @Input() currency: Currency = null;

    @Input() solelySymbol: boolean;

    computedDiscount(price: number, discount: number): number {
        return computeDiscountPrice(price, discount);
    }

    get hasDiscount(): boolean {
        return this.discount > 0;
    }

    get hasOriginalPrice(): boolean {
        return this.originalPrice > 0;
    }

    get hasOriginalMinPriceRange(): boolean {
        return this.priceRange?.originalMin > 0;
    }

    get hasOriginalMaxPriceRange(): boolean {
        return this.priceRange?.originalMin > 0;
    }

    get discountPrice(): number {
        return this.hasDiscount ? computeDiscountPrice(this.price, this.discount) : this.price;
    }

    get componentClasses(): string {
        const alignment = this.priceRange ? 'align-end' : '';
        return `${this.orientation} ${this.color} ${this.fontSize} ${alignment}`;
    }

    get originalVisible(): boolean {
        if (this.hasDiscount || this.hasOriginalPrice) {
            return this.showBothPrices;
        } else {
            return true;
        }
    }

    get originalMinVisible(): boolean {
        if (this.hasDiscount || this.hasOriginalMinPriceRange) {
            return this.showBothPrices;
        } else {
            return true;
        }
    }

    get originalMaxVisible(): boolean {
        if (this.hasDiscount || this.hasOriginalMaxPriceRange) {
            return this.showBothPrices;
        } else {
            return true;
        }
    }

    get computedOriginalPrice(): number {
        if (this.hasDiscount) {
            return this.price;
        }
        return this.hasOriginalPrice ? this.originalPrice : this.price;
    }

    get computedDiscountedPrice(): number {
        if (this.hasDiscount) {
            return this.discountPrice
        }
        return this.hasOriginalPrice ? this.price : this.discountPrice;
    }

    get computedOriginalMinPriceRange(): number {
        if (this.hasDiscount) {
            return this.priceRange.min;
        }
        return this.priceRange.originalMin > 0 ? this.priceRange.originalMin : this.priceRange.min;
    }

    get computedDiscountedMinPriceRange(): number {
        if (this.hasDiscount) {
            return this.computedDiscount(this.priceRange.min, this.discount) || this.priceRange.min;
        }
        return this.priceRange.originalMin > 0 ? this.priceRange.min :
            this.computedDiscount(this.priceRange.min, this.discount) || this.priceRange.min;
    }

    get computedOriginalMaxPriceRange(): number {
        if (this.hasDiscount) {
            return this.priceRange.max;
        }
        return this.priceRange.originalMax > 0 ? this.priceRange.originalMax : this.priceRange.max;
    }

    get computedDiscountedMaxPriceRange(): number {
        if (this.hasDiscount) {
            return this.computedDiscount(this.priceRange.max, this.discount) || this.priceRange.max;
        }
        return this.priceRange.originalMax > 0 ? this.priceRange.max :
            this.computedDiscount(this.priceRange.max, this.discount) || this.priceRange.max;
    }

}
