import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AppSettings } from '../app.settings';
import { ToastrService } from 'ngx-toastr';
import { Response } from '../models/response.model';
import { TimeZone } from '../models/time-zone.model';
import { Country } from '../models/country.model';
import {API_URL} from '../constants/api-urls';
import {Features} from '../interfaces/features';

// feature flags  received from config api
const SHOW_BILLING_INFO = 'SHOW_BILLING_INFO';
const USER_GUIDES  = 'USER_GUIDES';
const LOGO_URL = 'LOGO_URL';
const CLIENT_NAME = 'CLIENT_NAME';
const SHOW_WBS = 'SHOW_WBS';
const ORDER_WINDOW_LABEL = 'ORDER_WINDOW_LABEL';
const ON_DEMAND_LABEL = 'ON_DEMAND_LABEL';
const SHOW_DELIVERY_COMMENTS = 'SHOW_DELIVERY_COMMENTS';
const SHOW_ORDER_NOTES = 'SHOW_ORDER_NOTES';
const SHOW_PLANNING_COMMENTS = 'SHOW_PLANNING_COMMENTS';
const PRODUCT_CODE_LABEL = 'PRODUCT_CODE_LABEL';
const FAVOURITES_PRODUCTS = 'FAVOURITES_PRODUCTS';
const LIST_ORDERING = 'LIST_ORDERING';
const INTERNAL_ORDER = 'INTERNAL_ORDER';

const initialFeatures: Features = {
    showBillingInfo: false,
    userGuides: [],
    logoUrl: '',
    clientName: '',
    showWBS: false,
    orderWindowLabel: 'Order Window',
    onDemandLabel: 'Quick Buy',
    showDeliveryComments: false,
    showOrderNotes: false,
    showPlanningComments: false,
    productCodeLabel: 'Product Code',
    showFavorites: true,
    productListViewEnabled: false,
    showInternalOrderCodes: false,
}

@Injectable()
export class ConfigService extends BaseService {

    public timezones: Array<TimeZone>;
    public countries: Country[] = [];

    public isCredCardPaymentActive = false;
    public isSingleCreditCardOnly =  false;

    private _features: Features;


    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService,
    ) {
        super('/config', http, appSettings, toastr);
        this._features =  initialFeatures;
    }

//     getNavItems(): Observable<NavItem[]> {
//         // const eTag: string = this.cache.getETag('navItems');
//         // const httpOptions: Object = {headers: new HttpHeaders({'If-Match': eTag})};
//         //
//         // if (this.tmpCache.has('navItems')) {
//         //     const result = this.cache.getItems('navItems');
//         //     _.each(result, (item, i) => {
//         //         result[i] = new NavItem(item);
//         //     });
//         //     return of(result);
//         // }
//
//         return this.http.get<Response>(this.apiURL + '/navigation')
//             .pipe(
//                 map(response => {
//                     const result = response.data;
//
//                     // if (result.length === 0 && response.eTag === eTag) {
//                     //     result = this.cache.getItems('navItems');
//                     // }
//
//                     _.each(result, (item, i) => {
//                         result[i] = new NavItem(item);
//                     });
//
//                     // if (response.eTag != eTag) {
//                     //     this.cache.deleteItems('navItems');
//                     //     this.cache.setItems('navItems', result);
//                     //     this.cache.setETag('navItems', response.eTag);
//                     // }
//
// //                    this.tmpCache.set('navItems', true);
//
//                     return result;
//                 }),
//                 catchError(this.handleError('ConfigService::getNavItems', []))
//             );
//     }

//     getTimeZones(): Observable<any[]> {
//         // const eTag: string = this.cache.getETag('timezones');
//         // const httpOptions: Object = {headers: new HttpHeaders({'If-Match': eTag})};
//         //
//         // if (this.tmpCache.has('timezones')) {
//         //     const result = this.cache.getItems('timezones');
//         //     _.each(result, (item, i) => {
//         //         result[i] = new TimeZone(item);
//         //     });
//         //     this.timezones = result;
//         //     return of(result);
//         // }
//
//         return this.http.get<Response>(this.apiURL + '/timezones')
//             .pipe(
//                 map(response => {
//                     const result = response.data;
//
//                     // if (result.length === 0 && response.eTag == eTag) {
//                     //     result = this.cache.getItems('timezones');
//                     // }
//
//                     _.each(result, (item, i) => {
//                         result[i] = new TimeZone(item);
//                     });
//
//                     // if (response.eTag != eTag) {
//                     //     this.cache.deleteItems('timezones');
//                     //     this.cache.setItems('timezones', result);
//                     //     this.cache.setETag('timezones', response.eTag);
//                     // }
//
//                     this.timezones = result;
// //                    this.tmpCache.set('timezones', true);
//
//                     return this.timezones;
//                 }),
//                 catchError(this.handleError('ConfigService::getTimeZones', []))
//             );
//     }

    getCountries(): Observable<Country[]> {
        // const eTag: string = this.cache.getETag('countries', true);

        // const httpOptions: Object = {headers: new HttpHeaders({'If-Match': eTag})};
        //
        // if (this.tmpCache.has('countries')) {
        //     const result = this.cache.getItems('countries');
        //     this.countries = result.map(item => new Country(item));
        //     return of(this.countries);
        // }

        const url  = `${API_URL}/config/countries`;
        return this.http.get<Response>(url)
            .pipe(
                map(response => {
                    const result = response.data;

                    // if (result.length === 0 && response.eTag === eTag) {
                    //     result = this.cache.getItems('countries');
                    // }
                    //
                    this.countries = result.map(item => new Country(item));

                    return this.countries;
                }),
                catchError(this.handleError('ConfigService::getCountries', []))
            );
    }

    getShippingMethods(): Observable<any[]> {
        // tmp, to be replaced by a endpoint
        const items = [
            {label: 'GROUND'},
            {label: 'TWO_DAY'},
            {label: 'NEXT_DAY'},
        ];

        return of(items);
    }

    getConfigData(key: string): Observable<any[]> {
        return this.http.get<Response>(this.apiURL + '/data/' + key)
            .pipe(
                map(response => {
                    return response.data;
                }),
                catchError(this.handleError('ConfigService::getConfigData', []))
            );
    }

    // retrieve credit card settings (turn off/on)
    public fetchCreditCardSettings(): Observable<boolean> {
        const url = `${this.apiURL}/credit-cards`;
        this.isCredCardPaymentActive = false;

        return this.http.get<Response>(url).pipe(
            map (response => {
                if (response.data) {
                    this.isCredCardPaymentActive = response.data.enabled;
                    return this.isCredCardPaymentActive;
                } else {
                    return false;
                }
            }),
            catchError(this.handleError('ConfigService::credit-card', false))
        );
    }

    protected handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.log(`${operation} failed: ${error.message}`);

            return of(result as T);
        };
    }


    public getFeatureBrands(): Observable<any> {
        return this.getConfigData('brands');
    }

    public get showBillingInfo(): boolean {
        return this.features?.showBillingInfo === true
    }


    public fetchFeatures(userId: number): Observable<boolean> {
        const url = `${this.apiURL}/features`;

        return this.http.post(url, {user_id: userId}).pipe(
            map((response) => {
                if (response) {
                    const respUserGuide = response?.[USER_GUIDES];
                    const userGuides = typeof respUserGuide === 'string' ? JSON.parse(respUserGuide) : (respUserGuide || []);
                    // '/assets/img/logos/diageo-logo.png' : '/assets/img/logos/remy_logo.jpg'
                    this._features =  {
                        showBillingInfo: response[SHOW_BILLING_INFO] === '1',
                        userGuides,
                        logoUrl: response?.[LOGO_URL] || '',
                        clientName: response?.[CLIENT_NAME] || '',
                        showWBS: response?.[SHOW_WBS] === '1',
                        orderWindowLabel: response?.[ORDER_WINDOW_LABEL] || 'Order Window',
                        onDemandLabel: response?.[ON_DEMAND_LABEL] || 'Catalog',
                        showOrderNotes: response?.[SHOW_ORDER_NOTES] === '1',
                        showPlanningComments: response?.[SHOW_PLANNING_COMMENTS] === '1',
                        showDeliveryComments: response?.[SHOW_DELIVERY_COMMENTS] === '1',
                        productCodeLabel: response?.[PRODUCT_CODE_LABEL] || 'Product Code',
                        showFavorites: true,
                        productListViewEnabled: response?.[LIST_ORDERING] === '1',
                        showInternalOrderCodes: response?.[INTERNAL_ORDER] === '1',
                    }
                    return true;
                }
                this._features = initialFeatures;
                return false;
            }
        ), catchError(this.handleError('ConfigService::fetchFeatures', false)));
    }



    get isPresetAddressesAllowed(): boolean {
        // for future use
        return true;
    }


    get features(): Features {
        return this._features;
    }

    // get logoImageUrl(): string {
    //     return this._features.logoUrl;
    // }
    //
    // get canShowWBS(): boolean {
    //     return this._features.showWBS;
    // }
    // get orderWindowLabel(): string {
    //     return this._features.orderWindowLabel;
    // }
    // get onDemandLabel(): string {
    //     return this._features.onDemandLabel;
    // }
    // get clientName(): string {
    //     return this._features.clientName;
    // }
}
