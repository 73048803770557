<ng-container *ngIf="canSeeFavorites">
    <ng-container *ngIf="!product.isFavorite; else productFavorite">
        <button class="btn btn-icon" (click)="toggleFavorites()" role="button">
            <i class="fal fa-heart" *ngIf="!isUpdating"></i>
            <i class="fal fa-sync rotate" *ngIf="isUpdating"></i>
        </button>
        Add to <a href="#" class="link link-underline small" (click)="navigateToFavorites($event)">Favorites</a>
    </ng-container>
</ng-container>
<ng-template #productFavorite>
    <button class="btn btn-icon" role="button" (click)="toggleFavorites()">
        <i class="fas fa-heart color-red" *ngIf="!isUpdating"></i>
        <i class="fal fa-sync rotate" *ngIf="isUpdating"></i>
    </button>
    Remove from <a href="#" class="link link-underline small" (click)="navigateToFavorites($event)">Favorites</a>
</ng-template>

