import {Component, Input, ViewEncapsulation} from '@angular/core';
import {MultiTenantService} from '../../../services/multi-tenant.service';

@Component({
  selector: 'app-product-item-number',
  templateUrl: './product-item-number.component.html',
  styleUrls: ['./product-item-number.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductItemNumberComponent {

  @Input() public supplierId: string;

  @Input() public itemNumber  = '';

  @Input() public className = '';

  constructor(private multiTenant: MultiTenantService) { }

  get supplierLabel(): string {
    return this.multiTenant.productCodeLabel;
  }
}
