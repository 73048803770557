import { Component } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import { OrderItem } from '../../../models/order-item.model';
import {isEmptyArray} from '../../../shared/utils';
import { Currency } from '../../../interfaces/currency';

interface BudgetDetails {
    currency: Currency,
    label: string; 
    available: number, 
    applied: number, 
    remaining: number, 
}

@Component({
    selector: 'budget-details-modal',
    templateUrl: './budget-details--modal.component.html',
    styleUrls: ['./budget-details--modal.component.scss']
})
export class BudgetDetailsModalComponent {

    orderItems: OrderItem[];

    constructor(private activeModal: NgbActiveModal) {
    }

    close() {
        this.activeModal.close();
    }

    get budgets(): BudgetDetails[] {
        if (isEmptyArray(this.orderItems)) {
            return [];
        }

        const items = this.orderItems.filter( i => (i.budgetItem && i.budgetItem.id > 0));
        if (isEmptyArray(items)) {
            return [];
        }

        return items.map((i: OrderItem) => {
            const budget = i.budgetItem;
            const applied = i.financials_total.budget_total || 0;
            const available = budget.balance + applied;
            const currency = i.product.currency;
            return { 
                label: budget.label, 
                available, applied, 
                remaining: budget.balance ,
                currency
            };
        });
    }


}
