import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../services/auth.service';
import {ConfigService} from '../../../services/config.service';
import {Address} from '../../../models/address.model';
import {flatternSortedAddressesByWslr} from '../../../shared/helpers';
import {AddressService} from '../../../services/address.service';
import cloneDeep from 'lodash/cloneDeep';


@Component({
    selector: 'app-manage-order-list-addresses',
    templateUrl: './manage-order-list-addresses-modal.component.html',
    styleUrls: ['./manage-order-list-addresses-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ManageOrderListAddressesComponent implements OnInit, OnDestroy {

    alive = true;
    addressList: Address[];  // input  addresses
    editableAddressList: Address[];

    inProgress = false;


    constructor(
        protected toastr: ToastrService,
        private activeModal: NgbActiveModal,
        private authService: AuthService,
        private config: ConfigService,
        private addressService: AddressService) {
    }


    ngOnInit() {
        if (!this.addressList) {
            this.addressList = [];
        }
        this.editableAddressList = cloneDeep(this.addressList);

    }

    ngOnDestroy() {
        this.alive = false;
    }


    // hide and reset modal dialog
    hideModal(addresses?: Address[]) {
        this.activeModal.close(addresses);
    }

    get canCloseDialog(): boolean {
        return true;
    }


    get canApply(): boolean {
        // if (this.verifyingSAP || this.updatingPaymentMethod) {
        //     return false;
        // }
        //
        // if (this.order.error) {
        //     return false;
        // }
        //
        //
        // if (!this.isWBSOrIODefined) {
        //     return false;
        // }
        //
        // if (this.order.isCustom) {
        //     // cost center should be defined for custom order type
        //     return this.isCostCenterDefined;
        // }
        //
        // if (this.authService.canShowSAP) {
        //     if (this.order.isByingWindow) {
        //         if (this.isSapType === SAP_TYPE.STANDARD) {
        //             return this.isCostCenterDefined;
        //         } else if (this.isSapType === SAP_TYPE.DECISION_POINT) {
        //             return this.isCostCenterDefined && this.isDPSelected;
        //         } else {
        //             return true;
        //         }
        //
        //     }
        // }
        //
        // if (this.isSapType === SAP_TYPE.STANDARD) {
        //     return this.isPaymentDefined;
        // } else if (this.isSapType === SAP_TYPE.DECISION_POINT) {
        //     return this.isPaymentDefined && this.isDPSelected;
        // } else {
        //     return true;
        // }
        return true;
    }



    async onApply() {

        // TODO compare with the original addresses
        this.hideModal(this.editableAddressList);
        // if (this.isWBSOrIODefined === false) {
        //     this.toastr.warning('Either IO or WBS must be provided.\n' +
        //       'Please input a value.');
        //     return;
        // }
        //
        //
        // this.closeWithWarning = false;
        // this.inProgress = true;
        //
        // if (this.creditCard) {
        //     if (this.creditCard.isValid) {
        //         if (this.saveCard) {
        //             await  this.paymentService.saveCreditCardForFuture(this.order.id, this.creditCard.label).toPromise();
        //         }
        //     } else {
        //         this.inProgress = false;
        //         return this.toastr.warning('Cannot link the order with unverified card.')
        //     }
        // }
        //
        // // validate internal_order and wbs length
        // if (this.internalOrder && this.internalOrder !== '0') {
        //     if (this.internalOrder.length > INTERNAL_ORDER_LENGTH) {
        //         this.inProgress = false;
        //         return this.toastr.warning(`Internal Order should not exceed ${INTERNAL_ORDER_LENGTH} symbols.`);
        //     }
        // }
        //


        // const orderAttributes = {
        //     cart_name: this.orderName
        // };
        //
        // if (this.isSapType === SAP_TYPE.DECISION_POINT &&  this.dpSelection && this.dpSelection.id !== '0') {
        //     orderAttributes['decision_point_id'] = this.dpSelection.id;
        //     this.order.attr_decision_point_id = this.dpSelection.id;
        //     orderAttributes['cart_name'] = this.dpOrderName;
        //     this.order.attr_cart_name = this.dpOrderName;
        //     this.order.decisionPointData = {
        //         id: this.dpSelection.id,
        //         label: this.dpSelection.label,
        //         wslr_count: this.dpSelection.wslr_count,
        //         store_count: this.dpSelection.store_count
        //     }
        //
        //     orderAttributes['notes'] = this.dpOrderName;
        //     if (!this.poNumber) {
        //         this.poNumber = this.order.attr_cart_name;
        //     }
        // }
        // if (this.isTaxExemptAllowed) {
        //     orderAttributes['tax_exempt'] = this.taxExempt.toString();
        // }
        //
        // this.order.attr_po_num = this.poNumber;
        // orderAttributes['po_num'] = this.poNumber;
        //
        //
        // orderAttributes['business_unit'] = this.costCenter.business_unit;
        // orderAttributes['company_billing_entity'] = this.companyBillingEntity;
        // orderAttributes['vendor_number'] = this.vendorNumber;
        // orderAttributes['internal_order'] = !!this.internalOrder ?  this.internalOrder.trim() :  '';
        // orderAttributes['wbs'] = !!this.wbs  ? this.wbs.trim() : '';
        //
        // if (isEmptyArray(this.presetAddresses)) {
        //     orderAttributes['preset_addresses'] = [];
        // } else {
        //     orderAttributes['preset_addresses'] = this.presetAddresses.map( address => {
        //         if (!address.entity_id) {
        //             return {id: address.id};
        //         }
        //         return {id: address.id, entity_id: address.entity_id};
        //     });
        // }
        //
        // if (this.isAttentionChanged) {
        //     this.order.attr_attention = this.attention || '';
        //     orderAttributes['attention'] = this.order.attr_attention;
        // }
        //
        // const retOrder: Order = await this.orderService.setOrderAttributes(this.order, orderAttributes).toPromise();
        // this.inProgress = false;
        // if (retOrder && !retOrder.error) {
        //     this.order = retOrder;
        //
        //     if (this.isNewOrder) {
        //         const message = 'New ' + (this.order.type === ECOMMERCE_TYPE ? 'cart' : 'order') + ' created.';
        //         this.toastr.success(message);
        //     } else {
        //         this.toastr.success('Cart details updated.');
        //     }
        //
        //     this.hideSapModal(retOrder);
        // }
    }



    // create new wholesaler

    close() {
        this.hideModal();
    }


    // preset addresses can be editable only for new orders
    get canEditPresetAddresses(): boolean {
        return true;
    }

    onAddressListChanged(addresses: Address[]) {
        this.editableAddressList = [...addresses];
    }
}

