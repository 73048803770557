import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-remove-item',
  templateUrl: './remove-item-modal.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RemoveItemModalComponent implements OnInit {

  options: {
    label: 'ARE YOU SURE YOU WANT TO REMOVE THE ITEM?',
    removeButton: 'Remove'
    closeButton: 'Close'
  }

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
  }


  close(result = false) {
    this.activeModal.close(result)
  }

  delete() {
    this.close(true);
  }

  get label(): string  {
    return this.options.label ? this.options.label : 'ARE YOU SURE YOU WANT TO REMOVE THE ITEM?';
  }

  get closeLabel(): string  {
    return this.options.closeButton ? this.options.closeButton : 'Close';
  }

  get removeLabel(): string  {
    return this.options.removeButton ? this.options.removeButton : 'Remove';
  }

}
