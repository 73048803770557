<footer class="footer" [class.fixed-bottom]="settings.theme.footerIsFixed">
  <div class="container">
    <div class="footer__logo">
    </div>
    <div class="footer__links">
<!--      <span><a class="link font-6 color-primary-dark footer__link separator" href="javascript:void(0)" (click)="showTermsAndConditions()">Terms &amp; Conditions</a></span>-->
      <span><a class="link font-6 color-primary-dark footer__link" href="https://www.hhglobal.com/privacy-policy" target="_blank">Privacy and Tracking Policy</a></span>
    </div>
    <div class="footer__copywright">
      <span class="powered-by color-primary-dark">Powered By</span>
      <img class="iw-footer" src="assets/img/logos/hh-logo.png">
      <span class="underline color-primary-dark">&copy;{{ year }} HH Global, Inc.</span>
    </div>
  </div>
</footer>
