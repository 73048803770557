import {BaseModel} from './base.model';

export class ProductInfo  extends  BaseModel {

    imageUrl: string;
    label: string;
    path: string
    slug: string;
    window_name;
    sku_number: string;
    supplier_id: string;

    constructor(data) {
        super(data);

        if (data.image) {
            this.imageUrl = data.image.url;
        }
    }


    get isOnDemand(): boolean {
        return this.window_name === 'On Demand';
    }
}
