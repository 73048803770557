import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import { hidePreloader } from '../../shared/utils';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotFoundComponent implements OnInit, AfterViewInit {

  searchStore: any;
  isSearching = false;
  searchSuggestionsIsOpen = false;

  constructor(private searchService: SearchService) {
  }

  ngOnInit() {
    // this.searchService.globalTerm = '';
  }

  searchResult(): void {
      // this.router.navigate(['pages/search']);
  }

  ngAfterViewInit() {
      hidePreloader();
  }

  showSearchSuggestions() {
    this.searchSuggestionsIsOpen = true;
  }

  hideSearchSuggestions() {
    this.searchSuggestionsIsOpen = false;
  }


}
