import { Component, ViewEncapsulation } from '@angular/core';
import { CostCenter } from '../../../models/cost-center.model';
import { PaymentMethod } from '../../../models/payment-method.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PaymentService} from '../../../services/payments.service';



@Component({
    selector: 'app-select-sap-modal',
    templateUrl: './select-sap.component.html',
    styleUrls: ['./select-sap.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SelectSapComponent  {

    costCenter: CostCenter;
    isValid = true;


    constructor(
        private activeModal: NgbActiveModal, private paymentService: PaymentService) {
    }



    // public methods

    // hide and reset modal dialog
    hideSapModal(result) {
        this.activeModal.close(result);
    }

    selectedSap(sap: PaymentMethod) {
        this.isValid = false;

        if (sap instanceof  CostCenter) {
            this.paymentService.verifySap(sap, 'Selected payment method is invalid').subscribe( result => {
                this.isValid = result.success;
                if (this.isValid) {
                    this.costCenter = sap;
                }
            });
        }
    }

    // apply sap
    onApplyPayment() {
        this.hideSapModal(this.costCenter);
    }

    close() {
        this.hideSapModal(null);
    }

    // getters
    get canApply(): boolean {
        return this.isPaymentDefined;
    }

    // private methods
    private get isPaymentDefined(): boolean {
        return !!this.costCenter && this.isValid;
    }




}

