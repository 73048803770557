<div class="modal-md terms-modal">
    <div class="modal-header state">
        <button type="button" (click)="close()" class="btn btn-icon close" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
        <div class="scroll-container">
            <div [innerHTML]="content | safeContent"></div>
            <div class="mt-3 text-align-center" *ngIf="!withAcceptance"><button class="btn btn-outline-primary" (click)="close()">Close</button></div>
            <div class="my-3 text-align-center control-buttons" *ngIf="withAcceptance">
                <button type="button" class="btn btn-outline-primary" (click)="logout()">Log Out</button>
                <button type="button" class="btn btn-primary" (click)="acceptTerms()">Accept Terms &amp; Conditions</button>
            </div>
        </div>
    </div>
</div>
