<div  class="credit-card--label--modal">
    <div class="modal-header state">
        <button type="button" (click)="close()" class="btn btn-icon close" aria-label="Close"><i class="fal fa-times" aria-hidden="true"></i></button>
    </div>
    <div class="modal-body">

        <h1 class="pb-4 text-center">ARE YOU SURE YOU WANT TO REMOVE THIS PAYMENT METHOD?</h1>
        <hr class="pb-4"/>
        <button (click)="close()" class="btn btn-outline-primary float-left" type="button" name="Remove Item">Keep Item</button>
        <button (click)="deleteItem()"
                [disabled]="performingAction"
                class="btn btn-primary float-right" type="button" name="Remove Item">
            <i *ngIf="performingAction" class="fal fa-sync rotate"></i>&nbsp;Remove Item</button>
    </div>
</div>
