<ng-container *ngTemplateOutlet="showMobileNav ? mobile : desktop"></ng-container>

<ng-template #desktop>
    <header class="header" *mobxAutorun [style.height.px]="headerOffset">
        <div #stickyHeader [ngClass]="{'top-sticky-header': this.fixedHeader}" >
            <app-header-callout *ngIf="headerCalloutVisible" [statistics]="statistics" [orderWindow]="activeOrderWindow"></app-header-callout>
            <nav class="nav">
                <div class="container">
                    <a class="logo" routerLink="/">
                        <img class="logo__svg" [src]="logoUrl">
                    </a>

                    <app-header-search [pageType]="currentPage"></app-header-search>

                    <div class="nav__utility">
                        <ul>
                            <li *ngIf="canSeeNotifications">
                                <a class="btn btn-icon" placement="bottom" routerLink="/notifications">
                                    <i class="fal fa-envelope" aria-hidden="true"></i>
                                    <span *ngIf="unreadNotificationCount > 0" class="badge"></span>
                                </a>
                            </li>
                            <li class="nav__utility__dropdown__trigger" (mouseenter)="dropdownMouseEnter($event)" (mouseleave)="dropdownMouseLeave($event)">
                                <app-user-menu></app-user-menu>
                            </li>
                            <li *ngIf="canSeeFavorites" class="nav__utility__dropdown__trigger">
                                <button  class="btn btn-icon" title="Favorites" (click)="navigateToFavorites($event)"><i class="fal fa-heart"></i></button>
                            </li>
                            <li *ngIf="canOder" class="nav__utility__dropdown__trigger" (mouseenter)="dropdownMouseEnter($event)" (mouseleave)="dropdownMouseLeave($event)">
                                <button class="btn btn-icon shopping-cart" placement="bottom">
                                    <i class="fal fa-shopping-cart" aria-hidden="true"></i>
                                    <span *ngIf="totalOrderItems">{{totalOrderItems}}</span>
                                </button>
                                <div class="nav__utility__dropdown right-align orders-box uppercase font-6">
                                    <div class="arrow"></div>
                                    <div class="row ">
                                        <div *ngIf="canSeeBuyingWindows" class="column col-6">

                                            <div *ngIf="activeOrderWindow && orderService.activeBWOrder && orderService.activeBWOrder.item_count > 0" class="nav__main__dropdown__summary">
                                                <h4>{{orderWindowLabel}}</h4>
                                                <p>Order Summary
                                                    <span class="price font-1 font-weight-bold color--primary-blue"><app-currency-price [price]="orderService.activeBWOrder.totalCost" [currency]="orderService.activeBWOrder.currency" [solelySymbol]="true"></app-currency-price></span>
                                                </p>
                                                <p>Items
                                                    <span class="font-1 font-weight-bold color--primary-blue">{{ orderService.activeBWOrder.item_count }}</span>
                                                </p>
                                                <button (click)="navigateToActiveBWOrder()" class="btn btn-primary">View Order</button>
                                            </div>

                                            <div *ngIf="activeOrderWindow && (!orderService.activeBWOrder || orderService.activeBWOrder.item_count == 0)" class="nav__main__dropdown__summary">
                                                <h4>{{orderWindowLabel}}</h4>
                                                <p>Your Order Summary is empty.</p>
                                                <p style="flex: 1 1">You should probably fix that.</p>
                                                <button (click)="navigateToAllBWPrograms()" class="btn btn-outline-primary">Programs</button>
                                            </div>

                                            <div *ngIf="!activeOrderWindow" class="nav__main__dropdown__summary">
                                                <h4>{{orderWindowLabel}}</h4>
                                                <p>There is no active {{orderWindowLabel}} open at this time.</p>
                                                <p *ngIf="statistics?.ow_upcoming" style="flex: 1 1">Please check back on {{ statistics.ow_upcoming.start_at.format('MMMM D, Y') }}</p>
                                            </div>
                                        </div>
                                        <div class="column" [ngClass]="{'col-6': canSeeBuyingWindows, 'col-12': !canSeeBuyingWindows}">
                                            <div *ngIf="orderService.activeODOrder && orderService.activeODOrder.item_count > 0; else fixODOrder" class="nav__main__dropdown__summary border-none">
                                                <h4>{{onDemandLabel}}</h4>
                                                <p>Shopping Cart
                                                    <span class="price font-1 font-weight-bold color--primary-blue"><app-currency-price [price]="orderService.activeODOrder.totalCost" [currency]="orderService.activeODOrder.currency" [solelySymbol]="true"></app-currency-price></span>
                                                </p>
                                                <p>Items
                                                    <span class="font-1 font-weight-bold color--primary-blue">{{ orderService.activeODOrder.item_count }}</span>
                                                </p>
                                                <button (click)="navigateToActiveODOrder()" class="btn btn-primary">Checkout</button>
                                            </div>
                                            <ng-template #fixODOrder>
                                                <div class="nav__main__dropdown__summary border-none">
                                                    <h4>{{onDemandLabel}}</h4>
                                                    <p>Your Shopping Cart is empty</p>
                                                    <p style="flex: 1 1">You should probably fix that.</p>
                                                    <button (click)="onSelectModal('onDemand')" class="btn btn-outline-primary" routerLink="/on-demand/products">Shop</button>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <a class="link small mt-3 view-orders" (click)="onSelectModal('buyingWindow')" routerLink="/cart">
                                        VIEW ALL ORDERS / CARTS
                                    </a>

                                </div>
                            </li>
                            <li class="nav__utility__dropdown__trigger" (mouseenter)="dropdownMouseEnter($event)" (mouseleave)="dropdownMouseLeave($event)">
                                <button class="btn btn-icon" placement="bottom">
                                    <i class="fal fa-question" aria-hidden="true"></i>
                                </button>
                                <div class="nav__utility__dropdown right-align">
                                    <app-user-guide></app-user-guide>
                                </div>


                            </li>
                        </ul>
                    </div>
                </div>

            </nav>
            <a [ngClass]="{'is-active': mobileNavActive}" href="#main-menu-toggle" class="mobile__menu__backdrop" tabindex="-1" aria-hidden="true" hidden></a>
            <!--            links -->
            <div class="links-container">
                <ul class="nav__main">
                    <li *ngIf="canSeeBuyingWindows" class="nav__main__dropdown__trigger"
                        [ngClass]="{'disabled': !activeOrderWindow, 'link--disabled': isRestricted}"
                        [class.is-active]="buyingWindowMenuActive"
                        role="link"
                        (mouseenter)="showMenu(buyingWindowType)"
                        (mouseleave)="hideMenu(buyingWindowType)"
                    >
                        <span class="nav__main__dropdown__title click font-4" [ngClass]="{'active': onBuyingWindowPage, 'on-hover': buyingWindowMenuActive}">{{orderWindowLabel}}</span>
                        <app-header-navigation [type]="buyingWindowType"
                                               [visible]="buyingWindowMenuActive"
                                               (close)="hideMenu(buyingWindowType)"></app-header-navigation>
                    </li>

                    <li class="nav__main__dropdown__trigger" role="link"
                        (mouseenter)="showMenu(onDemandType)"
                        (mouseleave)="hideMenu(onDemandType)"
                        [ngClass]="{'link--disabled': isRestricted}"
                        [class.is-active]="ondemandMenuActive"
                    >
                        <span class="nav__main__dropdown__title click font-4" [ngClass]="{'active': onDemandPage, 'on-hover': ondemandMenuActive}">{{onDemandLabel}}</span>
                        <app-header-navigation [type]="onDemandType"
                                               [visible]="ondemandMenuActive"
                                               (close)="hideMenu(onDemandType)"></app-header-navigation>
                    </li>

<!--                    <li *ngIf="clearanceUrl" class="nav__main__dropdown__trigger" role="link" (click)="navigateToClearance()">-->
<!--                        <span class="nav__main__dropdown__title click font-4">Clearance</span>-->
<!--                    </li>-->

<!--                    <li *ngIf="truckGraphicsUrl" class="nav__main__dropdown__trigger" role="link" (click)="navigateToTruckGraphics()">-->
<!--                        <span class="nav__main__dropdown__title click font-4">Truck Graphics</span>-->
<!--                    </li>-->

                    <li *ngIf="canSeeReports" class="nav__main__dropdown__trigger" routerLink="/reports" role="link">
                        <span class="nav__main__dropdown__title click font-4">Reports</span>
                    </li>
                </ul>
            </div>
        </div>
    </header>
</ng-template>

<ng-template #mobile>
    <header class="mobile">
        <app-header-callout *ngIf="headerCalloutVisible" [statistics]="statistics" [orderWindow]="activeOrderWindow"></app-header-callout>
        <div class="d-flex align-items-center justify-content-between">
            <a class="ml-2" routerLink="/">
                <img class="logo__svg" [src]="logoUrl" alt='Logo'>
            </a>

<!--            <div *ngIf="!isMobile" style="flex: 1 1">-->
<!--                <div style="max-width: 400px; margin: 0 auto">-->
<!--                    <app-header-search [pageType]="currentPage"></app-header-search>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="search&#45;&#45;container" *ngIf="isMobile">-->
<!--                <form *ngIf="showSearchButton" class="app-search mobile" action="/search">-->
<!--                    <input type="submit" value="Search" class="form-control" (click)="toggleGlobalSearch($event)">-->
<!--                    <i class="far fa-search"></i>-->
<!--                </form>-->
<!--            </div>-->

            <nav>
                <button *ngIf="!isRestricted" class="btn btn-icon with-border with-light-shadow ml-2" title="Favorites" (click)="navigateToFavorites($event)"><i class="far fa-heart"></i></button>
                <button  class="btn btn-icon with-border with-light-shadow mx-2" title="Shopping Cart" (click)="mobileCartMenuVisible = !mobileCartMenuVisible">
                    <i class="far" [ngClass]="{'fa-shopping-cart' : !mobileCartMenuVisible, 'fa-times' : mobileCartMenuVisible}"></i>
                </button>
                <button  class="btn btn-icon with-border with-light-shadow mr-2" title="Links" (click)="mobileNavigationMenuVisible = !mobileNavigationMenuVisible">
                    <i class="far" [ngClass]="{'fa-bars' : !mobileNavigationMenuVisible, 'fa-times' : mobileNavigationMenuVisible}"></i>
                </button>
            </nav>
        </div>
        <app-global-search *ngIf="showSearchInput" [showBackdrop]="showSearchButton" (closed)="onSearchInputClosed()"></app-global-search>
    </header>
    <div class="position-relative">
        <app-mobile-cart-menu [visible]="mobileCartMenuVisible" (closed)="mobileCartMenuVisible = false;"></app-mobile-cart-menu>

        <app-mobile-navigation-menu
         [visible]="mobileNavigationMenuVisible"
         (onBWProductsClicked)="navigateToBWProducts()"
         (onBWProgramsClicked)="navigateToAllBWPrograms()"
         (onODProgramsClicked)="navigateToODPrograms()"
         (onODProductsClicked)="navigateToODProducts()"
         (closed)="mobileNavigationMenuVisible = false"
        ></app-mobile-navigation-menu>
    </div>
</ng-template>

