import { Component, Input , NgZone } from '@angular/core';
import { Router }                    from '@angular/router';

@Component({
    selector: 'app-public-api',
    template: '<div></div>',
})
export class PublicApiComponent
{
    public constructor(private zone: NgZone, private router: Router) {
        window['publicAPI'] = {
            zone: this.zone,
            route: (value) => this.routeApp(value),
            component: this,
        };
    }

    // usage: window.publicAPI.zone.run(() => { window.publicAPI.route('/buying-window/products/24'); });
    public routeApp(value: any): any {
        this.router.navigate([value]);
    }
}
