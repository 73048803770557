import { BaseModel } from './base.model';
import { AppDate } from './date.model';

export class OrderWindow extends BaseModel {
    public id: number;
    public entity_id: number;
    public access_data: Object;
    public label: string;
    public start_at: AppDate;
    public end_at: AppDate;
    public updated_at: AppDate;
    public active: boolean;

    constructor(data) {
        super(data, {
            num: ['id', 'entity_id'],
            date: ['start_at', 'end_at', 'updated_at'],
            bool: ['active'],
        });
    }
}
