import {AppDate} from './date.model';
import {BaseModel} from './base.model';
import {CreditCard} from './credit-card.model';
import {TransactionState, transactionStateDescription} from '../constants/transaction-state';
import {OrderItem} from './order-item.model';
import {OrderItemDelivery} from './order-item-delivery.model';
import {Order} from './order.model';
import {Product} from './product.model';

export class Transaction  extends BaseModel {
    public id: number;
    public order_id: number;
    public item_id;
    public quantity: number;
    public amount: number;
    public product_id: number;
    public shipment_id: number;
    public product_label: string;
    public product_media: string;
    public state: TransactionState;
    public credit_card: CreditCard;
    public created_at: AppDate;
    public transaction_tag: string;

    public orderItem: OrderItem;
    public delivery: OrderItemDelivery;


    constructor(data: any) {
        super(data, {
            num: ['id', 'order_id', 'item_id', 'quantity', 'amount', 'product_id', 'shipment_id'],
            date: ['created_at']
        });


        if (data) {
            Object.assign(this, data);
        }
    }


    get stateDescription(): string {
        return transactionStateDescription(this.state)
    }

    setOrderInfo(order: Order) {
        this.orderItem = order.findOrderItem(this.item_id);
        if (this.orderItem) {
            this.delivery = this.orderItem.findDelivery(this.shipment_id);
            if (this.delivery) {
                this.delivery.orderAttention = order.attr_attention
            }
        }
    }

    get product(): Product {
        return this.orderItem ? this.orderItem.product : null;
    }

    get hasFailed(): boolean {
        return this.state === TransactionState.FAILED;
    }
}
