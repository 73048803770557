<div class="modal-md order-settings">
    <div class="modal-header state">
        <h1 class="modal-title text-truncate uppercase full-width text-align-center" id="modal-label">Customization</h1>
        <button type="button" (click)="close()" class="btn btn-icon close" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body customization">
        <ng-container *ngIf="customizations.length > 0; else noCustomizations">

            <div *ngIf="isMaxQuantityReached">
                <p class="text-danger">Maximum available quantity is {{availableQuantity}}</p>
            </div>
            <div *ngFor="let customItem of customizations, let idx = index" class="customization--item">
                <div class="d-flex flex-vertical quantity-container" *ngIf="!hasVariations">
                    <label>QTY:</label>
                    <input (change)="onQuantityChanged(customItem)"  class="digit-input"
                           type="number" digitOnly min="0" [(ngModel)]="customItem.custom_qty" [min]="customItem.custom_min_qty">
                    <span class="font-5 uppercase" [ngClass]="{'red': customItem.error}">Min QTY: {{customItem.custom_min_qty}}</span>

                    <a (click)="removeCustomization(idx)" class="link link-underline color-red mt-4" href="javascript:void(0)">Remove</a>

                </div>
                <div class="labels-container">
                    <div *ngFor="let label of customItem.custom_text_fields" class="input-group">
                        <label>{{label.label}} ({{label.required ? 'REQUIRED, ' : ''}}{{label.max_char }} characters max)</label>
                        <input type="text" [(ngModel)]="label.text" [maxLength]="label.max_char" (change)="onTexChanged(label)">
                        <span *ngIf="label.error" class="text-danger uppercase">text is required</span>
                    </div>
                </div>


            </div>

        </ng-container>


    </div>
    <div class="modal-footer" >
        <div class="d-flex buttons-container" [ngClass]="{'justify-content-between': canAddCustomization, 'justify-content-end': !canAddCustomization}">
            <button *ngIf="canAddCustomization" (click)="addCustomization()" class="btn btn-outline-primary">Add Customization</button>
            <button (click)="done()" class="btn btn-primary">OK</button>
        </div>
    </div>
</div>


<ng-template #noCustomizations>
    <div style="min-height: 5rem">
        <p class="font-3 text-danger text-align-center">Customizations will be removed.</p>
    </div>
</ng-template>

