import {Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AuthService} from '../../../../services/auth.service';
import {ECOMMERCE_TYPE, PREORDER_TYPE} from '../../../../constants/order-types';
import {Pages} from '../../../../enums/pages.enum';
import {SearchService} from '../../../../services/search.service';
import {OrderService} from '../../../../services/order.service';
import {Router} from '@angular/router';
import {MultiTenantService} from '../../../../services/multi-tenant.service';

const MIN_SYMBOLS_TO_SEARCH = 3;

@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderSearchComponent implements OnInit {

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  orderTypes = [];


  // selectedOrderType = ECOMMERCE_TYPE;
  searchText = '';
//  currentPage: Pages;

  @Input() set pageType(val: Pages) {
    // this.currentPage = val;
    //
    // if ( this.currentPage === Pages.BuyingWindowPage) {
    //   this.selectedOrderType = PREORDER_TYPE;
    // } else if ( this.currentPage === Pages.OnDemand) {
    //   this.selectedOrderType = ECOMMERCE_TYPE;
    // }
  }

  constructor(private  authService: AuthService, private searchService: SearchService,
              private orderService: OrderService,
              private multiTenantService: MultiTenantService,
              private router: Router) {

    this.orderTypes = [
      {id: ECOMMERCE_TYPE, label: this.multiTenantService.onDemandLabel},
      {id: PREORDER_TYPE, label: this.multiTenantService.orderWindowLabel}
    ];
  }

  ngOnInit(): void {
  }

  get canChooseOrderType(): boolean {
    return false;
    // return this.authService.canBuyingWindow;
  }


  selectOrderType(orderType) {
    this.orderService.currentOrderType = orderType.id;
    // this.selectedOrderType = orderType.id;
    this.searchInput.nativeElement.focus();
    this.search();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.search();
    }

    if (e.key === 'Escape') {
      // exit from search mode
      this.searchText = '';
    }
  }

  search() {

    if (this.searchText && this.searchText.length >= MIN_SYMBOLS_TO_SEARCH) {
      this.searchService.globalTerm = this.searchText;

      this.searchService.currentBuyingWindowId = this.orderService.currentOrderWindowId;

      this.searchService.productsSearch().subscribe( () => {});
      if (!this.isSearchResultPage) {
        this.router.navigate(['/search-results']).then(() => {});
      }
    }

    if (this.searchText === '') {
      this.searchService.clearSearchResults();
    }

  }

  get isSearchResultPage(): boolean {
    return this.router.url.indexOf('search-result') !== -1;
  }


  get selectedOrderType(): string {
    return this.orderService.currentOrderType || ECOMMERCE_TYPE;
  }

  set selectedOrderType(val: string) {

  }

  clearSearch() {
    this.searchText =  '';
  }
}
