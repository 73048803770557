import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

import {Customization, CustomizationLabel} from '../../../interfaces/customization';
import { getCustomizationsTotal, validateCustomization } from '../../../shared/utils';

@Component({
    selector: 'product-customization',
    templateUrl: './product-customization.component.html',
    styleUrls: ['./product-customization.component.scss']
})
export class ProductCustomizationComponent {

    private productCustomization: Customization;
    customizations: Customization[] = [];
    isValid = true;
    isMaxQuantityReached = false;
    availableQuantity: number;
    hasVariations = false;

    set customizationData(val:
          {maxQTY: number; productCustomization: Customization, deliveryCustomizations: Customization[], hasVariations: boolean}) {
        this.productCustomization = val.productCustomization;
        this.availableQuantity = val.maxQTY;
        this.hasVariations = val.hasVariations === true;

        if (Array.isArray(val.deliveryCustomizations) && val.deliveryCustomizations.length > 0) {
            this.customizations = _.cloneDeep(val.deliveryCustomizations);
        } else {
            this.addCustomization();
        }
    }

    constructor(private activeModal: NgbActiveModal) {}

    close(customizations?: Customization[]) {
        this.activeModal.close(customizations);
    }



    addCustomization() {
        const item =  _.cloneDeep(this.productCustomization);
        item.custom_qty = item.custom_min_qty || 0;
        this.customizations = [...this.customizations, item];
    }

    removeCustomization(index: number) {
        if (this.customizations.length === 0) {
            return;
        }
        this.customizations =  [...this.customizations.slice(0, index), ...this.customizations.slice(index + 1)];

        this.validateMaxQTY();

    }

    done() {

        this.validateFields(true);
        this.validateMaxQTY();


        if (this.isValid && !this.isMaxQuantityReached) {

            this.customizations.forEach( item => {
                delete item.touched;
                delete item.error;
                item.custom_text_fields.forEach( text => {
                    delete text.error;
                    delete text.touched;
                })
            });


            this.close(this.customizations)
        }

    }

    onQuantityChanged(customItem: Customization) {
        customItem.touched = true;

        this.validateFields();
        this.validateMaxQTY();
    }

    onTexChanged(label: CustomizationLabel) {
        label.touched = true;
        label.text = label.text.trim();
        this.validateFields();
    }


    private validateMaxQTY() {
        this.isMaxQuantityReached = Number.isInteger(this.availableQuantity) &&
            this.availableQuantity < getCustomizationsTotal(this.customizations);
    }

    private validateFields(force = false) {
        this.isValid = validateCustomization(this.customizations, force, this.hasVariations);
    }


    get canAddCustomization(): boolean {
        return this.productCustomization.allow_multiple && !this.hasVariations;
    }
}

