import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {forkJoin, Observable, of} from 'rxjs';
import { AppSettings } from '../app.settings';
import { ToastrService } from 'ngx-toastr';


import * as _ from 'lodash';
import { Response } from '../models/response.model';
import { Order } from '../models/order.model';
import { Product } from '../models/product.model';
import { ProductCatalog } from '../models/product-catalog.model';
import { FilterGroup } from '../models/filter-group.model';
import { ProductService } from './product.service';
import { SearchStoreInstance } from '../stores/search.store';
import {escapeRegExp, isEmptyArray} from '../shared/utils';
import { GoogleAnalytics } from './google-analytics.service';
import {action, computed, observable} from 'mobx';
import {AutoShipService} from './auto-ship.service';
import {AutoShipItem} from '../models/auto-ship.model';
import {BRANCH_TYPE, CUSTOM_TYPE, ECOMMERCE_TYPE, PREORDER_TYPE, SUMMARY_TYPE} from '../constants/order-types';
import {OrderService} from './order.service';
import {IOrderSuggestion} from '../interfaces/order-suggestion.type';
import {AuthService} from './auth.service';
import {PageService} from './page.service';
import {OrderHistory} from '../models/order-history.model';
import {AppDate} from '../models/date.model';
import {AvailableOrderWindow} from '../interfaces/order-window.interface';
import {MultiTenantService} from './multi-tenant.service';
import {ConfigService} from './config.service';

const DEFAULT_LIMIT = 20;
const MIN_SYMBOLS_TO_SEARCH = 3;

const ORDER_SEARCH_LIMIT = 10;

export interface OrderCategory  {
    type: string;
    label: string;
    total: number;
    orders: OrderHistory[];
    selected: boolean;
    fetching: boolean;
    sorting: boolean
}

export interface SearchParam  {
    name: string;
    label: string;
    value: string;
    selected: boolean
}

export const ALL_SEARCH_OPTIONS: SearchParam[] = [
    {name: 'order_id', label: 'Order #',  value: '', selected: false},
    {name: 'sku_number', label: 'Product #', value: '', selected: false},
    {name: 'product_name', label: 'Product Name', value: '', selected: false},
    {name: 'window_id', label: 'Window Name', value: '', selected: false},
    {name: 'purchaser', label: 'Purchaser',  value: '', selected: false},
    {name: 'cart_name', label: 'Order Name', value: '', selected: false},
    {name: 'sap', label: 'SAP #',  value: '', selected: false},
    {name: 'tracking', label: 'Tracking #',  value: '', selected: false},
];


const ORDER_HISTORY_TYPES = {
    PREORDER_TYPE: 'OW',
    ECOMMERCE_TYPE: 'OD',
    SUMMARY_TYPE: 'AB',
    BRANCH_TYPE: 'BR',
    CUSTOM_TYPE: 'AS'
}

@Injectable()
export class SearchService extends BaseService {

    public searchParams: SearchParam[] = []

    public selectedSearchParam: any;

    @observable
    public searchingOrders = false;

    @observable
    public orderCategories: OrderCategory[] = [];


    @observable recentOnDemandOrders: OrderHistory[] = [];

    public globalTerm: string;
    public currentBuyingWindowId = 0;
    public selectedOrderType: string;

    @observable
    public foundProducts: Product[] = [];

    @observable
    totalProducts = 0;

    private searchOffset = 0;

    @observable
    public searchingProducts = false;

    @observable
    public sortedColumn = {column: 'order_id', direction: 'DESC'};

    @observable searchPerformed  = false;
    @observable initialOrderSearchPerformed = false;


    @computed
    public get canLoadMore(): boolean {
        if (this.searchingProducts) {
            return false;
        }
        return this.totalProducts > this.foundProducts.length;
    }

    // private foundProductIdCache = new CacheModel(TEN_MINUTES);


    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService,
        private productService: ProductService,
        private orderService: OrderService,
        private autoShipService: AutoShipService,
        private googleAnalytics: GoogleAnalytics,
        private authService: AuthService,
        private pageService: PageService,
        private multiTenantService: MultiTenantService,
        private config: ConfigService
    ) {
        super('/search', http, appSettings, toastr);
        this.initOrderSearch();
    }

    @action
    setTotalProducts(val: number) {
        this.totalProducts = val;
    }

    @action
    setFoundProducts(val: Product[], append = false) {
        if (!val) {
            return;
        }
        if (append) {
            this.foundProducts = [...this.foundProducts, ...val];
        } else {
            this.foundProducts = val;
        }

    }


    public productsSearch(options: {limit?: number, offset?: number, autoShip?: boolean, loadMore?: boolean,
        refresh?: boolean, entity_id?: boolean} = {})
        : Observable< (Product| AutoShipItem)[]> {

        if (!this.globalTerm || this.globalTerm.length < MIN_SYMBOLS_TO_SEARCH) {
            return of([]);
        }

        this.totalProducts = 0;
        if (!options.loadMore) {
            this.foundProducts = [];
            this.searchOffset = 0;
        }
        const params = {
            match: this.globalTerm,
            window_id: this.currentBuyingWindowId
        };
        if (options.autoShip) {
            params['autoship'] = true;
        }

        if (options.entity_id) {
            params['entity_id'] = options.entity_id;
        }

        let limit = options.limit || DEFAULT_LIMIT;
        let offset = options.offset || 0;
        if (options.loadMore) {
            offset = this.searchOffset;
        }

        if (options.refresh) {
            limit = this.foundProducts.length;
            offset = 0;
        }

        this.searchingProducts = true;
        this.searchPerformed = false;
        return this.http.post<HttpResponse<Response>>(this.apiURL, params,
            {observe: 'response',
                headers: new HttpHeaders({'Range': `${limit}-${offset}`, 'x-xsrf-token': ''})
            }).pipe( switchMap(httpResponse => {
                const count = parseInt(httpResponse.headers.get('X-Count'), 10);
                this.setTotalProducts(count);
                this.searchOffset += DEFAULT_LIMIT;
                const response = new Response(httpResponse.body);
                // this.foundProductIdCache.set(key, {count, ids: response.data});
                return this.fetchProductsAndAutoships(response.data, options);
                }),
            catchError(this.handleError('SearchService::productsSearch', [])),
            tap( () => {
                this.searchingProducts = false;
                this.searchPerformed = true;
            })
        );
    }

    public isSearchingProducts(): Observable<boolean> {
        return this.toRx('searchingProducts');
    }


    private fetchProductsAndAutoships(data,
        options: {limit?: number, offset?: number, autoShip?: boolean, loadMore?: boolean, refresh?: boolean} = {}):
        Observable<(Product | AutoShipItem) []> {
        if ( Array.isArray(data)) {
            const productsIds = data.filter( i => i.type === 'product').map( i => i.id);
            const autoShipIds = options.autoShip ? data.filter( i => i.type === 'autoship').map( i => i.id) : [];

            return forkJoin([this.getProductsByIds(productsIds, options.loadMore), this.getAutoShipsByIds(autoShipIds)]).pipe(
                map( ([products, autoships]) => {
                    const result = [];
                    data.forEach( i => {
                        if (i.type === 'product') {
                            const product = products.find( p => p.id === i.id);
                            if (product) {
                                result.push(product);
                            }
                        }
                        if (options.autoShip && i.type === 'autoship') {
                            const autoship = autoships.find( a => a.id === i.id);
                            if (autoship) {
                                result.push(autoship);
                            }
                        }
                    });

                    return result;
                })
            );
        }

        return of([]);

    }

    private getProductsByIds(ids: number[], append = false): Observable<Product[]> {
        return this.productService.getProductsByIds(ids).pipe(
            tap(products => {
                if (Array.isArray(products) && products.length > 0) {
                    this.googleAnalytics.viewSearchResults(this.globalTerm, products);
                }
                this.setFoundProducts(products, append);
            })
        );
    }

    private getAutoShipsByIds(ids: number[]): Observable<AutoShipItem[]> {
        return this.autoShipService.fetchByIds(ids);
    }

    highlight(str) {

        if (!this.globalTerm) {
            return ;
        }

        const safeStr = str.replace(/&amp;/gm, '&');
        const searchPattern = escapeRegExp(this.globalTerm); // escape special characters
        const pattern = new RegExp('(' + searchPattern + ')', 'gi');
        return safeStr.replace(pattern, match => `<strong>${match}</strong>`);
    }

    orderSuggestions(term: string, filter: any = null): Observable<IOrderSuggestion> {
        const resultKey: string = btoa(JSON.stringify({k: term, f: filter})).replace('=', '');

        if (this.cache.has(resultKey)) {
            return of(this.cache.get(resultKey));
        }

        return this.http.post<Response>(this.apiURL + '/order-suggestions', {term: term, filter: filter},
            {headers : this.xsrfTokenHeader})
            .pipe(
                map(response => {
                    const result = response.data;

                    this.cache.set(resultKey, result);

                    return result;
                }),
                catchError(this.handleError('SearchService::orderSuggestions', {}))
            );
    }

    orderResults(key: string, filter: any = null, paging: string = '18-0'): Observable<any> {
        return this.results('order', key, filter, paging);
    }

    results(type: string, key: string, filter: any = null, paging: string = '18-0'): Observable<any> {
        const filterKey: string = btoa(JSON.stringify({t: type, k: key})).replace('=', '');
        const resultKey: string = btoa(JSON.stringify({t: type, k: key, f: filter, p: paging})).replace('=', '');
        // const eTag: string = this.cache.getETag(filterKey);
        const headers: HttpHeaders = new HttpHeaders({'Range': paging});
        const data = {key: key, filter: filter};

        // if (this.cache.has(resultKey)) {
        //     return of(this.cache.get(resultKey));
        // }

        switch (type) {
            case 'order':
                return this.getOrders(resultKey, data, headers);
        }
    }

    getCatalogs(key: string, filterKey: string, data: any, headers: HttpHeaders): Observable<any[]> {
        return this.http.post<HttpResponse<Response>>(
            this.apiURL + '/program/results', data, {observe: 'response', headers: headers}
        ).pipe(
            map(httpResponse => {
                const response = new Response(httpResponse.body);
                const result = response.data;

                SearchStoreInstance.updateMaxItemCount(httpResponse.headers.get('X-Count'));

                // this.cacheFilters(filterKey, result);

                this.productService.catalogCache.set(key, _.clone(result.items));

                _.each(result.items, (id, i) => {
                    if (this.productService.catalogCache.has(id)) {
                        result.items[i] = new ProductCatalog(this.productService.catalogCache.get(id));
                    }
                });

                return result.items;
            }),
            catchError(this.handleError('SearchService::getCatalogs', []))
        );
    }

    getProducts(type: string, key: string, filterKey: string, data: any, headers: HttpHeaders): Observable<Product[]> {
        return this.http.post<HttpResponse<Response>>(
            this.apiURL + '/' + type + '/results', data, {observe: 'response', headers: headers}
        ).pipe(
            map(httpResponse => {
                const response = new Response(httpResponse.body);
                const result = response.data;

                SearchStoreInstance.updateMaxItemCount(httpResponse.headers.get('X-Count'));

                // this.cacheFilters(filterKey, result);

                this.productService.productCache.set(key, _.clone(result.items));

                _.each(result.items, (id, i) => {
                    if (this.productService.productCache.has(id)) {
                        result.items[i] = new Product(this.productService.productCache.get(id));
                    }
                });

                return result.items;
            }),
            catchError(this.handleError('SearchService::getProducts', []))
        );
    }

    getOrders(key: string, data: any, headers: HttpHeaders): Observable<any[]> {
        return this.http.post<HttpResponse<Response>>(
            this.apiURL + '/order/results', data, {observe: 'response', headers: headers}
        ).pipe(
            map(httpResponse => {
                const response = new Response(httpResponse.body);
                const result = response.data;

                SearchStoreInstance.updateMaxItemCount(httpResponse.headers.get('X-Count'));

                _.each(result, (order, i) => {
                    result[i] = new Order(order);
                });

                return result;
            }),
            catchError(this.handleError('SearchService::getOrders', []))
        );
    }

    cacheResult(resultKey: string, filterKey: string, result: any): Observable<any> {
        const out = {items: result, filters: this.cache.get(filterKey)};
        this.cache.set(resultKey, out);
        return of(out);
    }

    // cacheFilters(key: string, data: any) {
    //     const eTag: string = this.cache.getETag(key);
    //
    //     if (eTag !== data.eTag) {
    //         _.each(data.filters, (item, i) => {
    //             data.filters[i] = new FilterGroup(item);
    //         });
    //         this.cache.setETag(key, data.eTag);
    //         this.cache.set(key, data.filters);
    //     }
    // }

    public clearSearchResults() {
        this.globalTerm = '';
        this.foundProducts = [];
        this.totalProducts = 0;
        this.searchPerformed = false;
        this.searchOffset = 0;
    }

    @computed
    public get hasProducts(): boolean {
        return this.foundProducts && this.foundProducts.length > 0;
    }

    @action
    public setCurrentOrderWindowId(val: number) {
        this.currentBuyingWindowId = val;
        this.selectedOrderType = this.currentBuyingWindowId > 0 ? PREORDER_TYPE : ECOMMERCE_TYPE;
    }


    private initOrderSearch() {

        this.sortedColumn = {column: 'order_id', direction: 'DESC'};
        this.selectedSearchParam = null;
        this.searchParams = ALL_SEARCH_OPTIONS.filter( o => {
                // remove sap from search options if user can't have access to it
                if (!this.authService.canShowSAP && this.config.showBillingInfo && o.name === 'sap') {
                    return false;
                }

                // remove window from search options if user can't have access to it
                if (!this.authService.canBuyingWindow && o.name === 'window_id') {
                    return false;
                }

                return true;
            });

        this.orderCategories = [];

        if (this.authService.canBuyingWindow) {
            this.orderCategories.push({type: PREORDER_TYPE, label: this.multiTenantService.orderWindowLabel, total: 0,
                orders: [], fetching: false, selected: false, sorting: false});
        }

        if (this.authService.isWholeSaler || this.authService.isWholeSalerMultiple) {
            this.orderCategories.push({type: SUMMARY_TYPE, total: 0, label: `${this.multiTenantService.projectLabel} Orders`,
                orders: [], fetching: false, selected: false, sorting: false});
        }

        if (this.authService.isWholeSalerMultiple) {
            this.orderCategories.push({type: BRANCH_TYPE, total: 0, label: 'Branch Orders',
                orders: [], fetching: false, selected: false, sorting: false});
        }


        this.orderCategories.push({type: ECOMMERCE_TYPE, total: 0, label: this.multiTenantService.onDemandLabel,
            orders: [], fetching: false, selected: false, sorting: false});

        if (this.authService.canAutoship) {
            this.orderCategories.push({type: CUSTOM_TYPE, total: 0, label: 'Auto-Ship',
                orders: [], fetching: false, selected: false, sorting: false});
        }

        const selectedTab  = this.pageService.getSelectedOrderHistoryTab();
        if (selectedTab) {
            this.orderCategories.forEach( s => {
                s.selected = s.type === selectedTab;
            });

        }

        // check if one of category is selected
        if (this.orderCategories.filter( oc  => oc.selected).length === 0 ) {
            this.orderCategories[0].selected = true;
        }
    }


    private getFilters(type: string, force = false)  {
        const filter = {type: ORDER_HISTORY_TYPES[type]};
        if (this.selectedSearchParam && !force) {
            filter['search_params'] = this.selectedSearchParam;
        }

        if (this.sortedColumn.column) {
            filter['sort'] = {column: this.sortedColumn.column, direction: this.sortedColumn.direction};
        }
        return filter;
    }


    public searchOrders(searchParams: any = null) {

        if (!searchParams) {
            // search by default on init page
        } else {
            this.selectedSearchParam = searchParams;
        }

        this.searchingOrders = true;
        forkJoin( this.orderCategories.map (s => {
            const paging = `${ORDER_SEARCH_LIMIT}-${0}`;
            return this.orderService.getOrderHistory(this.getFilters(s.type), paging);
        })).subscribe( resultList => {
            const searchResults = [...this.orderCategories];
            resultList.forEach( result => {
                const  searchResult = searchResults.find( s => s.type === this.getMappedOrderHistoryType(result.type));
                if (searchResult) {
                    searchResult.orders = result.orders;
                    searchResult.total = result.count;
                }
            });

            this.searchingOrders = false;
            this.initialOrderSearchPerformed = true;
        })
    }


    public searchRecentODOrders() {

        this.searchingOrders = true;
        const paging = `${ORDER_SEARCH_LIMIT}-${0}`;
        this.recentOnDemandOrders = [];

        const filters = this.getFilters(ECOMMERCE_TYPE, true);
        filters['user_only'] = true;

        return this.orderService.getOrderHistory(filters, paging).pipe(map(resultList => {
            if (!isEmptyArray(resultList?.orders)) {
                this.recentOnDemandOrders = resultList.orders;
            }
            this.recentOnDemandOrders = resultList.orders;
            this.searchingOrders = false;
        }));
    }

    loadMore(type: string) {
        const ordersType = this.orderCategories.find( s => s.type === type);
        if (!ordersType) {
            return;
        }

        if (ordersType.total === ordersType.orders.length) {
            return;
        }


        const paging = `${ORDER_SEARCH_LIMIT}-${ordersType.orders.length}`;
        ordersType.fetching = true;

        this.orderService.getOrderHistory(this.getFilters(ordersType.type), paging).subscribe( result => {
            this.orderCategories = this.orderCategories.map( s => {
                s.fetching = false;
                const returnedType = this.getMappedOrderHistoryType(result.type);
                if (s.type !== returnedType) {
                    return s;
                }

                s.orders = [...s.orders, ...result.orders];
                return s;
            })
        });

    }

    public sortOrders(column: string) {
        const direction = this.sortedColumn.direction === 'ASC' ? 'DESC' : 'ASC'
        this.sortedColumn = {column, direction};

        forkJoin(this.orderCategories.map ( oc => {
            return this.sort(oc);
        })).subscribe( result => {
           this.orderCategories = result.map( r => {
               r.sorting = false;
               return r;
           });

        });
    }


    public exportToExcel(type: string) {
        const params = btoa(JSON.stringify(this.getFilters(type)));
        window.open(`${this.orderService.getApiUrl()}s/spreadsheet?params=${params}`, '_blank');
    }

    private getOrderValueByColumnsName (order: OrderHistory, columnName: string) {
        switch (columnName) {
            case 'order_id':
                return order.id;
            case 'state':
                return order.state;
            case 'order_date':
                return order.order_date;
            case 'purchaser':
                return order.purchaser;
            case 'cart_name':
                return order.cart_name;
            case 'items_count':
                return order.items_count;
            case 'order_total':
                return order.order_total;
            default:
                return null;
        }
    }

    private internalSort ( order1: OrderHistory, order2: OrderHistory, columnName: string, dir: string ): number  {
        const value1 = this.getOrderValueByColumnsName(order1, columnName);
        const value2 = this.getOrderValueByColumnsName(order2, columnName);

        if ( typeof  value1 === 'string' && typeof value2 === 'string') {
            return dir === 'ASC' ? value1.localeCompare(value2) : value2.localeCompare(value1);
        }

        if (typeof  value1 === 'number' && typeof value2 === 'number') {
            return dir === 'ASC' ?  value1 - value2 : value2 - value1;
        }

        if (columnName === 'order_date') {

            if (!value1 && !value2) {
                return 0;
            }

            if (dir === 'ASC') {
                if (!value1) {
                    return -1;
                }
                if (!value2) {
                    return 1;
                }
                return (value1 as AppDate).isBefore(value2) ? -1 : 1;

            } else {
                if (!value2) {
                    return -1;
                }
                if (!value1) {
                    return 1;
                }
                return (value2 as AppDate).isBefore(value1) ? -1 : 1;

            }

        }


        return 0;
    }


    private sort(orderCategory: OrderCategory): Observable<OrderCategory> {
        if (orderCategory.total > orderCategory.orders.length) {
            // sorting on server
            const paging = `${orderCategory.orders.length}-0`;
            orderCategory.fetching = true;

            this.orderService.getOrderHistory(this.getFilters(orderCategory.type), paging).subscribe( result => {
                this.orderCategories = this.orderCategories.map( s => {
                    s.fetching = false;
                    const returnedType = this.getMappedOrderHistoryType(result.type);
                    if (s.type !== returnedType) {
                        return s;
                    }
                    s.orders = result.orders;
                    return s;
                })
            });
        } else {
            // internal sorting
            const orders = orderCategory.orders.sort( (o1, o2) => {
                return this.internalSort(o1, o2, this.sortedColumn.column, this.sortedColumn.direction);
            });

            orderCategory.orders = orders;
        }
        return of(orderCategory);
    }



    @action
    public selectedOrdersType(type: string) {
        this.pageService.setSelectedOrderHistoryTab(type);
        this.orderCategories = this.orderCategories.map( s => {
            return {...s, selected : s.type === type}
        })
    }


    public getBuyingWindows(): Observable<AvailableOrderWindow[]> {
        return this.orderService.getAvailableOrderWindows();
    }


    public resetSearch() {
        this.initOrderSearch();
        this.searchOrders();
    }

    @computed
    public get selectedOrderCategory() {
        return this.orderCategories.find( oc => oc.selected);
    }


    @computed public get onDemandOrdersHistory() {
        return this.orderCategories.find( category => category.type === ECOMMERCE_TYPE)?.orders || [];
    }

    @computed get selectedOrders(): OrderHistory[] {
        return this.selectedOrderCategory?.orders || [];
    }

    private getMappedOrderHistoryType(type: string): string {
        for (const [key, value] of Object.entries(ORDER_HISTORY_TYPES)) {
            if (value === type) {
                return key;
            }
        }
    }
}
