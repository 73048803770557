import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {FormControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { takeWhile } from 'rxjs/operators';

import { CostCenter } from '../../../models/cost-center.model';
import { PaymentService } from '../../../services/payments.service';

const BUSINESS_UNITY = 'business_unity';
const COMPANY_BILLING_ENTITY  = 'company_billing_entity';
const VENDOR_NUMBER  = 'vendor_number';


@Component({
    selector: 'app-sap-info',
    templateUrl: './sap-info.component.html',
    styleUrls: ['./sap-info.component.scss']
})

export class  SapInfoComponent implements OnInit, OnDestroy {

    // private _label: string;
    _costCenter: CostCenter;
    @Output() verifiedCostCenter = new EventEmitter<CostCenter>();

    @Output() sapChanged = new EventEmitter<void>();

    // form items
    sapForm: UntypedFormGroup;
    businessUnity: FormControl;
    companyBullingEntity: FormControl;
    vendorNumber: FormControl;

    // vendorName: FormControl;
    // // description: FormControl;
    // account_assignment: FormControl;
    // gl: FormControl;
    // internal_order: FormControl;
    // wbs: FormControl;
    // tax_data: FormControl;
    // psa_flag: FormControl;

    invalidCostCenter = false;
    invalidCostCenterMessage = '';

    inProgress = false;
    isVerified = false;
    private alive = true;
    private formInitialized = false;



    closeWithWarning = false;

    @Input() set costCenter(val: CostCenter) {
        this.formInitialized = false;
        this._costCenter = val;
        this.showCostCenterData();
    };


    // @Input() set label(val: string) {
    //     if (val) {
    //         this._label = val;
    //         this.description.setValue(this._label);
    //     }
    // }


    constructor(private formBuilder: UntypedFormBuilder, private paymentService: PaymentService) {}

    ngOnInit(): void {
        this.sapForm = this.formBuilder.group({
            'business_unity': ['', Validators.compose([Validators.required])],
            'company_billing_entity' : ['', Validators.compose([Validators.required])],
            'vendor_number': ['', Validators.compose([Validators.required])],
        });
        // this.description = this.sapform.controls['description'];
        this.businessUnity = this.sapForm.controls[BUSINESS_UNITY] as FormControl;
        this.companyBullingEntity = this.sapForm.controls[COMPANY_BILLING_ENTITY] as FormControl;
        this.vendorNumber = this.sapForm.controls[VENDOR_NUMBER] as FormControl;

        this.showCostCenterData();


        this.sapForm.valueChanges.pipe( takeWhile(() => this.alive)).subscribe( () => {
            if (!this.formInitialized) {
                return;
            }
            this.isVerified = false;
            this.sapChanged.emit();
        })
    }

    ngOnDestroy(): void {
        this.alive = false;
        this.sapForm.reset();
    }


    // public methods

    // checks wether sap information is valid
    verifySap() {
        this._costCenter = this.getCostCenter();
        this.invalidCostCenterMessage = '';
        this.inProgress = true;
        this.paymentService.verifySap(this._costCenter).subscribe( result => {
            this.inProgress = false;
            this._costCenter.verified = result.success;
            if (result.success) {
                this.isVerified = true;
                // sap verified
                this.verifiedCostCenter.emit(this._costCenter);

            } else {
                this.isVerified = false;
                // verification error
                this.invalidCostCenterMessage = result.errorMessage;
            }
        });
    }


    public resetForm() {
        if (this.sapForm) {
            this.sapForm.reset();
        }
    }



    get canVerify(): boolean {
        return !(!this.sapForm.valid || this.inProgress);
    }


    get sapLongAccount(): string {
        const costCenter = this.getCostCenter();
        return  costCenter.getLongAccount();
    }

    private getCostCenter(): CostCenter {

        this.businessUnity = this.sapForm.controls[BUSINESS_UNITY] as FormControl;
        this.companyBullingEntity = this.sapForm.controls[COMPANY_BILLING_ENTITY] as FormControl;
        this.vendorNumber = this.sapForm.controls[VENDOR_NUMBER] as FormControl;

        const costCenter = new CostCenter({});
        // costCenter.label = this.description.value;
        costCenter.business_unit = this.businessUnity.value;
        costCenter.company_billing_entity = this.companyBullingEntity.value;
        costCenter.vendor_number = this.vendorNumber.value;
        // costCenter.gl = this.gl.value;
        // costCenter.internal_order = this.internal_order.value;
        // costCenter.wbs = this.wbs.value;
        // costCenter.tax_data = this.tax_data.value;
        // costCenter.psa_flag = this.psa_flag.value;

        if (this._costCenter) {
            costCenter.id = this._costCenter.id;
            costCenter.active = this._costCenter.active || false;
        }
        // if (this.employeeSelection) {
        //     costCenter.employee_id = this.employeeSelection.employee_id;
        //     costCenter.first_name = this.employeeSelection.first_name;
        //     costCenter.last_name = this.employeeSelection.last_name;
        //     costCenter.limit_per_po = this.employeeSelection.limit_per_po;
        // }

        return costCenter;

    }

    private showCostCenterData() {
        if (!this.sapForm || !this._costCenter) {
            return;
        }
        this.businessUnity.setValue( this._costCenter?.business_unit || '');
        this.companyBullingEntity.setValue(this._costCenter?.company_billing_entity || '');
        this.vendorNumber.setValue( this._costCenter?.vendor_number || '');
        // this.internal_order.setValue( this._costCenter.internal_order || '');
        // this.wbs.setValue( this._costCenter.wbs || '');
        // this.tax_data.setValue( this._costCenter.tax_data || '');
        // this.psa_flag.setValue( this._costCenter.psa_flag || '');
        this.isVerified = this._costCenter.id > 0;
        this.formInitialized = true;

    }




}
