import { Component, ViewEncapsulation } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import { Order } from '../../../models/order.model';
import { OrderItem } from '../../../models/order-item.model';
import { Currency } from '../../../interfaces/currency';

@Component({
    selector: 'price-details-modal',
    templateUrl: './price-details--modal.component.html',
    styleUrls: ['./price-details--modal.component.scss']
})
export class PriceDetailsModalComponent {

    order: Order;
    orderItem: OrderItem;

    showBudgetDetails: any;

    constructor(private activeModal: NgbActiveModal) {
    }

    close(next: string = null) {
        this.activeModal.close(next);
    }

    get subTotal(): number {
        return this.orderItem ?  this.orderItem.subTotal : this.order.subTotal;
    }

    get taxes(): number {
        return this.orderItem ?  this.orderItem.taxes : this.order.taxes;
    }

    get shipping(): number {
        return this.orderItem ? this.orderItem.shippingTotal : this.order.shippingTotal;
    }

    get discount(): number {
        return this.orderItem ? this.orderItem.totalDiscount : this.order.discountTotal;
    }

    get budget(): number {
        return this.orderItem ? this.orderItem.budget : this.order.budgetTotal;
    }

    get totalCost(): number {
        return this.orderItem ? this.orderItem.totalCost : this.order.totalCost;
    }

    get currency(): Currency {
        return this.orderItem ? this.orderItem.product.currency : this.order.currency;
    }


    onBudgetDetailsClick() {
        if (this.showBudgetDetails) {
            this.showBudgetDetails();
        }
        return false;
    }
}
