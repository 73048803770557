import {createFeatureSelector, createSelector} from '@ngrx/store';
import {updateItemInArray} from '../shared/utils';
import {ProductOrdersInfo} from '../interfaces/product-orders-info';
import {RecommendedProductsActions, FETCHED_PRODUCT_ORDERS_INFO, FETCHING_PRODUCT_ORDERS_INFO} from '../actions/recommended-products.actions';


export interface IRecommendedProductsState {
    fetchingProductOrdersInfo: boolean;
    productOrdersInfo: ProductOrdersInfo[];
}


export const initialState: IRecommendedProductsState = {
    fetchingProductOrdersInfo: false,
    productOrdersInfo: []
};


export function recommendedProductsReducer(state = initialState, action: RecommendedProductsActions) {
    switch (action.type) {
        case FETCHING_PRODUCT_ORDERS_INFO:
            return {...state, fetchingProductOrdersInfo: true};
        case FETCHED_PRODUCT_ORDERS_INFO:
            if (action.info) {
                const newInfo = updateItemInArray<ProductOrdersInfo>(state.productOrdersInfo, action.info, true, 'productId');
                return {
                    ...state, fetchingProductOrdersInfo: false, productOrdersInfo: newInfo
                }
            }
            return {...state, fetchingProductOrdersInfo: false};
        default:
            return state;
    }
}

export const getRecommendedProductsState = createFeatureSelector<IRecommendedProductsState>('recommendedProducts');

export const isFetchingProductOrdersInfo =
    createSelector(getRecommendedProductsState, (state: IRecommendedProductsState) => state.fetchingProductOrdersInfo === true);

export const productOrdersInfoById =
    createSelector(getRecommendedProductsState, (state: IRecommendedProductsState, props) =>
        state.productOrdersInfo.find(p => p.productId === props.id));

