import { PaymentMethod } from './payment-method.model';
import { PaymentMethodType } from '../enums/payment-method';

export class CostCenter extends PaymentMethod {
    public cost_center_id: string;
    public label: string;
    public account_assignment: string;
    public gl: string;
    public internal_order: string;
    public wbs: string;
    public tax_data: string;
    public psa_flag: string;
    public id: number;
    public business_unit: string;
    public company_billing_entity: string;
    public vendor_number: string;


    public type = PaymentMethodType.CostCenter; // type of payment method

    public verified = true; // true if cost center has been verified

    constructor(data: any) {
        super(data);
        if (typeof data === 'string') {
            data = JSON.parse(data);
        }
        if (data) {
            Object.assign(this, data);
        }
    }

    public getLongAccount() {

        // const la = [this.account_assignment || ' ', this.cost_center_id || ' ',
        //     this.gl || ' ', this.internal_order || ' ', this.wbs || ' ',
        //     this.tax_data || ' ', this.psa_flag || ' '];
        // return la.join('-');

        const la = [this.business_unit || ' ', this.company_billing_entity || ' ',
            this.vendor_number];
        return la.join('-');

    }

    isAccount(): boolean {
        return true;
    }

    isCard(): boolean {
        return false;
    }
}
