export enum AutoShipState {
    // created in FUSE
    CREATED = 1,

    // waiting for action in InnerTrak (IT)
    PENDING = 2,

    // ready for purchase (approved in IT)
    READY = 3,

    // purchased in FUSE
    IN_PRODUCTION = 4,

    // cancelled (rejected in IT)
    CANCELLED = 5,

    RETURNED = 6,

    ON_HOLD = 7,

    SHIPPED = 8,

    SUBMITTED = 9
}

export function stateDescription(state: AutoShipState): string {
    switch (state) {
        case AutoShipState.CREATED:
            return 'Draft';
        case AutoShipState.IN_PRODUCTION:
            return 'In Production';
        case AutoShipState.CANCELLED:
            return 'Cancelled';
        case AutoShipState.ON_HOLD:
            return 'On Hold';
        case AutoShipState.PENDING:
            return 'Under Review';
        case AutoShipState.RETURNED:
            return 'Returned';
        case AutoShipState.READY:
            return 'Enter Ship Lists';
        case AutoShipState.SHIPPED:
            return 'Completed';
        case AutoShipState.SUBMITTED:
            return 'Under Review';

        default:
            return '';
    }
}

export function stateColor(state: AutoShipState): string {
    switch (state) {
        case AutoShipState.READY:
            return 'green';
        case AutoShipState.PENDING:
            return 'gold';
        case AutoShipState.CREATED:
            return 'gold';
        case AutoShipState.CANCELLED:
            return 'red';
        case AutoShipState.IN_PRODUCTION:
            return 'green';
        case AutoShipState.SUBMITTED:
            return 'gold';
        case AutoShipState.SHIPPED:
            return 'green';
        default:
            return '';
    }
}
