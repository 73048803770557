<div class="modal-md sap-modal">
    <div class="modal-header state">
        <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label">CHOOSE BILLING CODE</h1>
        <button type="button" (click)="close()" class="close btn btn-icon" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body sap-selector">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="input-group pt-3" style="height: 200px">
                            <app-sap-selector [costCenterId]="costCenter ? costCenter.id : null" (selectedPaymentMethod)="selectedSap($event)"></app-sap-selector>
                        </div>
                    </div>
                </div>

                <div class="row mb-3 mt-3">
                    <button (click)="onApplyPayment()"
                            [disabled]="!canApply"
                            type="button"
                            class="btn btn-primary apply-sap">
                        Apply Billing Code
                    </button>
                </div>

            </div>
    </div>
</div>

