<div class="modal-md price-details">
    <div class="modal-header state">
        <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label"> {{order ? 'Order' : 'Item'}} Summary </h1>
        <button type="button" (click)="close()" class="close btn btn-icon" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
        <div class="price-details--container">
            <div><span class="label">Subtotal</span> <span class="price"><app-currency-price [price]="subTotal" [currency]="currency"></app-currency-price></span></div>
            <div><span class="label">Estimated Shipping</span> <span class="price"><app-currency-price [price]="shipping" [currency]="currency"></app-currency-price></span></div>
            <div><span class="label">Taxes</span> <span class="price"><app-currency-price [price]="taxes" [currency]="currency"></app-currency-price></span></div>

            <hr *ngIf="budget > 0 || discount > 0"  class="mb-0"/>

            <div *ngIf="discount > 0"><span class="label">Discount</span> <span class="price green">-<app-currency-price [price]="discount" [currency]="currency"></app-currency-price></span></div>
            <div *ngIf="budget > 0">
                <a href="#" class="link link-underline font-5 font-weight-normal" (click)="onBudgetDetailsClick()">Budget Applied&nbsp;<i class="fas fa-exclamation-circle"></i></a> <span class="price green">-<app-currency-price [price]="budget"></app-currency-price></span>
            </div>

            <hr/>

            <div>
                <span class="label total">Total Cost</span> <span class="price"><app-currency-price [price]="totalCost" [currency]="currency"></app-currency-price></span>
            </div>
        </div>
    </div>
</div>

