import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Order} from '../../../models/order.model';

@Component({
    selector: 'remove-order-modal',
    templateUrl: './remove-order-modal.component.html'
})
export class RemoveOrderModalComponent {

    order: Order;

    constructor(public activeModal: NgbActiveModal) {}

    close(result) {
        this.activeModal.close(result)
    }

    delete() {
        this.activeModal.close(this.order);
    }

}
