import {observable, action, computed} from 'mobx';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, map, finalize, tap, switchMap} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { BaseService } from './base.service';
import { AppSettings } from '../app.settings';
import { Response } from '../models/response.model';
import { Budget } from '../models/budget.model';
import {isEmptyArray, updateItemInArray} from '../shared/utils';
import {OrderItem} from '../models/order-item.model';
import {Order} from '../models/order.model';
import {Product} from '../models/product.model';
import {AuthService} from './auth.service';

@Injectable()
export class BudgetService extends BaseService {

    public fetching = false;
    private fetched = false;

    @observable budgets: Budget[] = [];

    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService,
        private authService: AuthService
    ) {
        super('/account/budgets', http, appSettings, toastr);
    }

    public get(force= false): Observable<Budget[]> {
        if (!this.authService.canSeeBudgets) {
            return of([]);
        }

        if (!force && this.fetched) {
            return of (this.budgets);
        }

        this.fetching = true;
        return this.http.get<Response>(this.apiURL + '/all')
            .pipe(
                map(response => {
                    if (response && !isEmptyArray(response.data)) {
                        return response.data.map ( i => new Budget(i));
                    }
                    return [];
                }),
                catchError(this.handleError('BudgetService::get', [])),
                tap( budgets => {
                    this.setBudgets(budgets);
                }),
                finalize( () => {
                    this.fetched = true;
                    this.fetching = false;
                })
            );
    }

    public getBudgetById(id: number): Observable<Budget> {
        if (!this.authService.canSeeBudgets) {
            return of(null);
        }

        if (!id) {
            return of(null);
        }

        if (id === -1) {
            return of(this.emptyBudget);
        }

        return this.http.get<Response>(`${this.apiURL}/${id}`).pipe(
            map( response => {
                if (response?.data) {
                    return new Budget(response.data);
                }
                return null;
            }),
            catchError(this.handleError('BudgetService::getBudgetById', null)),
            tap( result => {
                if (result) {
                    // update array of budgets
                    this.updateBudgetInList(result);
                }
            })
        );
    }

    @action setBudgets(budgets: Budget[]) {
        this.budgets = budgets;
    }

    @computed public get activeBudgets(): Budget[] {
        return this.budgets.filter( b => b.isActive);
    }

    @computed public get hardlimitTrimesterBudget(): Budget {
        return this.activeBudgets.find( b => b.hard_limit ===  true);
    }
    @computed public get hasHardlimitTrimesterBudget(): boolean {
        return !!this.hardlimitBudget;
    }

    public getHardlimitBudgetForProduct(product: Product): Budget {
        const budget = this.hardlimitBudget;
        if (!budget) {
            return null;
        }

        if (budget.isMatchedWithProduct(product)) {
            return budget;
        }

        return null;
    }


    @action public updateBudget(orderItem: OrderItem): Observable<Budget[]> {
        const budgetId = orderItem?.financials_total?.budget_id
        if (!budgetId || budgetId === -1) {
            // no need to update budgets
            return of ([]);
        }

        return this.getBudgetById(budgetId).pipe( switchMap( () => {
            return of(this.getBudgetsForOrderItem(orderItem));
        }))
    }

    @action private updateBudgetInList(budget: Budget) {
        this.setBudgets(updateItemInArray(this.budgets, budget));
    }


    public getBudgetsForOrderItem(orderItem: OrderItem): Budget[] {
        if (!orderItem) {
            return [];
        }

        const filteredBudgets = this.filterBudgets(this.activeBudgets, orderItem);
        if (!isEmptyArray(filteredBudgets)) {
            if (!this.getHardlimitBudget(filteredBudgets)) {
                filteredBudgets.push( this.emptyBudget );
            }
        }
        return filteredBudgets;

    }


    public getBudgetByOrderItem(orderItem: OrderItem): Budget {
        const budgetId = orderItem?.financials_total?.budget_id;
        if (budgetId === -1) {
            return this.emptyBudget;
        }


        if (budgetId && !isEmptyArray(this.budgets)) {
            return this.budgets.find( b => b.id === budgetId);
        }
        return null;
    }


    public fetchBudgetItemForOrderItem(orderItem: OrderItem): Observable<OrderItem> {
        const budgetId = orderItem?.financials_total?.budget_id;
        if (budgetId === -1) {
            orderItem.budgetItem = this.emptyBudget;
            return of(orderItem);
        }

        return this.getBudgetById(budgetId).pipe( map( budget => {
            if (budget) {
                orderItem.budgetItem = budget;
                this.updateBudgetInList(budget);
            }
            return orderItem;
        }));
    }

    public getBudgetsByOrder(order: Order): Observable<Order> {
        if (!order) {
            return of(order);
        }

        // skip of order items nave no budget
        if (!order.items.filter( i => i.financials_total.budget_id > 0).length) {
           return of(order);
        }

        return this.get().pipe(
            map( budgets => {
                if (isEmptyArray(budgets)) {
                   return order;
                }


                const result = new Order(order);
                result.items.forEach( item => {
                    item.budgetItem = this.findBudgetByOrderItem(budgets, item);
                });
                return result;
            })
        );
    }

    @computed get trimesterBudgets(): Budget[] {
        return this.activeBudgets.filter( b => b.trimester === true);
    }

    @computed get hardlimitBudget(): Budget {
        return this.getHardlimitBudget(this.trimesterBudgets);
    }

    public getHardlimitBudget(budgets: Budget[] = []): Budget {
        return budgets.find(  b => b.hard_limit === true);
    }

    private filterBudgets(budgets: Budget[], orderItem: OrderItem): Budget[] {
        if (isEmptyArray(budgets)) {
            return budgets;
        }
        const matchedBudgets = budgets.filter( b =>  b.isMatchedWithOrderItem(orderItem));

        const hardlimit = this.getHardlimitBudget(matchedBudgets);
        if (hardlimit) {
            // in case of hardlimit show only one budget
            return [hardlimit];
        }

        return matchedBudgets;
    }

    private findBudgetByOrderItem(budgets: Budget[], orderItem: OrderItem): Budget {
        const budgetId = orderItem?.financials_total?.budget_id;
        if (isEmptyArray(budgets) || !orderItem ||  !budgetId) {
            return null;
        }

        if (budgetId === -1) {
            return this.emptyBudget;
        }

        return budgets.find((item) => item.id === orderItem.financials_total.budget_id);
    }


    public get emptyBudget(): Budget {
        return new Budget({id: -1, label: 'Don\'t apply budget'});
    }

}
