export const PRINTED_NUM_SIDES  = [
    {name: '1side', description: '1 Side'},
    {name: '2differentart', description: '2 Different Art'},
    {name: '2sameart', description: '2 Same Art'},
    {name: '2sides', description: '2 Sides'},
    {name: '2sidesdifferent', description: '2 Sides different'},
    {name: '2sidessame', description: '2 Sides Same'},
    {name: '2sideswithfeet', description: '2 Sides With Feet'},
    {name: '4differentart', description: '4 Different Art'},
    {name: '4sidesdifferent', description: ' 4 Sides Different'},
    {name:  'other', description: 'Other'},
    {name: 'printedsidena', description: 'N/A'},
];
export const TOTAL_NUM_PRINT_COLORS = [
    {name: '1colorprocess', description: '1 Color Process'},
    {name: '2colorprocess', description: '2 Color Process'},
    {name: '2colorscreenprint', description: '2 Color Screenprint'},
    {name: '3colorprocess', description: '3 Color Process'},
    {name: '4color1pms', description: '4 Color + 1 PMS'},
    {name: '4colorprocess', description: '4 Color Process'},
    {name: '4colorscreenprint', description: '4 Color Screenprint'},
    {name: '4pms', description: '4 PMS'},
    {name: 'na', description: 'N/A'},
    {name: 'other', description: 'Other'}];
