import { BaseModel }     from './base.model';
import { ProductOption } from './product-option.model'

export class ProductAttribute extends BaseModel {
    public id: number;
    public label: string;
    public required: boolean;
    public hidden: boolean;
    public ord: number;

    public options: Array<ProductOption>;

    constructor(data) {
        super(data, {
            num: ['id','product_id','ord'],
            bool: ['required','hidden'],
        });
    }
}
