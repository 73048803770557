import { Component, ViewEncapsulation } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {Order} from '../../../models/order.model';

@Component({
    selector: 'app-credit-card-modal',
    templateUrl: './credit-card-payment-failed.component.html',
    styleUrls: ['./credit-card-payment-failed.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CreditCardPaymentFailedComponent {


    order: Order;
    transactionId: number;

    constructor(private activeModal: NgbActiveModal) {}

    close() {
        this.activeModal.close();
    }

    get paymentMethod(): string {
        if (!this.order || !this.order.creditCard) {
            return '';
        }

        return this.order.creditCard.label;
    }

    get orderId(): number {
        return this.order ? this.order.id : null;
    }

    get orderCost(): number {
        return this.order ? this.order.totalCost : null;
    }
}

