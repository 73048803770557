<div class="modal-md fix-transaction-modal">
    <div class="modal-header state">
        <div class="d-flex justify-content-center full-width transaction-header">
            <h1 class="" id="modal-label">Order #{{transactionGroup.totals.order_id}} </h1>
            <h1 class="mx-3 px-3 vertical-separators">Transaction #{{transactionGroup.totals.transactionId}}</h1>
            <h1>
                STATUS: <span class="transaction--state" [ngClass]="transactionStateClass(transactionGroup.totals.transactionState)">{{transactionStateDescription(transactionGroup.totals.transactionState)}}</span>
            </h1>

        </div>
        <button type="button" (click)="close()" class="btn btn-icon close" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
        <div class="fix-transaction--container">
            <ng-container *ngIf="order &&!loading">
                <div *ngIf="hasFailedTransaction" class="px-4 pb-3 line-separator--light-grey">
                    <label class="uppercase font-5 font-weight-bold">Apply Existing Card</label>
                    <div class="d-flex justify-content-between">
                        <div style="width: 240px">
                            <ng-select
                                    placeholder="Credit Card"
                                    class="ng-select--custom"
                                    [items]="creditCards"
                                    [(ngModel)] = "selectedCreditCard"
                                    [ngModelOptions]="{standalone: true}"
                                    [searchable]="false"
                                    (change)="updateCreditCard($event)"
                                    [clearable]="false">
                                <ng-template ng-label-tmp let-item="item">
                                    <span>{{displayCreditCardLabel(item)}}</span>
                                </ng-template>
                                <ng-template ng-header-tmp>
                                    Saved Credit Cards:
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <div>{{displayCreditCardLabel(item)}}</div>
                                </ng-template>
                            </ng-select>
                        </div>
                        <a class="link" (click)="addCreditCard()" href="#">Add Credit Card <i class="fal fa-plus"></i></a>
                    </div>
                </div>

                <ng-container *ngFor="let transactionItem of transactionGroup.transactions" >

                    <div *ngIf="(hasFailedTransaction && transactionItem.hasFailed) || !hasFailedTransaction" class="transaction-details--container line-separator--light-grey">
                        <app-product-order-item
                                [order]="order"
                                [orderItem]="transactionItem.orderItem"
                                [delivery]="transactionItem.delivery"
                                [paidQuantity]="transactionItem.quantity"
                                [hideFinancials]="false"
                                viewType="list-item"
                                [showBudget]="false"
                                [modal]="false"
                                [readonly]="true"></app-product-order-item>

                    </div>

                </ng-container>




                <button *ngIf="hasFailedTransaction" class="btn btn-primary d-block mx-auto my-3" (click)="chargeCreditCard()" [disabled]="!canCharge">
                    <i class="fal fa-sync rotate" *ngIf="charging"></i>&nbsp;Charge Credit Card
                </button>
            </ng-container>

            <div *ngIf="loading" style="min-height: 300px; padding: 100px">
                <div class="loader-container" style="height: 50px; width: 50px; margin: auto">
                    <div class="inline-loader"></div>
                </div>
            </div>

        </div>
    </div>
</div>
