import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import { Order } from '../../../models/order.model';
import { ECOMMERCE_TYPE } from '../../../constants/order-types';
import { OrderService } from '../../../services/order.service';
import { AuthService } from '../../../services/auth.service';
import {ORDER_NOTES_INFO} from '../../../constants/texts';
import {ConfigService} from '../../../services/config.service';
import {MultiTenantService} from '../../../services/multi-tenant.service';
import {Address} from '../../../models/address.model';
import {AddressService} from '../../../services/address.service';
import {PresetAddress} from '../../../interfaces/preset-address';

@Component({
    selector: 'app-order-settings-modal',
    templateUrl: './order-settings-modal.component.html',
    styleUrls: ['./order-settings-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OrderSettingsModalComponent implements OnInit, OnDestroy {

    public orderSettingsForm: UntypedFormGroup;
    public label: String;

    public orderName: AbstractControl;
    public notes: AbstractControl;
    public planningComments: AbstractControl;
    public poNumber: AbstractControl;
    public taxExempt: UntypedFormControl;
    public attention: AbstractControl;
    isAttentionChanged = false;

    private alive = true;

    inProcess = false;

    order: Order;
    presetAddresses: Address[];

    constructor(private formBuilder: UntypedFormBuilder,
                private orderService: OrderService,
                private authService: AuthService,
                private configService: ConfigService,
                private activeModal: NgbActiveModal,
                private multiTenantService: MultiTenantService,
                private addressService: AddressService) {
    }

    ngOnInit(): void {
        this.orderSettingsForm = this.formBuilder.group({
            'orderName': ['', Validators.compose([Validators.required])],
            'notes': ['', Validators.compose([])],
            'planningComments': ['', Validators.compose([])],
            'poNumber': ['', Validators.compose([])],
            'attention': ['', Validators.compose([])],
            'taxExempt': ['', Validators.compose([])]
        });
        this.orderName = this.orderSettingsForm.controls['orderName'];
        this.notes = this.orderSettingsForm.controls['notes'];
        this.planningComments = this.orderSettingsForm.controls['planningComments'];
        this.poNumber = this.orderSettingsForm.controls['poNumber'];
        this.attention = this.orderSettingsForm.controls['attention'];
        this.taxExempt = this.orderSettingsForm.controls['taxExempt'] as UntypedFormControl;


        this.showDialog(this.order)
    }

    ngOnDestroy(): void {
        this.alive = false;
    }

    showDialog(order: Order) {

        const orderName = this.order.cartName;
        this.orderName.setValue(orderName);
        this.notes.setValue(order.attr_notes  || '');
        if (order.isAttentionOverridden) {
            this.attention.setValue('');
        } else {
            this.attention.setValue(order.attr_attention || '');

        }
        this.poNumber.setValue(order.attr_po_num  || '');
        this.taxExempt.setValue(order.taxExempt  || false);

        if (this.isTaxExemptAllowed === false) {
            this.taxExempt.disable();
        } else {
            this.taxExempt.enable();
        }

        if (this.showPlanningComments) {
            this.planningComments.setValue(order.attr_planning_comments || '');
        }

        this.presetAddresses = order.presetAddresses || [];

    }

    saveOrderName() {

        this.order.attr_cart_name = this.orderName.value;
        this.order.attr_po_num = this.poNumber.value;
        this.order.attr_notes = this.notes.value;
        if (this.showPlanningComments) {
            this.order.attr_planning_comments = this.planningComments.value || '';
        }


        const attrs = {
            cart_name: this.order.attr_cart_name,
            po_num : this.order.attr_po_num,
            notes : this.order.attr_notes,
        };

        if (this.isAttentionChanged) {
            this.order.attr_attention = this.attention.value || '';
            attrs['attention'] = this.order.attr_attention;
        }

        if (this.isTaxExemptAllowed) {
            this.order.taxExempt = this.taxExempt.value;
            attrs['tax_exempt'] = this.order.taxExempt.toString();
        }

        if (this.showPlanningComments) {
            attrs['planning_comments'] = this.order.attr_planning_comments;
        }


        const presetAddressesIds: PresetAddress[] = this.presetAddresses.map( address => {
            if (!address.entity_id) {
                return {id: address.id};
            }
            return {id: address.id, entity_id: address.entity_id};
        });

        this.order.attr_preset_addresses = presetAddressesIds;
        attrs['preset_addresses'] = presetAddressesIds;

        // update cost center label
        // this.order.costCenter.label = this.orderName.value;
        this.inProcess = true;
        this.orderService.setOrderAttributes(this.order, attrs)
            .subscribe(data => {
                this.inProcess = false;
                if (data) {
                    this.close(data);
                }

            });

    }

    clearManageCartForm() {
        this.orderSettingsForm.reset();
    }


    get isEmployee(): boolean {
        return this.authService.isEmployee;
    }

    get canEditSap(): boolean {
        return this.authService.canShowSAP && this.configService.showBillingInfo;
    }

    get canShowTaxExempt(): boolean {
        return this.authService.isWholeSaler || this.order.isWSLR;
    }

    get isTaxExemptAllowed(): boolean {
        return this.authService.user.isTaxExemptAllowed || this.order.isWSLR;
    }

    onClosed() {
        this.clearManageCartForm();
    }

    close(next: Order | string = null) {
        this.activeModal.close(next);
    }


    get orderLabel() {
        return (this.order && this.order.type === ECOMMERCE_TYPE) ?
            'Cart' :
            'Order'
    }


    get orderIntoPlaceHolder(): string {
        return this.showDeliveryComments ? 'Enter Delivery Comments' : ORDER_NOTES_INFO;
    }

    get showDeliveryComments(): boolean {
        return this.multiTenantService.showDeliveryComments;
    }
    get showPlanningComments(): boolean {
        return this.multiTenantService.showPlanningComments;
    }

    get showOrderNotesOrDeliveryComments(): boolean {
        return this.multiTenantService.showOrderNotesOrDeliveryComments;
    }

    get notesLabel(): string {
        return this.multiTenantService.orderNotesLabel;
    }

    get canEditPresetAddresses(): boolean {
        return true;
    }

    onAddressListChanged(addresses: Address[]) {
        this.presetAddresses = [...addresses];
    }
}
