import {Component, HostListener, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-logout-modal',
    templateUrl: './logout-modal.component.html',
    styleUrls: ['./logout-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LogoutModalComponent  {

    constructor(private activeModal: NgbActiveModal) {}

    hideDialog(result =  false) {
        this.activeModal.close(result);
        return false;
    }

    @HostListener('keydown', ['$event'])
    private onKeyup(e): void {
        if (e.key === 'Enter') {
            this.hideDialog(true);
        }
    }

}

