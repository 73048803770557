import {Component, Input, ViewEncapsulation} from '@angular/core';
import {OrderItemDelivery} from '../../../models/order-item-delivery.model';
import {EntityService} from '../../../services/entity.service';
import {Observable, of} from 'rxjs';
import {Entity} from '../../../models/entity.model';
import { concatMap, map, take } from 'rxjs/operators';
import {EntityLocation} from '../../../models/entity-location.model';
import {computeShipmentLabel} from '../../../shared/helpers';

@Component({
    selector: 'app-shipping-label',
    templateUrl: './shipping-label.component.html',
    styleUrls: ['./shipping-label.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ShippingLabelComponent {

    _delivery: OrderItemDelivery;
    @Input()
    set delivery(val: OrderItemDelivery) {
        this._delivery = val;
    }

    entityLabel: string;


    @Input()
    isDecisionPoint = false;

    _entity: Entity;
    @Input() set wslrEntity(entity: Entity) {
        this._entity = entity;
            this.getLocation();
    }

    @Input() attention = '';


    private getLocation( ) {
        if (this._entity) {
            const location: EntityLocation = this._entity.locations.find( i => i.id === this._delivery.addr_id);
            this.entityLabel = `WSLR ${this._entity.external_id} - ${location ? (location.label || '') : ''}`;
        }
    }

    constructor(private entityService: EntityService) { }



    get label() {
        if (this._entity) {
            return  this.entityLabel;
        }

        return computeShipmentLabel(this._delivery, this.attention);
    }

    get address() {
        return `${this._delivery.street_1}, ${this._delivery.getCSZ() }`;
    }
}
