import { Component, Input } from '@angular/core';
import { Product } from '../../../../../models/product.model';
import { OrderItem } from '../../../../../models/order-item.model';
import {isEmptyArray} from '../../../../../shared/utils';

@Component({
    selector: 'app-on-demand-min-max',
    templateUrl: './on-demand-min-max.component.html',
    styleUrls: ['./on-demand-min-max.component.scss']
})
export class OnDemandMinMaxComponent {

    private _orderItem: OrderItem;

    @Input() viewType = '';

    @Input() fontSize = '';

    @Input() product: Product;


    @Input() set orderItem(val: OrderItem) {
        this._orderItem = val;
        if (this._orderItem) {
            this.product = this._orderItem.product;
        }
    }


    get productMinQuantity(): number {
        return this.product?.minQuantity || 0;
    }

    get productMaxQuantity(): number {
        let quantity = this.product?.maxQuantity || 0;
        if (this.productHasAvailableQuantity && this.productAvailableQuantity < quantity) {
            quantity =  this.productAvailableQuantity;
        }
        return quantity;
    }

    get productHasMinQuantity(): boolean {
        return this.product?.hasMinQuantity || false;
    }

    get productHasMaxQuantity(): boolean {
        return this.productMaxQuantity > 0;
    }

    get productAvailableQuantity(): number {
        return this.product?.availableQuantity || 0;
    }

    get productHasAvailableQuantity(): boolean {
        return this.product?.hasAvailableQuantity || false;
    }

    get moqMet(): boolean {
        if (!this.product) {
            return false;
        }
        return  this.orderedQuantity >=  this.productMinQuantity;
    }


    get maxQtyMet(): boolean {
        if (!this.product) {
            return false;
        }

        return this.orderedQuantity === this.productMaxQuantity;
    }

    get classes(): string {
        return this.viewType === 'grid-item' ? 'horizontal' : '';
    }

    get orderedQuantity(): number {
        return this._orderItem?.quantity || 0;
    }


    get isQuickBuyProduct(): boolean {
        return this.product?.isBuyingWindow === false;
    }

}

