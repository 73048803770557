import {BaseModel} from './base.model';
import {AppDate} from './date.model';

export class EntityLocation extends BaseModel {
    public id: number;
    public entity_id: number;
    public type: string;
    public label: string;
    public street_1: string;
    public street_2: string;
    public city: string;
    public state: string;
    public zip_code: string;
    public country_code: string;
    public is_default: boolean;
    public created_at: AppDate;
    public updated_at: AppDate;

    constructor(data) {
        super(data, {
            num: ['id', 'entity_id'],
            bool: ['residential', 'is_default'],
            date: ['created_at', 'updated_at']
        });
    }

    public getCSZ() {
        let result = this.city;
        if (this.state) {
            result += ', ' + this.state;
        }
        if (this.zip_code) {
            result += ', ' + this.zip_code;
        }
        return result;
    }
}
