import { Component, Input } from '@angular/core';
import { Product } from '../../../../../models/product.model';
import { OrderItem } from '../../../../../models/order-item.model';
import {SKU} from '../../../../../models/sku.model';

@Component({
    selector: 'app-on-demand-sku-available-qty',
    templateUrl: './on-demand-sku-available-qty.component.html',
    styleUrls: ['./on-demand-sku-available-qty.component.scss']
})
export class OnDemandSkuAvailableQtyComponent {

    private _orderItem: OrderItem;

    @Input() viewType = '';

    @Input() fontSize = '';

    @Input() product: Product;

    @Input() sku: SKU;


    @Input() set orderItem(val: OrderItem) {
        this._orderItem = val;
        if (this._orderItem) {
            this.product = this._orderItem.product;
        }
    }



    get classes(): string {
        return this.viewType === 'grid-item' ? 'horizontal' : '';
    }

    get availableQuantity(): number {
        if (!this.sku) {
            return 0;
        }

        const orderItemSku = this._orderItem.skus.find( s => s.sku_id === this.sku.id);
        const orderedQty  =  orderItemSku?.quantity || 0;
        const leftQuantity  =  this.sku.availableQuantity -  orderedQty;
        return Math.max( leftQuantity, 0 );
    }

    get skuHasAvailableQty(): boolean {
        if (!this.sku) {
            return false;
        }
        return this.sku.availableQuantity > 0;
    }


    get quantityDelta(): number {
        if (this.sku) {
            const orderItemSku = this._orderItem.skus.find( s => s.sku_id === this.sku.id);
            return orderItemSku?.quantityDelta || 0;
        }
        return this._orderItem?.quantityDelta || 0;
    }

    get hasVariations(): boolean {
        return this.product.hasVariations
    }

    get isQuickBuyProduct(): boolean {
        return this.product?.isBuyingWindow === false;
    }

    get isInventoryProduct(): boolean {
        return this.product.has_inventory
    }

    get showSKUAvailableQuantity(): boolean {
        return this.isInventoryProduct && this.isQuickBuyProduct && this.hasVariations && this.skuHasAvailableQty;
    }


}

