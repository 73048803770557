import {ChangeDetectionStrategy, Component} from '@angular/core';
import { ConfigService } from '../../../services/config.service';
import { AuthService } from '../../../services/auth.service';
import {ProfileService} from '../../../services/profile.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class UserMenuComponent {

    constructor(private config: ConfigService, private auth: AuthService, private profileService: ProfileService) {}

    public logoutWarning() {
        this.profileService.showLogout();
    }

    get canShowTransaction(): boolean {
        return false;
        // return this.auth.canSeeTransactions;
    }

    get canShowPaymentPage(): boolean {
        return this.auth.canSeePaymentMethods && this.config.showBillingInfo;
    }

    get canShowBudgets(): boolean {
        return false;
        // return this.auth.canSeeBudgets;
    }

    get canShowAutoshipPage(): boolean {
        return  false;
        // return this.auth.canAutoship;
    }

    get canSeeOrders(): boolean {
        return this.auth.canSeeOrders;
    }

    get canSeeAddresses(): boolean {
        return this.auth.canSeeAddresses;
    }
    get canSeeRepairRequests(): boolean {
        return false;
        // return this.auth.canSeeRepairRequests;
    }
}
