import { BaseModel } from './base.model';
import { Attribute } from './attribute.model';
import { ProductCatalogMedia } from './product-catalog-media.model';

import * as _ from 'lodash';
import {AppDate} from './date.model';

export class ProductCatalog extends BaseModel {
    public id: number;
    public type: string;
    public parent_id: number;
    public store_id: number;
    public entity_id: number;
    public window_id: number;
    public label: string;
    public slug: string;
    public data: Object;
    public ord: number;
    public active: boolean;

    public attrs: Array<Attribute>;

    // derived properties
    public content: any;
    public media: Array<ProductCatalogMedia>;

    // we have to manually create these psuedo properties for the compiler
    public attr_is_featured: any;
    public in_market_start_at: AppDate;
    public in_market_end_at: AppDate;
    public ship_at: AppDate;
    public attr_program_type: any;
    public attr_calendar_bgcolor: any;

    constructor(data) {
        super(data, {
            num: ['id', 'parent_id', 'store_id', 'entity_id', 'window_id', 'ord'],
            bool: ['active'],
            date: ['created_at', 'updated_at', 'in_market_start_at', 'in_market_end_at', 'ship_at']
        }, ['is_featured', 'program_type', 'calendar_bgcolor']);
    }

    public getSlug() {
        return this.id + '-' + this.slug;
    }

    public getImage() {
        return this.filterMedia('DEFAULT', 'IMAGE_MEDIA', true);
    }

    public getHeroImage() {
        return this.filterMedia('DEFAULT', 'HERO_MEDIA', true);
    }

    public getSuccessImages() {
        return this.filterMedia('SUCCESS', 'IMAGE_MEDIA');
    }

    public getContent(): any {
        if (!this.content) {
            return null;
        }
        return (this.content.DEFAULT) ? this.content.DEFAULT : null;
    }

    public getSuccessContent(): any {
        if (!this.content) {
            return null;
        }
        return (this.content.SUCCESS) ? this.content.SUCCESS : null;
    }

    public get description() {
        return (this.getContent() && this.getContent().description) ? this.getContent().description : '';
    }

    public get brandGuideUrl() {
        return (this.getContent() && this.getContent().brand_guide_url) ? this.getContent().brand_guide_url : '';
    }

    public get playbookUrl() {
        return (this.getContent() && this.getContent().playbook_url) ? this.getContent().playbook_url : '';
    }

    public get successDescription() {
        return (this.getSuccessContent() && this.getSuccessContent().description) ? this.getSuccessContent().description : '';
    }

    protected filterMedia(cat: string, type: string, first: boolean = false): ProductCatalogMedia | ProductCatalogMedia[] {
        if (!this.media) {
            return null;
        }
        const media = _.filter(this.media, {category: cat, type: type});

        return (first) ? ((media.length > 0) ? media[0] : null) : media;
    }

    public get programDetailsUrl(): string {
        if (this.id === 1) {
            // special case OD ALL_Products
            return '/on-demand/products'
        }

        return this.isOnDemand ? `/on-demand/programs/${this.getSlug()}` :  `/buying-window/${this.window_id}/programs/${this.getSlug()}`;
    }

    public get isOnDemand(): boolean {
        return !this.window_id;
    }

    public get featureType(): number {
        if (!this.attr_is_featured) {
            return 0;
        }

        return +this.attr_is_featured;
    }

    public get isFeatureType(): boolean {
        return this.featureType > 0;
    }
}
