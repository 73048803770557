import {BaseModel} from './base.model';
import {AppDate} from './date.model';
import {TransactionState} from '../constants/transaction-state';

export class TransactionTotals extends BaseModel {
    amount: number;
    date: AppDate;
    last_four: string
    order_id: number;
    quantity: number;
    state: TransactionState;
    transaction_tag: number;
    tracking_number: any;

    constructor(data) {
        super(data, {
            date: ['date']
        });

        if (data.state && typeof data.state === 'string') {
            this.state = +data.state;
        }

    }

    get cardNumber(): string {
        return this.last_four ? this.last_four : 'N/A';
    }

    get transactionId(): string {
        return this.transaction_tag ? '' + this.transaction_tag : 'N/A'
    }

    get transactionState(): TransactionState {
        return this.state ? this.state : TransactionState.UNKNOWN;
    }

    get hasFailed(): boolean {
        return this.state === TransactionState.FAILED;
    }
}
