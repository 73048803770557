import {Action} from '@ngrx/store';
import {ProductOrdersInfo} from '../interfaces/product-orders-info';

export const FETCHING_PRODUCT_ORDERS_INFO = 'FETCHING_PRODUCT_ORDERS_INFO';
export const FETCHED_PRODUCT_ORDERS_INFO = 'FETCHED_PRODUCT_ORDERS_INFO';

export class FetchingProductOrdersInfo implements Action  {
  readonly type = FETCHING_PRODUCT_ORDERS_INFO;
}

export class FetchedProductOrdersInfo implements Action  {
  readonly type = FETCHED_PRODUCT_ORDERS_INFO;
  constructor(public info: ProductOrdersInfo) {
  }
}


export type RecommendedProductsActions = FetchingProductOrdersInfo | FetchedProductOrdersInfo;
