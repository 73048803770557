import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { hidePreloader } from '../../shared/utils';
import {MultiTenantService} from '../../services/multi-tenant.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-supported.component.html',
  styleUrls: ['./not-supported.component.scss'],
})
export class NotSupportedComponent implements AfterViewInit {

  constructor(private multiTenantService: MultiTenantService) {
  }


  ngAfterViewInit() {
     hidePreloader();
  }

  get logoUrl(): string {
    return this.multiTenantService.logoImageUrl;
  }
}
