import { AppDate }   from './date.model';
import { Attribute } from './attribute.model';

import * as _        from 'lodash';

export class BaseModel {

    public attrs: Array<Attribute> = [];

    constructor(data, conversions = {}, attrProperties = []) {
        if (typeof data == 'string') {
            data = JSON.parse(data);
        }

        const propKeys = Object.keys(conversions);
        propKeys.forEach( key => {
           const props = conversions[key];

            switch (key) {
                case 'num':

                    props.forEach( ( prop: string) => {
                        if (data && data[prop]) { data[prop] = Number(data[prop]); }
                    });
                    break;
                case 'bool':
                    props.forEach( (prop: string) => {
                        if (data && data[prop]) {
                            if (isNaN(data[prop])) {
                                data[prop] = (data[prop] === 'true');
                            } else {
                                data[prop] = Boolean(Number(data[prop]));
                            }
                        }
                    });
                    break;
                case 'date':
                    props.forEach( (prop: string) => {
                        if (data && data[prop]) { data[prop] = new AppDate(data[prop]); }
                    });
                    break;
            }

        });


        _.each(attrProperties, (prop) => {
            Object.defineProperty(this, 'attr_' + prop, {

                get: function () {
                    if (this.hasAttr(prop)) {
                      return this.getAttrValue(prop);
                    }

                    return null;
                },
                set: function (value) {
                    this.setAttrValue(prop, value);
                }
            });
        });

        if (data && data['attrs']) {
            let attrs = [];

            _.each(data['attrs'], attr => {
                attrs.push(new Attribute(attr));
            });

            data['attrs'] = attrs;
        }

        if (data) { Object.assign(this, data); }
    }

    public hasAttr(key): boolean {
        return (_.find(this.attrs, {attr: key}) != undefined)
    }

    public getAttr(key): Attribute {
        return this.attrs.find( attr => attr.attr === key);
        //return _.find(this.attrs, {attr: key});
    }

    public getAttrValue(key): string
    {
        let attr: Attribute = this.getAttr(key);

        return (attr) ? attr.value : null;
    }

    public setAttrValue(key: string, value: any)
    {
        let attr: Attribute = this.getAttr(key);

        if (attr) {
            attr.value = value;
        } else {
            this.attrs.push(new Attribute({attr: key, value: value}));
        }
    }
}
