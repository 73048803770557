export enum Pages {
    Home = 1,
    Search = 2,
    BuyingWindowPage = 3,
    OnDemand = 4,
    Others = 5,
    Favorites = 6,
}


