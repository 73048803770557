import {Component, Input, ViewEncapsulation, OnInit} from '@angular/core';
import {CurrencyService} from '../../../services/currency.service';
import {isEmptyArray} from '../../../shared/utils';
import { Currency } from '../../../interfaces/currency';

// component show price with different currencies
@Component({
  selector: 'app-currency-price',
  templateUrl: './currency-price.component.html',
  styleUrls: ['./currency-price.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CurrencyPriceComponent implements OnInit {
  _formattedPrice: string;
  _price: number | string;
  _currency: Currency;
  _solelySymbol = false;

  @Input() fractionDigits = 2;

  constructor(private currencyService: CurrencyService) {
  }

  ngOnInit(): void {
    this.currencyService.currencyChanged.subscribe(() => {
      this.setFormattedPrice();
    });
  }

  @Input() set price(value: number| string) {
    this._price = value;
    if (value === undefined || value === null) {
      this._formattedPrice = '';
      return;
    }

    this.setFormattedPrice();
  };

  @Input() set currency(currency: Currency) {
    this._currency = currency;
    this.setFormattedPrice();
  }
  
  @Input() set solelySymbol(solelySymbol: boolean) {
    this._solelySymbol = solelySymbol;
    this.setFormattedPrice();
  }

  private setFormattedPrice(): void {
    this._formattedPrice = this.currencyService.getFormattedPrice(this._price,
      {
        fractionDigits: this.fractionDigits,
        solelySymbol: this._solelySymbol,
        currency: this._currency
      }
    ).trim();
  }
}
