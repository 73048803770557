import { AppDate } from './date.model';
import { PaymentMethod } from './payment-method.model';
import { CardState } from '../enums/payment-method';

export class CreditCard extends PaymentMethod {
    public label: string;
    public last_four: string;
    public is_default: boolean;
    public created_at: AppDate;
    public expires_at: string;
    public updated_at: AppDate;
    public state: number;
    public cardholder_name: string;
    public type = 1;
    public order_id: number;
    public error: string;

    constructor(data: any) {
        super(data);

        if (data) {
            Object.assign(this, data);
        }
    }

    isAccount(): boolean {
        return false;
    }

    isCard(): boolean {
        return true;
    }

    get cardNumber(): string {
        return this.last_four ? `XXXX-XXXX-XXXX-${this.last_four} ` : '';
    }

    get status(): string {
        switch (this.state) {
            case CardState.INIT:
                return 'Pending';
            case CardState.PENDING:
                return 'Pending';
            case CardState.VERIFIED:
                return 'Verified';
            case CardState.ERROR:
                return 'Invalid';
        }
    }

    get isValid(): boolean {
        return this.state === CardState.VERIFIED;
    }

    // convert 1222 to 12/22
    get expirationDate(): string {
        if (!this.expires_at) {
            return '';
        }

        if (this.expires_at.length !== 4) {
            return this.expires_at;
        }

        const month = this.expires_at.substring(0, 2);
        const year = this.expires_at.substring(2);
        return `${month}/${year}`

    }

    get canDisplay(): boolean {
        return this.state  !== CardState.INIT && this.state !== CardState.PENDING;
    }
}
