import { Component, ViewEncapsulation } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-terms-modal',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TermsComponent {

    public content: string;
    public withAcceptance: boolean;

    constructor(private activeModal: NgbActiveModal) {}

    close(result?: {accept?: boolean, logout?: boolean}) {
        this.activeModal.close(result);
    }

    acceptTerms() {
        this.close({accept: true});
    }

    logout() {
        this.close({logout: true});
    }
}

