import { Component, OnInit, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { ModalComponent } from 'app/components/modal.component';
import { ContentService } from 'app/services/content.service';
import { Vendor } from 'app/models/vendor.model';

@Component({
  selector: 'app-vendor-modal',
  templateUrl: './vendor-modal.component.html',
  styleUrls: ['./vendor-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VendorModalComponent implements OnInit {
  @Input() buttonLabel = '';
  @Input() vendorLabel: string;
  @ViewChild('vendorModal', { static: true }) vendorModal: ModalComponent;
  public vendors: Vendor[];

  constructor(private contentService: ContentService) { }

  ngOnInit() {
    this.vendors = [];
    if (!this.buttonLabel && this.vendorLabel === 'IMS') {
      this.buttonLabel = 'Sold by Anheuser-Busch, Shipping from ';
    }
  }

  public getVendorInfo() {
    this.contentService.get('vendor').subscribe(response => {
      let vendors: Array<Vendor> = JSON.parse(response.data);
      if (this.vendorLabel) {
        vendors = vendors.filter(vendor => vendor.label === this.vendorLabel) || [];
      }
      this.vendors = vendors;
    });
  }

  public toggleModal(): void {
    if (this.vendorModal.isVisible()) {
      this.vendorModal.hide();
    } else {
      this.getVendorInfo();
      this.vendorModal.show();
    }
  }

}
