<div class="modal-md upload-result-modal">
    <div class="modal-header state">
        <div style="width: 100%;">
            <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label">Your CSV Has Been Uploaded</h1>
            <div class="header--line line-separator--light-grey"></div>

        </div>
        <button type="button" (click)="close()" class="btn btn-icon close" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
        <div class="upload-result--container">
            <ng-container *ngIf="uploadResult.success && uploadResult.success.result.length > 0">
                <h3 class="mt-4 pb-2">Results:</h3>
                <p *ngFor="let line of uploadResult.success.result">{{line}}</p>
            </ng-container>

            <ng-container *ngIf="uploadResult.failed && uploadResult.failed.result.length > 0">
                <h3 class="mt-4 pb-2">Errors:</h3>
                <p *ngFor="let line of uploadResult.failed.result">{{line}}</p>
            </ng-container>

            <div class="mt-5 mb-3 d-flex justify-content-center">
                <button class="btn btn-outline-primary btn-download mr-3" (click)="close()">Close</button>
                <button class="btn btn-primary btn-download ml-3" (click)="close({downloadResults: true})">Download results</button>
            </div>

        </div>
    </div>
</div>
