import {TrackingNumber, UNKNOWN_SLUG} from '../interfaces/tracking-number';
import {FilterGroup} from '../models/filter-group.model';
import {from, Observable, of} from 'rxjs';
import {Customization, CustomizationLabel} from '../interfaces/customization';
import {FilterItem} from '../models/filter-item.model';
import * as moment from 'moment';
import {COMPLETE_STATE, PENDING_STATE, PRODUCTION_STATE, QUOTE_STATE, VOID_STATE, AFTER_APPROVAL_STATE} from '../constants/order-states';


const insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index)
];

const remove = (arr, index) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // part of the array after the specified index
    ...arr.slice(index + 1)
];


/*
    it should hide preloader element which is outside of angular scope
    preloader has id #preloader. See src/index.html
 */
export function hidePreloader() {
    const div = document.getElementById('preloader');
    if (div) {
        div.classList.add('hide');
    }

}


export function getSiblings(el, filter = null): Element[] {
    const siblings: Element[] = [];
    el = el.parentNode.firstChild;
    do {
        if (el.nodeType === Node.ELEMENT_NODE) {
            if (!filter || filter(el)) {
                siblings.push(el);
            }
        }
    } while (el = el.nextSibling);

    return siblings;
}


export function normalizeTrackingData(tracking_numbers: any): TrackingNumber[] {
    const result: TrackingNumber[] = [];
    if (tracking_numbers) {

        const slugs = Object.keys(tracking_numbers);
        for (const slug of slugs) {

            for (const track of tracking_numbers[slug]) {

                const number = track['track_num'];

                const status: string = track['state'];

                const url = `https://hhgbuy.aftership.com/${number}`;

                result.push({
                    slug,
                    number,
                    status,
                    url
                });
            }
        }
    }

    return result;
}

export function computeHash(val: string): number {
    let hash = 0;
    if (!val || val.length === 0) {
        return hash;
    }
    for (let i = 0; i < val.length; i++) {
        const chr = val.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}



export function generateUUID() { // Public Domain/MIT
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now(); // use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export function getSlugFromUri(uri: string): string {
    if (!uri) {
        return '';
    }

    const idx = uri.lastIndexOf('/');
    if (idx !== -1) {
        return uri.substr(idx + 1);
    }

    return  '';
}


export function transformFilters(filters: FilterGroup[]= [], selectedType = '', selectedLabel = ''): string[] {
    if (isEmptyArray(filters)) {
        return [];
    }
    const selectedFilters = filters;
    selectFiltersByLabel(selectedFilters, selectedType, selectedLabel);
    return [].concat( ...selectedFilters.map( f => f.getSelectedItemIDs()));
}


export function selectFiltersByLabel(filters: FilterGroup[] = [], selectedType: string, selectedLabel = '') {

    if (!selectedLabel || !selectedType) {
        return;
    }

    filters.forEach((filterGroup) => {
        if (filterGroup.label.toLowerCase().includes(selectedType.toLowerCase())) {
            filterGroup.items.forEach((filter) => {
                if (filter.children.length > 0) {
                    filter.children.forEach(child => {
                        matchTerm(child, selectedLabel);
                    })
                }
                matchTerm(filter, selectedLabel);
            });
        }
    });
}

function matchTerm(filterItem: FilterItem, term: string) {
    if (filterItem.attr_id === 5 ) {
        // sub-item should be ignored
        return;
    }

    const matchedTerm = term.toLowerCase();
    const filterLabel = filterItem.label.toLowerCase();

    if (filterLabel === matchedTerm || filterLabel.includes( `${matchedTerm} - `)) {
        filterItem.selected = true;
    } else {
        filterItem.selected = false;
    }
}

export function handleHttpError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
        return of(result as T);
    };
}




export function validateCustomization(customizations: Customization[], force = false, hasVariations = false): boolean {
    if (!Array.isArray(customizations)) {
        return false;
    }

    if (force) {
        customizations.forEach( item => {
           item.touched = true;
           item.custom_text_fields.forEach( (text: CustomizationLabel) => text.touched = true);
        });
    }

    let result = true;
    customizations.forEach( item => {
       if (hasVariations === false &&
           item.touched &&
           ( !Number.isInteger(item.custom_qty)  || item.custom_min_qty > 0 && item.custom_qty < item.custom_min_qty) ) {
           item.error = true;
           result = false;
       } else {
           item.error = false;
       }

       item.custom_text_fields.forEach( text => {
           if (text.touched && text.required === true && !text.text ) {
               text.error = true;
               result = false;
           } else {
               text.error = false;
           }
       });
    });

    return result;

}


export function getCustomizationsTotal(customizations: Customization[]): number {
    return customizations.reduce( (total, item) => {
        if (Number.isInteger(item.custom_qty)) {
            total += item.custom_qty;
        }
        return total;
    }, 0)
}



export function escapeRegExp(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function computePageHash(orderWindowId: number, url: string): number {
    return computeHash(`bw:${orderWindowId}/${url}`);
}


export function objectDateToMoment({year, month, day}) {
    return moment([year, month - 1, day]);
}

export function objectDateToString(objDate: any, format= 'MM-DD-YYYY'): string {
    if (!objDate) {
        return null;
    }
    const date =  objectDateToMoment(objDate);
    return date.isValid() ? date.format(format) : '';
}

export function stringToObjectDate(val: string, format = 'MM-DD-YYYY') {
    if (!val) {
        return null;
    }
    const date = moment(val, format);
    return {month: date.month() + 1, day: date.date(), year: date.year()};
}


export function getMatrixPrice(priceTiers, quantity: number): number {
    if (Number.isNaN(quantity)) {
        return 0;
    }

    if (!Array.isArray(priceTiers) || priceTiers.length === 0) {
        return 0;
    }

    const tier = getMatrixTier(priceTiers, quantity);
    return tier ? +tier.cost_per_pack : 0;

}

// get carton factor from matrix tiers
export function getCartonFactor(priceTiers, quantity: number): number {
    if (Number.isNaN(quantity)) {
        return 1;
    }

    if (!Array.isArray(priceTiers) || priceTiers.length === 0) {
        return 1;
    }

    const tier = getMatrixTier(priceTiers, quantity);
    return tier ? tier.carton_factor : 1;
}


function getMatrixTier(priceTiers, quantity: number) {
    if (priceTiers.length === 1) {
        return priceTiers[0];
    }

    const tierIdx = priceTiers.findIndex( pt => pt.quantity_packs > quantity);
    if (tierIdx === -1) {
        return priceTiers[priceTiers.length - 1];
    }
    if (tierIdx === 0) {
        return priceTiers[0];
    }
    return priceTiers[tierIdx - 1];
}


// iterate over filter tree
export function applyFilterAction(filter: FilterGroup, applyFn: any) {
    if (!filter) {
        return;
    }
    if (Array.isArray(filter.items)) {
        filter.items.forEach( item => {
            if (Array.isArray(item.children)) {
                item.children.forEach( child => applyFn(child))
            }
            applyFn(item);
        });
    }
}


export function isInternetExplorer() {

    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        return true;
    }

    return false;
}

export function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}


export function isIOS() {
    return  /iPad|iPhone|iPod/.test(navigator.userAgent);
}

export function mobileAndTabletCheck() {
    const agent = navigator.userAgent || navigator.vendor || window['opera'];

    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(agent) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(agent.substr(0, 4))) {
        return  true;
    }
    return false;
}

export function updateItemInArray<T>(array: T[], item: T | number,  insertFirst = false, key = 'id'): T[] {

    const getId = (i: any) => i[key];

    if (!item) {
        return array;
    }

    if ( typeof  item === 'number') {
        // remove order from list
        return array.filter( i => getId(i) !== item);
    }

    const idx = array.findIndex( i => getId(i) === getId(item));
    if (idx !== -1) {
        // update order in list
        return Object.assign([...array], {[idx]: item});
    }

    if (insertFirst) {
        return [item, ...array];
    }
    // add order to list
    return [...array, item];
}

export function moveElementInArray<T>( array: T[], fromItem: number, to: number): T[] {
    if (!array || !array.length ) {
        return array;
    }

    const lastIndex = array.length - 1;
    if (fromItem < 0  || fromItem > lastIndex || to < 0 || to > lastIndex) {
        return array;
    }

    const item = array[fromItem];

    return insert(remove(array, fromItem), to, item);
}


export function updateItemByUUID<T>(array: T[], item: T | string, option: {insert?: boolean} = {}): T[] {

    const getId = (i: any) => i['uuid'];

    if (!item) {
        return array;
    }

    if ( typeof  item === 'string') {
        // remove order from list
        return array.filter( i => getId(i) !== item);
    }

    const idx = array.findIndex( i => getId(i) === getId(item));
    if (idx !== -1) {
        // update order in list
        return Object.assign([...array], {[idx]: item});
    }

    // add order to list
    if (option.insert) {
        return [item, ...array];
    } else {
        return [...array, item];
    }

}



export function convertToNumber(obj: any): number {
    if (!obj || typeof obj === 'number') {
        return obj;
    }
    return parseFloat(obj.replace(/,/g, '.').replace(/[^\d.-]/g, ''));
}

// generates unique key from input params for cache model
export function getKeyFromParams(params: any = {}) {
    return btoa(JSON.stringify(params)).replace('=', '');
}


export function convertOrderState(s: string) {
    if (!s) {
        return s;
    }


    if (s === QUOTE_STATE) {
        return 'In Quote';
    }
    if (s === PRODUCTION_STATE) {
        return 'In Production';
    }
    if (s === PENDING_STATE) {
        return 'Requires Approval';
    }
    if (s === COMPLETE_STATE) {
        return 'Completed';
    }
    if (s === VOID_STATE ) {
        return 'Cancelled';
    }
    if (s === AFTER_APPROVAL_STATE){
        return 'Approved';
    }

    return s;

}


export function htmlDecode(str: string): string {
    return (str || '').replace(/&amp;/gm, '&');
    // const e = document.createElement('div');
    // e.innerHTML = input;
    // const result = e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
    // document.removeChild(e);
    // return result;
}


export function flatternArray ( items: any[]): any[] {
    let result: any[]  = [];

    if (Array.isArray(items)  && items.length > 0) {
        items.forEach( i => {
            if (i) {
                if (Array.isArray(i)) {
                    result = result.concat(...i);
                } else {
                    result = [...result, i];
                }
            }
        });
    }
    return result;
}

export function isEmptyArray(data: any): boolean {
    return !data || !data.length;
}

export function floorLastCent(val: number): number {
    const round = Math.round( val * 10000) / 10000;
    return Math.floor( round * 100) / 100;
}


export function convertBlobToBase64(blob: Blob): Observable<any> {
    const promise: Promise<string | ArrayBuffer> = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        }
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });

    return from(promise);
}

export function reformatDate(date: string, inputFormat = 'YYYY-MM-DD', outputFormat = 'MM-DD-YYYY') {
    if (!date) {
        return date;
    }
    return moment(date, inputFormat).format(outputFormat)
}

export function flatArray(array: any[]): any[] {
    if (isEmptyArray(array)) {
        return [];
    }
    return [].concat(...array);
}

export function getCurrentPageUrl(): string {
    return window.location.pathname;
}

