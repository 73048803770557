import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './error.interceptor';
import { RequestInterceptor } from './request.interceptor';
import { VersionInterceptor } from './version.interceptor';
import { TokenRequestInterceptor } from './token-request-interceptor';

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenRequestInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true }
];
