<div class="customization-info" *ngIf="hasCustomization">
    <a href="#" (click)="viewCustomization()" class="customization-link link link-underline color--primary-grey font-6 text-transformation-none ">View Customizations</a>
    <ul class="customization-info--list">
        <ng-container *ngFor="let customItem of customizations">
            <li class="quantity">QTY: {{customItem.custom_qty}}</li>
            <ng-container *ngFor="let label of customItem.custom_text_fields">
                <li *ngIf="label.text">
                    {{label.label}}: {{label.text}}
                </li>
            </ng-container>
        </ng-container>
    </ul>
</div>
