import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import * as _ from 'lodash';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const setObj: any = {};
        const headers: any = {};

        // skip to set content-type for multipart/form-data request
        if ((request.body instanceof FormData) === false
            && !request.headers.has('content-type')) {
            headers['Content-Type'] = 'application/json';
        } 
        
        if (!request.withCredentials) {
            setObj.withCredentials = true;
        }

        if (!_.isEmpty(headers)) {
            setObj.setHeaders = headers;
        }
        if (!_.isEmpty(setObj)) {
            return next.handle(request.clone(setObj));
        }
        
        return next.handle(request);
    }
}
