import {Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {Address} from '../../../../models/address.model';
import {isEmptyArray} from '../../../../shared/utils';
import {AddressService} from '../../../../services/address.service';
import {flatternSortedAddressesByWslr} from '../../../../shared/helpers';
import {AuthService} from '../../../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'app-order-list-address-list',
  templateUrl: './order-list-address-list.component.html',
  styleUrls: ['./order-list-address-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderListAddressListComponent {

  @Input() addresses: Address[] = [];

  @Input() disabled = false;

  @Output() onAddressListChanged = new EventEmitter<Address[]>();

  constructor(private addressService: AddressService, private authService: AuthService, private toastr: ToastrService) {
  }

  get hasAddresses(): boolean {
    return !isEmptyArray(this.addresses);
  }


  onRemoveAddressHandler(index: number): void {
    const newAddresses = this.addresses.filter((_, i) => i !== index);
    this.addresses = newAddresses;
    this.onAddressListChanged.emit(newAddresses);
  }

  onAddAddressHandler(): void {
    let newAddresses: Address[] = [];
    const allAddresses = this.addressService.addresses;
    if (isEmptyArray(allAddresses)) {
      // no addresses available
      this.toastr.warning('No addresses available, Please add new address first');
      return;
    }
    const computedAddressList = flatternSortedAddressesByWslr([...allAddresses], this.authService.user.entity_id);
    if (isEmptyArray(computedAddressList)) {
      // no addresses available
      this.toastr.warning('No addresses available, Please add new address first');
      return;
    }

    const selectedAddressesIds: number[]   = this.addresses.map((address: Address) => address.id);

    const availableAddresses = computedAddressList.filter((address: Address) => !selectedAddressesIds.includes(address.id));
    if (isEmptyArray(availableAddresses)) {
      /// no more addresses available pickup first one
      newAddresses = [...this.addresses, computedAddressList[0]];
    } else {
      newAddresses = [...this.addresses, availableAddresses[0]];
    }

    this.onAddressListChanged.emit(newAddresses);
    // setTimeout(() => {
    //   this.scrollToBottom();
    // }, 100);
  }


  onAddressUpdatedHandler(address: Address, index): void {
    const newAddresses = cloneDeep(this.addresses);
    // find index by address id
    newAddresses[index] = address;
    this.addresses = newAddresses;
    this.onAddressListChanged.emit(newAddresses);
  }

  trackByFn(index, item) {
    return item.id;
  }

  // scrollToBottom = () => {
  //   // get the div element by its id
  //   const div = this.scrollContainer.nativeElement;
  //   // smooth scroll to the bottom of the div
  //   div.scrollTo({
  //     top: div.scrollHeight,
  //     behavior: 'smooth'
  //   });
  //
  // }


  get addAddressLabel(): string  {
    return isEmptyArray(this.addresses) ? 'Add Address' : 'Add Another Address';
  }
}
