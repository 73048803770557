import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertType} from '../../../enums/alert-type';


@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

    public id: string;
    public defaults: any = {
        type: AlertType.Alert,
        class: '',
        headerClass: '',
        headerIcon: '',
        title: '',
        message: '',
        cancelLabel: 'Cancel',
        actionLabel: 'Action',
        actionClass: 'btn-primary'
    };
    public settings: any;
    public data: any;
    public types = AlertType;

    constructor(private activeModal: NgbActiveModal) {

    }

    ngOnInit(): void {
        this.data = {...this.defaults};
        if (this.settings) {
            Object.assign(this.data, this.settings);
        }

    }

    close(result: any = null) {
        this.activeModal.close(result);
    }

    handler() {
        this.close(true);
    }
}
