import { BaseModel } from './base.model';

export class Doa extends BaseModel {
    employee_id: string;
    first_name: string;
    last_name: string;
    limit_per_po: number;

    constructor(data) {
        super(data);

        if (typeof data === 'string') {
            data = JSON.parse(data);
        }
        if (data) {
            Object.assign(this, data);
        }
    }

    get fullName(): string {
        return `${this.first_name} ${this.last_name}`;
    }

}
