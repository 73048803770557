import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';

import {BaseService} from './base.service';
import {AppSettings} from '../app.settings';
import {Response} from '../models/response.model';
import {Doa} from '../models/doa.model';
import {catchError, map, delay, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {CacheModel} from '../models/cache.model';
import {isEmptyArray} from '../shared/utils';
import {AuthService} from './auth.service';
import {ConfigService} from './config.service';

const CACHE_KEY = 'employeeList';

@Injectable()
export class DoaService extends BaseService {

    private employeeCache = new CacheModel(3600);

    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService,
        private authService: AuthService,
        private  configService: ConfigService
    ) {
        super('/payment/search-doa', http, appSettings, toastr);
    }

    findEmployee(term: string): Observable<Doa[]> {
        return this.http.post<Response>(`${this.apiURL}`, {match: term}, {headers: this.xsrfTokenHeader}).pipe(
            map( (response: Response) => response.data.map( item => new Doa(item))),
            catchError(this.handleError('DoaService::findEmployee', [])),
        );
    }


    public fetchAllEmployee(): Observable<Doa[]> {
        if (!this.authService.canShowSAP || !this.configService.showBillingInfo) {
            return of([]);
        }

        if (this.employeeCache.has(CACHE_KEY)) {
            const employeeList = this.employeeCache.get(CACHE_KEY).map ( v => new Doa(v));
            return of(employeeList);
        }

        const  url = `${this.appSettings.settings.apiBaseURL}/payment/list-doa`;
        return this.http.get<Response>(url, {}).pipe(
            map( (response: Response) => {
                if (!isEmptyArray(response.data)) {
                    return response.data.map( item => new Doa(item));
                }
                return [];
            }),
            catchError(this.handleError('DoaService::findEmployee', [])),
            tap( employeeList => {
                if (!isEmptyArray(employeeList)) {
                    this.employeeCache.set(CACHE_KEY, employeeList);
                }
            })
        )
    }
}

