import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {AppSettings} from '../app.settings';
import {AutoShipGroup} from '../models/auto-ship-group.model';
import {AutoShipItem} from '../models/auto-ship.model';
import {Response} from '../models/response.model';
import {SportTeam} from '../models/sport-team.model';
import {AutoShipState} from '../enums/auto-ship-state.enum';
import {OrderItem} from '../models/order-item.model';
import {findDetailedErrorMessage} from '../shared/helpers';
import {Agency} from '../interfaces/agency';

const BASE_URL = '/custom-products-requests';

@Injectable()
export class AutoShipApiService {

    readonly apiURL: string;

    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService,
        protected router: Router
    ) {
        this.apiURL = this.appSettings.settings.apiBaseURL + BASE_URL;
    }


    // fetch all auto-ships with paging
    public fetchAll(paging: string): Observable<{ items: AutoShipGroup[], total: number }> {

        const headers: HttpHeaders = new HttpHeaders({'Range': paging});

        return this.http.get<HttpResponse<Response>>(this.apiURL, {observe: 'response', headers: headers}).pipe(
            map((httpResponse) => {
                const response = new Response(httpResponse.body);
                if (response.data) {
                    const total = +httpResponse.headers.get('X-Count') || 0;
                    return {items: response.data.map(item => new AutoShipGroup(item)), total};
                } else {
                    return {items: [], total: 0};
                }
            }),
            catchError(this.handleError('AutoShipService::fetchAll', {items: [], total: 0})),
        )
    }

    public fetchAutoShipGroupByOrderId(orderId: number): Observable<AutoShipGroup> {
        return this.http.get<Response>(`${this.apiURL}/order/${orderId}`).pipe(
            map( response => {
               if (response.data) {
                   const autoShip = new AutoShipGroup(response.data);
                   if (!autoShip.order_id) {
                       autoShip.order_id = orderId;
                   }
                   return autoShip;
               }
               return null;
            }),
            catchError(this.handleError('AutoShipService::fetchAuthoShipGroupByOrderId', null)),
        )
    }

    public fetchAutoShipItems(ids: number[]): Observable<AutoShipItem[]> {
        return this.http.post<Response>(`${this.apiURL}/for-ids`, ids).pipe(
            map( response => {
                if (response.data && Array.isArray(response.data)) {
                    return response.data.map( i => new AutoShipItem(i));
                }
                return [];
            }),
            catchError(this.handleError('AutoShipService::fetchAutoShipItems', [])),
        )
    }


    public saveAutoShip(autoShip: AutoShipItem): Observable<AutoShipItem> {
        if (!autoShip) {
            return of(null);
        }

        autoShip.beforeSave(); // prepare item to be saved

        if (autoShip.id) {
            // update autoship
            // save new autoship
            return this.http.put<Response>(`${this.apiURL}/${autoShip.id}`, autoShip, {headers: this.xsrfTokenHeader}).pipe(
                map( response => {
                    if (response.data) {
                        return new AutoShipItem({...response.data, uuid: autoShip.uuid});
                    }
                    return null;
                }),
                catchError(this.handleError('AutoShipService::saveAutoShip', null)));

        } else  {
            // save new autoship
            return this.http.post<Response>(this.apiURL, autoShip, {headers: this.xsrfTokenHeader}).pipe(
                map( response => {
                    if (response.data) {
                        return new AutoShipItem(response.data);
                    }
                    return null;
                }),
                catchError(this.handleError('AutoShipService::saveAutoShip', null)));
        }

    }

    public removeAutoshipGroup(autoShipGroup: AutoShipGroup): Observable<boolean> {
        return this.http.delete<Response>(`${this.apiURL}/order/${autoShipGroup.order_id}`).pipe(
            map( response => response.data),
            catchError(this.handleError('AutoShipService::removeAutoshipGroup', false))
        );
    }


    public removeAutoshipItem(autoShipItem: AutoShipItem): Observable<boolean> {
        return this.http.delete<Response>(`${this.apiURL}/${autoShipItem.id}`).pipe(
            map( response => response.data),
            catchError(this.handleError('AutoShipService::removeAutoshipItem', false))
        );
    }

    public cancelAutoshipGroup(autoShipGroup: AutoShipGroup): Observable<AutoShipGroup> {
        return this.http.post<Response>(`${this.apiURL}/order/${autoShipGroup.order_id}/cancel`, '').pipe(
            map(response => {

                if (Array.isArray(response.data)) {
                    const items = response.data.map( d => new AutoShipItem(d));
                    const newAutoShip = new AutoShipGroup(autoShipGroup);
                    newAutoShip.items = items;
                    newAutoShip.state = AutoShipState.CANCELLED;
                    return newAutoShip;
                }

                return null;
            }),
            catchError(this.handleError('AutoShipService::cancelAutoshipGroup', null)),
        )
    }

    public cancelAutoshipItem(autoShipItem: AutoShipItem): Observable<AutoShipItem> {
        return this.http.post<Response>(`${this.apiURL}/${autoShipItem.id}/cancel`, '').pipe(
            map(response => {
                return response.data ? new AutoShipItem({...response.data, uuid: autoShipItem.uuid}) : null;
            }),
            catchError(this.handleError('AutoShipService::cancelAutoshipItem', null)),
        )
    }

    public fetchSportTeams(): Observable<SportTeam[]> {
        return this.http.get<Response>(`${this.apiURL}/sport-teams`).pipe(
            map( response => {
                if (response.data) {
                    const sportTeams: SportTeam[] = [];
                    const data = response.data;
                    const keys = Object.keys(data);
                    for ( const key of keys) {
                        sportTeams.push({id: key, label: data[key]});
                    }
                    return sportTeams;
                }

                return [];
            }),
            catchError(this.handleError('AutoShipService::fetchSportTeams', [])));
    }

    public fetchProductTypes(): Observable<any> {
        return this.http.get<Response>(`${this.apiURL}/item-types-tree`).pipe(
            map(response => {
                if (response.data) {
                    return response.data;
                }
                return null;
            }),
            catchError(this.handleError('AutoShipService::fetchProductTypes', null)),
        )
    }

    public fetchBrands(): Observable<string[]> {
        return this.http.get<Response>(`${this.apiURL}/brands`).pipe(
            map(response => {
                if (Array.isArray(response.data)) {
                    return response.data;
                }
                return [];
            }),
            catchError(this.handleError('AutoShipService::fetchBrands', [])),
        )
    }


    public fetchAgencies(): Observable<Agency[]> {
        return this.http.get<Response>(`${this.apiURL}/agencies`).pipe(
            map(response => {
                if (Array.isArray(response.data)) {
                    return response.data;
                }
                return [];
            }),
            catchError(this.handleError('AutoShipService::fetchAgencies', [])),
        )
    }

    // upload an image to server
    public uploadImage(file: File): Observable<string> {

        const formData = new FormData();
        formData.append('files[]', file, file.name);

        return this.http.post<Response>(`${this.apiURL}/file`, formData, {headers: this.xsrfTokenHeader}).pipe(
            map((response: Response) => {

                if (response.data) {
                    this.toastr.success('Image has been uploaded');
                    return response.data.url;
                } else {
                    this.toastr.warning('Failed to upload the image');
                    return null;
                }
            }),
            catchError(this.handleError('AutoShipService::uploadImage', null))
        );
    }

    public deleteDeliveries(delivery_type: string, autoShipItemId: number): Observable<OrderItem> {
        return this.http.delete<Response>(`${this.apiURL}/${autoShipItemId}/shipment-csv/${delivery_type}`)
            .pipe(map(response => {
                    if (response.data && response.data.item) {
                        return new OrderItem(response.data.item);
                    }
                    return null;
                }),
                catchError(this.handleError('AutoShipService::deleteDeliveries', null)))
    }

    public populateAuto(autoship: AutoShipItem) {
        const url = `${this.appSettings.settings.apiBaseURL}/increment-popularity/autoship/${autoship.id}`;
        this.http.post<Response>(url, {}).subscribe(() => {
        });
    }


    public uploadCSV(file: File, autoshipItemId: number): Observable<{orderItem: OrderItem, uploadResult: any }> {

        const formData = new FormData();
        formData.append('files[]', file, file.name);

        return this.http.post<Response>(`${this.apiURL}/${autoshipItemId}/shipment-csv`, formData, {headers: this.xsrfTokenHeader}).pipe(
            map((response: Response) => {

                if (response.data) {
                    let orderItem: OrderItem;
                    let uploadResult: any;
                    if (response.data.item) {
                        orderItem = new OrderItem(response.data.item);
                    }

                    if (response.data.status) {
                        uploadResult = response.data.status;
                    }


                    return {orderItem, uploadResult};
                } else {
                    this.toastr.warning('Failed to upload CVS File');
                }

                return null;
            }),
            catchError(this.handleError('AutoShipService::uploadCSV', null))
        );

    }

    public submit(autoShip: AutoShipGroup): Observable<AutoShipGroup> {
        if (!autoShip) {
            return of(autoShip);
        }

        return this.http.post<Response>(`${this.apiURL}/order/${autoShip.order_id}/submit`, autoShip).pipe(
            map( response => {
               if (Array.isArray(response.data)) {
                   const newAutoship = new AutoShipGroup(autoShip);
                   newAutoship.state = AutoShipState.SUBMITTED;
                   newAutoship.items = response.data.map( d => new AutoShipItem(d));
                   return newAutoship;
               }
               return null;
            }),
            catchError(this.handleError('AutoShipService::submit', null))
        );
    }

    public getApiUrl(): string {
        return this.apiURL;
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            const errorMessage = findDetailedErrorMessage(error);
            if (errorMessage) {
                this.toastr.error(errorMessage);
                console.log(operation + ' failed', error);
            }
            return of(result as T);
        };
    }

    get xsrfTokenHeader() {
        return new HttpHeaders({'x-xsrf-token': ''});
    }


}
