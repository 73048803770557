import { NgModule }                 from '@angular/core';
import { CommonModule }             from '@angular/common';

// Common Application Directives
import { TrimValueAccessor }        from './trim.directive';
import { IFrameResizerDirective }   from './iframeResizer.directive';
import {DigitOnlyDirective} from './digit-only.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        TrimValueAccessor,
        IFrameResizerDirective,
        DigitOnlyDirective
    ],
    exports: [
        TrimValueAccessor,
        IFrameResizerDirective,
        DigitOnlyDirective
    ]
})
export class DirectivesModule { }
