import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PaymentService} from '../../../services/payments.service';

@Component({
    selector: 'remove-payment-method-modal',
    templateUrl: './remove-payment-method-modal.component.html'
})
export class RemovePaymentMethodModalComponent {

    item: any;

    constructor(public activeModal: NgbActiveModal, private paymentService: PaymentService) {}

    close() {
        this.activeModal.close()
    }

    deleteItem() {
        if (this.item) {
            this.paymentService.delete(this.item).then((result) => {
                // close dialog even if delete is failed as user can't don anything with what
                this.activeModal.close(this.item);
            })
        }
    }

    get performingAction(): boolean {
        return this.paymentService.performingAction
    }
}
