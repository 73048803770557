import {Component, Output, EventEmitter, TemplateRef, ViewChild, Input, OnInit} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html'
})

export class ModalComponent implements OnInit {


    public id: string;

    public contextObject: any;

    @Output() close = new EventEmitter();
    @Output() closed = new EventEmitter();



    @Input()
    public title: string;

    @Input()
    public class: string;

    @Input()
    public headerClass: string;

    @Input()
    public headerIcon: string;

    @Input()
    public backdrop: boolean | 'static' = 'static';

    @Input()
    public allowEscape = true;

    @Input()
    public showHeader = true;

    @Input()
    public showFooter = true;

    @ViewChild('modalTemplate', { static: true }) private templateRef: TemplateRef<any>;
    private modalRef: NgbModalRef;
    private opened = false; // true when modal dialog is opened otherwise false


    @Input() set isPerforming(val: boolean) {
        // val ? this.blockUI.start() : this.blockUI.stop();
    };

    constructor(private modalService: NgbModal) {
        // compute unique id

    }

    ngOnInit() {
        this.id = 'modal-' + Date.now() + Math.random().toString(36).substr(2);
    }

    show(obj: any = null) {

        this.contextObject = obj;

        if (this.opened) {
            return;
        }

        this.opened = true;

        this.modalRef = this.modalService.open(this.templateRef, {
            windowClass : this.class,
            backdrop: this.backdrop,
            keyboard: this.allowEscape
        });

        // check whether the modal has been closed by escape
        this.modalRef.result.then( () => {}, () => {
           this.hide();
        });

    }

    hide() {
        if (!this.opened) {
            return;
        }
        this.close.emit(true);
        this.opened = false;
        this.modalRef.close();
        this.closed.emit(true);
    }

    isVisible() {
        return this.opened;
    }
}
