import { Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';

@Component({
    selector: 'app-decision-point-modal',
    templateUrl: './decision-point-faq.component.html',
    styleUrls: ['./decision-point-faq.component.scss']
})
export class DecisionPointFaqComponent {

    dpName: string;

    constructor(private activeModal: NgbActiveModal, private router: Router) {
    }

    close(redirectToFaq = false) {
        this.activeModal.close(redirectToFaq);
    }

    redirectToFAQ() {
        this.router.navigate(['/faq']);
        this.close(true);
        return false;
    }

}
