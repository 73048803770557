import { Component, Input } from '@angular/core';
import { Product } from '../../../../../models/product.model';
import { SKU } from '../../../../../models/sku.model';
import { AppDate } from '../../../../../models/date.model';

@Component({
    selector: 'app-product-order-item-in-stock',
    templateUrl: './product-order-item-in-stock.component.html',
    styleUrls: ['./product-order-item-in-stock.component.scss']
})
export class ProductOrderItemInStockComponent {

    @Input() product: Product;

    @Input() quantity = 0;

    @Input() viewType: string;

    @Input() fontSize = 'font-4'; // normal (24px) | small (20px) |  tiny (16px) | font-14 (14px)

    @Input() sku: SKU;

    @Input() label = '';

    @Input() showCombinedQuantity = false;

    // DISPLAY USE ONLY - returns available_quantity - pending_quantity + item.init_quantity
    get inStockQuantity(): number {
        if (this.sku) {
            return  this.showCombinedQuantity ? this.sku.inStockCombinedQuantity :  this.sku.inStockQuantity;
        } else {
            if (!this.product) {
                return 0;
            }
            return this.product.inStockQuantity;
        }

    }

    //  DISPLAY USE ONLY - returns  pending quantity - (total ordered quantity - in stock quantity)
    get pendingQuantity(): number {
        if (this.sku) {
            return (this.sku.availableQuantity < this.sku.pendingQuantity) ?
                this.sku.aggregatedQuantity : this.sku.pendingQuantity;

        }

        return this.product ? this.product.pendingQuantity : 0;
    }

    get inStock(): boolean {
        if (this.sku) {
            return this.sku.inStock;
        }

        if (!this.product) {
            return false;
        }

        return this.product.inStock;
    }


    get hasPendingQuantity(): boolean {
        if (this.sku) {
            return this.sku.pendingQuantity > 0;
        }

        if (!this.product) {
            return false;
        }

        return this.product.pendingQuantity > 0;
    }

    get inStockDate(): AppDate {
        if (this.sku) {
            return this.sku.in_stock_at;
        }
        return this.product.skus[0].in_stock_at;

    }

    get className(): string {
        return `${this.fontSize}`;
    }


    get shippingTiming(): string {
        return this.product.shippingTiming;
    }

    get isOutOfStock(): boolean {
        return this.inStockQuantity === 0 && this.pendingQuantity === 0;
    }
}
