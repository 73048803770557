import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import { Order } from '../../../models/order.model';
import { OrderService } from '../../../services/order.service';
import { AuthService } from '../../../services/auth.service';
import { OrderItem } from '../../../models/order-item.model';
import { OrderItemDelivery } from '../../../models/order-item-delivery.model';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-ship-to-modal',
    templateUrl: './ship-to-modal.component.html',
    styleUrls: ['./ship-to-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ShipToModalComponent implements OnInit, OnDestroy {

    attention = '';
    private alive = true;
    inProcess = false;

    order: Order;
    orderItem: OrderItem;
    orderItemDelivery: OrderItemDelivery;
    waitForUpdate = true;

    constructor(private orderService: OrderService,
                private authService: AuthService,
                private activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {
        this.attention = this.orderItemDelivery.deliver_to  || '';
    }

    ngOnDestroy(): void {
        this.alive = false;
    }

    saveShipTo() {

        if (this.waitForUpdate) {
            const updatedOrder = new Order(this.order);
            const updatedOrderItem = updatedOrder.createOrderItem(this.orderItem);
            const updateOrderItemDelivery = new OrderItemDelivery(this.orderItemDelivery);
            updateOrderItemDelivery.deliver_to = this.attention;
            updatedOrderItem.updateDelivery(updateOrderItemDelivery, this.order.isOnDemand);

            this.inProcess = true;
            this.orderService.updateOrderItem(updatedOrder, updatedOrderItem)
                .subscribe(data => {
                    this.inProcess = false;
                    if (data) {
                        this.close({attention: this.attention});
                    }
                });
        } else {
            this.close({attention: this.attention});
        }

    }

    close(next: any = null) {
        this.activeModal.close(next);
    }

}
