import {Injectable} from '@angular/core';

import {SortItem} from '../models/sort-item.model';
import {cloneDeep} from 'lodash';
import {isEmptyArray} from '../shared/utils';

export interface  ShowItem {
    label: string;
    name: string;
    selected: boolean;
}

@Injectable()
export class SortService {

    private showItems: ShowItem[] = [
        {label: 'All Items', name: 'all', selected: true},
        {label: 'Ordered Items', name: 'ordered', selected: false},
        {label: 'Unordered Items', name: 'unordered', selected: false}
    ];


    public sorts: Array<SortItem>;

    constructor(
    ) {
        this.sorts = [
            new SortItem({
                name: '',
                label: 'Featured Items',
                selected: true,
                moduleType: '',
                intentType: '',
            }),
            new SortItem({
                name: 'is_recommended DESC',
                label: 'Recommended',
                selected: true,
                moduleType: '',
                intentType: 'product',
            }),
            new SortItem({
                name: 'is_featured DESC',
                label: 'Recommended',
                selected: true,
                moduleType: '',
                intentType: 'product',
            }),
            new SortItem({
                name: 'created_at DESC',
                label: 'Newest Products',
                selected: false,
                moduleType: '',
                intentType: 'product',
            }),
            new SortItem({
                name: 'label',
                label: 'Program Name',
                selected: false,
                moduleType: 'buying-window',
                intentType: 'program',
                direction: 'ASC',
                directionType: 'alpha'
            }),
            new SortItem({
                name: 'label',
                label: 'Program Name',
                selected: false,
                moduleType: 'on-demand',
                intentType: 'program',
                direction: 'ASC',
                directionType: 'alpha'
            }),
            new SortItem({
                name: 'label',
                label: 'Product Name',
                selected: false,
                moduleType: '',
                intentType: 'product',
                direction: 'ASC',
                directionType: 'alpha'
            }),
            new SortItem({
                name: 'ship_at',
                label: 'Ship Date',
                selected: false,
                moduleType: 'buying-window',
                intentType: '',
                direction: 'ASC',
                directionType: 'date'
            }),
            new SortItem({
                name: 'in_market_start_at',
                label: 'In-Market Date',
                selected: false,
                moduleType: 'buying-window',
                intentType: '',
                direction: 'ASC',
                directionType: 'date'
            }),
            new SortItem({
                name: 'price_per_unit',
                label: 'Price',
                selected: false,
                moduleType: '',
                intentType: 'product',
                direction: 'ASC',
                directionType: 'amount'
            }),
            new SortItem({
                name: 'popularity',
                label: 'Popularity',
                selected: false,
                moduleType: '',
                intentType: 'product',
                direction: 'DESC',
                directionType: 'amount'
            }),
            new SortItem({
                name: 'created_at',
                label: 'Date',
                selected: true,
                moduleType: 'transactions',
                intentType: '',
                prefix: true,
                direction: 'DESC',
                directionType: 'date'
            }),
            new SortItem({
                name: 'success-state',
                label: 'Successful Only',
                selected: false,
                moduleType: 'transactions',
                intentType: '',
            }),
            new SortItem({
                name: 'failed-state',
                label: 'Failed Only',
                selected: false,
                moduleType: 'transactions',
                intentType: '',
            }),
            new SortItem({
                name: 'amount',
                label: 'Amount',
                selected: false,
                moduleType: 'transactions',
                intentType: '',
                prefix: true,
                direction: 'ASC',
                directionType: 'amount'
            }),

            new SortItem({
                name: 'order_id',
                label: 'Order #',
                selected: false,
                moduleType: 'orderHistory',
                intentType: '',
                direction: 'ASC',
                directionType: 'date'
            }),
            new SortItem({
                name: 'cart_name',
                label: 'Order Name',
                selected: false,
                moduleType: 'orderHistory',
                intentType: '',
                direction: 'ASC',
                directionType: 'alpha'
            }),
            new SortItem({
                name: 'state',
                label: 'Order State',
                selected: false,
                moduleType: 'orderHistory',
                intentType: '',
                direction: 'ASC',
                directionType: 'alpha'
            }),
            new SortItem({
                name: 'created_at',
                label: 'Order Date',
                selected: false,
                moduleType: 'orderHistory',
                intentType: '',
                direction: 'ASC',
                directionType: 'date'
            }),
            new SortItem({
                name: 'items_count',
                label: '# Of Items',
                selected: false,
                moduleType: 'orderHistory',
                intentType: '',
                direction: 'ASC',
                directionType: 'date'
            }),
            new SortItem({
                name: 'order_total',
                label: 'Order Total',
                selected: false,
                moduleType: 'orderHistory',
                intentType: '',
                direction: 'ASC',
                directionType: 'date'
            }),

        ];
    }

    get(moduleType: string, intentType: string, isRecommended: boolean = false, isBuyingWindow = false): SortItem[] {
        const sortItems = cloneDeep(this.sorts).filter((item) => {
            if (
                (item.moduleType === moduleType || item.moduleType === '') &&
                (item.intentType === intentType || item.intentType === '')
            ) {
                if (isBuyingWindow) {
                    if (isRecommended) {
                        return   item.name !== '' && item.name !== 'is_featured DESC';
                    }
                    return item.name !== '' && item.name !== 'is_recommended DESC';
                } else {
                    return item.name !== 'is_recommended DESC' && item.name !== 'is_featured DESC';
                }
            }

        });

        if (isEmptyArray(sortItems)) {
            return [];
        }

        const selected = sortItems.find( s => s.selected);
        if (!selected) {
            sortItems[0].selected = true;
        }

        return sortItems;
    }

    public getByModuleType(moduleType: string): SortItem[] {
        return this.sorts.filter( s => s.moduleType === moduleType);
    }


    getShowItems(): ShowItem[] {
        return cloneDeep(this.showItems);
    }
}
