import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {AppSettings} from '../app.settings';
import { Response } from '../models/response.model';
import {Observable, of} from 'rxjs';
import {catchError, delay, map, switchMap} from 'rxjs/operators';
import {findDetailedErrorMessage} from '../shared/helpers';
import {ToastrService} from 'ngx-toastr';
import {ProductOrdersInfo, ProductOrdersInfoItem} from '../interfaces/product-orders-info';
import {API_URL} from '../constants/api-urls';
const BASE_URL = '/recommended-products/';



@Injectable()
export class ApiRecommendedProductsService {
    readonly apiURL: string;

    constructor(private http: HttpClient, private appSettings: AppSettings, private toastr: ToastrService) {
        this.apiURL = this.appSettings.settings.apiBaseURL + BASE_URL;
    }

    public getProductOrderInfo(productId: number): Observable<ProductOrdersInfoItem[]> {
        const url = `${API_URL}${BASE_URL}orders-placed/${productId}`;
        return this.http.get<Response>(url).pipe(
            map( response => {
                if (Array.isArray(response.data)) {
                    return response.data;
                }
                return [];
            }),
            catchError(this.handleError('ApiRecommendedProductsService::getProductOrderInfo', []))
        );
    }

    public exportRecommendedProductToExcel(productId: number): Observable<boolean> {
        return this.http.get<Response>(`${this.apiURL}orders-placed/${productId}/download/xlsx`).pipe(
            map(response => {
             return response.data === true
            }),
            catchError(this.handleError('ApiRecommendedProductsService::exportRecommendedProductToExcel', false))
        )
    }

    public exportRecommendedProductToPDF(productId: number) {
        return this.http.get<Response>(`${this.apiURL}orders-placed/${productId}/download/pdf`).pipe(
            map(response => {
                return response.data === true
            }),
            catchError(this.handleError('ApiRecommendedProductsService::exportRecommendedProductToPDF', false))
        )
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            const errorMessage = findDetailedErrorMessage(error);
            if (errorMessage) {
                this.toastr.error(errorMessage);
                console.log(operation + ' failed', error);
            }
            return of(result as T);
        };
    }


    private get xsrfTokenHeader() {
        return new HttpHeaders({'x-xsrf-token': ''});
    }

}
