import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Product } from '../../../models/product.model';
import { SKU } from '../../../models/sku.model';
import { isEmptyArray } from '../../../shared/utils';
import { CurrencyService } from '../../../services/currency.service';
import { IRunOnPrice, IPriceTiers } from 'app/interfaces/price-tiers';
import { ProductService } from 'app/services/product.service';

const TIER_WINDOW_WIDTH = 200;
const RANGE_WINDOW_WIDTH = 240;
const MAX_VISIBLE_TIERS = 10;

@Component({
    selector: 'app-pricing-tiers',
    templateUrl: './price-tiers.component.html',
    styleUrls: ['./price-tiers.component.scss'],
//    encapsulation: ViewEncapsulation.None
})
export class PriceTiersComponent implements OnInit, AfterViewInit {

    _quantity = 0;

    priceTiers: Array<IPriceTiers> = [];
    pricesRunOnRange: Array<IRunOnPrice> = [];

    placementPosition: string;

    @Input() product: Product;

    @Input() sku: SKU;

    @Input() showAsTable = false;

    @Input() modal = false;

    @Input() position: 'relative' | 'absolute' = 'absolute';

    @Input()
    set quantity(value: number) {
        this._quantity = value || 0;
        const tiers = ProductService.computePriceTiers(this.product, this.currentSKU, this._quantity);
        this.priceTiers = tiers.priceTiers;
    }

    constructor(private elementRef: ElementRef, private currencyService: CurrencyService) {
    }


    ngOnInit(): void {
        const tiers = ProductService.computePriceTiers(this.product, this.currentSKU, this._quantity);
        this.priceTiers = tiers.priceTiers;
    }

    ngAfterViewInit(): void {
        this.setDropdownPlacement();
    }


    @HostListener('window:resize')
    private onResize() {
        this.setDropdownPlacement();
    }


    private setDropdownPlacement() {
        // check if modal window is open then get size of modal window
        const modalWindow = document.querySelector('app-order-item-modal');
        let windowSize = 0;
        if (modalWindow) {
            windowSize = modalWindow.clientWidth;
        }
        if (!windowSize) {
            windowSize = window.innerWidth;
        }
        const div = this.elementRef.nativeElement.querySelector('.dropdown-toggle') as HTMLDivElement | null;
        if (div) {
            const {x} = div.getBoundingClientRect();
            setTimeout(() => {
                if (this.modal) {
                    this.placementPosition = 'bottom-right';
                } else {
                    this.placementPosition = (windowSize < x + this.windowSize) ? 'bottom-right' : 'bottom-left';
                }

            });
        }
    }

    private get windowSize(): number {
        return this.hasPriceRunOnRange ? RANGE_WINDOW_WIDTH : TIER_WINDOW_WIDTH;
    }

    private setPriceTierVisible() {
        const div = this.elementRef.nativeElement.querySelector('.dropdown-menu-content.pricing-tiers') as HTMLDivElement | null;
        if (!div) {
            return;
        }


        if (
          (isEmptyArray(this.priceTiers) || this.priceTiers?.length < MAX_VISIBLE_TIERS) &&
          (isEmptyArray(this.pricesRunOnRange) || this.pricesRunOnRange?.length < MAX_VISIBLE_TIERS)) {
            return;
        }

        // no need to scroll if visible lower than MAX_VISIBLE_TIERS
        let selectedPriceIdx = -1;
        if (this.priceTiers?.length > 0) {
            selectedPriceIdx = this.priceTiers.findIndex(p => p.selected);
        } else if (this.pricesRunOnRange?.length > 0) {
            selectedPriceIdx = this.pricesRunOnRange.findIndex(p => p.selected);
        }


        if (selectedPriceIdx < MAX_VISIBLE_TIERS - 1) {
            return;
        }


        const rootSelector = this.hasPricingTiers ? 'ul' : 'table';
        const selectedElement = this.hasPricingTiers ? 'li.is-bold' : 'tr.is-bold';

        // get selected  prices element
        const ul = div.querySelector(rootSelector) as HTMLUListElement;
        if (!ul) {
            return;
        }

        // get selected price element
        const priceLi = ul.querySelector(selectedElement) as HTMLLIElement;
        if (!priceLi) {
            return;
        }

        // add some offset for correct scrolling
        if (this.hasPricingTiers) {
            const offset = Math.max(priceLi.offsetTop - 35, 0);
            ul.scrollTo({
                top: offset,
                behavior: 'smooth'
            });
        } else {
            priceLi.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
        }
        // ul.scrollTop = priceLi.offsetTop;
    }


    get currentSKU(): SKU {
        return this.sku ? this.sku : this.product.skus[0];
    }

    checkDropDown(event, data) {
        if (event === true) {
            this.setPriceTierVisible();
        }
    }

    get hasScroll(): boolean {
        return this.priceTiers?.length > MAX_VISIBLE_TIERS || this.pricesRunOnRange?.length > MAX_VISIBLE_TIERS;
    }

    get hasPricingTiers(): boolean {
        if (!this.currentSKU) {
            return false;
        }
        return this.currentSKU.hasPricingTiers;
    }

    get hasPriceRunOnRange(): boolean {
        if (!this.currentSKU) {
            return false;
        }
        return this.currentSKU.hasPriceRunOnRange;
    }


    get showTiers(): boolean {
        if (!this.product || !this.currentSKU) {
            return false;
        }
        if (this.hasPricingTiers) {
            return !isEmptyArray(this.priceTiers);
        } else {
            return !isEmptyArray(this.pricesRunOnRange);
        }
    }


    get extraStyles(): any {
        const styles =  { 'width': `${this.windowSize}px`, 'z-index': 5 };
        if (this.position === 'relative') {
            styles['position'] = 'relative !important';
        }
        return styles;
    }
}


// purchase opitiosn - icon on photo
