import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import isEqual from 'lodash/isEqual';

import { Currency } from '../interfaces/currency';

export interface PriceFormatOptions {
    solelySymbol?: boolean;
    fractionDigits?: number;
    currency?: Currency;
}

// const CURRENCY_LOCATION_MAP = {
//     'AED': ['ar-AE'],
//     'AFN': ['fa-AF'],
//     'ALL': ['sq-AL'],
//     'AMD': ['hy-AM'],
//     'ANG': ['nl-AN'],
//     'AOA': ['pt-AO'],
//     'ARS': ['es-AR'],
//     'AUD': ['en-AU'],
//     'AWG': ['nl-AW'],
//     'AZN': ['az-Latn-AZ'],
//     'BAM': ['bs-Latn-BA', 'bs-Latn'],
//     'BBD': ['en-BB'],
//     'BDT': ['bn-BD'],
//     'BGN': ['bg-BG'],
//     'BHD': ['ar-BH'],
//     'BIF': ['fr-BI', 'en-BI'],
//     'BMD': ['en-BM'],
//     'BND': ['ms-BN', 'en-BN'],
//     'BOB': ['es-BO'],
//     'BRL': ['pt-BR', 'es-BR'],
//     'BSD': ['en-BS'],
//     'BTN': ['dz-BT'],
//     'BWP': ['en-BW'],
//     'BYN': ['be-BY'],
//     'BZD': ['en-BZ'],
//     'CAD': ['en-CA', 'fr-CA'],
//     'CDF': ['fr-CD', 'sw-CD'],
//     'CHF': ['de-CH', 'fr-CH', 'it-CH'],
//     'CLF': ['es-CL'],
//     'CLP': ['es-CL'],
//     'CNY': ['zh-CN'],
//     'COP': ['es-CO'],
//     'COU': ['es-CO'],
//     'CRC': ['es-CR'],
//     'CUC': ['es-CU'],
//     'CUP': ['es-CU'],
//     'CVE': ['pt-CV'],
//     'CZK': ['cs-CZ'],
//     'DJF': ['fr-DJ', 'aa-DJ', 'so-DJ', 'ar-DJ'],
//     'DKK': ['da-DK', 'en-DK', 'fo-DK', 'de-DK'],
//     'DOP': ['es-DO'],
//     'DZD': ['ar-DZ'],
//     'EGP': ['ar-EG'],
//     'ERN': ['ar-ER', 'ti-ER', 'en-ER'],
//     'ETB': ['am-ET', 'om-ET', 'ti-ET', 'so-ET', 'sid-ET', 'en-ET'],
//     'EUR': ['de-DE', 'de-AT', 'en-AT', 'en-AU', 'ca-AD', 'fr-BE', 'de-BE', 'nl-BE',
//         'bg-BG', 'ca-ES', 'hr-HR', 'da-DK', 'et-EE', 'fi-FI', 'fr-FR',  'el-GR', 'hu-HU', 'ga-IE', 'it-IT', 'lv-LV',
//         'lt-LT', 'lb-LU', 'mt-MT', 'nl-NL', 'pl-PL', 'pt-PT', 'ro-RO', 'sk-SK', 'sl-SI', 'es-ES', 'sv-SE'],
//     'FJD': ['en-FJ'],
//     'FKP': ['en-FK'],
//     'GBP': ['en-GB', 'cy-GB', 'gd-GB', 'gv-GB'],
//     'GEL': ['ka-GE'],
//     'GHS': ['en-GH'],
//     'GIP': ['en-GI'],
//     'GMD': ['en-GM'],
//     'GNF': ['fr-GN'],
//     'GTQ': ['es-GT'],
//     'GYD': ['en-GY'],
//     'HKD': ['zh-HK', 'en-HK'],
//     'HNL': ['es-HN'],
//     'HTG': ['fr-HT'],
//     'HUF': ['hu-HU'],
//     'IDR': ['id-ID'],
//     'ILS': ['he-IL', 'ar-IL', 'en-IL'],
//     'INR': ['hi-IN', 'bn-IN', 'te-IN', 'mr-IN', 'ta-IN', 'ur-IN', 'gu-IN', 'kn-IN', 'ml-IN', 'or-IN', 'pa-IN', 'as-IN'],
//     'IQD': ['ar-IQ', 'ku-IQ'],
//     'IRR': ['fa-IR'],
//     'ISK': ['is-IS'],
//     'JMD': ['en-JM'],
//     'JOD': ['ar-JO'],
//     'JPY': ['ja-JP'],
//     'KES': ['en-KE', 'sw-KE'],
//     'KGS': ['ky-KG', 'ru-KG'],
//     'KHR': ['km-KH'],
//     'KMF': ['ar-KM', 'fr-KM'],
//     'KPW': ['ko-KP'],
//     'KRW': ['ko-KR'],
//     'KWD': ['ar-KW'],
//     'KYD': ['en-KY'],
//     'KZT': ['kk-KZ', 'ru-KZ'],
//     'LAK': ['lo-LA'],
//     'LBP': ['ar-LB'],
//     'LKR': ['si-LK', 'ta-LK'],
//     'LRD': ['en-LR'],
//     'LSL': ['en-LS', 'st-LS'],
//     'LYD': ['ar-LY'],
//     'MAD': ['ar-MA', 'fr-MA', 'tzm-MA'],
//     'MDL': ['ro-MD'],
//     'MGA': ['fr-MG', 'mg-MG'],
//     'MKD': ['mk-MK'],
//     'MMK': ['my-MM'],
//     'MNT': ['mn-MN'],
//     'MOP': ['zh-MO', 'pt-MO'],
//     'MRU': ['ar-MR', 'fuc-MR', 'snk-MR', 'fr-MR', 'mey-MR', 'wo-MR'],
//     'MUR': ['en-MU'],
//     'MVR': ['dv-MV'],
//     'MWK': ['en-MW', 'ny-MW'],
//     'MXN': ['es-MX'],
//     'MXV': ['es-MX'],
//     'MYR': ['ms-MY'],
//     'MZN': ['pt-MZ'],
//     'NAD': ['en-NA', 'af-NA', 'de-NA', 'hz-NA', 'naq-NA'],
//     'NGN': ['en-NG', 'ha-NG', 'yo-NG', 'ig-NG', 'ff-NG'],
//     'NIO': ['es-NI'],
//     'NOK': ['nn-NO', 'nb-NO', 'se-NO', 'smj-NO', 'sma-NO', 'sms-NO'],
//     'NPR': ['ne-NP'],
//     'NZD': ['en-NZ', 'mi-NZ'],
//     'OMR': ['ar-OM'],
//     'PAB': ['es-PA'],
//     'PEN': ['es-PE'],
//     'PGK': ['en-PG'],
//     'PHP': ['en-PH', 'fil-PH'],
//     'PKR': ['ur-PK', 'en-PK'],
//     'PLN': ['pl-PL'],
//     'PYG': ['es-PY', 'gn-PY'],
//     'QAR': ['ar-QA'],
//     'RON': ['ro-RO'],
//     'RSD': ['sr-Latn-RS'],
//     'RUB': ['ru-RU'],
//     'RWF': ['rw-RW', 'en-RW', 'fr-RW'],
//     'SAR': ['ar-SA'],
//     'SBD': ['en-SB'],
//     'SCR': ['en-SC', 'fr-SC'],
//     'SDG': ['ar-SD', 'en-SD'],
//     'SEK': ['sv-SE'],
//     'SGD': ['en-SG', 'ms-SG', 'ta-SG', 'zh-SG'],
//     'SHP': ['en-SH'],
//     'SLL': ['en-SL'],
//     'SOS': ['so-SO', 'ar-SO'],
//     'SRD': ['nl-SR'],
//     'SSP': ['en-SS'],
//     'STN': ['pt-ST'],
//     'SVC': ['es-SV'],
//     'SYP': ['ar-SY'],
//     'SZL': ['en-SZ', 'ss-SZ'],
//     'THB': ['th-TH'],
//     'TJS': ['tg-Cyrl-TJ', 'ru-TJ'],
//     'TMT': ['tk-TM', 'ru-TM'],
//     'TND': ['ar-TN'],
//     'TOP': ['to-TO'],
//     'TRY': ['tr-TR'],
//     'TTD': ['en-TT'],
//     'TWD': ['zh-TW'],
//     'TZS': ['sw-TZ', 'en-TZ'],
//     'UAH': ['uk-UA'],
//     'UGX': ['sw-UG', 'en-UG'],
//     'USD': ['en-US', 'es-US'],
//     'USN': ['en-US', 'es-US'],
//     'UYI': ['es-UY'],
//     'UYU': ['es-UY'],
//     'UYW': ['es-UY'],
//     'UZS': ['uz-Latn-UZ', 'uz-Cyrl-UZ', 'ru-UZ'],
//     'VES': ['es-VE'],
//     'VND': ['vi-VN'],
//     'VUV': ['en-VU', 'fr-VU'],
//     'WST': ['en-WS', 'sm-WS'],
//     'XAF': ['fr-CF', 'sg-CF'],
//     'XCD': ['en-AG', 'en-AI', 'en-DM', 'en-GD', 'en-KN', 'en-LC', 'en-VC'],
//     'XOF': ['fr-BJ', 'ff-BJ', 'fr-CI', 'ff-CI', 'fr-GQ', 'fr-TG'],
//     'YER': ['ar-YE'],
//     'ZAR': ['en-ZA', 'zu-ZA', 'xh-ZA', 'tn-ZA', 'ts-ZA', 'ss-ZA', 've-ZA', 'af-ZA', 'st-ZA'],
//     'ZMW': ['en-ZM'],
//     'ZWL': ['en-ZW']
// }


@Injectable()
export class CurrencyService {
    public currency: Currency;

    public currencyChanged: Subject<boolean> = new Subject<boolean>();

    // private _locale: string;

    constructor() {
        // this._locale = this.browserLocale;
    }

    public setCurrentCurrency(currency: Currency): void {
        if (!isEqual(this.currency, currency)) {
            this.currency = currency;
            this.currencyChanged.next(true);
        }
    }

    public getFormattedPrice(price: number | string, option?: PriceFormatOptions): string {
        let fractionDigits = 2;
        let currency = this.currency;

        if (typeof price === 'string') {
            price = parseFloat(price);
        }

        if (option?.fractionDigits) {
            fractionDigits = option.fractionDigits;
        }

        if (option?.currency) {
            currency = option.currency;
        }

        // const locale = this.getLocaleForCurrency(currency.code)
        const intlPrice = new Intl.NumberFormat('en-US', { 
            minimumFractionDigits: fractionDigits, 
            maximumFractionDigits: fractionDigits
        }).format(price);

        const symbol = currency?.symbol
            ? currency.symbol
            : currency?.code && !currency?.symbol
            ? ''
            : '$';

        const code = currency?.code
            ? currency.code
            : currency?.symbol && !currency?.code
            ? ''
            : 'USD';

        return `${symbol}${intlPrice.replace(/\,/g, ' ')} ${!option.solelySymbol ? code : ''}`;
    }

    // private getLocaleForCurrency(currencyCode: string): string {
    //     const locales = CURRENCY_LOCATION_MAP[currencyCode];

    //     if (isEmptyArray(locales)) {
    //         // currency is not defined - return default locale
    //         return 'en-US';
    //     }

    //     if (locales.includes(this.browserLocale)) {
    //         return this.browserLocale;
    //     }

    //     return locales[0]; // return first available
    // }


    // private get browserLocale(): string {
    //     if (navigator.languages && navigator.languages.length) {
    //         return navigator.languages[0];
    //     } else {
    //         return navigator['userLanguage'] || navigator.language || navigator['browserLanguage'] || 'en-US';
    //     }
    // }
}

