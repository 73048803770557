<div class="modal-md news-modal">
    <div class="modal-header state">
        <button type="button" (click)="close()" class="btn btn-icon close" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
        <div class="scroll-container">
            <div [innerHTML]="content | safeContent"></div>
        </div>
        <div *ngIf='showCloseButton' class='text-align-center'>
            <button type="button" (click)="close()" class="btn btn-primary">Close</button>
        </div>
    </div>
</div>
