<div class="search-container" *ngIf="false">
    <div style="width: 130px" class="d-inline-block" *ngIf="canChooseOrderType">
        <ng-select  class="order-type--select font-5"
                   [items]="orderTypes" bindValue="id"  [searchable]="false"
                   [(ngModel)]="selectedOrderType"
                   (change)="selectOrderType($event)"
                   [clearable]="false">
            <ng-template ng-label-tmp let-item="item">
                {{ item.label }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                {{ item.label }}
            </ng-template>
        </ng-select>
    </div>
    <div class="search-input">
        <input type="text" placeholder="Search" autocomplete="off" class="form-control" [(ngModel)]="searchText" #searchInput>
        <button *ngIf="searchText" class="btn-close" (click)="clearSearch()"><i class="fal fa-times"></i></button>
        <button class="search-button" title="Search" (click)="search()">
            <i class="fal fa-search"></i>
        </button>
    </div>
</div>
