import { BaseModel } from './base.model';

export class Brand extends BaseModel {

    public id: number;
    public label: string;
    public image_url: string;

    constructor(data) {
        super(data, {
            num: ['id'],
        }, []);
    }
}
