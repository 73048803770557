import {ViewportScroller} from '@angular/common';

// const exceptPages = ['', '/'];
const exceptPages = [];



export class ScrollHelper {

    routeHistory: {url: string, scroll: [number, number]}[] = [];

    /**
     * smooth scrolling to selector
     * @param containerSelector
     */
    static scrollToElement(containerSelector: string) {
        const div = document.querySelector(containerSelector);
        if (div) {
            div.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
        }
    }

    /**
     * smooth scrolling to top
     */
    static scrollToTop() {
        ScrollHelper.scrollToElement('.app');
    }


    constructor(private viewportScroller: ViewportScroller) {}



    storeScroll( url: string): void {

        const scroll: [number, number] = this.viewportScroller.getScrollPosition();

        if ( scroll[1] !== 0 && url && exceptPages.indexOf(url) === -1 ) {
            this.routeHistory.push({url, scroll});
        }
    }

    handleScroll(url: string) {
        let scroll: [number, number] = [0, 0];
        if (url) {

            const idx = this.routeHistory.findIndex( route => route.url === url);
            if (idx !== -1) {
                scroll = this.routeHistory[idx].scroll;
            }

            // clean up history
            this.routeHistory = this.routeHistory.filter( route => route.url.indexOf(url) !== 0);
        }

        // in case of scroll position is different from 0 add more timeout
        const isTimeOut  = scroll[1] !== 0;
        setTimeout(() => {
            this.viewportScroller.scrollToPosition(scroll);
        }, isTimeOut ? 500 : 0);
    }
}
