import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { observe } from 'mobx';
import { AppSettings } from '../app.settings';
import { CacheModel } from '../models/cache.model';
import { ToastrService } from 'ngx-toastr';
import {findDetailedErrorMessage} from '../shared/helpers';


@Injectable()
export abstract class BaseService {

    protected apiURL: string;
    protected cache: CacheModel = null;

    constructor(
        protected serviceGroup: string,
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService
    ) {
        this.apiURL = this.appSettings.settings.apiBaseURL + this.serviceGroup;
        this.cache = new CacheModel();
    }

    public regenApiURL() {
        this.apiURL = this.appSettings.settings.apiBaseURL + this.serviceGroup;
    }

    public computeApiURL(serviceGroup: string) {
        return this.appSettings.settings.apiBaseURL + serviceGroup;
    }

    public toRx(prop) {
        return Observable.create(observer =>
            observe(this, prop, (change) => {
                observer.next(change.newValue)
            }, true)
        );
    }

    protected handleError<T>(operation = 'operation', result?: T, options: {hideResponseError?: boolean, customErrorText?: string} =  {}) {
        return (error: any): Observable<T> => {

            const errorMsg = findDetailedErrorMessage(error, options);
            if (errorMsg) {
                this.toastr.error(errorMsg, '', {enableHtml: true});
                console.log(operation + ' failed', error);
            }
            return of(result as T);

        };
    }

    public getApiURL() {
        return this.apiURL;
    }

    get baseUrl(): string {
        return this.appSettings.settings.apiBaseURL;
    }

    get xsrfTokenHeader() {
        return new HttpHeaders({'x-xsrf-token': ''});
    }
}
