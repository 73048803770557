<div class="products-item-price" [ngClass]="componentClasses">
    <ng-container *ngIf="!priceRange; else priceRangeView">
<!--        <span *ngIf="originalVisible" class="price"-->
<!--          [ngClass]="{'has-discount': hasDiscount || hasOriginalPrice}">{{computedOriginalPrice | currency}}</span>-->
<!--        <span *ngIf="hasDiscount || hasOriginalPrice" class="price discount">{{computedDiscountedPrice | currency}}</span>-->

        <span *ngIf="originalVisible" class="price"
              [ngClass]="{'has-discount': hasDiscount || hasOriginalPrice}"><app-currency-price [price]="computedOriginalPrice" [currency]="currency" [solelySymbol]="solelySymbol"></app-currency-price></span>
        <span *ngIf="hasDiscount || hasOriginalPrice" class="price discount"><app-currency-price [price]="computedDiscountedPrice" [currency]="currency" [solelySymbol]="solelySymbol"></app-currency-price></span>
    </ng-container>
</div>

<ng-template #priceRangeView>
    <div>
        <span *ngIf="originalMinVisible" class="price m-0"
              [ngClass]="{'has-discount': hasDiscount || hasOriginalMinPriceRange}"><app-currency-price [price]="computedOriginalMinPriceRange" [currency]="currency" [solelySymbol]="solelySymbol"></app-currency-price></span>
        <span *ngIf="hasDiscount || hasOriginalMinPriceRange" class="price discount"><app-currency-price [price]="computedOriginalMinPriceRange" [currency]="currency" [solelySymbol]="solelySymbol"></app-currency-price></span>
    </div>
    <ng-container *ngIf="priceRange.max">
        <p class="m-0" style="line-height: 1.3">&nbsp;-&nbsp;</p>
        <div>
            <span *ngIf="originalVisible || hasOriginalMaxPriceRange" class="price m-0"
              [ngClass]="{'has-discount': hasDiscount || hasOriginalMaxPriceRange}"><app-currency-price [price]="computedOriginalMaxPriceRange" [currency]="currency" [solelySymbol]="solelySymbol"></app-currency-price></span>
            <span *ngIf="hasDiscount || hasOriginalMaxPriceRange" class="price discount"><app-currency-price [price]="computedDiscountedMaxPriceRange" [currency]="currency" [solelySymbol]="solelySymbol"></app-currency-price></span>
        </div>
    </ng-container>
</ng-template>
