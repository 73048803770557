import { Component, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-order-item-modal',
    templateUrl: './csv-upload-result.component.html',
    styleUrls: ['./csv-upload-result.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CsvUploadResultComponent  {

    uploadResult: any = {};
    constructor(private activeModal: NgbActiveModal) {}

    close(result: any = {}) {
        this.activeModal.close(result);
    }

}

