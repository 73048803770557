import {BaseModel} from './base.model';
import {Product} from './product.model';
import {Order} from './order.model';
import {AppDate} from './date.model';
import {AutoShipState, stateColor, stateDescription} from '../enums/auto-ship-state.enum';
import {PRINTED_NUM_SIDES, TOTAL_NUM_PRINT_COLORS} from '../constants/auto-ship';
import {CostCenter} from './cost-center.model';
import {generateUUID} from '../shared/utils';

export class AutoShipItem extends BaseModel {

    id: number;
    order_id: number;
    item_id: number; // link to order item


    concept_image_url: string;
    foreign_sku_id: string;

    height: string;
    depth: string;
    width: string;

    order_quantity: number;
    requested_price: number;
    requested_delivery_date: string; // YYYY-MM-DD
    carton_factor: number;

    brand: string;
    sport_team: string;
    item_name: string;
    item_subtype: string;
    item_type: string;
    category: string;
    description: string;
    extra_information: string;
    agency_id: number;
    supplier_name
    contact_person_name
    contact_person_email
    contact_person_phone

    product_id: number;
    product: Product;
    order: Order;
    cost_center: CostCenter;


    printed_num_sides: string;
    total_num_print_colors: string;

    state: AutoShipState;
    substrate: string;
    updated_at: AppDate;

    user_id: number;

    spec: { key: string, value: any }[] = [];

    selected: boolean;

    uuid: string;

    metadata: any;
    editable = true;   // flag is set to false for re-run items, by default is true

    constructor(data) {
        super(data, {
            num: ['id', 'max_quantity', 'requested_price', 'product_id',
                'order_id', 'printed_num_slides', 'user_id'],
            date: ['updated_at']
        });

        this.fillSpec();

        if (data.cost_center) {
            this.cost_center = new CostCenter(data.cost_center);
        }

        this.uuid = data.uuid ? data.uuid : generateUUID();


        if (data.metadata) {
            Object.assign(this, data.metadata); // assign all properties  from metadata to this item
        }

    }

    // map properties to metadata
    beforeSave() {
        this.metadata =  {editable: this.editable};
    }

    get skuId(): string {
        return (this.product) ? this.product.firstSku.client_sku_number : this.foreign_sku_id;
    }


    get productName(): string {
        return this.product ? this.product.label : this.item_name;
    }

    get brandName(): string {
        return this.product ? this.product.getBrand() : '';
    }

    get programName(): string {
        return this.product ? this.product.getProgram() : '';
    }

    get imageURL(): string {
        return (this.product && this.product.media) ? this.product.media[0].url : this.concept_image_url;
    }


    get productDescription(): string {
        return (this.product && this.product.content) ? this.product.content.description : this.description;
    }

    get productsSpecs(): { key: string, value: string }[] {
        return (this.product) ? [...this.product.getSpecs(), ...this.product.geAutoShipSpecs()] : this.spec;
    }

    private fillSpec() {
        this.spec = [];

        if (this.brand) {
            this.spec.push({key: 'Brand', value: this.brand});
        }
        if (this.category) {
            this.spec.push({key: 'Category', value: this.category});
        }
        if (this.item_type) {
            this.spec.push({key: 'Type', value: this.item_type});
        }
        if (this.item_subtype) {
            this.spec.push({key: 'Sub-type', value: this.item_subtype});
        }

        if (this.height) {
            this.spec.push({key: 'HEIGHT', value: this.height});
        }
        if (this.depth) {
            this.spec.push({key: 'DEPTH', value: this.depth});
        }

        if (this.width) {
            this.spec.push({key: 'WIDTH', value: this.width});
        }

        if (this.printed_num_sides) {
            this.spec.push({key: 'Printed # sides', value: this.printNumSidesDescription});
        }

        if (this.total_num_print_colors) {
            this.spec.push({key: 'Total # of print colors', value: this.totalNumPrintColorsDescription});
        }

        if (this.substrate) {
            this.spec.push({key: 'Substrate', value: this.substrate});
        }
        if (this.carton_factor) {
            this.spec.push({key: 'Carton Factor', value: this.carton_factor});
        }

    }


    get printNumSidesDescription(): string {
        return this.printed_num_sides ? PRINTED_NUM_SIDES.find(i => i.name === this.printed_num_sides).description : '';
    }

    get totalNumPrintColorsDescription(): string {
        return this.total_num_print_colors ? TOTAL_NUM_PRINT_COLORS.find(i => i.name === this.total_num_print_colors).description : '';
    }

    // map to dropbox
    get label(): string {
        return this.item_name;
    }

    get stateDescription(): string {
        return stateDescription(this.state);
    }

    get stateColor(): string {
        return stateColor(this.state);
    }

    get canCopy(): boolean {
        return this.state === AutoShipState.CREATED;
    }

    get canModify(): boolean {
        return this.state === AutoShipState.CREATED;
    }

    get canAddDelivery(): boolean {
        return this.state === AutoShipState.READY  && this.product_id > 0;
    }

    get canSubmit(): boolean {
        return this.state === AutoShipState.READY && this.product_id > 0 && this.item_id > 0;
    }

    get isSubmitted(): boolean {
        return this.state === AutoShipState.SUBMITTED;
    }

    get isCancelled(): boolean {
        return this.state === AutoShipState.CANCELLED;
    }

    get isPurchasable(): boolean {
        return this.state === AutoShipState.READY;
    }

    get canShowOrder(): boolean {
        return this.state !== AutoShipState.CREATED
            && this.state  !== AutoShipState.PENDING
            && this.state  !== AutoShipState.SUBMITTED;
    }

    get canDelete(): boolean {
        return this.state === AutoShipState.CREATED;
    }

    get canCancel(): boolean {
        return this.state === AutoShipState.PENDING || this.state === AutoShipState.SUBMITTED;
    }

    get productImage(): string {
        return this.product ? this.product.mainImageUrl : this.concept_image_url;
    }
}
