<!--in stock-->
<ng-container *ngIf="product">
    <div class="d-flex">
        <ng-container *ngIf="product.inventoryType; else nonInventoryView">
            <ng-container *ngTemplateOutlet="viewType === 'grid-item' ? gridView : (viewType === 'sku-item' ?  skuView : detailedView)"></ng-container>
        </ng-container>
    </div>
</ng-container>

<ng-template #gridView>
    <div class="product--quantity text-align-left" [ngClass]="className">
        <ng-container *ngIf="isOutOfStock || inStockQuantity > 0">
            <div class="info-item items-in-stock">
                <span *ngIf="isOutOfStock" class="out-of-stock color-red font-weight-bold">Out Of Stock</span>
                <ng-container *ngIf="inStock && inStockQuantity > 0">
                    <strong class="quantity">{{ inStockQuantity }}</strong>
                    <span>&nbsp;In Stock</span>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="hasPendingQuantity">
            <div class="info-item items-on-order">
                <strong class="quantity">{{ pendingQuantity }}</strong>
                <span>&nbsp;On Order</span>
            </div>
        </ng-container>
        <div class="info-item" *ngIf="inStockQuantity > 0 && shippingTiming">
            <span>{{ shippingTiming }}</span>
        </div>
    </div>
</ng-template>

<ng-template #skuView>
    <div class="product--quantity full-width d-flex flex-column align-items-start justify-content-center" [ngClass]="className">
        <ng-container *ngIf="(inStockQuantity === 0 && pendingQuantity === 0)">
            <div class="info-item items-in-stock full-width">
                <strong class="quantity sku-quantity empty"></strong>
                <span class="out-of-stock color-red font-weight-bold">Out Of Stock</span>
            </div>
        </ng-container>
        <ng-container *ngIf="(inStockQuantity > 0 || pendingQuantity > 0)">
            <div class="info-item items-in-stock">
                <strong class="quantity sku-quantity">{{ inStockQuantity }}</strong>
                <span>Items in Stock</span>
            </div>
            <div class="info-item items-on-order" *ngIf="pendingQuantity">
                <strong class="quantity sku-quantity">{{ pendingQuantity }}</strong>
                <span>Items on Order</span>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #detailedView>
    <div class="product--quantity" [ngClass]="className">
        <ng-container *ngIf="(inStockQuantity === 0 && pendingQuantity === 0)">
            <div class="info-item items-in-stock">
                <span class="out-of-stock color-red font-weight-bold">Out Of Stock</span>
            </div>
        </ng-container>
        <ng-container *ngIf="(inStockQuantity > 0 || pendingQuantity > 0)">
            <div class="info-item items-in-stock">
                <span>Availability: </span>
                <span class="quantity">{{ inStockQuantity }}</span>
                <span>&nbsp;in Stock</span>
                <span *ngIf="inStockQuantity > 0 && shippingTiming">&nbsp;|&nbsp;{{shippingTiming}}</span>
            </div>
            <div class="info-item items-on-order" *ngIf="pendingQuantity">
                <span>On Order: </span>
                <span class="quantity">{{ pendingQuantity }}</span>
                <span *ngIf="inStockDate">&nbsp;|&nbsp;Due:&nbsp;{{ inStockDate | dateFormat: 'MMM D, YYYY formatAsLocal'}}</span>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #nonInventoryView>
    <div *ngIf="!product.isBuyingWindow" class="product--quantity" [ngClass]="className">
        <div class="info-item items-in-stock">
            <strong class="quantity sku-quantity empty" *ngIf="viewType === 'sku-item'"></strong>
            <span>{{ shippingTiming ? shippingTiming : 'Ready to Ship'}}</span>
        </div>
    </div>
</ng-template>
