import {BaseModel} from './base.model';

export class NotificationSettings extends BaseModel {
    email_daily_digest: number
    email_od_order_confirm: number;
    email_bw_shipment_notification: number;
    email_od_shipment_notification: number;
    email_as_shipment_notification: number;

    constructor(data) {
        super(data, {
            num: ['email_daily_digest',
                'email_od_order_confirm',
                'email_bw_shipment_notification',
                'email_od_shipment_notification',
                'email_as_shipment_notification'
            ]
        });
    }


    get daily(): boolean {
        return  !!this.email_daily_digest;
    }

    set daily(val: boolean) {
        this.email_daily_digest = val ? 1 : 0;
    }

    get orderConfirm(): boolean {
        return  !!this.email_od_order_confirm;
    }

    set orderConfirm(val: boolean) {
        this.email_od_order_confirm = val ? 1 : 0;
    }

    get bwShipment(): boolean {
        return  !!this.email_bw_shipment_notification;
    }

    set bwShipment(val: boolean) {
        this.email_bw_shipment_notification = val ? 1 : 0;
    }

    get odShipment(): boolean {
        return  !!this.email_od_shipment_notification;
    }

    set odShipment(val: boolean) {
        this.email_od_shipment_notification = val ? 1 : 0;
    }

    get asShipment(): boolean {
        return  !!this.email_as_shipment_notification;
    }

    set asShipment(val: boolean) {
        this.email_as_shipment_notification = val ? 1 : 0;
    }

}
