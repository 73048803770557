import * as _ from 'lodash';

export class CacheModel {
    public persistent: boolean = false;
    public prefix: string = null;
    public expiration: number; // seconds, ignored if 0

    private data: Object = {};
    private store: any = window.localStorage;

    constructor(expiration = 0) {
        this.expiration = expiration;
    }

    public has(key) {
        if (!key) {
            return false;
        }

        if (this.expiration > 0 && key.indexOf('-expiration') > -1) { return true; }

        const keyExists = (this.persistent && this.store) ?
            (this.store.getItem(this.prefix + '-' + key) != null) : this.data.hasOwnProperty(key);

        if (keyExists && this.expiration == 0) { return true; }
        return (keyExists && Date.now() < Number(this.get(key + '-expiration')));
    }

    public get(key) {
        if (!key) {
            return null;
        }

        if (this.has(key)) {
            if (this.persistent && this.store) {
                const value = this.store.getItem(this.prefix + '-' + key);
                const first = value.substr(0, 1);
                return (typeof value === 'string' && (first === '{' || first === '[')) ? JSON.parse(value) : value;
            }

            return this.data[key];
        }

        return null;
    }

    public getItems(key) {
        return this.getItemArray(key);
    }

    public getItemArray(key) {
        let items = [];
        _.each(this.getIDs(key), (id) => {
            if (this.has(key + '-' + id)) {
                items.push(this.get(key + '-' + id));
            }
        });

        return items;
    }

    public getItemAssoc(key) {
        let items = {};
        _.each(this.getIDs(key), (id) => {
            if (this.has(key + '-' + id)) {
                items[id] = this.get(key + '-' + id);
            }
        });

        return items;
    }

    public set(key, value) {
        if (!key) {
            return;
        }

        if (this.persistent && this.store) {
            if (typeof value == 'object') {
                value = JSON.stringify(value);
            }

            this.store.setItem(this.prefix + '-' + key, value);
            if (this.expiration > 0) {
                this.store.setItem(this.prefix + '-' + key + '-expiration', Date.now() + (this.expiration * 1000));
            }
        } else {
            this.data[key] = value;
            if (this.expiration > 0) {
                this.data[key + '-expiration'] = Date.now() + (this.expiration * 1000);
            }
        }
    }

    public setItems(key, items) {
        let ids = this.has(key + 'IDs') ? this.get(key + 'IDs') : [];

        _.each(items, (item, i) => {
            var id = (item.hasOwnProperty('id')) ? item.id : i;
            if (ids.indexOf(id) == -1) { ids.push(id); }
            this.set(key + '-' + id, item);
        });

        this.set(key, true);
        this.set(key + 'IDs', ids);
    }

    public delete(key) {
        (this.persistent && this.store) ? this.store.removeItem(this.prefix + '-' + key) : delete this.data[key];
    }

    public deleteItems(key) {
        _.each(this.getIDs(key), (id) => {
            this.delete(key + '-' + id);
            this.delete(key + '-' + id + '-expiration');
        });

        this.delete(key);
        this.delete(key + 'IDs');
        this.delete(key + '-expiration');
    }

    public clear() {
        (this.persistent && this.store) ? this.store.clear() : this.data = {};
    }

    protected getIDs(key) {
        if (this.has(key + 'IDs')) {
            return this.get(key + 'IDs');
        }

        return [];
    }

    public getETag(key: string, ignoreCount: boolean = false) {
        let eTag: string = (this.has(key) && this.has(key + '-eTag')) ? this.get(key + '-eTag') : '';

        if (eTag && eTag.indexOf('-') > -1) {
            let cnt = Number(eTag.split('-')[1]);

            if (!ignoreCount && this.getItems(key).length != cnt) {
                eTag = '';
            }
        }

        return eTag;
    }

    public setETag(key, eTag) {
        this.set(key + '-eTag', eTag);
    }
}
