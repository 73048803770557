<div class="modal-md credit-card-result-modal">
    <div class="modal-header state">
        <div style="width: 100%;">
            <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label">Order #{{orderId}}</h1>
            <div class="header--line line-separator--light-grey"></div>

        </div>
        <button type="button" (click)="close()" class="btn btn-icon close" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
        <div class="result--container">
            <h3 class="text-center color-red mt-3">Your Card was unable to processed</h3>
            <div class="mt-4 line-separator--light-grey pb-3">
                <div class="d-flex details--info">
                    <span class="title uppercase font-weight-bold">Transaction #</span>
                    <span>{{ transactionId }}</span>
                </div>
                <div class="d-flex mt-3 details--info">
                    <span class="title uppercase font-weight-bold">Payment Method</span>
                    <span>{{ paymentMethod }}</span>
                </div>
            </div>
            <div class="mt-3">
                <div class="d-flex details--info">
                    <span class="title uppercase">Total Cost</span>
                    <span class="price"><app-currency-price [price]="orderCost" [currency]="order.currency"></app-currency-price></span>
                </div>
            </div>

            <p class="font-5 mt-4 text-center">Please close this window and use a different payment method to process your order.
            If you continue to have issues with your credit card, please reach out to your issuing bank.
            </p>

        </div>
    </div>
</div>
