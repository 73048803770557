import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';

import {Product} from '../../../../models/product.model';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-favorite-toggle',
  templateUrl: './favorite-toggle.component.html',
  styleUrls: ['./favorite-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoriteToggleComponent implements OnInit {

  @Input() product: Product;
  @Input() isUpdating = false;
  @Output() toggleFavorite = new EventEmitter<Product>();
  @Output() close = new EventEmitter<void>();

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
  }

  toggleFavorites() {
    if (!this.isUpdating) {
      this.toggleFavorite.emit(this.product);
    }
  }

  navigateToFavorites(event: Event) {
    event.preventDefault();
    this.close.emit();
    const isBWProduct = this.product.isOnDemand !== true ? {bw: 1} : null;
    this.router.navigate(['/favorites'], {queryParams: isBWProduct});
  }

  get canSeeFavorites(): boolean {
    return this.authService.canSeeFavorites;
  }
}
