import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-infinite-progress',
  templateUrl: './infinite-progress.component.html',
  styleUrls: ['./infinite-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfiniteProgressComponent {

  @Input() inProgress = false;

  @Input() label = '';

  @Input() size: 'default' | 'container-fit' = 'default';

  constructor() { }

}
