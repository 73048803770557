import {
    Component,
    ElementRef, EventEmitter, HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {Router} from '@angular/router';

import { SearchService } from '../../services/search.service';
import { OrderService } from '../../services/order.service';

const MIN_SYMBOLS_TO_SEARCH = 3;

@Component({
    selector: 'app-global-search',
    templateUrl: './global-search-container.component.html',
    styleUrls: ['./global-search-container.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GlobalSearchContainerComponent implements OnInit {
    @Input() showBackdrop = true;

    @Output() closed = new EventEmitter<void>();

    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

    searchText = '';

    constructor(
        private searchService: SearchService,
        private elementRef: ElementRef,
        private router: Router,
        private orderService: OrderService,
    ) {
    }

    ngOnInit() {
        this.searchText = this.searchService.globalTerm || '';

        this.setFocus();
    }

    close() {
        this.closed.emit();
    }

    clearSearch() {
        // this.searchInput.nativeElement.value = '';
        this.searchText = '';
        this.searchService.clearSearchResults();
        this.setFocus();

        if (!this.isSearchResultPage) {
            this.close();
        }
    }

    search() {


        if (this.searchText && this.searchText.length >= MIN_SYMBOLS_TO_SEARCH) {
            this.searchService.globalTerm = this.searchText;
            this.searchService.currentBuyingWindowId = this.orderService.currentOrderWindowId;

            this.searchService.productsSearch().subscribe( () => {});
            if (!this.isSearchResultPage) {
                this.router.navigate(['/search-results']).then(() => {});
            }
        }

        if (this.searchText === '') {
            this.searchService.clearSearchResults();
        }

    }

    private setFocus() {
        setTimeout(() => {
            this.searchInput.nativeElement.focus();
        }, 500);
    }


    @HostListener('keydown', ['$event'])
    onKeyDown(e: KeyboardEvent) {
        if (e.key === 'Enter') {
            this.search();
        }

        if (e.key === 'Escape') {
            // exit from search mode
            this.closed.emit();
        }
    }


    get isSearchResultPage(): boolean {
        return this.router.url.indexOf('search-result') !== -1;
    }

    // @HostBinding('style.z-index') get zIndex(): string {
    //     return this.isSearchResultPage ? '-1' : '5';
    // };

    @HostBinding('class.fixed') get isFixed(): boolean {
        return this.isSearchResultPage;
    };

}
