import { BaseModel } from './base.model';

export interface CountryState {
    abbr: string, label: string
}


export class Country extends BaseModel {
    public isoCode: string;
    public label: string;
    public states: CountryState[] = [];

    constructor(data) {
        super(data);
        if (data.states) {
           this.states = data.states;
        }
    }
}
