import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {debounceTime, distinctUntilChanged, filter, switchMap, takeWhile, tap} from 'rxjs/operators';
import {from, of, Subject} from 'rxjs';

import {Order} from '../../../models/order.model';
import {CostCenter} from '../../../models/cost-center.model';
import {CreditCard} from '../../../models/credit-card.model';
import {PaymentService} from '../../../services/payments.service';
import {PaymentMethod} from '../../../models/payment-method.model';
import {ECOMMERCE_TYPE} from '../../../constants/order-types';
import {ConfigService} from '../../../services/config.service';
import {AuthService} from '../../../services/auth.service';
import {ModalService} from '../../../services/modal.service';
import {ToastrService} from 'ngx-toastr';
import {DoaService} from '../../../services/doa.service';
import {Doa} from '../../../models/doa.model';
import {isEmptyArray} from '../../../shared/utils';
import {DOA_EXCEED_LIMIT, INVALID_SAP} from '../../../constants/error.codes';
import {MobileCheckServiceService} from '../../../services/mobile-check-service.service';

const ACCOUNT_PAYMENT_TYPE = 'account';
const CREDIT_CARD_PAYMENT_TYPE = 'credit-card';


@Component({
    selector: 'app-payment-method-selector',
    templateUrl: './payment-method-selector.component.html',
    styleUrls: ['./payment-method-selector.component.scss']
})
export class PaymentMethodSelectorComponent implements OnInit, OnDestroy, AfterViewInit {

    private alive = true;
    private allEmployee: Doa[] = [];
    _order: Order;


    @Input() showCreditCardInfo = false;
    @Input() showDOA = false;
    @Input() verifyingSAP = false;
    @Input() updatingPaymentMethod = false;
    @Input() useDefaultPayment = false;

    @Output() savedCard = new EventEmitter<boolean>();
    @Output() paymentChanged = new EventEmitter<PaymentMethod>();


    selectedCostCenter: CostCenter;
    selectedCostCenterId: number;
    selectedCreditCard: CreditCard;
    selectedCreditCardId: number;
    selectedEmployeeId: string;

    paymentType = ''; // by default


    // sap search
    sapSearchResults: CostCenter[] = [];
    sapSearchLoading = false;
    sapSearchSubject: Subject<string> = new Subject<string>();
    private sapSearchTerm: string;

    // credit card search
    ccSearchResults: CreditCard[] = [];
    ccSearchLoading = false;
    ccSearchSubject: Subject<string> = new Subject<string>();


    // sap search
    employeeSearchResults: Doa[] = [];
    employeeSearchLoading = false;
    employeeSearchSubject: Subject<string> = new Subject<string>();

    saveCard = false;
    isSaveCheckboxVisible = false;


    creditCards: CreditCard[] = [];
    costCenters: CostCenter[] = [];

    private initialized = false;

    radioButtonName = '';
    accountButtonId = '';
    creditCardButtonId = '';



    @Input() set order(val: Order) {
        if (val) {
            this._order = val;

            if (!this.initialized) {
                this.initSAPSearch();
                this.initCCSearch();
                this.addCreditCartToList(this._order.creditCard);
                this.initialized = true;
            }
        }
    }

    constructor(private paymentService: PaymentService,
                private config: ConfigService,
                private authService: AuthService,
                private modalService: ModalService,
                private toastr: ToastrService,
                private doaService: DoaService,
                private mobileCheck: MobileCheckServiceService,
                private configService: ConfigService
                ) {

        this.radioButtonName = `payment-type-${new Date().valueOf()}`;
        this.accountButtonId = `radio_account-${new Date().valueOf()}`;
        this.creditCardButtonId = `radio_credit_card-${new Date().valueOf()}`;
    }

    ngOnInit(): void {
        this.initEmployeeSearch();
    }

    ngAfterViewInit() {
        this.initSelectedFields(this._order);
    }

    ngOnDestroy(): void {
        this.alive = false;
        this._order = null;
    }


    // add new sap
    addSap() {

        const newCostCenter = new CostCenter({
            company_billing_entity: this.userBillingEntity,
            vendor_number: this.userVendorNumber
        })

        this.modalService.showSapAccountDialog(newCostCenter).then( costCenter => {
            if (costCenter) {
                // add new cost center to dropdown
                this.costCenters.push(costCenter);
                this.sapSearchResults = this.costCenters.slice();

                this.setPaymentMethod(costCenter);
                this.paymentChanged.emit(costCenter);
            }
        })
    }

    addCreditCard() {
        this.paymentService.getPaymentJsToken(this._order.id).pipe( switchMap( result => {
            if (!result) {
                this.toastr.error('Cannot add a new credit card to the order. Please contact the support.');
                return of(null);
            } else {
                return from(this.modalService.showCreditCardDialog(this._order.id, result.creditCard, result.token, this.saveCard));
            }
        })).subscribe( creditCard => {
            if (creditCard) {
                this.checkCreditCard(creditCard);
                this.paymentChanged.emit(creditCard);
            }
        });
    }





    onSelectPaymentMethod(paymentMethod: PaymentMethod | any) {
        this.setPaymentMethod(paymentMethod);
        this.selectDOA();
        this.paymentChanged.emit(paymentMethod);
    }

    onSaveCreditCard() {
        this.savedCard.emit(this.saveCard);
    }

    private setPaymentMethod(val: PaymentMethod) {
        if (!val) {
            return;
        }

        if (val.isAccount()) {
            this.selectedCostCenter = new CostCenter(val as CostCenter);
            this.selectedCostCenterId = this.selectedCostCenter.id;
            this.selectDOA();

        } else if (this.canUseCreditCards) {
            this.selectedCreditCard = new CreditCard(val as CreditCard);
            this.selectedCreditCardId = this.selectedCreditCard.id;
        }


    }

    private checkCreditCard(card: CreditCard) {
        if (card) {
            this.isSaveCheckboxVisible = true;
            this.addCreditCartToList(card);
            this.setPaymentMethod(card);
        }
    }

    private addCreditCartToList(card: CreditCard) {
        if (card) {
            const foundIndex = this.creditCards.findIndex( c => c.id === card.id);
            if (foundIndex === -1) {
                this.creditCards.push(card);
            } else {
                this.creditCards[foundIndex] = card;
            }
        }
        this.ccSearchResults = this.creditCards.slice();
    }

    paymentTypeChanged() {
        let paymentMethod: PaymentMethod;
        if (this.paymentType === ACCOUNT_PAYMENT_TYPE) {
            paymentMethod = this.canShowPaymentMethods ? this.selectedCostCenter : null;
        } else {
            paymentMethod = this.selectedCreditCard;
        }
        this.onPaymentChanged(paymentMethod);
    }


    private filterEmployee(employeeList: Doa[], term: string): Doa[] {
        if (isEmptyArray(employeeList) || !term) {
            return employeeList;
        }

        const searchText = term.trim().toLowerCase();
        return this.allEmployee.filter( emp =>  emp.fullName.toLowerCase().indexOf(searchText) !== -1);
    }


    get hasEmployees(): boolean {
        return !isEmptyArray(this.allEmployee);
    }

    get performingUpdate(): boolean {
        return this.verifyingSAP || this.updatingPaymentMethod;
    }

    onSelectEmployee(val: Doa) {

        if (!val || !this.selectedCostCenter) {
            return;
        }
        this.selectedEmployeeId = val.employee_id;

        const newCostCenter = new CostCenter(this.selectedCostCenter);
        // newCostCenter.employee_id = val.employee_id;
        // newCostCenter.first_name = val.first_name;
        // newCostCenter.last_name = val.last_name;
        // newCostCenter.limit_per_po = +val.limit_per_po;

        this.paymentChanged.emit(newCostCenter);
    }


    get isLimitExceeded(): boolean {
        if (!this._order) {
            return false;
        }

        return this._order.error?.errorCode === DOA_EXCEED_LIMIT;

    }

    get isSapInvalid(): boolean {
        if (!this._order) {
            return false;
        }

        return this._order.error?.errorCode === INVALID_SAP;

    }

    private onPaymentChanged(paymentMethod: PaymentMethod) {
        this.paymentChanged.emit(paymentMethod);
    }

    private setPaymentType() {
        if (this.paymentType) {
            return;
        }

        if (this.selectedCreditCard) {
            this.paymentType = CREDIT_CARD_PAYMENT_TYPE;
        } else {
            this.paymentType = ACCOUNT_PAYMENT_TYPE;
        }
    }

    // init CostCenter dropdown
    private initSAPSearch() {
        if (!this.canShowPaymentMethods) {
            return;
        }
        this.costCenters = [...this.paymentService.sapAccounts];
        this.sapSearchResults = this.costCenters;

        this.sapSearchSubject.pipe(
            takeWhile(() => this.alive),
            debounceTime(400),
            distinctUntilChanged(),
            tap(() => {
                this.sapSearchLoading = true;
            }),
        ).subscribe(term => {
            this.sapSearchTerm = term;
            this.sapSearchResults = this.paymentService.findPaymentMethodsByTerm<CostCenter>(term, this.paymentService.sapAccounts);
            this.sapSearchLoading = false;
        });

    }

    // init CreditCard dropdown
    private initCCSearch() {
        if (!this.canUseCreditCards) {
            return;
        }

        this.creditCards = [...this.paymentService.creditCards];
        this.ccSearchResults = this.creditCards;

        this.ccSearchSubject.pipe(
            takeWhile(() => this.alive),
            debounceTime(400),
            distinctUntilChanged(),
            tap(() => {
                this.ccSearchLoading = true;
            }),
        ).subscribe(term => {
            if (term.length > 0) {
                this.ccSearchResults = this.paymentService.findPaymentMethodsByTerm<CreditCard>(term, this.paymentService.creditCards);
            }
            this.ccSearchLoading = false;
        });

    }


    // init Employee dropdown
    private initEmployeeSearch() {
        if (!this.showDOA) {
            return;
        }
        this.doaService.fetchAllEmployee().subscribe( result => {
            this.allEmployee = result;
            this.employeeSearchResults = this.filterEmployee(this.allEmployee, '');
            this.selectDOA();
        });

        this.employeeSearchSubject.pipe(
            takeWhile(() => this.alive),
            debounceTime(400),
            filter( search => (search && search.length > 0)),
            tap(() => {
                this.employeeSearchLoading = true;
            }),
        ).subscribe(term => {
            this.employeeSearchResults = this.filterEmployee(this.allEmployee, term);
            this.employeeSearchLoading = false;
        });

    }

    private selectDOA() {
        // if (this.selectedCostCenter && this.selectedCostCenter.employee_id && !this._order.error) {
        //     // check if user exists
        //     this.selectedEmployeeId = this.allEmployee.find( e => e.employee_id === this.selectedCostCenter.employee_id)?.employee_id;
        // }
    }

    private initSelectedFields(order: Order) {
        if (!order) {
            return;
        }

        if (order.costCenter) {
            this.selectedCostCenter =  order.costCenter;
        }

        if (order.creditCard && order.isOnDemand) {
            this.selectedCreditCard = order.creditCard;
        }

        if (!this.selectedCreditCard && !this.selectedCostCenter) {
            //  for employee  one payment method must be selected
            if (this.useDefaultPayment) {
                const paymentMethod = this.paymentService.getDefaultPaymentForOrder(order);
                if (paymentMethod) {
                    if (paymentMethod.isAccount()) {
                        this.selectedCostCenter = paymentMethod as CostCenter;
                    } else {
                            this.selectedCreditCard = paymentMethod as CreditCard;
                    }
                    setTimeout(() => {
                        this.paymentChanged.emit(paymentMethod);
                    });
                }
            }
        }


        setTimeout(() => {
            this.selectedCostCenterId  = this.selectedCostCenter?.id;
            this.selectedCreditCardId = this.selectedCreditCard?.id;
            this.selectDOA();
            this.setPaymentType();
        });

    }

    get canAddCreditCard(): boolean {
        return this.authService.canEditCreditCard;
    }

    get canUseCreditCards(): boolean {
        return (this._order  && this._order.type === ECOMMERCE_TYPE) && this.authService.canEditCreditCard;
    }

    get canAddSAP(): boolean {
        return this.authService.canManagePaymentMethods;
    }

    get canShowPaymentMethods(): boolean {
        return this.authService.canShowSAP;
    }

    get mobileDevice(): boolean {
        return this.mobileCheck.mobileDevice;
    }

    private get userBillingEntity(): string {
        return this.authService.user?.attr_company_billing_entity || '';
    }

    private get userVendorNumber(): string {
        return this.authService.user?.attr_vendor_number || '';
    }

}
