<block-ui [message]="'Loading...'" [delayStart]="500">
    <div class="app" [ngClass]="settings.theme.skin" [class.mobile]="mobile"
    [class.navbar-fixed]="settings.theme.navbarIsFixed">
        <div class="wrapper">
            <app-header *ngIf="headerVisible"></app-header>
            <router-outlet></router-outlet>
        </div>
    </div>
</block-ui>
<app-public-api></app-public-api>
