<div class="credit-card-modal">
    <div class="modal-header state">
        <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label"> <i class="fal fa-credit-card"></i> ADD NEW CREDIT CARD </h1>
        <button type="button" (click)="hideDialog()" class="btn btn-icon close" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">

        <form class="row px-3" [formGroup]="cardForm" (ngSubmit)="saveCreditCard($event)" #cardRef>
            <div class="col-12">
                <div class="input-group full-width mb-0" [ngClass]="validationClassName(label)">
                    <label for="card-name">Name of Card <span class="lowercase">(Name Your Entry On {{projectLabel}} For Future Use)</span>
                        &nbsp;<span class="text-danger font-6"  [ngStyle]="validationLabelStyle(label)">Name is required</span>
                    </label>
                    <input [formControl]="label" type="text" name="card-name" id="card-name" placeholder="My Corporate Card">
                </div>
            </div>


            <div class="col-12 my-2">
                Please input your credit card information below. An authorization will be put on your credit card for the total amount of the order placed. You will be charged when your item(s) are shipped. This may result in multiple charges appearing on your card.<br/>
                Note: We accept VISA, Mastercard, and AMEX as payment.
            </div>

            <div class="col-sm-12 col-md-6">
                <div class="input-group full-width" [ngClass]="{'input-danger' : creditCardHolderNameIsInvalid}" >
                    <label for="cardholder-name">Cardholder Name
                    &nbsp;<span class="text-danger font-6"  [ngStyle]="validationLabelStyle(creditCardHolderNameIsInvalid)">Cardholder Name is not valid</span>
                    </label>
                    <div class="form-controls payment-fields" id="cardholder-name" data-cardholder-name></div>
                </div>
            </div>

            <div class="col-sm-12 col-md-6">
                <div class="input-group full-width" [ngClass]="{'input-danger' : creditCardNumberIsInvalid || creditCardTypeIsInvalid}">
                    <label for="cc-number">Credit Card Number
                        &nbsp;<span class="text-danger font-5"  [ngStyle]="validationLabelStyle(creditCardNumberIsInvalid)">Credit Card Number is not valid</span>
                        <span class="text-danger font-5"  [ngStyle]="validationLabelStyle(creditCardTypeIsInvalid)">This card type is not accepted. Please use different card.</span>
                    </label>
                    <div class="form-controls payment-fields" id="cc-number" data-cc-card></div>
                </div>
            </div>

            <div class="col-sm-12 col-md-6">
                <div class="input-group" [ngClass]="{'input-danger' : creditCardExpIsInvalid}">
                    <label for="cc-expiry">Expiry Date
                        &nbsp;<span class="text-danger font-6"  [ngStyle]="validationLabelStyle(creditCardExpIsInvalid)">Expiry Date is not valid</span>
                    </label>
                    <div class="form-controls payment-fields" id="cc-expiry" data-cc-exp></div>
                </div>
            </div>

            <div class="col-sm-12 col-md-6">
                <div class="input-group" [ngClass]="{'input-danger' : creditCardCVVIsInvalid}">
                    <label for="cc-cvv">Security Code <span class="lowercase">(CVV)</span>
                        &nbsp;<span class="text-danger font-6"  [ngStyle]="validationLabelStyle(creditCardCVVIsInvalid)">Security Code is not valid</span>
                    </label>
                    <div class="form-controls payment-fields" id="cc-cvv" data-cc-cvv></div>
                </div>
            </div>

            <div class="col-12">
                <div class="input-group full-width" [ngClass]="validationClassName(street)">
                    <label for="card-name">Street Address
                        &nbsp;<span class="text-danger font-6"  [ngStyle]="validationLabelStyle(street)">Street Address is required</span>
                    </label>
                    <input [formControl]="street" type="text" name="street" id="street" placeholder="Enter Billing Street Address for this Card">
                </div>
            </div>


            <div class="col-sm-12 col-md-6">
                <div class="input-group" [ngClass]="validationClassName(city)">
                    <label for="cc-cvv">City
                        &nbsp;<span class="text-danger font-6"  [ngStyle]="validationLabelStyle(city)">City is required</span>
                    </label>
                    <input [formControl]="city" type="text" name="city" id="city" placeholder="Enter Billing City">
                </div>
            </div>

            <div class="col-sm-12 col-md-6">
                <div class="input-group" [ngClass]="validationClassName(state)">
                    <label for="cc-cvv">State / Province / Region</label>
                    <input [formControl]="state" type="text" name="state" id="state" placeholder="Enter Billing State">
                </div>
            </div>

            <div class="col-sm-12 col-md-6">
                <div class="input-group" [ngClass]="validationClassName(zip_code)">
                    <label for="cc-cvv">ZIP / Postal Code
                        &nbsp;<span class="text-danger font-6"  [ngStyle]="validationLabelStyle(zip_code)">Postal Code is required</span>
                    </label>
                    <input [formControl]="zip_code" type="text" name="zip" id="zip" placeholder="Enter Billing Postal Code">
                </div>
            </div>

            <div class="col-sm-12 col-md-6">
                <div class="input-group"   [ngClass]="{'input-danger' :  selectedCountry == ''}">
                    <label for="cc-cvv">Country
                        &nbsp;<span class="text-danger font-6"  [ngStyle]="validationLabelStyle(selectedCountry)">Country is required</span>
                    </label>
                    <ng-select
                            class="custom-select__dropdown"
                            formControlName="country"
                            [items]="countries"
                            bindLabel="label"
                            bindValue="isoCode"
                            placeholder="Choose Billing Country"
                            dropdownPosition="top"
                            [searchable]="false"
                            [clearable]="false"
                            (change)="selectCountry($event)">
                    </ng-select>
                </div>
            </div>

            <button class="btn btn-primary d-block mx-auto" type="submit" [disabled]="savingCreditCard">
                <i *ngIf="savingCreditCard" class="fal fa-sync rotate mr-2"></i>Submit
            </button>

        </form>
    </div>
</div>
