import {observable, computed, action} from 'mobx';
import {BaseService} from './base.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map, tap, delay, switchMap} from 'rxjs/operators';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {AppSettings} from '../app.settings';
import {ToastrService} from 'ngx-toastr';
import * as moment from 'moment';

import {CacheModel} from '../models/cache.model';
import {Response} from '../models/response.model';
import {Order} from '../models/order.model';
import {OrderItem} from '../models/order-item.model';
import {OrderWindow} from '../models/order-window.model';
import { OnDemand } from '../models/on-demand.model';
import {CostCenter} from '../models/cost-center.model';
import {BRANCH_TYPE, CUSTOM_TYPE, ECOMMERCE_TYPE, PREORDER_TYPE, SUMMARY_TYPE} from '../constants/order-types';
import {ProductService} from './product.service';
import {AuthService} from './auth.service';
import {EntityService} from './entity.service';
import {CreditCard} from '../models/credit-card.model';
import {ApiOrderService} from './api-order.service';
import {QUOTE_STATE} from '../constants/order-states';
import {Router} from '@angular/router';
import {GoogleAnalytics} from './google-analytics.service';
import {ACTIVE_ORDER_WINDOW_ID, LocalStorageService} from './local-storage.service';
import {AddressService} from './address.service';
import {isWSLROrder} from '../shared/helpers';
import {isEmptyArray} from '../shared/utils';
import {BudgetService} from './budget.service';
import {IError} from '../models/error.model';
import {UNKNOWN_ERROR_CODE} from '../constants/error.codes';
import {OrderHistory} from '../models/order-history.model';
import {AvailableOrderWindow} from '../interfaces/order-window.interface';
import {MultiTenantService} from './multi-tenant.service';


@Injectable()
export class OrderService extends BaseService {

    public id: any;
    @observable activeOrderWindow: OrderWindow;
    @observable orderWindows: OrderWindow[] = [];
    @observable switchingOrderWindow = false;

    // Order History State
    @observable historyScrollY = 0;
    @observable historyOrders: any = {PREORDER_TYPE: [], ECOMMERCE_TYPE: [], SUMMARY_TYPE: [], CUSTOM_TYPE: [], SEARCH: []};
    @observable historyCounts: any = {PREORDER_TYPE: 0, ECOMMERCE_TYPE: 0, SUMMARY_TYPE: 0, SEARCH: 0, CUSTOM_TYPE: 0};
    // @observable historyOffsets: any = {PREORDER_TYPE: 0, ECOMMERCE_TYPE: 0, SUMMARY_TYPE: 0, CUSTOM_TYPE: 0};
    //
    // Active Orders State
    @observable inited = false;
    @observable orders: Array<Order> = [];
    @observable selectedOrder: Order;
    //


    @observable currentOrderType: string; // PREORDER_TYPE or ECOMMERCE_TYPE
    // @observable currentActiveOrder: Order  = new Order({id: -1}); // can be BW or OD orders depends on selectedOrderType

    protected trackingCache: CacheModel;
    public isUpdatingTaxExempt = false;

    // checks whether order is WSLR


    // autoshipOrderCheckouted
    // it should send autoship order id which is checkout  to update status
    public autoshipOrderCheckouted = new Subject<number>();


    @observable cartSelectedOrdersType = '';
    @observable fetchingOrderWindows = false;


    public internalOrderCodes: string[] = []; // list of  internal order codes in case if feature flag is enabled

    constructor(
        private api: ApiOrderService,
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService,
        private productService: ProductService,
        private authService: AuthService,
        private entityService: EntityService,
        private router: Router,
        private googleAnalytics: GoogleAnalytics,
        private localStorage: LocalStorageService,
        private addressService: AddressService,
        private budgetService: BudgetService,
        private multiTenantService: MultiTenantService
    ) {
        super('/order', http, appSettings, toastr);

        this.trackingCache = new CacheModel();
        this.trackingCache.expiration = 3600;
    }

    // get order windows list and put into store
    private getOrderWindows(fetchAllOrderWindows = false): Observable<boolean> {
        if (this.authService.canBuyingWindow) {
            this.fetchingOrderWindows = true;
            return this.api.fetchOrderWindows(fetchAllOrderWindows).pipe(
               switchMap((orderWindows: OrderWindow[]) => {
                   this.fetchingOrderWindows = false;
                   this.orderWindows = orderWindows;
                   if (!this.activeOrderWindow) {
                       this.activeOrderWindow = this.restoreActiveOrderWindow(orderWindows);

                       if (!this.activeOrderWindow) {
                           this.activeOrderWindow = this.findLastActiveOrder();
                       }

                       if (this.activeOrderWindow) {
                           return this.switchActiveOrderWindow$(this.activeOrderWindow);
                       }

                       return of(true);
                   } else {
                       return of(true);
                   }

               })
            );
        } else {
            return of(true);
        }
    }


    public switchActiveOrderWindowById(id: number): OrderWindow {
        if (!id) {
            return;
        }

        const window = this.orderWindows.find(ow => ow.id === id);
        if (!window) {
            this.router.navigateByUrl('/404', {skipLocationChange: true});
        }

        this.switchActiveOrderWindow(window);
        return window;
    }

    public switchActiveOrderWindow(orderWindow?: OrderWindow) {
        if (!orderWindow) {
            orderWindow = this.findLastActiveOrder();
        }

        if (orderWindow) {
            this.switchActiveOrderWindow$(orderWindow).subscribe(() => {});
        }
    }

    public switchActiveOrderWindow$(orderWindow: OrderWindow): Observable<any> {
        this.switchingOrderWindow = true;
        // get active orders

        return forkJoin(
            [
                this.productService.onActiveOrderWindowChanged(orderWindow.id),
                this.getActiveOrders(orderWindow).pipe(
                    tap(() => {
                        this.setActiveOrderWindow(orderWindow);
                        this.switchingOrderWindow = false;
                    }),
                )
            ]
        )
    }

    public switchActiveOrder(order: Order): Observable<Order> {
        return this.getOrder(order.id).pipe(
            switchMap(resOrder => {

                if (!resOrder || resOrder.is_active) {
                    return of(resOrder);
                }
                return this.api.makeActiveOrder(resOrder.id).pipe(
                    map(result => {
                        if (result) {
                            const previousActiveOrders = this.findActiveOrdersByType(order.type);
                            previousActiveOrders.forEach(o => {
                                o.is_active = false;
                                this.updateOrderList(o);
                            });

                            const newActive = new Order(resOrder);
                            newActive.is_active = true;

                            this.updateOrderList(newActive);

                            return newActive;
                        }

                        return resOrder;
                    }),
                );

            })
        )


    }

    // 1. fetch  OD orders
    // 2. fetch BW orders if necessary
    // 3  set inited state  to true
    public fetchAllOrders() {

        const odParams  = {id: 0} as OnDemand;

        this.getActiveOrders(odParams).pipe(
          switchMap( ()   => {

              if (!this.activeOrderWindow) {
                  return this.getOrderWindows(true);
              } else {
                  return of(true);
              }

          })
        ).subscribe(() => {
            this.setInited(true);
        })

    }

    public getActiveOrders(property?: OrderWindow | OnDemand): Observable<Order> { //
        if (!this.authService.canSeeOrders) {
            return of(null);
        }

        return this.api.fetchActiveOrders(property)
            .pipe(
                tap(orders => {

                    if (property.id > 0) {
                        // remove BW  orders
                        this.setOrders(this.orders.filter(o => !o.isByingWindow));
                    }

                    orders.forEach(o => {
                        // o.isWSLR = isWSLROrder(o, this.authService.user);
                        // o.loaded = true;
                        this.updateOrderList(o);
                    });

                    this.setOrders(this.orders);
                }),
                switchMap(() => this.getDefaultODOrder()),
                switchMap(() => this.getDefaultBWOrder()),
            );
    }

    private findActiveOrdersByType(type: string): Order[] {
        return this.orders.filter(o => (o.type === type && o.is_active));
    }

    private getDefaultODOrder(): Observable<Order> {
        if (this.currentODOrders.length === 0) {
            return of(null);
        }

        const order = this.activeODOrder || this.currentODOrders[0];
        return this.switchActiveOrder(order);
    }

    private getDefaultBWOrder(): Observable<Order> {
        if (this.currentBWOrders.length === 0) {
            return of(null);
        }

        const order = this.activeBWOrder || this.currentBWOrders[0];
        return this.switchActiveOrder(order);
    }

    // returns last order window with active = true or undefined
    public findLastActiveOrder(): OrderWindow {
        return this.orderWindows.filter(i => i.active === true).pop();
    }

    private findOrderById(id: number, forse = false): Observable<Order> {
        if (!forse) {
            const order = this.orders.find(o => o.id === id);
            if (order && order.loaded) {
                return of(order);
            }
        }
        return this.api.fetchOrder(id);
    }

    private isOrdersOD(orders: Order[]): boolean  {
        return Boolean(orders.filter(o => (o.type === ECOMMERCE_TYPE && o.state === QUOTE_STATE)).length);
    }

    private setOrderItem(order: Order) {
        if (!order) {
            return;
        }
        order.items.forEach(i => {
            i.order_id = order.id;
            i.order_type = order.type;
        });
    }

    public updateOrderList(order: Order | number) {
        if (!order) {
            return;
        }

        if (typeof order === 'number') {
            this.setOrders(this.orders.filter(o => o.id !== order));
            return;
        }


        // check if order is WSLR
        order.isWSLR = isWSLROrder(order, this.authService.user);
        // order.loaded = true;


        const idx = this.orders.findIndex((o) => o.id === order.id);
        this.setOrders(
          idx !== -1
            ? Object.assign([...this.orders], { [idx]: order })
            : [...this.orders, order]
        );
    }

    private fetchOrderEntity(order: Order): Observable<Order> {
        if (order && order.entity_id > 0 && !order.entity) {
            return this.entityService.getEntityWithLocations(order.entity_id).pipe(
                map(entity => {
                    order.entity = entity;
                    return order;
                })
            )
        }

        return of(order);
    }

    private fetchOrderDecisionPoints(order: Order): Observable<Order> {
        if (!order || isWSLROrder(order, this.authService.user) || !order.isDecisionPoint || order.decisionPointData) {
            return of(order);
        }

        return this.productService.searchDecisionPoints(order.attr_decision_point_name).pipe(map(  result => {
            if (!isEmptyArray(result)) {
                order.decisionPointData = result[0];
            }
            return order;
        }))

    }


    private fetchAutoshipLocations(order: Order): Observable<Order> {
        if (!order || !order.entity_id || !order.isCustom || !isEmptyArray(order.autoshipLocations)) {
            return of(order);
        }
        return this.entityService.getAutoShipLocations(order.entity_id).pipe(
            map( locations => {
                order.autoshipLocations = locations;
                return order;
            })
        );
    }


    private fetchBudgets(order: Order): Observable<Order> {
        if (!order || !order.canEdit) {
            return of(order);
        }
        return this.budgetService.getBudgetsByOrder(order);
    }

    private fetchPresetAddresses(order: Order): Observable<Order> {
        if (!order || !order.canEdit) {
            return of(order);
        }

        return this.addressService.getPresetAddressesForOrder(order).pipe(
            map( (addresses) => {
                order.presetAddresses = addresses;
                return order;
            })
        );
    }

    public getOrder(id: number = null, forse = false): Observable<Order> {
        return this.findOrderById(id, forse).pipe(
            switchMap(order => this.fetchOrderEntity(order)),
            switchMap(order => this.fetchOrderDecisionPoints(order)),
            switchMap(order => this.fetchBudgets(order)),
            switchMap(order => this.fetchAutoshipLocations(order)),
            switchMap(order => this.fetchPresetAddresses(order)),
            tap(resOrder => {
                resOrder.loaded = true;
                this.setOrderItem(resOrder)
                this.updateOrderList(resOrder);
            })
        );
    }

    getOrderHistory(filter: any = null, paging: string = '10-0', append = false):
        Observable<{ orders: OrderHistory[], count: number, type: string }> {
        return this.api.fetchOrderHistory(filter, paging).pipe(
            map( result => ({...result, type: filter.type}))
        )
    }

    public getAvailableOrderWindows(): Observable<AvailableOrderWindow[]> {
        if (!this.authService.canBuyingWindow) {
            return of([]);
        }
        return this.api.getAvailableOrderWindows();
    }
    // getOrderHistoryCount(type: string): number {
    //     return this.api.getOrderHistoryCount(type);
    // }


    public createFakeOrder(type: string): Order {

        console.log('temporal order is created.');
        const order = new Order({
            id: -1, is_active: true, items: [], items_count: 0, type, state: QUOTE_STATE,
            user: this.authService.user, isNotReal: true, loaded: true
        });

        this.updateOrderList(order);
        return order;

    }


    // Order level methods
    createOrder(type: string = null, orderOptions: any = {}): Observable<Order> {
        if (!type) {
            type = this.currentOrderType;
        }

        if (!this.authService.canOrder || (type === PREORDER_TYPE && !(this.activeOrderWindow && this.activeOrderWindow.active))) {

            console.log('Limited order is created.');

            let order = this.orders.find(o => o.id === 0 && o.type === type);
            if (!order) {
                order = new Order({
                    id: 0, is_active: true, items: [], items_count: 0, type, state: QUOTE_STATE,
                    user: this.authService.user
                });
                this.updateOrderList(order);
            }
            return of(order);
        }

        const windowID = (type === PREORDER_TYPE) ? this.activeOrderWindowID : null;
        const body: any = {
            window_id: windowID,
            attr_cart_name: orderOptions.orderLabel ? orderOptions.orderLabel : this.getDefaultOrderName(type),
            attr_attention: this.getDefaultAttention()

        };
        if (type === CUSTOM_TYPE) {
            body.autoship = true;
        }

        if (orderOptions.entity_id) {
            body.entity_id = orderOptions.entity_id;
        }

        return this.api.createOrder(body).pipe(
            switchMap( order => {
               if (!order) {
                   return of(order);
               }
               return this.getOrder(order.id, true);
            }),
            tap(order => {
                if (order) {

                    if (type === PREORDER_TYPE || type === ECOMMERCE_TYPE) {
                        // make previous order not active
                        const active = type === PREORDER_TYPE ? this.activeBWOrder : this.activeODOrder;
                        if (active) {
                            const previousActive = new Order(active);
                            previousActive.is_active = false;
                            this.updateOrderList(previousActive);
                        }

                        const newOrder = new Order(order);
                        newOrder.is_active = true;
                        this.updateOrderList(newOrder);
                    }

                }
            })
        );
    }

    public createLimitedOrder(type: string): Order {
        return  new Order({
            id: 0, is_active: true, items: [], items_count: 0, type, state: QUOTE_STATE,
            user: this.authService.user
        });

    }
    updateCostCenter(order: Order, costCenter: CostCenter): Observable<Order> {
        if (!order || !costCenter) {
            return of(null);
        }
        if (costCenter) {
            costCenter.internal_order = '' + order.id;
        }

        return this.api.updateCostCenter(order.id, costCenter).pipe(
            map(result => {
                if (result['errorCode']) {
                    order.error = result as IError;
                    return order;
                }

                if (result === true) {
                    order.setPaymentMethod(costCenter);
                    order.error = null;
                    return order;
                }

                order.error = {errorCode: UNKNOWN_ERROR_CODE};
                return order;
            }),
            tap( retOrder => {
                if (retOrder) {
                    this.updateOrderList(retOrder);
                }
            })
        );
    }

    updateCreditCard(order: Order, creditCard: CreditCard): Observable<Order> {
        if (!order || !creditCard) {
            return of(null);
        }

        return this.api.setOrderAttribute(order.id, 'credit_card', {id: creditCard.id, label: creditCard.label}).pipe(
            map(result => {
                if (result === true) {
                    order.setPaymentMethod(creditCard);
                    order.error = null;
                } else {
                    order.error = {errorCode: UNKNOWN_ERROR_CODE};
                }
                return order;
            })
        );
    }

    public removeCreditCardFromOrder(order: Order): Observable<Order> {
        return this.api.removeCreditCardFromOrder(order).pipe(
            map(result => {
                if (result) {
                    order.creditCard = null;
                    order.error = null;
                } else {
                    order.error = {errorCode: UNKNOWN_ERROR_CODE};
                }
                return order;
            })
        );
    }


    public setOrderAttributes(order: Order, attributes: any): Observable<Order> {
        return this.api.setOrderAttributes(order.id, attributes).pipe(
            switchMap(result => {
                if (result) {
                    // force to re-fetch order due to tax calculation
                    return this.getOrder(order.id, true);
                }
                return of(null);
            })
        );
    }


    public updateCreditCardByOrderId(orderId: number, creditCard: CreditCard): Observable<Order> {
        if (!orderId) {
            return of(null);
        }
        return this.getOrder(orderId).pipe(switchMap(order => {
            return this.updateCreditCard(order, creditCard);
        }));
    }


    /**
     * update order entity, returns updated order
     * @param orderId - order id
     * @param entityId - entity id
     */
    updateEntity(order: Order, entityId: number): Observable<Order> {
        return this.api.updateOrderEntity(order.id, entityId).pipe(
            switchMap(retOrder => {
                return this.fetchOrderWSLREntity(retOrder)
            })
        );
    }

    delete(orderID: number): void {
        this.api.deleteOrder(orderID).subscribe(result => {
            if (result) {
                this.updateOrderList(orderID);
                this.toastr.success('Your cart has been removed.');
            }
        });
    }

    printInvoice(orderID: number): Observable<any> {
        return this.api.getInvoice(orderID, '/print');
    }

    pdfInvoice(orderID: number): Observable<any> {
        return this.api.getInvoice(orderID, '/pdf');
    }

    emailInvoice(orderID: number, email: string): Observable<any> {
        return this.http.post<Response>(this.apiURL + '/' + orderID + '/invoice/email', {email: email}, {headers: this.xsrfTokenHeader})
            .pipe(
                map(response => {
                    return response.data;
                }),
                catchError(this.handleError('OrderService::emailInvoice', []))
            );
    }

    // Item level methods

    getOrderItem(order: Order, itemID: number) {
        this.api.getItem(order.id, itemID).subscribe(orderItem => {
        });
    }

    createOrderItem(order: Order, item: OrderItem): Observable<OrderItem> {
        return this.api.createItem(order, item)
            .pipe(tap(orderItem => {
                if (orderItem) {
                    this.googleAnalytics.addToShoppingCart(order, orderItem);
                    this.updateOrderList(order);

                    const product = orderItem.product;
                    this.productService.updateProductList(product);
                    // when order has been changed -  aggregated quantity should be cleared from cache
                    this.productService.clearAggregateQuantitiesFromCache(product.id);

                    if (product.inventoryType || product.has_inventory) {
                        this.productService.productCache.delete(product.id);
                        this.productService.putToCache(product);
                    }
                }
            }));
    }

    updateOrderItem(order: Order, item: OrderItem): Observable<Order> {
        const orderItemID = item.id;
        return this.api.updateItem(order, orderItemID, item).pipe(
            map(retOrder => {
                if (retOrder) {
                    this.googleAnalytics.updateShoppingCart(retOrder, item);
                    this.updateOrderList(retOrder);

                    const orderItem = retOrder.items.find(i => i.id === orderItemID);
                    const product = orderItem.product;
                    this.productService.updateProductList(product);
                    this.productService.clearAggregateQuantitiesFromCache(product.id);

                    if (product.inventoryType || product.has_inventory) {
                        this.productService.productCache.delete(product.id);
                        this.productService.putToCache(product);
                    }

                    return retOrder;
                }
                return null;
            })
        );
    }

    deleteOrderRestrictedItems(order: Order): Observable<Order> {
        if (!order) {
            return of(null);
        }
        return this.api.deleteRestrictedOrderItems(order.id).pipe(
            map(retOrder => {
                if (retOrder) {
                    this.updateOrderList(retOrder);
                    return retOrder;
                }
                return null;
            })
        );
    }


    deleteOrderItem(order: Order, orderItem: OrderItem): Observable<Order> {
        if (!order || !orderItem) {
            return of(null);
        }
        const orderItemId = orderItem.id;
        const product = orderItem.product;
        const productId = product.id;

        return this.api.deleteItem(order, orderItemId).pipe(
            map(retOrder => {
                if (retOrder) {

                    this.productService.clearAggregateQuantitiesFromCache(productId);

                    this.googleAnalytics.removeFromShoppingCart(retOrder, orderItem);
                    this.updateOrderList(retOrder);  // update order list

                    if (product.inventoryType || product.has_inventory) {
                        this.productService.productCache.delete(product.id);
                    }

                    return retOrder;
                }
                return null;
            })
        );
    }

    public updateOrderTaxExempt(order: Order, taxExempt: boolean): Observable<Order> {

        this.isUpdatingTaxExempt = true;
        return this.setOrderAttributes(order, {tax_exempt: taxExempt.toString()}).pipe(
            switchMap(result => {
                if (result) {
                    // re-fetch the order to get correct taxes
                    return this.getOrder(order.id, true);
                }
                // failed update
                return of(null);
            }),
            tap(() => this.isUpdatingTaxExempt = false)
        );
    }

    checkout(order: Order, updatePayment = false): Observable<Order> {
        if (!order) {
            return of(order);
        }


        this.googleAnalytics.checkout(order);
        return this.api.checkout(order.id).pipe(
            map( result => {
                if ( typeof  result === 'boolean'  && result === false) {
                    order.error = {errorCode: UNKNOWN_ERROR_CODE };
                }

                if (result['errorCode']) {
                    order.error = result as IError;
                }

                return order;
            }),
            tap(retOrder => {
                if (retOrder && !retOrder.error) {
                    this.googleAnalytics.purchase(order);
                    this.toastr.success('Order has been submitted');
                    if (order.isCustom) {
                        this.autoshipOrderCheckouted.next(order.id);
                    }
                }
            })
        );
    }


    @action setInited(val: boolean) {
        this.inited = val;
    }


    // @action async setActiveOrder(order: Order) {
    //     if (order) {
    //         if (order.type === ECOMMERCE_TYPE) {
    //             this.setActiveODOrder(order);
    //         } else {
    //             this.setActiveBWOrder(order);
    //         }
    //
    //
    //         this.setActive(order.id).pipe(take(1)).subscribe(noop => {
    //             this.resetActive(order.id, order.type);
    //             this.setCurrentOrders();
    //             this.setCurrentActiveOrder(order);
    //         });
    //     }
    // }

    @action setSelectedOrder(order: Order) {
        this.selectedOrder = order;
    }

    @computed get odCount() {
        return this.availableODOrders.filter(o  => !o.isNotReal).length;
    }

    @computed get bwCount() {
        return this.availableBuyingWindowOrders.filter(o  => !o.isNotReal).length;
    }

    @computed get branchCount() {
        return this.branchOrders.filter(o  => !o.isNotReal).length;
    }

    @computed get summaryCount() {
        return this.summaryOrders.filter(o  => !o.isNotReal).length;
    }

    @computed get autoshipCount() {
        return this.autoshipOrders.length;
    }

    @computed get bwTotalCount() {
        return this.bwCount + this.branchCount + this.summaryCount;
    }

    public createAutoShipOrder(): Observable<Order> {

        if (!this.authService.canOrder || this.authService.canAutoship === false) {
            return of(null);
        }

        return this.createOrder(CUSTOM_TYPE).pipe(
            tap((order: Order) => {
                if (order) {
                    this.updateOrderList(order);
                }
            })
        );

    }

    public getApiUrl() {
        return this.apiURL;
    }


    /**
     * refresh tracking_numbers in order delivires
     * result is cached
     * @param order
     */
    getTrackingData(order: Order): Observable<Order> {
        return this.http.get<Response>(`${this.apiURL}/${order.id}/tracking-state`)
            .pipe(
                map(response => {
                    if (!isEmptyArray(response.data)) {
                        return this.mapTracksToShipment(order, response.data);
                    }
                    return order;
                }),
                catchError(this.handleError('OrderService::tracking-state', null))
            );

    }


    /**
     * map track_numbers to related delivery items
     * @param order
     * @param trackData, contains tracking-numbers
     */
    private mapTracksToShipment(order: Order, trackData: any[]): Order {
        // TODO move to order model
        if (isEmptyArray(trackData) || isEmptyArray(order.items)) {
            return;  // empty track number
        }

        const orderItems  = order.items.map( i => new OrderItem(i));
        let updatedTrackingData = false;
        orderItems.forEach(item => {
            if (item.deliveries) {

                item.deliveries.forEach(delivery => {
                    const shipment = trackData.find(data => data.shipment_id === delivery.id);
                    if (shipment) {
                        delivery.tracking_number = shipment.tracking_number;
                        updatedTrackingData = true;
                    }
                });
            }
        });

        if (updatedTrackingData) {
            order.items = orderItems;
        }
        return order;
    }


    @action setActiveOrderWindow(orderWindow?: OrderWindow) {
        this.activeOrderWindow = orderWindow;

        // save to local storage
        if (this.activeOrderWindow) {
            this.localStorage.setItem(ACTIVE_ORDER_WINDOW_ID, '' + this.activeOrderWindow.id);
        }
    }

    // for BW order user can't add/modify order items in case oder window is not defined or not acvtive
    canEditOrderItems(order: Order): boolean {
        if (order.type !== PREORDER_TYPE) {
            return true;
        }

        return this.activeOrderWindow && this.activeOrderWindow.active;
    }

    public canPurchase(order: Order, orderItem: OrderItem): boolean {
        if (!order) {
            return false;
        }

        if (!this.canEditOrderItems(order)) {
            return false;
        }

        return orderItem.canPurchase;

    }


    @action setCurrentOrderType(type: string) {
        this.currentOrderType = type;
    }


    @computed get currentOrders(): Order[] {
        if (!this.currentOrderType) {
            return [];
        }
        const orders  = this.currentOrderType === PREORDER_TYPE ? this.currentBWOrders : this.currentODOrders;
        // filter  out fake orders
        return orders.filter(o => !o.isNotReal);
    }


    // reset all orders - should be called after logout
    @action reset() {
        this.activeOrderWindow = null;
        this.orderWindows = [];
        this.setOrders([]);
        this.selectedOrder = null;
        this.currentOrderType = '';

        this.api.clear();
    }


    // promocode

    applyPromoCode(order: Order, promoCode: string): Observable<Order> {
        return this.api.applyPromoCode(order, promoCode).pipe(
            map(retOrder => {
                if (retOrder) {
                    this.updateOrderList(retOrder);
                    this.toastr.success('Promo Code has been applied');
                }
                return retOrder;
            })
        );
    }


    revokePromoCode(order: Order): Observable<Order> {
        return this.api.revokePromoCode(order).pipe(
            map(retOrder => {
                if (retOrder) {
                    this.updateOrderList(retOrder);
                    this.toastr.success('Promo Code has been removed');
                }
                return retOrder;
            })
        );
    }

    private fetchOrderWSLREntity(order: Order): Observable<Order> {
        if (isWSLROrder(order, this.authService.user) && !order.entity) {
            return this.entityService.getEntityWithLocations(order.entity_id).pipe(
                map(entity => {
                    order.entity = entity;
                    this.updateOrderList(order);
                    return order;
                })
            );
        } else {
            return of(order);
        }
    }


    @action setOrders(orders: Order[]): void {
        this.orders = orders;
    }

    @computed get activeOrderWindowID(): number {
        return this.activeOrderWindow ? this.activeOrderWindow.id : 0;
    }

    @computed get availableODOrders(): Order[] {
        return this.orders.filter(o => (o.type === ECOMMERCE_TYPE && o.state === QUOTE_STATE));
    }

    @computed get currentODOrders(): Order[] {
        return this.availableODOrders.filter(o => this.authService.canEditOrder(o));
    };

    @computed get availableBuyingWindowOrders(): Order[] {
        return this.orders.filter(o => o.type === PREORDER_TYPE);
    }

    @computed get buyingWindowOrders(): Order[] {
        return this.availableBuyingWindowOrders.filter( o => this.authService.canEditOrder(o));
    }

    @computed get autoshipOrders(): Order[] {
        return this.orders.filter(o => o.type === CUSTOM_TYPE);
    }

    @computed get branchOrders(): Order[] {
        return this.orders.filter(o => o.type === BRANCH_TYPE);
    };

    @computed get summaryOrders(): Order[] {
        return this.orders.filter(o => o.type === SUMMARY_TYPE);
    };

    @computed get currentBWOrders(): Order[] {
        if (this.authService.isWholeSalerMultiple && this.branchOrders.length > 0) {
            return this.buyingWindowOrders.concat(this.branchOrders);
        } else {
            return this.buyingWindowOrders;
        }
    }


    @computed
    public get activeODOrder(): Order {
        return this.currentODOrders.find(o => o.is_active);
    }

    @computed
    public get activeBWOrder(): Order {
        return this.currentBWOrders.find(o => o.is_active);
    };

    @computed
    public get activeOrder(): Order {
        // if (!this.currentOrderType) {
        //     return null;
        // }
        //
        return this.currentOrderType === PREORDER_TYPE ? this.activeBWOrder : this.activeODOrder;
    }

    // wrappers for @observable
    public getActiveOrderWindow(): Observable<OrderWindow> {
        return this.toRx('activeOrderWindow');
    }

    public getDefaultOrderName(type: string): string {

        const creationDate = moment().format('MMM-DD-YYYY');
        let orderType = '';
        if (type === ECOMMERCE_TYPE) {
            orderType =  this.multiTenantService.onDemandLabel;
        } else if (type === PREORDER_TYPE) {
            // bw order
            orderType = this.activeOrderWindow ? this.activeOrderWindow.label
              : this.multiTenantService.orderWindowLabel;
        } else if (type === CUSTOM_TYPE) {
            orderType = 'Auto-ship';
        }

        return `${orderType} ${creationDate}`;

    }

    public getDefaultAttention(): string {
        if (!this.authService.user) {
            return '';
        }
        return `${this.authService.user.first_name} ${this.authService.user.last_name}`;
    }


    public isInitialized(): Observable<boolean> {
        return this.toRx('inited');
    }

    public get buyingWindowChanged(): Observable<OrderWindow> {
        return this.toRx('activeOrderWindow');
    }

    public get orderTypeChanged(): Observable<string> {
        return this.toRx('currentOrderType');
    }

    public get  activeOrderChanged(): Observable<Order> {
        return this.toRx('activeOrder');
    }

    private restoreActiveOrderWindow(orderWindows: OrderWindow[]): OrderWindow {
        if (!this.activeOrderWindow) {
            // get from localstorage
            const activeOrderId = +this.localStorage.getItem(ACTIVE_ORDER_WINDOW_ID);
            const orderWindow = orderWindows.find(o => o.id === activeOrderId); // make sure order window exists in the list
            if (orderWindow) {
                this.activeOrderWindow = orderWindow;
            }
        }

        return this.activeOrderWindow;
    }

    @computed
    public get currentOrderWindowId(): number {
        if (this.currentOrderType === PREORDER_TYPE) {
            return this.activeOrderWindowID;
        }

        return 0;
    }

    getOrdersByType(type: string): Order[] {
        switch (type) {
            case PREORDER_TYPE:
                return this.currentBWOrders;
            case ECOMMERCE_TYPE:
                return this.currentODOrders;
            case BRANCH_TYPE:
                return this.branchOrders;
            case SUMMARY_TYPE:
                return this.summaryOrders;
            case CUSTOM_TYPE:
                return this.autoshipOrders;
            default:
                return [];
        }
    }

    getOrderCountsByType(type: string): number {
        switch (type) {
            case PREORDER_TYPE:
                return this.bwCount;
            case ECOMMERCE_TYPE:
                return this.odCount;
            case CUSTOM_TYPE:
                return this.autoshipCount;
            case BRANCH_TYPE:
                return this.branchCount;
            case SUMMARY_TYPE:
                return this.summaryCount;
            default:
                return 0;
        }
    }

    @computed
    public get ordersByType(): {type: string, label: string, subLabel: string, orders: Order[]}[] {
        const orderList: {type: string, label: string, subLabel: string, orders: Order[]}[] = [];
        if (!this.inited) {
            return orderList;
        }

        if (this.authService.canBuyingWindow && !isEmptyArray(this.availableBuyingWindowOrders)) {
            orderList.push({ type: PREORDER_TYPE, label: `${this.multiTenantService.orderWindowLabel} Orders`,
                subLabel: 'YOUR ORDERS', orders: this.currentBWOrders});
        }

        if (!isEmptyArray(this.availableODOrders)) {
            orderList.push({ type: ECOMMERCE_TYPE, label: `${this.multiTenantService.onDemandLabel} Orders`,
                subLabel: 'YOUR ORDERS', orders: this.currentODOrders});
        }

        if (this.authService.canAutoship && !isEmptyArray(this.autoshipOrders)) {
            orderList.push({ type: CUSTOM_TYPE, label: 'AutoShip Orders', subLabel: '', orders: this.autoshipOrders});
        }

        if (this.authService.isWholeSalerMultiple && !isEmptyArray(this.branchOrders)) {
            orderList.push({ type: BRANCH_TYPE, label: 'Branch Orders', subLabel: '', orders: this.branchOrders});
        }

        if ( (this.authService.isWholeSaler || this.authService.isWholeSalerMultiple) && !isEmptyArray(this.summaryOrders)) {
            orderList.push({ type: SUMMARY_TYPE, label: `${this.multiTenantService.projectLabel} Orders`,
                subLabel: '', orders: this.summaryOrders});
        }

        if (!this.cartSelectedOrdersType && !isEmptyArray(orderList)) {
            this.cartSelectedOrdersType = orderList[0].type;
        }

        return orderList;

    }


    @computed
    public get cartSelectedOrders(): {type: string, label: string, subLabel: string, orders: Order[]} {
        if (!this.cartSelectedOrdersType || isEmptyArray(this.ordersByType)) {
            return null;
        }

        return this.ordersByType.find(i => i.type === this.cartSelectedOrdersType);
    }

    public getActiveOrNewOrder(): Observable<Order> {
        if (this.activeOrder) {
            return of(this.activeOrder);
        }

        return this.createOrder();
    }


    public canDoQuickOrder(orderType: string): boolean {
        if (!orderType) {
            return  false;
        }

        if (!this.authService.canOrder) {
            return false;
        }

        if (orderType === PREORDER_TYPE) {
            // quick order can be available only for active bw
            return !!this.activeOrderWindow?.active;
        }

        return true;

    }

    public getInternalOrderCodes() {
        this.api.getInternalOrderCodes().subscribe((codes: string[]) => {
            this.internalOrderCodes = codes;
        });
    }

}
