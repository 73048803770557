<div class="sap__selector d-flex">
    <ng-select
            id="sapSearch"
            class="custom-select__sap"
            [items]="sapSearchResults"
            bindValue="id"
            placeholder="Search saved Payment Methods"
            [searchable]="true"
            [loading]="sapSearchLoading"
            [typeahead]="sapSearchSubject"
            (change)="onSelectPaymentMethod($event)"
            [(ngModel)]="selectedCostCenterId"
            [clearable]="false">
        <ng-template ng-label-tmp let-item="item">
            {{ item.label }}
        </ng-template>
        <ng-template ng-header-tmp>
            Saved Payment Methods
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            <span>{{ item.label }}</span>
        </ng-template>
    </ng-select>
    <button *ngIf="canAddPaymentMethod" class="btn btn-link font-5" (click)="addSap()" title="Add SAP">Add Payment Method <i class="fas fa-plus"></i></button>
</div>

