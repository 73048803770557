import {Component, ViewEncapsulation, Input, OnInit, OnDestroy} from '@angular/core';

import {OrderService} from '../../../services/order.service';
import {ConfigService} from '../../../services/config.service';

import {Order} from '../../../models/order.model';
import {OrderItem} from '../../../models/order-item.model';
import {OrderItemDelivery} from '../../../models/order-item-delivery.model';
import {debounceTime, distinctUntilChanged, switchMap, takeWhile, tap} from 'rxjs/operators';
import {of, Subject} from 'rxjs';
import {Address} from '../../../models/address.model';


@Component({
  selector: 'app-internal-order-code',
  templateUrl: './internal-order-code.component.html',
  styleUrls: ['./internal-order-code.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InternalOrderCodeComponent implements OnInit, OnDestroy {

  searchSubject: Subject<string> = new Subject<string>();
  alive = true;
  filteredCodes: string[]  = [];
  selectedCode: string;


  @Input() disabled = false;
  @Input()  order: Order;
  @Input()  orderItem: OrderItem;
  @Input()  delivery: OrderItemDelivery;

  constructor(private configService: ConfigService, private orderService: OrderService) { }


  ngOnInit() {

    this.filteredCodes = [...this.internalCodes];
    this.selectedCode = !!this.delivery?.internal_order ? this.delivery?.internal_order : null;

    this.searchSubject.pipe(
      takeWhile( () => this.alive),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        if (!term) {
          return of(this.internalCodes);
        }
        const termLowerCase = term.toLowerCase();
        const foundCodes  =  this.internalCodes.filter( code => code.toLowerCase().indexOf(termLowerCase) > -1);
        return of(foundCodes);
      })
    ).subscribe( (retCodes: string[]) => {
      this.filteredCodes = retCodes;
    });
  }


  ngOnDestroy() {
    this.alive = false;
  }


  get showInternalOrderCodes(): boolean {
    return this.configService.features.showInternalOrderCodes;
  }

  get internalCodes(): string[] {
    return this.orderService.internalOrderCodes;
  }

  onInternalOrderChanged(newValue: string) {
    const value  =  !!newValue ? newValue.trim() : '';
    if (this.order.isWBSDefined) {
      this.delivery.wbs = value;
    } else {
      this.delivery.internal_order = value;
    }
    this.orderItem.updateDelivery(this.delivery, this.order.isOnDemand);
  }

  onInternalOrderReset() {
    this.delivery.wbs = '';
    this.delivery.internal_order = '';
    this.orderItem.updateDelivery(this.delivery, this.order.isOnDemand);
  }

  onSelect(data) {
    if (data) {
      this.selectedCode = data;
      this.onInternalOrderChanged(data);
    }
  }

}
