import {ActionReducerMap} from '@ngrx/store';

import * as fromRepairRequests from './reducers/repair-requests.reducer';
import * as fromRecommendedProducts from './reducers/recommended-products.reducer';
import * as fromPaymentMethods from './reducers/payment-methods.reducer';

export interface IAppState {
    repairRequests: fromRepairRequests.IRepairRequestsState;
    recommendedProducts: fromRecommendedProducts.IRecommendedProductsState;
    paymentMethods: fromPaymentMethods.IPaymentsMethodsState
}

export const reducers: ActionReducerMap<IAppState> = {
    repairRequests: fromRepairRequests.repairRequestsReducer,
    recommendedProducts: fromRecommendedProducts.recommendedProductsReducer,
    paymentMethods: fromPaymentMethods.paymentMethodsReducer
};

