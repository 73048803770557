import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, concatMap, map} from 'rxjs/operators';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpClient } from '@angular/common/http';

import { AppSettings } from '../app.settings';
import { Response } from '../models/response.model';
import {API_URL} from '../constants/api-urls';
export const X_XSFR_TOKEN = 'x-xsrf-token';

// const WHITE_LIST_URLS = [
//     'get-csrf',
//     'login',
//     '/for-ids',
//     '/refresh-aggregate-quantities/',
//     '/catalogs/',
//     '/for-ids',
//     '/catalog/',
//     '/window/',
//     '/account/transactions'
// ];

@Injectable()
export class TokenRequestInterceptor implements HttpInterceptor {

    private requestTokenUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.requestTokenUrl = this.appSettings.settings.apiBaseURL + '/get-csrf';
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const setObj: any = {};
        const headers: any = {};

        // only for POST, PUT, DELETE, PATCH requests
        if (request.method === 'POST'
            || request.method === 'PUT'
            || request.method === 'DELETE'
            || request.method === 'PATCH') {

            // check if request contains x-xsrf-token
            const requestHeaders = request.headers.keys().map( k => k.toLowerCase()) || [];
            if (requestHeaders.indexOf(X_XSFR_TOKEN.toLowerCase()) !== -1) {
                return this.getToken().pipe(
                    concatMap( token => {
                        headers[X_XSFR_TOKEN] = token;
                        setObj.setHeaders = headers;
                        return next.handle(request.clone(setObj));
                    }));
            }
        }

        return next.handle(request);

    }

    private getToken(): Observable<string> {
        const url = `${API_URL}/get-csrf`;
        return this.http.get<Response>(url).pipe(
            map( httpResponse => {
                if (httpResponse.data) {
                    return  httpResponse.data.token;
                }
                return '';
            }),
            catchError( () => {
                return '';
            })
        );
    }


}
