<div class="modal-md budget-details">
    <div class="modal-header state">
        <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label"> Budget Information</h1>
        <button type="button" (click)="close()" class="close btn btn-icon" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
        <div *ngFor="let budget of budgets" class="mt-4 pb-3">
            <h2 [innerHTML]="budget.label | safeContent" class="text-center text-transformation-none font-weight-normal"></h2>
            <div class="mt-4">
                <div class="d-flex justify-content-between">
                    <span>BUDGET AVAILABLE</span>
                    <span class="price font-weight-normal font-2"><app-currency-price [price]="budget.available" [currency]="budget.currency"></app-currency-price></span>
                </div>
                <div class="d-flex justify-content-between  mt-3 pb-3 line-separator--light-grey">
                    <span>BUDGET APPLIED</span>
                    <span class="green price font-weight-normal font-2">-<app-currency-price [price]="budget.applied" [currency]="budget.currency"></app-currency-price></span>
                </div>
                <div  class="d-flex justify-content-between mt-3">
                    <span>BUDGET REMAINING</span>
                    <span class="price font-weight-bold font-2"><app-currency-price [price]="budget.remaining" [currency]="budget.currency"></app-currency-price></span>
                </div>
            </div>
        </div>
    </div>
</div>

