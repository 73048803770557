import { Component, Input } from '@angular/core';
import { Product } from '../../../../../models/product.model';
import { OrderItem } from '../../../../../models/order-item.model';
import {SKU} from '../../../../../models/sku.model';
import {
    computeAggregatedQuantity,
    computeGroupAggregatedQuantity, computeGroupMinQuantity,
    computeMaxQuantity,
    computeMinQuantity
} from '../../../../../shared/helpers';
import {isEmptyArray} from '../../../../../shared/utils';

@Component({
    selector: 'app-product-order-item-min-max',
    templateUrl: './product-order-item-min-max.component.html',
    styleUrls: ['./product-order-item-min-max.component.scss']
})
export class ProductOrderItemMinMaxComponent {

    private _orderItem: OrderItem;

    @Input()  showGroupedMaxQuantity = false;

    @Input() viewType = '';

    @Input() fontSize = '';

    @Input() product: Product;

    @Input() sku: SKU;

    @Input() isRecommended = false;

    @Input() showQuantityInSeparateLine = false;

    @Input() shouldShowAvailableQuantity = true;

    @Input() headerPosition = false;

    @Input() set orderItem(val: OrderItem) {
        this._orderItem = val;
        if (this._orderItem) {
            this.product = this._orderItem.product;
        }
    }

    get minQuantity(): number {
        return computeMinQuantity(this.product, this.sku);
    }

    get maxQuantity(): number {
        return computeMaxQuantity(this.product, this.sku);
    }


    get moqMet(): boolean {
        if (!this.product) {
            return false;
        }
        const minQuantity = computeMinQuantity(this.product, this.sku);
        return  this.totalQuantity >=  minQuantity;
    }

    get groupMoqMet(): boolean {
        if (!this.product) {
            return false;
        }
        const groupAggregatedQuantity = this.groupAggregatedQuantity;
        return ((groupAggregatedQuantity + this.quantityDelta) >= this.groupMinQuantity);
    }

    get maxQtyMet(): boolean {
        if (!this.product) {
            return false;
        }
        const maxQuantity = this.maxQuantity;
        const aggregatedQuantity = this.aggregatedQuantity;
        const quantityDelta = this.quantityDelta;

        // return this.product ?  (this.product.skus[0].max_quantity - this.quantity) === 0 : false;
        return this.product.has_inventory ?
            (maxQuantity - quantityDelta) ===  0 :
            (maxQuantity - aggregatedQuantity - quantityDelta) === 0;
    }


    get groupAggregatedQuantity(): number {
       const groupMinQTY = computeMinQuantity(this.product);
       const groupAggregatedQuantity = computeGroupAggregatedQuantity(this.product);
       return  (groupMinQTY > 0 && groupAggregatedQuantity >= 0) ? groupAggregatedQuantity : -1;
    }


    get aggregatedQuantity(): number {
        return computeAggregatedQuantity(this.product, this.sku);
    }

    get groupQuantity(): number {
        return this.groupAggregatedQuantity + this.quantityDelta;
    }


    get groupMinQuantity(): number {
        return computeGroupMinQuantity(this.product);
    }

    get groupQuantityVisible() {
        const groupMaxQuantity = computeMaxQuantity(this.product);
        const groupAggregatedQuantity = this.groupAggregatedQuantity;
        const groupMinQuantity  = this.groupMinQuantity;

        return !groupMaxQuantity
            && groupAggregatedQuantity >= 0
            && groupMinQuantity > 0;
    }

    get showAvailableQuantity(): boolean {
        if (this.product.hasVariations && this.viewType === 'grid-item') {
            return (this.product.variationsMaxQuantity > 0 || this.product.has_inventory)
        }
        return (this.maxQuantity > 0 || this.product.has_inventory);
    }

    get classes(): string {
        return this.viewType === 'grid-item' ? 'horizontal' : '';
    }

    get totalQuantity(): number {
        return this.aggregatedQuantity + this.quantityDelta;
    }

    get availableQuantity(): number {
        if (this.product.hasVariations && this.viewType === 'grid-item') {
            return this.product.has_inventory ?
                this.product.variationsMaxQuantity :
                Math.max(this.product.variationsMaxQuantity - this.totalQuantity, 0);

        }

        return this.product.has_inventory ?
            this.maxQuantity :
            Math.max(this.maxQuantity - this.totalQuantity, 0);
    }

    get quantityDelta(): number {
        if (this.sku) {
            const orderItemSku = this._orderItem.skus.find( s => s.sku_id === this.sku.id);
            return orderItemSku?.quantityDelta || 0;
        }
        return this._orderItem?.quantityDelta || 0;
    }

    get hasVariations(): boolean {
        return this.product.hasVariations
    }

    get groupMinOrderTitle(): string {
        if (this.hasVariations) {
            return this.viewType === 'grid-item' ? 'Min Order Qty' : 'Min Order Quantity';
        }
        return this.viewType === 'grid-item' ? 'Group Min Order Qty' : 'Group Min Order Quantity';
    }

    get showMinQuantity(): boolean {
        return this.headerPosition && !this.product.hasVariations && this.product.firstSku?.minQuantity > 0;
    }


}

