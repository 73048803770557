<div class="modal-md address-modal">
    <div class="modal-header state">
        <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label">MANAGE LOCATIONS</h1>
        <button type="button" [disabled]="!canCloseDialog"
                (click)="close()" class="btn btn-icon close" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
      <div class="container modal-with-scroll">
        <div class="row">

          <div class="col-12">
            <div class="input-group address--container">
              <label><span>Select Addresses</span></label>
              <app-order-list-address-list [addresses]="editableAddressList"
                                       [disabled]="!canEditPresetAddresses"
                                       (onAddressListChanged)="onAddressListChanged($event)"></app-order-list-address-list>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3 mt-3">
        <button (click)="onApply()"
                [disabled]="!canApply || inProgress"
                type="button"
                class="btn btn-primary apply-sap">
          <i *ngIf="inProgress" class="fal fa-sync rotate"></i>&nbsp;
          Apply &amp; Begin
        </button>
      </div>
    </div>
</div>

