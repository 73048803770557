export enum TransactionState {
    PENDING = 2,
    IN_PROGRESS = 3,
    SUCCESS = 4,
    FAILED = 5,
    REJECTED = 6,
    BAD_DEBT = 8,
    UNKNOWN = 1001
}

export const transactionStateDescription = (state: TransactionState) => {
    switch (state) {
        case TransactionState.FAILED:
            return 'Failed';
        case TransactionState.BAD_DEBT:
            return 'Bad Debt';
        case TransactionState.IN_PROGRESS:
            return 'In Progress';
        case TransactionState.PENDING:
            return 'Pending';
        case TransactionState.REJECTED:
            return 'Rejected';
        case TransactionState.SUCCESS:
            return 'Success';
        default:
            return '';
    }
};

export const transactionStateToClass = (state: TransactionState) => {
    switch (state) {
        case TransactionState.FAILED:
            return 'failed';
        case TransactionState.BAD_DEBT:
            return 'pending';
        case TransactionState.IN_PROGRESS:
            return 'in-progress';
        case TransactionState.PENDING:
            return 'pending';
        case TransactionState.REJECTED:
            return 'rejected';
        case TransactionState.SUCCESS:
            return 'success';
        default:
            return '';
    }
};
