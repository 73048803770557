import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PaymentService} from '../../../services/payments.service';
import {CreditCard} from '../../../models/credit-card.model';

@Component({
    selector: 'app-credit-card-label-modal',
    templateUrl: './credit-card-label-modal.component.html',
    styleUrls: ['./credit-card-label-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CreditCardLabelModalComponent  implements OnInit {

    creditCard: CreditCard;
    label = '';

    constructor(private activeModal: NgbActiveModal, private paymentService: PaymentService) {}

    ngOnInit(): void {
        if (this.creditCard) {
            this.label = this.creditCard.label
        }
    }

    save() {
        if (this.creditCard  && this.creditCard.label !== this.label) {
            this.creditCard.label = this.label;
            this.paymentService.updateCreditCard(this.creditCard).then( (result: CreditCard) => this.close(result));
        } else  {
            this.close(null);
        }

    }

    close(creditCard?: CreditCard) {
        this.activeModal.close(creditCard);
    }

    get canSave(): boolean {
        return !!this.label;
    }

    get isSaving(): boolean {
        return this.paymentService.performingAction;
    }
}
