import {BaseModel} from './base.model';
import {Transaction} from './transaction.model';
import {TransactionTotals} from './transaction-total.model';
import {TransactionState} from '../constants/transaction-state';
import {AppDate} from './date.model';
import {Order} from './order.model';
import {generateUUID, updateItemInArray} from '../shared/utils';

export class TransactionGroup extends BaseModel {
    totals: TransactionTotals;
    transactions: Transaction[];

    id: string; // unique identifier

    constructor(data) {
        super(data);

        if (data && data.totals) {
            this.totals = new TransactionTotals(data.totals);
        }

        if (data && data.transactions) {
            this.transactions = data.transactions.map ( t =>  new Transaction(t));
        }

        this.id = generateUUID();
    }

    get hasFailed(): boolean {
        return  this.totals.hasFailed;
    }


    get state(): TransactionState {
        return this.totals.transactionState;
    }


    get transactionId(): string {
        return this.totals.transactionId;
    }

    get cardNumber(): string {
        return this.totals.cardNumber
    }

    get transactionDate(): AppDate {
        return this.totals.date;
    }

    get transactionState(): TransactionState {
        return this.totals.transactionState;
    }

    setTransactions(totals: TransactionTotals, transactions: Transaction[]) {
        this.totals = totals;
        this.transactions = transactions;
    }

    updateTransaction(transaction: Transaction) {
        this.transactions = updateItemInArray<Transaction>(this.transactions, transaction);
    }

    setOrder(order: Order) {
        if (order && Array.isArray(this.transactions)) {
            this.transactions.forEach( transaction =>  transaction.setOrderInfo(order));
        }
    }
}
