<div class="modal-md order-settings">
    <div class="modal-header state">
        <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label">Manage Order Settings</h1>
        <button type="button"  class="btn btn-icon close" (click)="close()" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="orderSettingsForm" (ngSubmit)="saveOrderName()">
            <div class="order-settings-info">

                <div class="order-settings--items">
                    <div class="input-group order-settings-input">
                        <label for="orderName">
                            <span>Set Order Default Address</span>
                        </label>
                        <app-preset-address-list [addresses]="presetAddresses"
                                                 [disabled]="!canEditPresetAddresses"
                                                 (onAddressListChanged)="onAddressListChanged($event)">

                        </app-preset-address-list>

                    </div>



                    <div class="order-settings--item">
                        <div class="input-group order-settings-input">
                            <label for="orderName">
                                <span>{{label}} Name</span>
                                &nbsp;<small>(Name Your Order For Easy Reference)</small>
                                &nbsp;<small class="text-danger" *ngIf="orderSettingsForm.get('orderName').touched && orderSettingsForm.get('orderName').hasError('required')">Required field</small>
                            </label>
                            <input [formControl]="orderName" type="text" name="orderName" id="orderName"  placeholder="Enter Order Name">
                        </div>
                    </div>

                    <div class="order-settings--item">
                        <div class="input-group order-settings-input">
                            <label for="attention">Attention&nbsp;<small>(Enter Attention Here)</small></label>
                            <input [formControl]="attention" name="attention" id="attention"
                                   placeholder="{{order.isAttentionOverridden ? 'See Ship-To Address Details' : 'Enter Attention'}}"
                                   (input)="isAttentionChanged = true" >
                        </div>
                    </div>

                    <div class="order-settings--item" *ngIf="false">
                        <div class="input-group order-settings-input">
                            <label for="poNumber">PO #
							&nbsp;<small>(Optional)</small>
							</label>
                            <input [formControl]="poNumber" name="poNumber" id="poNumber" placeholder="Enter Your PO#">
                        </div>
                    </div>
                    <div class="order-settings--item" *ngIf="canShowTaxExempt">
                        <div class="input-group order-settings-input">
                            <label for="tax-exempt">Tax Exempt:
                                <span class="color--primary-blue uppercase"
                                      *ngIf="!isTaxExemptAllowed" placement="right" tooltipClass="tooltips" ngbTooltip="No certificates are on file, please contact each vendor with your certificates.">
                                    &nbsp;<i class="fas fa-info-circle"></i>
                                </span>
                                <small>&nbsp;(Exemptions will be applied for suppliers holding proper exemption certificates)</small></label>
                            <input class="checkbox-dark-gray tax-exempt big-checkbox" [disabled]="!isTaxExemptAllowed"
                                   type="checkbox" [formControl]="taxExempt" id="tax-exempt">
                        </div>
                    </div>

                    <div *ngIf='showOrderNotesOrDeliveryComments' class="order-settings--item">
                        <div class="input-group order-settings-input">
                            <label for="notes">
                                <span>{{notesLabel}}</span>
                                &nbsp;<small *ngIf='!showDeliveryComments'>(These notes are not used by the Supplier)</small>
                            </label>

                            <textarea [formControl]="notes" name="notes" id="notes" class="notes" [placeholder]="orderIntoPlaceHolder" maxlength='2500'></textarea>
                        </div>
                    </div>
                    <div *ngIf='showPlanningComments' class="order-settings--item">
                        <div class="input-group order-settings-input">
                            <label for="planningComments">
                                <span>Planning Comments</span>
                            </label>
                            <textarea [formControl]="planningComments" name="planningComments" id="planningComments" class="notes" placeholder="Enter planning comments" maxlength='2500'></textarea>
                        </div>
                    </div>

                </div>

            </div>

            <div class="order-settings-controls">
                <button class="btn btn-outline-primary" type="reset" (click)="close()">Cancel</button>
                <button [disabled]="!orderSettingsForm.valid || inProcess"
                        class="btn btn-primary" type="submit">
                    <i *ngIf="inProcess" class="fal fa-sync rotate"></i>&nbsp;Save</button>
            </div>
        </form>
    </div>
    <div class="modal-footer" *ngIf="canEditSap">
        <div  class="order-settings-controls--edit-sap app-modal-footer">
            <a class="link link-underline small" (click)="close('openSapDialog')" href="javascript:void(0)">Edit Payment Information</a>
        </div>
    </div>
</div>

