export enum PaymentMethodType {
    CreditCard = 1,
    CostCenter = 2
}

export enum PaymentType {
    PaymentMethod = 1,
    OrdersPayment = 2
}

export enum CardState {
    INIT = 1,
    PENDING  = 2,
    VERIFIED = 3,
    ERROR = 4
}

