import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {action, computed, observable} from 'mobx';
import {from, Observable, of} from 'rxjs';
import {catchError, delay, finalize, map, switchMap, take, tap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

import {BaseService} from './base.service';
import {AppSettings} from '../app.settings';

import {Response} from '../models/response.model';
import {CreditCard} from '../models/credit-card.model';
import {CostCenter} from '../models/cost-center.model';
import {PaymentMethod} from '../models/payment-method.model';
import {PaymentMethodType} from '../enums/payment-method';
import {Order} from '../models/order.model';
import {OrderService} from './order.service';
import {Transaction} from '../models/transaction.model';
import {TransactionState} from '../constants/transaction-state';
import {ConfigService} from './config.service';
import {AuthService} from './auth.service';
import {isEmptyArray, updateItemInArray} from '../shared/utils';
import {TransactionGroup} from '../models/transaction-group.model';
import {TransactionTotals} from '../models/transaction-total.model';
import {findDetailedErrorMessage} from '../shared/helpers';
import {IPaymentJsToken} from '../interfaces/payment-js.token';
import {INVALID_SAP} from '../constants/error.codes';
import {API_URL} from '../constants/api-urls';


interface TransactionResponse {
    items: TransactionGroup[];
    count: number
}

@Injectable()
export class PaymentService extends BaseService {

    @observable transactions: TransactionGroup[] = [];
    @observable failedTransactions: TransactionGroup[] =  null;

    public transactionsSort = '';
    public transactionsFilter: any = null;

    @observable totalTransactions = 0;
    @observable fetchingTransactions = false;

    @observable paymentsMethods: PaymentMethod[] = [];
    @observable performingAction = false;

    @observable verifyingCard = false;
    @observable performingTransaction = false;

    @observable savingNewCreditCard = false;

    @observable verifyingSAP = false;
    @observable updatingPaymentMethod = false;

    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService,
        private orderService: OrderService,
        private configService: ConfigService,
        private authService: AuthService,
    ) {
        super('/account/payment-methods', http, appSettings, toastr);
    }


    /**
     * requested once -  right after user is logged in
     * promise is require to ensure addresses are loaded
     */
    public fetchUserPaymentMethods() {
        if (!this.authService.canSeePaymentMethods || !this.configService.showBillingInfo) {
            this.setPayments([]);
            return;
        }

        const url = `${API_URL}/account/payment-methods`;
        this.http.get<Response>(url)
            .pipe(
                take(1),
                catchError(this.handleError('PaymentService::fetchUserPaymentMethods', null))
            ).subscribe( response => {
                if (response && response.data ) {

                    const result = [];

                    if (Array.isArray(response.data)) {

                        this.paymentsMethods = [];
                        response.data.forEach(i => {
                            if (i.type === PaymentMethodType.CostCenter) {
                                result.push(new CostCenter(i));
                            } else if ( (i as CreditCard)) {
                                const creditCard = new CreditCard(i);
                                if (creditCard.canDisplay) {
                                    result.push(creditCard);
                                }

                            }
                        });
                    }
                    this.setPayments(result);
                } else {
                    this.setPayments([]);
                }

        });
    }

    @action
    setPayments(payments: PaymentMethod[]) {
        this.paymentsMethods = payments;
    }


    public updateCreditCard(creditCard: CreditCard): Promise<CreditCard> {
        return this.save(creditCard).then( (result) => {
            if (result) {
                this.toastr.success('Credit card has been updated.');
            }
            return result;
        })
    }

    public saveSapAccount(costCenter: CostCenter): Promise<CostCenter> {
        return this.save(costCenter).then( result => {
            if (result) {
                if (costCenter.id > 0) {
                   this.toastr.success('SAP Account has been updated.');
                } else {
                    this.toastr.success('New SAP Account has been added.');
                }
            }
            return result;
        })
    }

    // save payment
    async save( payment: PaymentMethod) {
        let savedPayment;

        this.performingAction = true;

        if (payment) {
            // clear BE properties
            delete  payment['error'];

            if (payment.id > 0) {

                savedPayment = await this.updatePayment(payment).toPromise();
                if (savedPayment) {
                    this.refreshPayments(savedPayment);
                    // this.toastr.success('Payment method has been changed');

                }
            } else {
                // add new
                savedPayment = await this.addPaymentMethod(payment).toPromise();
                if (savedPayment) {
                    this.refreshPayments(savedPayment);
                    // savedPayment = true;
                    // this.toastr.success('New payment method has been added');
                }
            }
        }

        this.performingAction = false;
        return savedPayment;
    }


    createCreditCard(orderId: number = null): Observable<CreditCard> {
        if (!orderId) {
            return this.addPaymentMethod(new CreditCard({label: 'Credit Card', active: false})) as Observable<CreditCard>;
        }
        return of(null);
        // this.showCreditCard.next({show: true, creditCard: card, orderId});
    }


    async setDefault(payment: PaymentMethod) {
        payment.active = true;
        const retPayment = await this.setActivePayment(payment).toPromise();
        if (retPayment) {
            this.toastr.success('Selected payment method has been set as default');
            // reset other payment methods
            this.paymentsMethods.forEach( p => {
                if ( p.id !== retPayment.id) {
                    p.active = false;
                }
            })
        }
    }

    async delete(payment: PaymentMethod) {
        this.performingAction = true;
        const result = await this.deletePaymentMethod(payment.id).toPromise();
        this.performingAction = false;
        if (result) {
            this.toastr.success('Payment method has been deleted');
            const payments = this.paymentsMethods.filter( p => p.id !== payment.id);
            this.setPayments( payments);
        }

        return result;
    }


    verifySap(costCenter: CostCenter, customErrorMessage = ''): Observable<{success: boolean, errorMessage?: string}> {
        return this.http.post<Response>(`${this.apiURL}/verify`, costCenter, {headers: this.xsrfTokenHeader} ).pipe(
            map( response =>  ({success: response.data === true})),
            catchError( (error: any): Observable<any> => {

                if (!error.error) {
                    return of({success: true});
                }

                // a special case error.data  has a higher priority
                if (customErrorMessage) {
                    error.error.message = customErrorMessage;
                }
                const errorMessage = findDetailedErrorMessage(error);
                if (errorMessage) {
                    this.toastr.error(errorMessage);
                }
                return of({success: false, errorMessage: errorMessage});
            })
        );
    }

    @action
    public refreshPayments(payment: PaymentMethod) {
        const idx = this.paymentsMethods.findIndex(p => p.id === payment.id);
        if (idx === -1) {
            // not found
            // skip if credit card has state INIT
            if ( payment.isAccount() || (payment.isCard() && (payment as CreditCard).canDisplay)) {
                this.paymentsMethods.push(payment);
            }
        } else {
            this.paymentsMethods[idx] = payment;
        }

        this.setPayments( this.paymentsMethods.slice());
    }



    private updatePayment(payment: PaymentMethod): Observable<PaymentMethod> {
        return this.http.put<Response>(`${this.apiURL}/${payment.id}`, payment, {headers: this.xsrfTokenHeader}).pipe(
            map( response => {
               if (response.data) {
                    if (payment.isCard()) {
                        return  new CreditCard(response.data);
                    }
                    return new CostCenter(response.data);
               }
               return null;
            }),
            catchError(this.handleError('PaymentService::updatePaymentMethod', null))
        );
    }

    private setActivePayment(payment: PaymentMethod): Observable<PaymentMethod> {
        return this.http.put<Response>(`${this.apiURL}/${payment.id}/active`, null).pipe(
            map( response => {
                if (response.data) {
                    if (payment.isCard()) {
                        return  new CreditCard(response.data);
                    }
                    return new CostCenter(response.data);
                }
                return null;
            }),
            catchError(this.handleError('PaymentService::setActivePaymentMethod', null))
        );
    }


    private addPaymentMethod(payment: PaymentMethod): Observable<PaymentMethod>  {
        return this.http.post<Response>(this.apiURL, payment, {headers: this.xsrfTokenHeader}).pipe(
            map( response => {
                if (response.data) {
                    if (payment.isCard()) {
                        return  new CreditCard(response.data);
                    }
                    return new CostCenter(response.data);
                }
                return null;
            }),
            catchError(this.handleError('PaymentService::addPaymentMethod', null))
        );
    }

    private deletePaymentMethod(paymentId: number): Observable<boolean>  {
        return this.http.delete<Response>(`${this.apiURL}/${paymentId}`).pipe(
            map( response => {
                return !response.error;
            }),
            catchError(this.handleError('PaymentService::deletePaymentMethod', false))
        )
    }


    public getPaymentMethod(paymentMethodId: number): Observable<PaymentMethod> {
        return this.http.get<Response>(`${this.apiURL}/${paymentMethodId}`).pipe(
            map( response => {
                if (response.data) {
                    if (response.data.type === PaymentMethodType.CostCenter) {
                        return new CostCenter(response.data);
                    } else {
                        return new CreditCard(response.data);
                    }
                }
                return null;
            }),
            catchError(this.handleError('PaymentService::getPaymentMethod', null))
        );
    }

    public get creditCards(): CreditCard[] {

        const cards = this.paymentsMethods.filter(  p => (p.isCard()  && (p as CreditCard).isValid)) as CreditCard[];

        const sortedCards = cards.sort( (a, b) => {
            if (a.active && !b.active) {
                return -1;
            }
            if (!a.active && b.active) {
                return 1;
            }

            return 0;
        });

        return sortedCards;

    }

    public get sapAccounts(): CostCenter[] {
        return  this.paymentsMethods.filter( m => m.isAccount()) as CostCenter[];
    }

    // save verified credit card from order to user payment methods
    public saveCreditCardForFuture(orderId: number, cardLabel: string = ''): Observable<CreditCard> {

        return this.saveCreditCardFromOrder(orderId).pipe(
            switchMap( creditCard => {
                if (creditCard) {
                    if (cardLabel && creditCard.label !== cardLabel) {
                        // update card label if it's required
                        creditCard.label = cardLabel;
                        return this.save(creditCard);
                    }
                }

                return of(creditCard);
            }),
            tap( result => {
                if (result && result instanceof CreditCard) {
                    this.refreshPayments(result);
                }
            }),
        );
    }


    private saveCreditCardFromOrder(orderId: number): Observable<CreditCard> {
        return this.http.put<Response>(`${this.apiURL}/${orderId}/save`, {}).pipe(
            map( (response: Response) => {
                if (response.data) {
                    return new CreditCard(response.data);
                }
                return null;
            }),
            catchError(this.handleError('PaymentService::saveCreditCardForFuture', null))
        )

    }

    @action
    public reset() {
        this.paymentsMethods = [];
        this.transactions = [];
        this.fetchingTransactions = false;
        this.totalTransactions = 0;

    }

    public get validPaymentMethod(): PaymentMethod[] {
        return this.paymentsMethods.filter( (p: PaymentMethod) => (p.isAccount() || (p as CreditCard).isValid));
    }

    public get defaultSAP(): CostCenter {
        const paymentMethod = this.validPaymentMethod.find( p =>  p.active && p.isAccount());
        if (paymentMethod) {
            return paymentMethod as CostCenter;
        }

        return null;
    }

    public get defaultCreditCard(): CreditCard {
        if (!this.authService.canEditCreditCard) {
            return null;
        }

        const paymentMethod = this.validPaymentMethod.find( p => p.active && p.isCard());
        if (paymentMethod) {
            return paymentMethod as CreditCard;
        }
        return null;
    }

    public getDefaultPaymentForOrder(order: Order): PaymentMethod {
        if (!order) {
            return null;
        }

        if (!this.authService.canShowSAP || !this.configService.showBillingInfo) {
            return null;
        }

        if (!order.isOnDemand) {
            return this.defaultSAP;
        }

        return this.defaultSAP || this.defaultCreditCard;
    }

    public findPaymentMethodsByTerm<T>(term: string, paymentMethods: T[]): T[] {
        if (!term || paymentMethods.length === 0) {
            return paymentMethods;
        }
        return paymentMethods.filter( p  => p['label'].toLowerCase().indexOf(term.toLowerCase()) !== -1)
    }

    // returns credit card or null
    public async checkCreditCardInOrder(orderId: number) {

        const order = await  this.orderService.getOrder(orderId, true).toPromise();
        if (order) {
            const creditCard = order.creditCard;
            // card should have verified state
            if (creditCard && creditCard.isValid) {
                // card should have unique id
                if (!this.creditCards.find( c => c.id === creditCard.id)) {
                    return creditCard;
                }
            } else {
                this.toastr.error('Failed to verify the credit card.');
            }
        }
        return null;
    }


    private requestTransactions(sort: string,
                                filter: any,
                                offset = 0,
                                limit = 18):
        Observable<TransactionResponse> {

        if (!this.authService.canSeeTransactions) {
            return of({count: 0, items: []});
        }

        const url = `${this.baseUrl}/account/transactions`;
        const range = `${limit}-${offset}`;
        const headers: HttpHeaders = new HttpHeaders({'Range': range, 'X-Sort': sort});
        let body = {};
        if (filter) {
            body = {...filter}
        }

        return this.http.post<HttpResponse<Response>>(url, body, {observe: 'response', headers: headers}).pipe(
            delay(3000),
            map( httpResponse => {

                const response = new Response(httpResponse.body);
                const count = parseInt(httpResponse.headers.get('X-Count'), 10);
                if (Array.isArray(response.data)) {
                    return {count, items: response.data.map( item => new TransactionGroup(item))};
                }

                return {count: 0, items: []};
            }),
            catchError(this.handleError('PaymentService::getTransactions', {count: 0, items: []}))
        );
    }

    @action public fetchFailedTransactions(force: boolean = false): Observable<TransactionGroup[]> {
        if (!force && this.failedTransactions) {
            return;
        }

        const  filter = { state: [TransactionState.FAILED]};
        return this.requestTransactions('', filter)
            .pipe( map((transactionResponse: TransactionResponse) => {
            if  (!isEmptyArray(transactionResponse.items)) {
                this.failedTransactions = transactionResponse.items;
            } else {
                this.failedTransactions = [];
            }
            return this.failedTransactions;
        }));
    }

    @computed get hasFailedTransactions(): boolean {
        return !isEmptyArray(this.failedTransactions);
    }

    public fetchTransactions() {
        this.fetchingTransactions = true;
        this.requestTransactions(this.transactionsSort, this.transactionsFilter).subscribe( transactionResponse => {
            this.fetchingTransactions = false;
            this.transactions = transactionResponse.items;
            this.totalTransactions = transactionResponse.count;
        });
    }

    public refetchTransactions() {
        this.fetchingTransactions = true;
        this.requestTransactions(this.transactionsSort, this.transactionsFilter, 0, this.transactions.length)
           .subscribe(transactionResponse => {
            this.fetchingTransactions = false;
            this.transactions = transactionResponse.items;
            this.totalTransactions = transactionResponse.count;
        });
    }

    public loadMoreTransactions() {
        this.fetchingTransactions = true;
        this.requestTransactions(this.transactionsSort, this.transactionsFilter, this.transactions.length)
            .subscribe( transactionResponse => {
            this.fetchingTransactions = false;
            this.transactions = [...this.transactions, ...transactionResponse.items];
            this.totalTransactions = transactionResponse.count;
        });
    }
    public moreTransactionsAvailable(): boolean {
        return this.totalTransactions > this.transactions.length;
    }

    private checkTransactionStatus(transactionId: number):
        Observable<{state: TransactionState, transaction_tag: string, aggregation?: any }> {
        return this.http.get<Response>(`${this.baseUrl}/account/transactions/${transactionId}/state`).pipe(
            map( response => {
                if (response.data) {
                    return response.data;
                }
                return {state: TransactionState.FAILED, transaction_tag: 'N/A'};
            }),
            catchError(this.handleError('PaymentService::checkTransactionStatus', null))
        );
    }

    private updateOrderCreditCard(order: Order, creditCard: CreditCard): Observable<boolean> {
        if (!creditCard) {
            return of(true);
        }
        return this.orderService.updateCreditCard(order, creditCard).pipe(
            map( retOrder => {
                return !!retOrder;
            })
        );
    }

    private waitForComplete(transactionGroup: TransactionGroup, transactionItem: Transaction): Promise<TransactionGroup> {
        const POLLING_INTERVAL = 5000;
        const WAITING_TIMEOUT = 45 * 1000; // 45 sec

        return new Promise<TransactionGroup>( resolve => {

            let interval = setInterval(() => {
                this.checkTransactionStatus(transactionItem.id)
                    .subscribe( (result: {state: TransactionState, transaction_tag: string, aggregation?: any}) => {
                    if (!result) {
                        clearInterval(interval);
                        interval = null;
                        transactionItem.state = TransactionState.UNKNOWN;
                        transactionGroup.updateTransaction(transactionItem);
                        resolve(transactionGroup);
                    }

                    if (result.state === TransactionState.SUCCESS
                        || result.state === TransactionState.REJECTED
                        || result.state === TransactionState.FAILED
                        || result.state === TransactionState.BAD_DEBT) {
                        clearInterval(interval);

                        if (result.aggregation) {
                            const totals = new TransactionTotals(result.aggregation.totals);
                            const transactions = result.aggregation.transactions.map( t => new Transaction(t));
                            transactionGroup.setTransactions(totals, transactions);
                        } else {
                            transactionItem.state = result.state || TransactionState.UNKNOWN;
                            transactionGroup.updateTransaction(transactionItem);
                        }
                        interval = null;
                        resolve(transactionGroup);
                    }
                });

            }, POLLING_INTERVAL);

            setTimeout( () => {
                if (interval) {
                    clearInterval(interval);
                    transactionItem.state = TransactionState.UNKNOWN;
                    transactionItem.transaction_tag = 'N/A';
                    transactionGroup.updateTransaction(transactionItem);
                    resolve(transactionGroup);
                }
            }, WAITING_TIMEOUT);
        });
    }

    public fixTransaction(transaction: TransactionGroup, transactionItem: Transaction, order: Order, creditCard: CreditCard)
        : Observable<TransactionGroup> {

        const clonedTransactionGroup = new TransactionGroup(transaction);
        const clonedTransactionItem = new Transaction(transactionItem);
        return this.updateOrderCreditCard(order, creditCard).pipe(
            switchMap( result => {
                if (result) {
                    return from( this.waitForComplete(clonedTransactionGroup, clonedTransactionItem));
                } else {
                    return of(clonedTransactionGroup);
                }
            }),
            tap( newTransactionGroup => {
                this.transactions = updateItemInArray<TransactionGroup>(this.transactions, newTransactionGroup);
            }),
            switchMap( result => {
                // refetch failed transactions
                return this.fetchFailedTransactions(true).pipe( map( () => result));
            })
        );

    }

    public getPaymentJsToken(orderId: number = null): Observable<{token: IPaymentJsToken, creditCard: CreditCard}> {

        const url = `${this.appSettings.settings.apiBaseURL}/paymentjs/auth`;

        return this.createCreditCard().pipe(
            switchMap( creditCard => {

                if (!creditCard && !orderId) {
                    return of(null);
                }
                const object_type = orderId ? 2 : 1;
                const object_id = orderId || creditCard.id;

                return  this.http.post(url, {object_id, object_type}).pipe(
                    map( (response: any) => {
                        if (response) {
                            return {token: {...response}, creditCard};
                        }
                        return null;
                    }),
                    catchError(this.handleError('PaymentService::getPaymentJsKeys', null, {hideResponseError: true}))
                );

            })
        );
    }

    public saveCreditCard(cardId: number,
                          orderId: number,
                          cardLabel: string,
                          saveToPaymentMethods = false): Observable<CreditCard> {

        this.savingNewCreditCard = true;

        return from(this.verifyCreditCard(cardId, orderId)).pipe(
            switchMap( verifiedResult => {
                if (!verifiedResult) {
                   // failed  to verify the card
                    this.toastr.error('Credit Card verification has been failed. Please try again or ask the support.');
                    return of(null);
                }

                if (verifiedResult instanceof CreditCard) {
                    const creditCard = verifiedResult as CreditCard;
                    creditCard.label = cardLabel;
                    if (!creditCard.isValid) {
                        if (creditCard.error) {
                            this.toastr.error(`${creditCard.error}`);
                        }
                        return of(null);
                    }

                    return from( this.save(creditCard)).pipe(
                        tap(result => {
                           if (result) {
                               this.toastr.success('Credit card has been saved');
                           }
                        })
                    );
                }


                if (verifiedResult instanceof Order) {
                    const creditCard = verifiedResult.creditCard;
                    creditCard.label = cardLabel;
                    if (!creditCard.isValid) {
                        if (creditCard.error) {
                            this.toastr.error(`${creditCard.error}`);
                        }
                        return of(null);
                    }

                    return  this.orderService.updateCreditCard(verifiedResult, creditCard).pipe(
                        switchMap( retOrder => {
                            if (saveToPaymentMethods) {
                                return this.saveCreditCardForFuture(retOrder.id);
                            }

                            return of(creditCard);
                        })
                    )
                }

                return of(null);
            }),
            finalize( () => {
                this.savingNewCreditCard = false;
            })
        )
    }


    public updatePaymentMethod(order: Order, paymentMethod: PaymentMethod): Observable<Order> {
        if (!order) {
            return of(order);
        }

        const updatedOrder = new Order(order);
        if (!paymentMethod) {
            updatedOrder.costCenter = null;
            updatedOrder.creditCard = null;
        } else {
            if (paymentMethod.isAccount()) {
                updatedOrder.costCenter = paymentMethod as CostCenter;
                updatedOrder.creditCard = null;
            } else {
                updatedOrder.creditCard = paymentMethod as CreditCard;
                updatedOrder.costCenter = null;
            }
        }


        if (updatedOrder.costCenter) {
            return this.updateCostCenter(updatedOrder);
        }

        if (updatedOrder.creditCard) {
            return this.updateCreditCardInOrder(updatedOrder);
        }

        if (order.isWSLR || !this.authService.isEmployee) {
            if (order.creditCard && !updatedOrder.creditCard) {
                return this.removeCreditCard(updatedOrder);
            }
        }

        return of(updatedOrder);
    }

    public verifyCostCenter(costCenter: CostCenter): Observable<CostCenter>  {
        if (!costCenter || costCenter.verified) {
            return of(costCenter);
        }
        this.verifyingSAP = true;
        return this.verifySap(costCenter, 'Selected payment method is invalid')
            .pipe( map(result => {
                    if (result?.success) {
                        const retCostCenter = new CostCenter(costCenter);
                        retCostCenter.verified = true;
                        return retCostCenter;
                    }

                    return null;
                }), finalize( () => {
                    this.verifyingSAP = false;
                })
            );
    }


    private updateCostCenter(order): Observable<Order> {
        this.updatingPaymentMethod = true;
        return this.orderService.updateCostCenter(order, order.costCenter).pipe(
            finalize( () => this.updatingPaymentMethod = false)
        )

        // return this.verifyCostCenter(order.costCenter).pipe(
        //     switchMap( costCenter => {
        //         if (costCenter && costCenter.verified) {
        //         } else {
        //             order.error = {errorCode: INVALID_SAP};
        //         }
        //         return of(order);
        //     })
        // )
    }

    private updateCreditCardInOrder(order): Observable<Order> {
        this.updatingPaymentMethod = true;

        return this.orderService.updateCreditCard(order, order.creditCard).pipe(
            finalize( () => this.updatingPaymentMethod = false)
        );
    }

    private removeCreditCard(order) {
        this.updatingPaymentMethod = true;

        return this.orderService.removeCreditCardFromOrder(order).pipe(
            finalize(() => this.updatingPaymentMethod = false)
        );
    }



    private verifyCreditCard(cardId: number, orderId?: number): Promise<Order | CreditCard> {

        const POLLING_INTERVAL = 5000;
        const WAITING_TIMEOUT = 45 * 1000; // 45 sec

        return new Promise<Order | CreditCard>( resolve => {

            let interval = setInterval(() => {
                this.checkCreditCardStatus(cardId, orderId)
                    .subscribe( (result: Order | CreditCard | boolean) => {
                        if (result === null) {
                            // error case
                            clearInterval(interval);
                            interval = null;
                            resolve(null);
                        }


                        if (result instanceof Order || result instanceof CreditCard) {
                            clearInterval(interval);
                            interval = null;
                            resolve(result);
                        }


                    });
            }, POLLING_INTERVAL);

            setTimeout( () => {
                if (interval) {
                    clearInterval(interval);
                    resolve(null);
                }
            }, WAITING_TIMEOUT);
        });


    }

    private checkCreditCardStatus(cardId: number, orderId?: number): Observable<Order | CreditCard | boolean> {
        const url = `${this.appSettings.settings.apiBaseURL}/paymentjs/poll`;
        return this.http.post<Response>(url, this.getCreditCardType(cardId, orderId)).pipe(
            map( result => {
                const data: any = result?.data;

                if (typeof  data === 'boolean') {
                    return data;
                }

                if (data.order) {
                    const order = new Order(data.order);
                    this.orderService.updateOrderList(order);
                    return order;
                }

                if (data.payment_method) {
                    return new CreditCard(data.payment_method);
                }

                return null;
            }),
            catchError(this.handleError('PaymentService::checkCreditCardStatus', null, {hideResponseError: true}))
        );
    }


    private getCreditCardType(cardId: number, orderId?: number): any {
        return {object_type: orderId ? 2 : 1,
            object_id: orderId || cardId}
    }

}
