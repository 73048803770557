import {BaseService} from './base.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {action, computed, observable} from 'mobx';
import {AppSettings} from '../app.settings';
import {ToastrService} from 'ngx-toastr';

import * as _ from 'lodash';
import {CacheModel} from '../models/cache.model';
import {Response} from '../models/response.model';
import {Product} from '../models/product.model';
import {ProductCatalog} from '../models/product-catalog.model';
import {FilterGroup} from '../models/filter-group.model';
import {OrderWindow} from '../models/order-window.model';
import {ApiProductService} from './api-product.service';
import {ProductResult} from '../interfaces/product.result';
import {CAROUSEL_TYPE} from '../constants/product-type';
import {GoogleAnalytics} from './google-analytics.service';
import {IDecisionPointData} from '../interfaces/decision-point.data';
import {floorLastCent, computeHash, isEmptyArray, transformFilters} from '../shared/utils';
import {OrderItem} from '../models/order-item.model';
import {IDecisionPointDelivery} from '../interfaces/decision-point-delivery';
import {PRODUCT_ACCESS_ERROR} from '../constants/error.codes';
import {ProductCategory} from '../models/product-category.model';
import {Brand} from '../models/brand.model';
import { IRunOnPrice, IPriceTiers, ITierInfo } from 'app/interfaces/price-tiers';
import {convertCatalogsToHeroSlides} from '../shared/helpers';
import {CurrencyService} from './currency.service';
import {API_URL} from '../constants/api-urls';
import { SKU } from 'app/models/sku.model';

const BASE_URL = '/products';
@Injectable()
export class ProductService extends BaseService {
    readonly apiV2URL: string;
    public catalogCache: CacheModel;
    public productCache: CacheModel;
    // protected filterCache: CacheModel;
    protected statCache: CacheModel;
    protected moqCache: CacheModel;
    protected dpCache: CacheModel;

    private favoriteFiltersCache: CacheModel;

    protected statFetching = false;

    // state methods
    public currentCartFilters: any = {};

    @observable statistics = {};


    // @observable heroSlides = [];
    // @observable onDemandHeroSlides = [];
    // @observable buyingWindowHeroSlides = [];

    productCatalogs = {};
    products = {};
    briefProducts = {};

    @observable fetchingCatalogsForBuyingWindow = false;
    @observable fetchingCatalogsForOnDemand = false;
    @observable fetchingCatalogsForBuyingWindowHomepage = false;
    @observable fetchingCatalogsForOnDemandHomepage = false;
    @observable fetchingPopularOnDemandProducts = false;
    @observable fetchingOnDemandProductCategories = false;
    @observable fetchingOnDemandProductBrands = false;
    @observable fetchingRecentPurchasedProducts = false;
    @observable fetchingFavoriteProducts = false;



    @observable public onDemandCatalogs: ProductCatalog[] = [];
    @observable public buyingWindowCatalogs: ProductCatalog[] = [];

    @observable public onDemandHomepageCatalogs: ProductCatalog[] = [];  // always sorted by is_featured DESC
    @observable public buyingWindowHomepageCatalogs: ProductCatalog[] = []; // always sorted by is_featured DESC

    @observable public onDemandPopularProducts: Product[] = [];
    @observable public onDemandRecentPurchasesProducts: Product[] = [];
    @observable public onDemandRecentPurchasesProductsTotalCount = 0;
    @observable public onDemandProductCategories: ProductCategory[] = [];
    @observable public onDemandProductBrands: Brand[] = [];
    @observable public favoriteProducts = {};


    public updatedProduct = new Subject<Product>();
    public updatedBriefProduct = new Subject<Product>();

    private activeBuyingWindowId: number;


    /**
     * returns  discount price or original if discount hasn't been see
     * @param price - original price
     * @param product - product model
     * @param hasTrimesterBudget - if true discount is not applied
     */
    static computeDiscountPrice(price: number, product: Product, hasTrimesterBudget: boolean = false): number {
        if (!product.discount || hasTrimesterBudget) {
            return price;
        }

        return floorLastCent(price * (1 - product.discount / 100) + 0);
    }

    static computePriceTiers(product: Product, sku: SKU, quantity: number): ITierInfo {
        // reset price tiers
        let priceTiers: IPriceTiers[] = [];
        let runOnPrices: IRunOnPrice[] = [];


        if (product.hasPriceRunOnRange) {
            runOnPrices =  sku.getPriceTiers().map( p => {
                  return {
                      quantity: p.quantity,
                      pricing: this.checkPriceType(p.pricing),
                      each_additional:  this.checkPriceType(p.each_additional),
                      selected: false,
                      currency: product.currency,
                      packageType: product.getUOM()
                  };
              }
            );

            this.findSelectedTier(runOnPrices, quantity);

            return{
                priceTiers: [],
                runOnPrices
            }
        }

        const result: Array< IPriceTiers> = [];
        if (!product) {
            return {
                priceTiers: result,
                runOnPrices: []
            }
        }

        const tiers = sku.getPriceTiers();
        if (tiers.length < 2) {
            return{
                priceTiers: result,
                runOnPrices: []
            }
        }

        for ( let i = 0; i < tiers.length; i++) {
            let range: string;
            let selected = false;
            const value = tiers[i].value;
            const key = parseInt(tiers[i].key, 10);
            const alreadySelected = result.some( p => p.selected);
            // const {key, value} = tiers[i];
            if (i === 0) {
                range = `< ${key}`;
                selected = quantity < key;
            } else if ( i < tiers.length - 1) {
                const previousTier = tiers[i - 1];
                const from = parseInt(previousTier.key, 10);
                const to = key - 1;
                if (from !== to) {
                    range = ( from !== to ) ? `${from} - ${to}` : `${to}`;
                    selected = !alreadySelected  && (quantity >= from && quantity <= to);
                } else {
                    range =  `${to}`;
                    selected = !alreadySelected && (quantity === from);
                }
            } else {
                const previousTier = tiers[i - 1];
                range = `>= ${previousTier.key}`;
                selected = quantity >=  parseInt(previousTier.key, 10);
            }
            result.push({ range, price: this.checkPriceType(value), selected, packageType: product.getUOM(),
                currency: product.currency});
        }
        priceTiers = result;

        return {priceTiers, runOnPrices};
    }

    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService,
        protected router: Router,
        private api: ApiProductService,
        private  googleAnalytics: GoogleAnalytics,
        private currencyService: CurrencyService
    ) {
        super('/products', http, appSettings, toastr);
        this.apiV2URL = `${API_URL}/products`;

        this.cache.persistent = true;
        this.cache.prefix = 'Products';

        this.catalogCache = new CacheModel();
        this.productCache = new CacheModel();
        // this.filterCache = new CacheModel();
        // this.filterCache.expiration = 3600;

        this.statCache = new CacheModel();
        this.moqCache = new CacheModel();
        this.moqCache.expiration = 300;
        this.dpCache = new CacheModel();

        this.favoriteFiltersCache = new CacheModel();

        // fetch on-demand catalogs
        // this.onActiveOrderWindowChanged(0).subscribe( data => {});
    }

    private getStatistics(orderWindowId: number) {
        this.statFetching = true;
        return this.api.getStatistics(orderWindowId).pipe( tap( stats => {
            this.statFetching = false;
            this.setStatistics(stats);
        }));
    }

    private static checkPriceType(value: string | number): number {
        if (value === undefined || value === null) {
            return 0;
        }

        if (typeof value === 'string') {
            return parseFloat(value);
        }

        return value;
    }

    private static findSelectedTier(runOnRange: IRunOnPrice[], quantity: number) {
        for (let i = runOnRange.length - 1; i >= 0; i--) {
            if (quantity >= runOnRange[i].quantity) {
                runOnRange[i].selected =  true;
                return;
            }

            if (i === 0) {
                // select first tier by default
                runOnRange[i].selected = true;
                return;
            }
        }
    }

    getEcomProducts(programId = 1, filter: Array<string> = null, paging: string = '20-0',
                    sort: string = '', wslrEntityId = 0): Observable<ProductResult> {
        return this.getProducts(1, programId, filter, paging, sort, wslrEntityId);
    }

    // Buying Window

    public getCatalog(slugOrID: any = null): Observable<ProductCatalog> {
        if (!slugOrID) {
            return of(null);
        }
        return this.api.getProductCatalog(slugOrID).pipe( tap( catalog => {
            this.productCatalogs[catalog.id] = catalog;
        }));
    }

    public getCatalogsForHomepage(windowID = 0): Observable<{catalogs: ProductCatalog[]}> {
        windowID ?  this.fetchingCatalogsForBuyingWindowHomepage = true : this.fetchingCatalogsForOnDemandHomepage = true;
        this.setHomepageCatalogs(windowID, []);
        return this.fetchCatalogs(windowID).pipe(
            tap (({catalogs}) => {
                this.setHomepageCatalogs(windowID, catalogs);
                this.resetLoadingIndicator();
            })
        );
    }

    public getCatalogs(windowID: number = 0,
                filter: Array<string> = null,
                paging: string = '18-0',
                sort: string = 'is_featured DESC'): Observable<{catalogs: ProductCatalog[]; count: number}> {

        windowID ?  this.fetchingCatalogsForBuyingWindow = true : this.fetchingCatalogsForOnDemand = true;
        this.setCatalogs(windowID, []);
        return this.fetchCatalogs(windowID, filter, paging, sort);
    }

    @action resetLoadingIndicator() {
        this.fetchingCatalogsForBuyingWindowHomepage = false;
        this.fetchingCatalogsForOnDemandHomepage = false;
        this.fetchingCatalogsForBuyingWindow = false;
        this.fetchingCatalogsForOnDemand = false;
    }


    private fetchCatalogs(windowID: number = 0,
                          filter: Array<string> = null,
                          paging: string = '18-0',
                          sort: string = 'is_featured DESC'): Observable<{catalogs: ProductCatalog[]; count: number}> {


        if (!sort) {
            sort = 'is_featured DESC';
        }

        return this.getCatalogIds(windowID, filter, paging, sort).pipe( switchMap( ({ids, count}) => {
            return this.getCatalogsByIds(ids).pipe(
                map( catalogs => {
                    return {catalogs, count};
                }),
                tap ( val => {
                    this.setCatalogs(windowID, val?.catalogs || []);
                    this.resetLoadingIndicator();
                })
            );
        }));

    }

    public getPopularOnDemandProducts(wslrEntityId = 0): Observable<Product[]> {
        const sort = 'popularity DESC';
        if (this.fetchingPopularOnDemandProducts || this.onDemandPopularProducts.length > 0) {
            return of(this.onDemandPopularProducts);
        }
        this.fetchingPopularOnDemandProducts = true;
        this.onDemandPopularProducts = [];

        return this.getProductsShortInfo(1, 1, null, '20-0', sort, '', wslrEntityId).pipe(
            map( ({products, count}) => {
                this.onDemandPopularProducts = [...products];
                this.fetchingPopularOnDemandProducts = false;
                return this.onDemandPopularProducts;
            })
        )
    }


    private getCatalogIds(windowID: number = null, filter: Array<string> = null,
                                paging: string = '18-0', sort: string = ''):
        Observable<{ids: number[]; count: number}> {

        const key: string = btoa(JSON.stringify({w: windowID, f: filter, p: paging, s: sort}))
            .replace('=', '');
        const catalogIdsKey = `${key}-ids`;
        const catalogIdsCountKey = `${key}-ids-count`;

        if (this.catalogCache.has(catalogIdsKey)) {
            const ids = this.catalogCache.get(catalogIdsKey);
            const count = this.catalogCache.get(catalogIdsCountKey);
            return of({ids, count});
        }

        return this.api.getCatalogsIds(windowID, filter, paging, sort).pipe( tap( ({ids, count}) => {
            this.catalogCache.set(catalogIdsKey, ids);
            this.catalogCache.set(catalogIdsCountKey, count);
        }));

    }

    private getCatalogsByIds( ids: number[]): Observable<ProductCatalog[]> {

        if (!ids || ids.length === 0) {
            return of([]);
        }
        const getCatalogs = (idList) => idList.map( id => this.productCatalogs[id]);

        // find missing catalogs
        const missing = ids.filter( id =>  this.productCatalogs[id] === undefined);
        if (missing.length === 0) {
            return of ( getCatalogs(ids));
        }

        return this.api.getCatalogsByIds(missing).pipe( map(catalogs => {
            catalogs.forEach( catalog => this.productCatalogs[catalog.id] = new ProductCatalog(catalog));
            return getCatalogs(ids);
        }))


    }

    getFilters(groupID: number = null, type: string, filters: FilterGroup[] = []): Observable<FilterGroup[]> {
        type = type + '-filters';

        if (type === 'all-product-filters' && groupID === 0 ) {
            // if active window  is not loaded no filter should be requested
            return of([]);
        }

        if (isEmptyArray(filters)) {

            const key: string = type + '-' + groupID;
            // const eTag: string = this.cache.getETag(key);
            // const httpOptions: Object = {headers: new HttpHeaders({'If-Match': eTag})};

            // if (this.filterCache.has(key)) {
            //     const items = [];
            //     _.each(this.cache.getItems(key), (item, i) => {
            //         items[i] = new FilterGroup(item);
            //     });
            //     return of(items);
            // }

            const url = `${this.apiURL}/catalog/${groupID}/${type}`;
            // const url = `${API_URL}${BASE_URL}/catalog/${groupID}/${type}`;
            return this.http.get<Response>(url)
                .pipe(
                    map(response => {
                        const result = response.data;

                        // if (result.length === 0 && response.eTag === eTag) {
                        //     result = this.cache.getItems(key);
                        // }
                        //
                        // if (response.eTag !== eTag) {
                        //     this.cache.deleteItems(key);
                        //     this.cache.setItems(key, result);
                        //     this.cache.setETag(key, response.eTag);
                        // }

                        _.each(result, (item, i) => {
                            result[i] = new FilterGroup(item);
                        });

                        // this.filterCache.set(key, true);

                        return result;
                    }),
                    catchError(this.handleError('ProductService::getFilters', []))
                );

        } else {
            // post request  with filtering
            const filtersString = transformFilters(filters);
            const filterHash  = computeHash(filtersString.join(''));

            // const key = `${type}-${groupID}-${filterHash}`;
            // if (this.filterCache.has(key)) {
            //     return of(this.cache.getItems(key).map( i => new FilterGroup(i)));
            // }
            // const url = `${API_URL}${BASE_URL}/catalog/${groupID}/${type}`;
            const url = `${this.apiURL}/catalog/${groupID}/${type}`;
            return this.http.post<Response>(url, {filter: filtersString})
                .pipe(
                    map(response => {

                        if (!isEmptyArray(response.data)) {
                            return response.data.map( i => new FilterGroup(i));
                        }
                        return [];
                    }),
                    // tap( retFilters => {
                    //     this.filterCache.set(key, true);
                    //     this.cache.setItems(key, retFilters);
                    // }),
                    catchError(this.handleError('ProductService::getFilters', []))
                );

        }

    }

    getProduct(slugOrID: any = null, wslrEntityId = 0, isAutoShip = false, force = false): Observable<Product> {

        if (!force) {
            if ( typeof slugOrID === 'string') {
                // slug case
                const cachedProducts: Product[] = Object.values(this.products);
                const foundProduct = cachedProducts.find( p => p.slug === slugOrID);
                if (foundProduct) {
                    return of(foundProduct);
                }

            }

            if ( typeof slugOrID === 'number') {
                // id case
                if (this.products[slugOrID]) {
                    return of(this.products[slugOrID]);
                }
            }
        }

        // let url = `${this.apiURL}${isAutoShip ? '/inactive' : ''}/${slugOrID}`;  old api
        let url = `${this.apiV2URL}${isAutoShip ? '/inactive' : ''}/${slugOrID}`;
        if (wslrEntityId) {
            url += `?entity_id=${wslrEntityId}`;
        }
        return this.http.get<Response>(url)
            .pipe(
                map(response => {
                    // check if redirect_url presents
                    if (response['redirect_url']) {
                        this.router.navigateByUrl(response['redirect_url']);
                        return null;
                    }
                    if (response.data) {
                        const product  = new Product(response.data);
                        // this.products[product.id] = product;
                        this.populatedUpdatedProduct(product);
                        this.currencyService.setCurrentCurrency(product.currency);
                        return product;
                    }
                    return null;
                }),
                catchError(this.handleProductError('ProductService::getProduct', null))
            );
    }

        public getProductRedirectUrl(productId: number): Observable<string> {
        // const url = `${API_URL}${BASE_URL}/${productId}`;
            const url = `${this.apiURL}/${productId}`;

        return this.http.get<Response>(url)
            .pipe(
                map(response => {
                    return response['redirect_url'];
                }),
                catchError(this.handleProductError('ProductService::getProductRedirectUrl', null))
            );
    }

    // put  product in cache
    public putToCache(product: Product) {
        this.products[product.id] = product;
    }

    protected handleProductError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.log('error', error);


            if (error?.error?.code === PRODUCT_ACCESS_ERROR) {
                this.toastr.error(error?.error?.message || 'You have no access to the product.');
            } else {
                this.router.navigateByUrl('/404', {skipLocationChange: true});
            }
            return of(result as T);
        };
    }

    getProducts(windowID: number, slugOrID: any = null, filter: Array<string> = null, paging: string = '18-0', sort: string,
                wslrEntityId = 0, refetchAggregatedQuantities = false): Observable<ProductResult> {

        if (!sort) {
            sort = 'is_featured DESC';
        }

        return this.getProductsIds(windowID, slugOrID, filter, paging, sort, wslrEntityId).pipe(
            switchMap( ({ids, count}) => {
                return this.getProductsByIds(ids).pipe(
                    switchMap( (products) => {
                       if (refetchAggregatedQuantities) {
                           return this.refreshAggregateQuantities(products, windowID)
                       }
                       return of(products);
                    }),
                    tap( products => {
                       if (!isEmptyArray(products)) {
                           this.currencyService.setCurrentCurrency(products[0].currency);
                       }
                    }),
                    map( products => {
                        return {products, count}
                }))
            })
        )

    }


    public getProductsShortInfo(windowID: number, slugOrID: any = null, filter: Array<string> = null,
                                paging: string = '18-0', sort: string, show: string = '',
                                wslrEntityId = 0, refetchAggregatedQuantities = false): Observable<ProductResult> {
        if (!sort) {
            sort = 'is_featured DESC';
        }
        return this.api.getProductsShortInfo(windowID, slugOrID, filter, paging, sort, show, wslrEntityId).pipe(
            switchMap (retResult => {
                if (!isEmptyArray(retResult.products)) {
                    retResult.products.forEach( p => {
                        this.briefProducts[p.id] = p;
                    })
                    // set currency
                    this.currencyService.setCurrentCurrency(retResult.products[0].currency);
                }
                if (!refetchAggregatedQuantities) {
                    return of (retResult)
                } else {
                    return this.refreshAggregateQuantitiesForShortInfo(retResult.products, windowID).pipe(
                        map( retProducts => {
                            return {products: retProducts, count: retResult.count};
                        })
                    )
                }
            })
        );
    }

    public exportOrderedProducts(type: 'xlsx' | 'pdf', windowID: number, slugOrID: any = null, filter: Array<string> = null,
                                sort: string,
                                wslrEntityId = 0): Observable<boolean> {
        if (!sort) {
            sort = 'is_featured DESC';
        }
        return this.api.exportOrderedProducts(type, windowID, slugOrID, filter, sort,  wslrEntityId);
    }


    @action public getFavoriteProducts(windowID: number, filter: Array<string> = null, paging: string = '18-0',
                                       sort: string = '', show: string = '', wslrEntityId = 0): Observable<ProductResult> {

        this.fetchingFavoriteProducts = true;

        return this.api.getFavoriteProducts(windowID, filter, paging, sort, show, wslrEntityId).pipe(
            tap( ({products}) => {
                if (!isEmptyArray(products)) {
                    this.googleAnalytics.viewItems(products);
                    this.currencyService.setCurrentCurrency(products[0].currency);
                }
            }),
            tap( ({products, count}) => {
                this.favoriteProducts[windowID] = { products, count};
                if (!isEmptyArray(products)) {
                    products.forEach( p => this.briefProducts[p.id] = p);
                }
                this.fetchingFavoriteProducts = false;
                // return {products, count}
            })
        );


    }

    public getFavoriteFilters(windowId = 0, filters: FilterGroup[] = []): Observable<FilterGroup[]> {

        let key = '' + windowId;
        if (!isEmptyArray(filters)) {
            key = `${windowId}-${computeHash(transformFilters(filters).join(''))}`;
        }

        if (this.favoriteFiltersCache.has(key)) {
            return of(this.favoriteFiltersCache.get(key).map(  i =>  new FilterGroup(i)));
        }
        return this.api.fetchFavoritesFilters(windowId, filters).pipe(
            tap( retFilters => {
                if (Array.isArray(retFilters)) {
                    this.favoriteFiltersCache.set(key, retFilters);
                }
            }));
    }

    private getProductsIds(windowID: number, slugOrID: any = null,
                           filter: Array<string> = null, paging: string = '18-0', sort: string = '', wslrEntityId = 0):
        Observable<{ids: number[]; count: number}> {

        const productKey: string = btoa(
            JSON.stringify({w: windowID, g: slugOrID, f: filter, p: paging, s: sort, wid: wslrEntityId}))
            .replace('=', '');
        const productKeyCount = `${productKey}-count`;

        if (this.productCache.has(productKey)) {
            const ids: number[] = this.productCache.get(productKey);
            const count: number = this.productCache.get(productKeyCount);
            return of({ids, count});
        }
        return this.api.getProductsIds(windowID, slugOrID, filter, paging, sort, wslrEntityId).pipe( tap(({ids, count}) => {
            this.productCache.set(productKey, ids);
            this.productCache.set(productKeyCount, count);
        }));

    }

    public getProductsByIds(ids: number[]): Observable<Product[]> {
        if (isEmptyArray(ids)) {
            return of([]);
        }

        const cachedProducts = (idList) => {
            const prodList: Product[] = [];
            idList.forEach( id => {
                if (this.products[id]) {
                    prodList.push(this.products[id]);
                }
            });
            return prodList;
        };

        // find missing catalogs
        const missing = ids.filter( id =>  this.products[id] === undefined);
        if (missing.length === 0) {
            return of ( cachedProducts(ids));
        }

        return this.api.getProductsByIds(missing).pipe(
            tap( products => {
                if (!isEmptyArray(products)) {
                    products.forEach( p => {
                        this.products[p.id] = p
                    });
                }
            }),
            map(() => {
                return cachedProducts(ids);
        }))

    }



    /**
     *
     * @param productIds - array of product ids
     * @param windowID
     */
    public refreshAggregateQuantities(products: Product[], windowID: number): Observable<Product[]> {

        const productIdList = products.map( p => p.id);

        return this.api.refreshAggregateQuantities(productIdList, windowID).pipe(
            map( response => {
                if (!response) {
                    return products;
                }
                const productIds = Object.keys(response);
                for ( const id of productIds) {

                    const product = products.find( p => p.id === +id);
                    if (product) {
                        const newSkus = response[id];
                        if (product.skus && newSkus) {
                            product.skus.forEach(sku => {
                                const newSku = newSkus.find(s => s.sku_id === sku.id);
                                if (newSku) {
                                    sku.aggregated_quantity = newSku.sku;
                                    sku.group_aggregated_quantity = newSku.group || 0;
                                }
                            });
                        }
                    }
                }
                return products;
            })
        );

    }

    private refreshAggregateQuantitiesForShortInfo(products: Product[], windowID: number): Observable<Product[]> {

        const productIdList = products.map( p => p.id);

        return this.api.refreshAggregateQuantities(productIdList, windowID).pipe(
            map( response => {
                if (!response) {
                    return products;
                }
                const productIds = Object.keys(response);
                for ( const id of productIds) {

                    const product = products.find( p => p.id === +id);
                    if (product) {
                        const newSkus = response[id];
                        if (product.skus && newSkus) {
                            product.skus.forEach(sku => {
                                const newSku = newSkus.find(s => s.sku_id === sku.id);
                                if (newSku) {
                                    sku.aggregated_quantity = newSku.sku;
                                    sku.group_aggregated_quantity = newSku.group || 0;
                                }
                            });
                        }
                    }
                }
                return products;
            })
        );

    }


    /**
     * Clear aggregated quantity from cache
     * @param productId
     */
    clearAggregateQuantitiesFromCache(productId: number) {
        const key = `productId-${productId}`;
        if (this.moqCache.has(key)) {
            this.moqCache.delete(key);
        }
    }

    public searchDecisionPoints(term: string): Observable<IDecisionPointData[]> {

        const key = term + '-0-0';

        if (this.dpCache.has(key)) {
            return of(this.dpCache.get(key));
        }

        return this.api.getDecisionPointData(term, '0', 0).pipe(
            map( data => {
                return data;
            }),
            tap( result => {
                if (!isEmptyArray(result)) {
                    this.dpCache.set(key, result);
                }
            })
        );

    }


    public getDecisionPointData(dpID: string, orderItem: OrderItem): Observable<IDecisionPointData> {
        const product = orderItem.product;
        const productId = product ? product.id : 0;
        return this.api.getDecisionPointData('', dpID, productId).pipe(
            map( data => {
                if (data && data.length === 1) {
                    const result = data[0];
                    if (product.inventoryType) {
                        result.availableQty = product.skus[0].available_quantity + orderItem.init_quantity;
                        result.dpQuantity = (result.availableQty > result.store_count) ? result.store_count : result.availableQty;
                    } else {
                        result.availableQty = -1;
                        result.dpQuantity = result.store_count;
                    }

                    result.orderItemQuantity = orderItem.quantity || 0;
                    return result;
                }
                return null;
            }),
        );
    }

    calculateDecisionPoints(dpID: string, productID: number, quantity: number): Observable<IDecisionPointDelivery[]> {
        return this.api.calculateDecisionPoints(dpID, productID, quantity);
    }


    public onActiveOrderWindowChanged(orderWindowId = 0) {
        if (orderWindowId > 0) {
            this.activeBuyingWindowId = orderWindowId;
        }

        return forkJoin([ this.getStatistics(orderWindowId), this.getCatalogs(orderWindowId), this.getCatalogsForHomepage(orderWindowId)]);

        // get statistics
        // this.getStatistics(orderWindow);

        // get hero slides
        // this.getHeroSlides(orderWindow);
        // this.getAllHeroSlides(orderWindow);
    }


    getHeroSlides(orderWindow: OrderWindow): Observable<any[]> {
        if (!orderWindow) {
            return of([]);
        }

        return this.getCatalogs(orderWindow.id, null, '5-0', 'is_featured DESC').pipe(
            map( ({catalogs}) => {

                const result = [];
                if (Array.isArray(catalogs)) {

                    catalogs.forEach( catalog => {
                        if (catalog.featureType === CAROUSEL_TYPE) {
                            const img = catalog.getHeroImage();
                            if (img) {
                                result.push({
                                    image: img['url'],
                                    headline: catalog.label,
                                    description: '',
                                    url: catalog.programDetailsUrl,
                                });
                            }
                        }

                    });
                }

                return result;
            })
        );
    }

    @action setStatistics(statistics: any) {
        this.statistics = statistics;
    }

    @action reset() {
        this.products = {};
        this.productCatalogs = {};
        this.catalogCache.clear();
        this.productCache.clear();
        // this.filterCache.clear();

        this.statistics = {};
        this.onDemandCatalogs = [];
        this.buyingWindowCatalogs = [];
    }



    @computed get heroSlides()  {
        return [...this.buyingWindowHeroSlides, ...this.onDemandHeroSlides].slice(0, 5);
    }

    @action public toggleFavorites(product: Product): Observable<Product> {
        if (!product?.id) {
            return of(product);
        }

        return this.api.toggleFavorite(product).pipe(
            tap(retProduct => {
                if (retProduct) {

                    this.products[retProduct.id] = retProduct; // update cached product
                    this.updatedProduct.next(retProduct);
                    const briefProduct  = this.briefProducts[retProduct.id];
                    if (briefProduct) {
                        briefProduct.setFavorite(retProduct.isFavorite);
                        this.updatedBriefProduct.next(briefProduct);
                    }
                    if (!isEmptyArray(this.onDemandPopularProducts)) {
                        this.onDemandPopularProducts = this.onDemandPopularProducts.map(p => {
                            if (p.id === retProduct.id) {
                                p.setFavorite(retProduct.isFavorite);
                            }
                            return p;
                        })
                    }
                    // TODO test favorites
                    // if (retProduct instanceof  Product) {
                    // } else {
                    //
                    //     const briefProduct: ProductShortInfo =  this.briefProducts[retProduct.id];
                    //     if (briefProduct) {
                    //         briefProduct.is_favorite =  retProduct.is_favorite;
                    //         this.updatedBriefProduct.next(briefProduct);
                    //     }
                    // }

                    const msg = retProduct.label +
                        (retProduct.isFavorite ? ' has been added to Favorites' : ' has been removed from Favorites');
                    this.toastr.success(msg, null, {enableHtml: true});
                    this.updateFavoriteProductsInCache(retProduct);
                }
            })
        );
    }

    public getApiUrl(): string {
        return this.apiURL;
    }

    public populateProduct(product: Product) {
        if (product) {
            const url = `${API_URL}/increment-popularity/product/${product.id}`;
            this.http.post<Response>(url, {}).subscribe( (result) => {
            });
        }
    }

    public updateProductList(product: Product) {
        this.products[product.id] = product;
    }

    public validateProduct(product: Product, buyingWindow: OrderWindow = null): Observable<boolean> {
        if (!product) {
            return of(null);
        }
        const orderWindowId = buyingWindow?.id || 0;
        return this.api.validateProduct(product.id, orderWindowId);
    }

    @action public fetchRecentProducts(filter: Array<string> = null, paging: string = '18-0',
                                       sort: string = ''): Observable<Product[]> {

        this.fetchingRecentPurchasedProducts = true;
        return this.api.fetchRecentProducts(filter, paging, sort).pipe(
            switchMap( ({ids, count}) => {
                return this.getProductsByIds(ids).pipe(
                    tap( products => {
                        if (!isEmptyArray(products)) {
                            this.currencyService.setCurrentCurrency(products[0].currency);
                        }
                        this.onDemandRecentPurchasesProducts = products;
                        this.onDemandRecentPurchasesProductsTotalCount = count;
                        this.fetchingRecentPurchasedProducts = false;
                    })
                )
            })
        );
    }

    @computed public get onDemandHeroSlides() {
        return convertCatalogsToHeroSlides(this.onDemandCatalogs);
    }

    @computed public get buyingWindowHeroSlides() {
        return convertCatalogsToHeroSlides(this.buyingWindowCatalogs);
    };


    private sortCatalogsByLabel(catalogs: ProductCatalog[]): ProductCatalog[] {
        return catalogs.sort( (c1, c2) => c1.label.localeCompare(c2.label));
    }

    @action private updateFavoriteProductsInCache(product: Product) {
        const windowId = product.window_id || 0;
        const isFavorite = product.isFavorite;

        const products =  this.favoriteProducts[windowId]?.products || [];
        const count = this.favoriteProducts[windowId]?.count || 0;
        const favorites = {...this.favoriteProducts};

        if (!isFavorite) {
            // remove from favorites cache

            favorites[windowId] = {products: products.filter( p => p.id !== product.id), count: count - 1};
        } else {
            // add to to favorites cache
            if (!products.some( p => p.id === product.id)) {
                favorites[windowId] = {products: [...products, product], count: count + 1};
            }
        }
        this.favoriteProducts = favorites;
    }

    @action private setCatalogs(windowId, catalogs: ProductCatalog[] = []) {
        windowId ? this.buyingWindowCatalogs = [...catalogs] : this.onDemandCatalogs = [...catalogs];
    }

    @action private setHomepageCatalogs(windowId, catalogs: ProductCatalog[] = []) {
        windowId ? this.buyingWindowHomepageCatalogs = [...catalogs] : this.onDemandHomepageCatalogs = [...catalogs];
    }

    @action public fetchOnDemandProductCategories(): Observable<ProductCategory[]> {
        if (!isEmptyArray(this.onDemandProductCategories)) {
            return of(this.onDemandProductCategories);
        }

        this.fetchingOnDemandProductCategories = true;
        return this.api.fetchProductCategories().pipe(
            tap( result => {
                this.onDemandProductCategories = result || [];
                this.fetchingOnDemandProductCategories = false;
            })
        );
    }

    @action public fetchOnDemandProductBrands(): Observable<Brand[]> {
        if (!isEmptyArray(this.onDemandProductBrands)) {
            return of(this.onDemandProductBrands);
        }

        this.fetchingOnDemandProductBrands = true;

        return this.api.fetchProductBrands().pipe(
            tap( result => {
                this.onDemandProductBrands = result || [];
                this.fetchingOnDemandProductBrands = false;
            })
        );
    }


    @computed get onDemandFavorites(): Product[] {
        return this.favoriteProducts[0]?.products || [];
    }

    public populatedUpdatedProduct(updatedProduct: Product) {
        if (!updatedProduct) {
            return;
        }
        this.products[updatedProduct.id] = updatedProduct; // update cached product
        this.updatedProduct.next(updatedProduct);

        this.briefProducts[updatedProduct.id] = updatedProduct;
        this.updatedBriefProduct.next(updatedProduct);
    }

}

