import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

const RANGE = 0.15;

@Component({
  selector: 'app-product-recommened-quantity',
  templateUrl: './product-recommended-quantity.component.html',
  styleUrls: ['./product-recommended-quantity.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ProductRecommendedQuantityComponent implements OnInit {

  @Input() recommendedQty = 0;

  @Input() currentQty = 0;

  @Input() view: 'detailed' | 'grid' = 'detailed';

  constructor() { }

  ngOnInit(): void {
  }


  get valueClass(): string {
    if (!this.recommendedQty) {
      return '';
    }

    const delta = ( this.recommendedQty - this.currentQty) / this.recommendedQty;

    if (Math.abs(delta) <= RANGE) {
      return 'in-range';
    }

    if (delta >= 0 ) {
      return 'under';
    }

    return 'over';
  }

  get isDetailedView(): boolean {
    return this.view === 'detailed'
  }


}
