export const BRANDS = 'brands';
export const PRODUCTS = 'products';
export const CUSTOM_PRODUCTS = 'autoship';
export const PROGRAMS = 'programs';
export const PRODUCT_TYPES = 'product_types';


export class SearchItem {
    image: string;
    label: string;
    uri: string;
}
export interface SearchList {
    count: number;
    items: SearchItem[]
}

export interface SearchResult {
    [BRANDS]: SearchList;
    [PRODUCTS]: SearchList;
    [PROGRAMS]: SearchList;
    [PRODUCT_TYPES]: SearchList;
    [CUSTOM_PRODUCTS]: SearchList
}
