

<header class="header">
    <div [ngClass]="{'top-sticky-header': false}">
        <nav class="nav" >
            <div class="container">
                <img *ngIf='logoUrl' class="logo__svg" [src]="logoUrl">
            </div>
        </nav>
    </div>
</header>


<div class="wrapper-inner">


    <div class="container not-supported">
        <h1 class="title mb-5">
          Update Your Browser
        </h1>
        <p class="description">You're using Internet Explorer, which is not a supported browser.</p>
        <p class="description">To use this website, you will need to use a different web browser, such as Chrome.</p>
        <p class="description mt-4">Get the Latest Version of Your Preferred Web Browser by Clicking the Links Below.</p>
        <div class="links-container mt-4">
            <a class="browser-link first" href="https://www.google.com/chrome" target="_blank">
                <img src="/assets/img/browsers/chrome.png" alt="Google Chrome">
                <div>Google<br/><span class="font-weight-bold">Chrome</span></div> <span class="recommended">Recommended</span>
            </a>
            <div class="d-flex">
                <a class="browser-link" href="https://www.microsoft.com/windows/microsoft-edge" target="_blank">
                    <img src="/assets/img/browsers/edge.jpg" alt="Google Chrome">
                    Microsoft <span class="font-weight-bold">Edge</span>
                </a>
                <a class="browser-link" href="https://www.apple.com/safari/" target="_blank">
                    <img src="/assets/img/browsers/safari.png" alt="Apple Safari">
                    Apple <span class="font-weight-bold">Safari</span>
                </a>
                <a class="browser-link" href="https://www.mozilla.org/firefox/" target="_blank">
                    <img src="/assets/img/browsers/firefox.svg" alt="Mozilla Firefox">
                    Mozilla <span class="font-weight-bold">Firefox</span>
                </a>
            </div>
        </div>
    </div>

</div>
<app-footer [hideLinks]="true"></app-footer>
