import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ClientCustomizationService {
    constructor() {}

    private setFavicon(url: string, size: string = null): void {
        if (!url) {
            return;
        }
        let link: HTMLLinkElement | null = document.querySelector('link[rel*="icon"]' + (size ? `[sizes='${size}']` : ''));

        if (link) {
            link.href = url;
        } else {
            link = document.createElement('link');
            link.rel = 'icon';
            link.href = url;

            if (size) {
                link.setAttribute('sizes', size);
            }
            document.head.appendChild(link);
          }
    }

    public setDefaultFaviconForCustomer(customer: string): void {
        const url = 'assets/img/icons' + (customer ? `/${customer.toLowerCase()}/favicon.ico` : '/demo/favicon.ico')
        this.setFavicon(url);
    }

    public setTitle(customer: string): void {
        if (customer) {
            document.title = customer;
        }

    }
}
