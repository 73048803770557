<ng-container *ngIf="_order">
    <div class="d-flex payment--selector" style="align-self: center" *ngIf="canUseCreditCards; else noPaymentSelector">
        <div class="mr-4">
            <input class="radio-dark-gray mr-2"
                   name="{{ radioButtonName }}" type="radio"
                   id="{{ accountButtonId }}"
                   [(ngModel)]="paymentType"
                   (change)="paymentTypeChanged()"
                   value="account"><label for="{{ accountButtonId }}">Pay On Account</label>
        </div>
        <div>
            <input class="radio-dark-gray mr-2"
                   name="{{ radioButtonName }}" type="radio"
                   id="{{ creditCardButtonId  }}"
                   value="credit-card"
                   [(ngModel)]="paymentType"
                   (change)="paymentTypeChanged()">
            <label for="{{ creditCardButtonId }}">Pay With Credit Card</label>
        </div>

    </div>
    <ng-template #noPaymentSelector>
        <div class="d-flex">
            <label>Payment Method:</label>
        </div>
    </ng-template>

    <div>
        <div class="sap--container" *ngIf="paymentType ==='account'; else creditCardTemplate" >
            <ng-container *ngIf="canShowPaymentMethods">
                <div class="sap__selector d-flex"  [ngClass]="{'has-danger': isSapInvalid}"
                     [class.mobile]="mobileDevice"
                     [class.align-items-center]="!mobileDevice"

                >
                    <ng-select
                            [disabled]="performingUpdate"
                            id="sapSearch"
                            class="custom-select__sap"
                            [items]="sapSearchResults"
                            bindValue="id"
                            placeholder="Search saved Payment Methods"
                            dropdownPosition="bottom"
                            [searchable]="true"
                            [loading]="sapSearchLoading"
                            [typeahead]="sapSearchSubject"
                            (change)="onSelectPaymentMethod($event)"
                            [(ngModel)]="selectedCostCenterId"
                            [clearable]="false">
                        <ng-template ng-label-tmp let-item="item">
                            {{ item.business_unit }}
                        </ng-template>
                        <ng-template ng-header-tmp>
                            Saved Billing Codes
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                            <span>{{ item.business_unit }}</span>
                        </ng-template>
                    </ng-select>
                    <ng-container *ngIf="canAddSAP && !performingUpdate">
                        <a class="link link-underline font-5 ml-2" (click)="addSap()" title="Add Payment Method" href="javascript:void(0)">Add Payment Method <i class="fas fa-plus"></i></a>
                    </ng-container>
                    <ng-container *ngIf="performingUpdate">
                        <div class="ml-2 font-weight-bold verify--container">
                            <i class="fal fa-sync rotate"></i>&nbsp;
                            <span *ngIf="verifyingSAP">Verifying...</span>
                            <span *ngIf="updatingPaymentMethod">Applying Billing Code...</span>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="showDOA" class="doa--container" [ngClass]="{'has-danger': isLimitExceeded}">
                    <label class="label">
                        <span class="font-weight-bold">DOA Information</span>&nbsp;<span class="font-6">(Required)</span>
                    </label>
                    <ng-select
                            [disabled]="performingUpdate"
                            id="employeeSearch"
                            class="custom-select__sap"
                            [items]="employeeSearchResults"
                            bindValue="employee_id"
                            [searchable]="true"
                            placeholder="Please choose DOA"
                            dropdownPosition="bottom"
                            [typeahead]="employeeSearchSubject"
                            (change)="onSelectEmployee($event)"
                            [(ngModel)]="selectedEmployeeId"
                            [clearable]="false">
                        <ng-template ng-label-tmp let-item="item">
                            <span>#{{ item.employee_id }} - {{ item.fullName }}</span>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                            <span>#{{ item.employee_id }} - {{ item.fullName }}</span>
                        </ng-template>
                    </ng-select>
                </div>
            </ng-container>
        </div>
        <ng-template #creditCardTemplate>
            <div>
                <div class="sap__selector" [class.mobile]="mobileDevice">

                    <div>
                        <ng-select
                                [disabled]="performingUpdate"
                                class="custom-select__sap"
                                [items]="ccSearchResults"
                                bindValue="id"
                                placeholder="Search saved Credit Cards"
                                dropdownPosition="bottom"
                                [searchable]="true"
                                [loading]="ccSearchLoading"
                                [typeahead]="ccSearchSubject"
                                (change)="onSelectPaymentMethod($event)"
                                [(ngModel)]="selectedCreditCardId"
                                [clearable]="false">
                            <ng-template ng-label-tmp let-item="item">
                                <span>{{ item.label }}</span>
                            </ng-template>
                            <ng-template ng-header-tmp>
                                Saved Credit Cards Information
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <div>{{ item.label }}</div>
                                <div>XXXX-XXXX-XXXX-{{item.last_four}}  </div>
                            </ng-template>

                        </ng-select>

                        <div class="mt-1" *ngIf="isSaveCheckboxVisible">
                            <input type="checkbox" id="saveCard" [(ngModel)]="saveCard" (change)="onSaveCreditCard()"> <label for="saveCard" class="ml-2">Save this card for future usage.</label>
                        </div>

                    </div>

                    <a *ngIf="canAddCreditCard" class="link link-underline font-5 ml-2"  [class.align-self-center]="!mobileDevice"  (click)="addCreditCard()" href="javascript:void(0)">Add Credit Card <i class="fas fa-plus"></i></a>

                </div>

                <p *ngIf="showCreditCardInfo" class="m-0"><span class="font-weight-bold">Your Card Will Not Be Charged Until Your Item Ships.</span><br>
                    <span class="font-5">This May Result In Multiple Credit Card Transactions For A Single Order.</span></p>
            </div>


        </ng-template>

    </div>

</ng-container>

