import { Component, Input, OnInit } from '@angular/core';
import { Customization } from '../../../interfaces/customization';
import { OrderItem } from '../../../models/order-item.model';

@Component({
    selector: 'customization-info',
    templateUrl: './customization-info.component.html',
    styleUrls: ['./customization-info.component.scss']
})
export class CustomizationInfoComponent implements OnInit {

    @Input() customizations: Customization[];
    @Input() orderItem: OrderItem;

    ngOnInit(): void {

        if (!this.customizations && this.orderItem) {
            this.customizations = this.getCustomizationByOrderItems(this.orderItem);
        }
    }


    private getCustomizationByOrderItems(orderItem: OrderItem): Customization[] {
        let result = [];
        orderItem.deliveries.forEach( delivery => {
            if (delivery.customizations) {
                result = [...result, ... delivery.customizations]
            }
        });

        return result;
    }


    get hasCustomization(): boolean {
        return this.customizations && this.customizations.length > 0;
    }

    viewCustomization() {
        return false;
    }
}
