import { NavItem } from './models/nav-item.model';

export class Settings {
    constructor(public name: string,
                public title: string,
                public apiBaseURL: string,
                public reportURL: string,
                public theme: {
                    menu: string,
                    menuType: string,
                    showMenu: boolean,
                    navbarIsFixed: boolean,
                    footerIsFixed: boolean,
                    sidebarIsFixed: boolean,
                    showSideChat: boolean,
                    sideChatIsHoverable: boolean,
                    canLockScreen: boolean,
                    skin: string,
                },
                public navigation: NavItem[],
                public userFeatures: {},
                public gaCode: string
    ) {
    }
}
