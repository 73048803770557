import {BaseModel} from './base.model';
import {AppDate} from './date.model';
import {isEmptyArray} from '../shared/utils';
import {OrderItem} from './order-item.model';
import {Product} from './product.model';

const GLOBAL = 'GLOBAL';

export class Budget extends BaseModel {
    public id: number;
    public entity_id: number;
    public amount: number;
    public spent: number;
    public balance: number;
    public label: string;
    public code: string;
    public start_at: AppDate;
    public end_at: AppDate;

    public apply_shipping: boolean;
    public apply_tax: boolean;
    public brands: any;
    public brandCodes: string[];
    public brandNames: string[];
    public methods: string[];
    public description;

    public trimester: boolean;
    public hard_limit: boolean

    constructor(data: any = {}) {
        super(data, {
            num: ['id', 'entity_id', 'amount', 'spent', 'balance'],
            boolean: ['apply_shipping', 'apply_tax', 'trimester', 'hard_limit'],
            date: ['start_at', 'end_at']
        });

        if (!this.brands) {
            this.brands = {};
        }

        if (!this.methods) {
            this.methods = [];
        }

        this.parseBrands();
    }

    public get formattedBrandNames(): string {
        if (isEmptyArray(this.brandNames)) {
            return '';
        }
        return this.brandNames.join(', ');
    }

    private parseBrands() {

        this.brandCodes = [];
        this.brandNames = [];
        for (const [key, value] of Object.entries(this.brands)) {
            if (key && value && typeof value === 'string') {
                this.brandCodes.push(key);
                this.brandNames.push(value);
            }
        }
    }


    public isMatchedWithOrderItem(orderItem: OrderItem): boolean {
        if (!orderItem || !orderItem.product) {
            return false;
        }

        const orderType = orderItem.isCustomOrder ? 'AS' : (orderItem.isBuyingWindowOrder ? 'OW' : 'OD');
        if (!this.methods.includes(orderType)) {
            return false;
        }

        if (this.isGlobal  && this.trimester === true) {
            return true;
        }

        const productBudgetCodes = orderItem.product.budgetCodes;
        if (isEmptyArray(productBudgetCodes)) {
            return false;
        }

        return  this.isGlobal || this.brandCodes.filter( budgetBrand => productBudgetCodes.includes(budgetBrand)).length > 0;
    }


    public get isActive(): boolean {
        return !this.end_at.isInPast && !this.start_at.isInFuture;
    }

    public get isGlobal(): boolean {
        return this.brandCodes.includes(GLOBAL);
    }

    public get isExhausted(): boolean {
        return this.hard_limit && this.balance <= 0;
    }

    public isMatchedWithProduct(product: Product): boolean {
        if (!product) {
            return false;
        }

        const productType = product.window_id > 0 ? 'OW' : 'OD';
        return this.methods.includes(productType);
    }
}
