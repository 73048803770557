import * as moment from 'moment';

export interface IInputDate {
    date: string;
    timezone: string;
    timezone_type: number;
}


function instanceOfIInputDate(object: any): object is IInputDate {
    return object.date !== undefined;
}

export class AppDate {

    // Date Formats
    static readonly DEFAULT_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
    static readonly UTC_STRING_FORMAT = 'ddd; DD MMM YYYY hh:mm:ss Z';
    static readonly SHORT_TIME_FORMAT = 'M/D/YYYY';
    static readonly APP_TIME_FORMAT = 'M/D/YYYY h:mm a';
    static readonly SERVER_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSSSSS';
    static readonly HOURS_FORMAT = 'h:mm a';
    static readonly WEEK_DAY_FORMAT = 'dddd';
    static readonly TIMESTAMP = 'x';
    static readonly LONG_DATE_FORMAT = 'MMMM D, YYYY';

    mDate: any;

    constructor(data: IInputDate | AppDate | string | number | Date) {
        // in some cases data is already parsed to AppData
        if ( typeof data === 'string') {
            this.mDate = moment(data + ' Z');
        } else if ( typeof  data  === 'number' || data instanceof  Date) {
            this.mDate = moment(data);
        }  else if (data instanceof AppDate) {
            this.mDate = data.date;
        } else if ( instanceOfIInputDate(data)) {
            this.mDate = moment(data.date + ' Z');
        }
    }

    public format(aFormat: string = AppDate.DEFAULT_TIME_FORMAT) {

        const userOffset: string = window.localStorage.getItem('u-utc-offset');
        const offset: number = (userOffset == null) ? moment().utcOffset() : Number(userOffset);

        if (AppDate.hasOwnProperty(aFormat)) {
            aFormat = AppDate[aFormat];
        }

        return this.mDate.utcOffset(offset).format(aFormat);
    }

    public get longDateFormatted(): string {
        return this.format(AppDate.LONG_DATE_FORMAT);
    }


    // returns moment
    public get date() {
        return this.mDate; // mData is a private
    }

    public isBefore(dt: any): boolean {
        if (dt instanceof AppDate) {
            return this.mDate.isBefore(dt.mDate);
        }
        return this.mDate.isBefore(dt);
    }

    public isAfter(dt: any): boolean {
        if (dt instanceof AppDate) {
            return this.mDate.isAfter(dt.mDate);
        }
        return this.mDate.isAfter(dt);
    }

    public get isInPast(): boolean {
        return this.isBefore(moment());
    }

    public get isInFuture(): boolean {
        return this.isAfter(moment());
    }
}
