<div class="search-form">
  <input type="text" placeholder="I am searching for" autocomplete="off" #searchInput
         [(ngModel)]="searchText">
  <button *ngIf="searchText" class="btn-close" (click)="clearSearch()"><i class="fal fa-times"></i></button>
  <button class="search-button" type="button" name="button"
          [ngClass]="{searching: isSearching}" (click)="search()">
    <i class="far fa-search"></i>
  </button>
</div>

<div class="searching" *ngIf="isSearching">
  Searching<span class="searching__dot">.</span><span class="searching__dot">.</span><span class="searching__dot">.</span>
</div>

<div *ngIf="searchPerformed;" class="search__autocomplete gold-border">

  <ng-container *ngIf="foundProductsCount > 0; else nothingFound">

    <ul class="search__result-list">
      <li *ngFor="let product of foundProducts">
        <a (click)="selectSearchOption(product)">
          <span [innerHTML]="showHighlights(product.label)"></span>
        </a>
      </li>
    </ul>


    <div *ngIf="isMoreDataAvailable" class="search__result-list__view-all">
      <a title="View All Results" (click)="selectViewAll()"><span>View All Results</span></a>
    </div>

    <div class="search__result-list__view-all mt-3">
      <a title="Close" (click)="clearSearch()"><span>Clear Search Results</span></a>
    </div>

  </ng-container>
  <ng-template #nothingFound>
    <div >
      <h4>No Results</h4>
    </div>
  </ng-template>

</div>
