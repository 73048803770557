<ng-container *ngIf='isDesktop'>
    <ul class="nav__utility__dropdown__links" *ngIf="hasUserGuides">
      <ng-container *ngIf="hasMultipleUSerGuides; else userGuideDesktop">
        <li class="nav__utility__subdropdown__trigger" (mouseenter)="dropdownMouseEnter($event)" (mouseleave)="dropdownMouseLeave($event)">
          <a class="link extra-small font-weight-normal">User Guides</a>
          <div class="nav__utility__subdropdown">
            <ul class="nav__utility__dropdown__links">
              <li *ngFor="let item of userGuides">
                <a [href]="item.url" target="_blank" download class='link link-underline extra-small font-weight-normal'>{{ item.label }}</a>
              </li>
            </ul>
          </div>
        </li>
      </ng-container>
    </ul>
</ng-container>

<!--mobile version-->
<ng-container *ngIf='isMobile'>
  <ng-container *ngIf='hasUserGuides'>
      <div class='user-guides--container' *ngIf="hasMultipleUSerGuides; else userGuideMobile">
          <div class="menu__header" (click)="userGuideMenuExpanded = !userGuideMenuExpanded">
              User Guides
              <i class="fas fa-angle-up" [ngClass]="{'fa-angle-up' : userGuideMenuExpanded, 'fa-angle-down': !userGuideMenuExpanded}">
              </i>
          </div>
          <div class="menu__item ml-2" [ngClass]="{'visible' : userGuideMenuExpanded}" *ngFor="let item of userGuides">
              <a [href]="item.url" target="_blank" class='link link-underline' download>{{ item.label }}</a>
          </div>
      </div>
  </ng-container>
</ng-container>


<ng-template #userGuideMobile>
    <a class="link link-underline font-weight-normal" [href]="userGuides[0].url" target="_blank" download>{{userGuides[0].label}}</a>
</ng-template>

<ng-template #userGuideDesktop>
  <li>
    <a class="link link-underline extra-small font-weight-normal" [href]="userGuides[0].url" target="_blank" download>{{userGuides[0].label}}</a>
  </li>
</ng-template>
