import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {action, computed, observable} from 'mobx';
import { ToastrService } from 'ngx-toastr';

import { AppSettings } from '../app.settings';
import { Response } from '../models/response.model';
import { Notification } from '../models/notification.model';
import {isEmptyArray} from '../shared/utils';
import {API_URL} from '../constants/api-urls';

const DEFAULT_PAGE_SIZE = 10;

@Injectable()
export class NotificationService extends BaseService {

    @observable totalNotifications = 0;

    @observable notifications: Notification[] = [];

    @observable fetching = false;

    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService
    ) {
        super('/account/notifications', http, appSettings, toastr);
    }

    get(fechtingMore = false): Observable<Notification[]> {

        if (!fechtingMore && !isEmptyArray(this.notifications)) {
           return of(this.notifications);
        }

        if (fechtingMore && !this.hasMoreNotifications) {
            // no notifications more are available
            return of([]);
        }

        this.fetching = true;
        const paging = `${DEFAULT_PAGE_SIZE}-${fechtingMore ? this.notifications.length : 0}`;
        const headers: HttpHeaders = new HttpHeaders({'Range': paging});
        const url = `${API_URL}/account/notifications`;
        return this.http.get<HttpResponse<Response>>(url, {observe: 'response', headers: headers})
            .pipe(
                map(httpResponse => {
                    this.totalNotifications = parseInt(httpResponse.headers.get('X-Count'), 10) || 0;
                    const response = new Response(httpResponse.body);
                    if (!isEmptyArray(response.data)) {
                        return response.data.map( d => new Notification(d));
                    }
                    return [];
                }),
                catchError(this.handleError('NotificationService::get', [])),
                tap( notifications => {
                    if (fechtingMore) {
                        if (!isEmptyArray(notifications)) {
                            this.setNotifications([...this.notifications, ...notifications]);
                        }
                    } else {
                        this.setNotifications(notifications);
                    }

                }),
                finalize( () => this.fetching = false)
            );
    }

    unreadCount(): Observable<number> {
        const url = `${API_URL}/account/notifications/unread-count`;
        return this.http.get<Response>(url)
            .pipe(
                map(response => {
                    if (response.data) {
                        return response.data.count;
                    }
                    return 0;
                }),
                catchError(this.handleError('NotificationService::unreadCount', 0))
            );
    }


    @action setNotifications(val: Notification[] = []) {
        this.notifications = val;
    }

    @computed get hasMoreNotifications(): boolean {
        return this.totalNotifications > this.notifications.length;
    }

    @computed get sortedNotifications(): any {
        if (isEmptyArray(this.notifications)) {
            return {};
        }

        const mapNotificationsByDate = (obj: any, notification: Notification) => {
            const formattedDate = notification.created_at.longDateFormatted;
            const notificationsList = obj[formattedDate];
            if (notificationsList) {
                obj[formattedDate] = [...notificationsList, notification];
            } else {
                obj[formattedDate] = [notification];
            }
        }

        const result = {};
        this.notifications.forEach( n => {
            mapNotificationsByDate(result, n);
        });

        return result;

    }
}
