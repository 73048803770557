import {Component, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-news-modal',
    templateUrl: './news-modal.component.html',
    styleUrls: ['./news-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NewsModalComponent {

    public content: string;
    public showCloseButton = true;

    constructor(private activeModal: NgbActiveModal) {}

    close() {
        this.activeModal.close(null);
    }
}

