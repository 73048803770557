import {BaseModel} from './base.model';
import {ProductInfo} from './product-info.model';
import {IShipmentItem} from './shipment-item.model';

export class NotificationData  extends BaseModel {

    product_id: number;
    order_id: number;
    as_request_id: number;
    product: ProductInfo;
    orderIdList: number[];
    new_price: number;
    old_price: number
    moq_string: string;

    shipments: IShipmentItem[];

    tracking_state: string;
    label: string;
    url: string;

    constructor(data) {
        super(data, {
            num: ['product_id', 'new_price', 'old_price', 'order_id', 'as_request_id'],
            bool: ['is_unread'],
            date: ['created_at', 'read_at']
        });

        if (data.product) {
            this.product = new ProductInfo(data.product);
        }
    }
}
