import { BaseModel } from './base.model';
import { AppDate }   from './date.model';

export class DamImage extends BaseModel {
    public id: number;
    public gallery_id: number;
    public label: string;
    public thumb_url: string;
    public print_url: string;
    public created_at: AppDate;

    constructor(data) {
        super(data, {
            num: ['id','gallery_id'],
            date: ['created_at']
        });
    }
}
