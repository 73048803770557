// it should keep pages settings like filters, sorts etc

import { Injectable } from '@angular/core';
import {ShowItem, SortService} from './sort.service';
import {isEmptyArray} from '../shared/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterGroup } from '../models/filter-group.model';
import { ProductService } from './product.service';
import {tap} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { Product } from '../models/product.model';
import { ProductCatalog } from '../models/product-catalog.model';
import {ECOMMERCE_TYPE, PREORDER_TYPE} from '../constants/order-types';
import {LocalStorageService} from './local-storage.service';
import {AuthService} from './auth.service';
import {Order} from '../models/order.model';
import {OrderItemService} from './order-item.service';
import {SortItem} from '../models/sort-item.model';
import {cloneDeep} from 'lodash';

export const DEFAULT_PRODUCT_LIMIT = 20;
export const DEFAULT_PROGRAMS_LIMIT = 18;

const SELECTED_CART_TAB = 'SELECTED_CART_TAB';
const SELECTED_ORDER_HISTORY_TAB = 'SELECTED_ORDER_HISTORY_TAB';
const SELECTED_FAVORITES_TAB = 'SELECTED_FAVORITES_TAB';
const SELECTED_QUICK_ORDER_TAB = 'SELECTED_QUICK_ORDER_TAB';

enum OrderType {
    OnDemand,
    BuyingWindow,
    WslrOrder,
    DecisionPointOrder
}



interface  Filters {
    data: any
}

interface  Sorts {
    data: any
}

interface  Show {
    data: any
}

interface PageData {
    isRecommended: boolean;
    orderWindowId: number;
    wslrEntityId: number;
    orderType?: string;
    sorts?: SortItem[];
    filters?: Filters,
    products: any[],
    programs: ProductCatalog[],
    programsTotalCount: number;
    // fetchedPrograms: number;
    // fetchedProducts: number;
    productsTotalCount: number;
    showItems?: ShowItem[],
}

@Injectable()
export class PageService {

    // public orderWindowId = 0; // current order window id
    //
    // public pages = {};
    //
    public preselectedFilter: {path: string, value: string, type: string} =  null;

//    public wslrEntityId = null;

    private _selectedOrderHistoryTab = '';
    private _selectedCartTab = '';
    private _selectedFavoriteTab = '';
    private _selectedQuickOrderTab = '';

    private pagesData = {};

    private initialPageState: PageData = {
        isRecommended: false,
        products: [],
        programs: [],
        programsTotalCount: 0,
        // fetchedProducts: 0,
        // fetchedPrograms: 0,
        productsTotalCount: 0,
        wslrEntityId: null,
        orderWindowId: null,
    };

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private authService: AuthService,
                private sortService: SortService,
                private productService: ProductService,
                private orderItemService: OrderItemService,
                private localStorage: LocalStorageService) {
    }


    public getPageData(url: string, order: Order = null) {

        let pagePath = url;
        if (order) {
            pagePath = `${pagePath}/type=${order.type}`;
        }

        let pageData = this.pagesData[pagePath];
        if (!pageData) {
            // get default filters  sort, and shows
            this.pagesData[pagePath] = {...this.initialPageState};
            pageData = {...this.initialPageState};
        } else {
            const isRecommended = this.orderItemService.canShowRecommendsProducts(order);
            if (pageData.isRecommended  !== isRecommended) {
                // recommended changed - reset page data
                this.pagesData[pagePath] = {...this.initialPageState, isRecommended: isRecommended};
                pageData = this.pagesData[pagePath];
            }
        }
        const result  =  pageData || {...this.initialPageState};
        return {...result};
    }

    private updatePageData(url: string, order: Order, val: PageData) {
        let pagePath = url;
        if (order) {
            pagePath = `${pagePath}/type=${order.type}`;
        }

        this.pagesData[pagePath] = val;
    }

    public getSort(url: string, order: Order, moduleType = '', intentType = '') {
        const pageData: PageData = this.getPageData(url, order);
        if (!pageData.sorts) {
            const isRecommended = this.orderItemService.canShowRecommendsProducts(order);
            const sortItems = this.sortService.get(moduleType, intentType, isRecommended, order?.isByingWindow === true);
            pageData.sorts = sortItems;
            this.updatePageData(url, order,  pageData);
        }

        return pageData.sorts;
    }

    public getShowItems(url: string, order: Order) {
        const pageData: PageData = this.getPageData(url, order);
        if (!pageData.showItems) {
            pageData.showItems = this.sortService.getShowItems();
            this.updatePageData(url, order, pageData);
        }
        return pageData.showItems;
    }

    public setShowItems(url: string, order: Order, showItems: ShowItem[]) {
        const pageData = this.getPageData(url, order);
        pageData.showItems = cloneDeep(showItems);
        this.updatePageData(url, order, pageData);
    }

    public setSorts(url: string, order: Order, sorts: SortItem[]) {
        const pageData = this.getPageData(url, order);
        pageData.sorts = _.cloneDeep(sorts);
        this.updatePageData(url, order, pageData);
    }

    public setOnDemandSorts(sorts) {
        const pageData = this.getPageData('/on-demand/products');
        pageData.sorts = _.cloneDeep(sorts);
        this.updatePageData('/on-demand/products', null, pageData);
    }


    public getFilters(url: string, order: Order, filterType = '',
                      programId: number = null,
                      filters: FilterGroup[] = []): Observable<FilterGroup[]> {

        const pageData = this.getPageData(url, order);

        if (isEmptyArray(filters) && pageData.filters && !this.preselectedFilter) {
            return of(pageData.filters);
        }

        return this.productService.getFilters(programId ? programId : pageData.orderWindowId, filterType, filters).pipe(
            tap(retFilters => {
                // set expanded for selected filters
                // selected filters should not expand anymore
                // if (!isEmptyArray(retFilters)) {
                //    retFilters.forEach( filter => {
                //        filter.expanded = filter.getSelectedItemIDs().length > 0
                //    });
                // }
                this.setFilters(url, order, retFilters);
            }),
        );

    }


    public getFavoriteFilters(url: string, order: Order,
                              orderWindowId,
                              filters: FilterGroup[] = []): Observable<FilterGroup[]> {
        // do not cache filters for favorites
        // const pageData = this.getPageData(url, order);
        // if (isEmptyArray(filters)  && pageData.orderWindowId === orderWindowId && pageData.filters?.length > 0) {
        //     return of(pageData.filters);
        // }

        return this.productService.getFavoriteFilters(orderWindowId, filters).pipe(
            tap(retFilters => {
                // set expanded for selected filters
                // selected filters should not expand anymore
                // if (!isEmptyArray(retFilters)) {
                //     retFilters.forEach( filter => {
                //         filter.expanded = filter.getSelectedItemIDs().length > 0
                //     });
                // }
                this.setFilters(url, order, retFilters);
            }),
        );
    }

    public setFilters(url: string, order: Order, filters: FilterGroup[]) {
        const pageData = this.getPageData(url, order);
        pageData.filters = filters;
        this.updatePageData(url, order, pageData);
    }

    public getFetchedProducts(url: string, order: Order = null): number {
        return this.getPageData(url, order).products.length  || 0;
    }

    public getSelectedShowItem(url: string, order: Order = null): string {
        const showItems = this.getShowItems(url, order) || [];
        return showItems.find( s => s.selected)?.name || '';
    }

    public getProducts(url: string, order: Order): Product[]  {
        return this.getPageData(url, order).products || [];
    }

    public getOrderWindowId(url: string, order: Order = null) {
        return this.getPageData(url, order).orderWindowId;
    }

    public getWslrEntityId(url: string, order: Order = null) {
        return this.getPageData(url, order).wslrEntityId;
    }

    public setWslrEntityId(url: string, order: Order, wslrEntityId: number) {
        const pageData  = this.getPageData(url, order);
        pageData.wslrEntityId = wslrEntityId;
        this.updatePageData(url, order, pageData);
    }

    // public setProducts(url: string, order: Order, val: Product[]) {
    //     this.getPageData(url, order).products = val;
    // }
    public setFetchedProducts(url: string, order: Order, val: Product[], totalProducts: number = undefined) {
        const pageData: PageData = this.getPageData(url, order);
        pageData.products = val;
        if (typeof totalProducts !==  'undefined') {
            pageData.productsTotalCount = totalProducts;
        }
        this.updatePageData(url, order, pageData);
    }

    public geProductsTotalCount(url: string, order: Order): number {
        return this.getPageData(url, order).productsTotalCount || 0;
    }
    // public setProductsTotalCount(url: string, order: Order, val: number) {
    //     this.getPageData(url, order).productsTotalCount = val;
    // }


    public getPrograms(url: string, order: Order = null): ProductCatalog[] {
        return this.getPageData(url, order).programs || [];
    }

    public setPrograms(url: string, order: Order, val: ProductCatalog[], programTotalCount: number) {
        const pageData = this.getPageData(url, order);
        pageData.programs = val;
        pageData.fetchedPrograms = val.length;
        if (typeof programTotalCount !== 'undefined') {
            pageData.programsTotalCount = programTotalCount;
        }
        this.updatePageData(url, order, pageData);

    }

    public getFetchedPrograms(url: string, order: Order = null): number {
        return this.getPageData(url, order).products.length || 0;
    }
    public getProgramsTotalCount(url: string, order: Order = null): number {
        return this.getPageData(url, order).programsTotalCount || 0;
    }
    // public setProgramsTotalCount(url: string, order: Order, val: number) {
    //     this.getPageData(url, order).programsTotalCount = val;
    // }


    // public get currentPage(): any {
    //     const pageHash = this.pageHash;
    //     if (!this.pages[pageHash]) {
    //         this.pages[pageHash] = {};
    //     }
    //
    //     return this.pages[pageHash];
    // }


    public getSelectedCartTab() {
        if (!this._selectedCartTab) {
            this._selectedCartTab = this.getTab(SELECTED_CART_TAB);
        }
        return this._selectedCartTab;
    }

    public setSelectedCartTab(val) {
        this._selectedCartTab = val;
        return this.saveTab(SELECTED_CART_TAB, val);
    }

    public getSelectedOrderHistoryTab() {
        if (!this._selectedOrderHistoryTab) {
            this._selectedOrderHistoryTab = this.getTab(SELECTED_ORDER_HISTORY_TAB) || PREORDER_TYPE;
        }
        return this._selectedOrderHistoryTab;
    }

    public setSelectedOrderHistoryTab(tabName: string) {
        this._selectedOrderHistoryTab = tabName;
        if (tabName !== 'SEARCH') {
            // do not save SEARCH  tab
            this.saveTab(SELECTED_ORDER_HISTORY_TAB, tabName);
        }

    }

    public setOrderWindowId(url: string, order: Order, orderIWindowId: number) {
        const pageData = this.getPageData(url, order);
        pageData.orderWindowId = orderIWindowId
        this.updatePageData(url, order, pageData);
    }

    public getSelectedFavoritesTab() {
        if (!this._selectedFavoriteTab) {
            this._selectedFavoriteTab = this.getTab(SELECTED_FAVORITES_TAB) || ECOMMERCE_TYPE;
        }
        return this._selectedFavoriteTab;
    }

    public setSelectedFavoritesTab(tabName: string) {
        this._selectedFavoriteTab = tabName;
        this.saveTab(SELECTED_FAVORITES_TAB, tabName);
    }

    public getQuickOrderTab(): string {
        if (!this._selectedQuickOrderTab) {
            this._selectedQuickOrderTab = this.getTab(SELECTED_QUICK_ORDER_TAB) || ECOMMERCE_TYPE;
        }
        return this._selectedQuickOrderTab;
    }

    public setQuickOrderTab(tabName: string) {
        this._selectedQuickOrderTab = tabName;
        this.saveTab(SELECTED_QUICK_ORDER_TAB, tabName);
    }

    private getTab(tabCategory: string): string {
        return this.localStorage.getItem(tabCategory) || ECOMMERCE_TYPE;
    }

    private saveTab(tabCategory: string, tabName: string) {
        this.localStorage.setItem(tabCategory, tabName);
    }

}
