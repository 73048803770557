import { Injectable } from '@angular/core';
import { OrderItem } from '../models/order-item.model';
import { Order } from '../models/order.model';
import { Product } from '../models/product.model';
import {TranslateService} from './translate.service';
import {SKU} from '../models/sku.model';
import {flatternArray, isEmptyArray} from '../shared/utils';
import {OrderItemSku} from '../models/order-item-sku.model';
import {MultiTenantService} from './multi-tenant.service';

@Injectable()
export class GoogleAnalytics {

    private isUserIdSubmitted = false;

    constructor(private translateService: TranslateService,
                private miltiTenantService: MultiTenantService) {
    }

    public submitUserId(userId) {
        if (this.isUserIdSubmitted || !userId) {
            return;
        }
        this.sendGoogleAnalytics({
            event : 'login',
            userId :  '' + userId
        });
        this.isUserIdSubmitted = true;
    }

    public get gtmDefined(): boolean {
        return !!this.dataLayer;
    }

    // add_to_cart	value, currency, items
    public addToShoppingCart(order: Order, orderItem: OrderItem, quantity = 0, sku: OrderItemSku = null) {
        if (!this.gtmDefined) {
            return;
        }

        if (order && orderItem) {
            this.sendGoogleAnalytics({
                event: 'addToCart',
                ecommerce: {
                    currencyCode: 'USD',
                    add: {
                        products:  this.mapOrderItemToProductData(order, orderItem, quantity, sku)
                    }
                }
            })
        }
    }

    // add_to_cart	value, currency, items
    public updateShoppingCart(order: Order, orderItem: OrderItem) {
        if (!this.gtmDefined) {
            return;
        }

        if (order && orderItem) {

            if (orderItem.hasVariations) {
                orderItem.skus.forEach( orderSku => {
                    const quantityDiff = orderSku.quantity - orderSku.init_quantity;
                    if (quantityDiff < 0) {
                        this.removeFromShoppingCart(order, orderItem, quantityDiff * (-1), orderSku);
                    }
                    if (quantityDiff > 0) {
                        this.addToShoppingCart(order, orderItem, quantityDiff, orderSku);
                    }
                });
            } else {
                const quantityDiff = orderItem.quantity - orderItem.init_quantity;
                if (quantityDiff < 0) {
                    this.removeFromShoppingCart(order, orderItem, quantityDiff * (-1));
                }
                if (quantityDiff > 0) {
                    this.addToShoppingCart(order, orderItem, quantityDiff);
                }
            }
        }
    }

    // remove_from_cart	{value, currency, items}
    public removeFromShoppingCart(order: Order, orderItem: OrderItem, quantity = 0, sku: OrderItemSku = null) {
        if (!this.gtmDefined) {
            return;
        }

        if (order && orderItem) {
            this.sendGoogleAnalytics({
                event: 'removeFromCart',
                ecommerce: {
                    remove: {
                        products: this.mapOrderItemToProductData(order, orderItem, quantity, sku)
                    }
                }
            });
        }
    }


    // search	search_term
    public searchTerm(term: string) {
        if (!this.gtmDefined) {
            return;
        }

        if (term && term.trim().length > 0) {
            this.sendGoogleAnalytics({
                event: 'search',
                search_term: term
            });
        }
    }


    public viewSearchResults(term: string, products: Product[]) {
        if (!this.gtmDefined) {
            return;
        }

        if (term && term.trim().length > 0) {
            this.sendGoogleAnalytics({
                event: 'view_search_results',
                search_term: term,
                products: flatternArray(products.map( (p) => this.mapProductToProductData(p, true)))
            });
        }
    }

    // view single product (detailed view)
    // view_item	items
    public viewItem(product: Product) {
        if (!this.gtmDefined) {
            return;
        }

        if (product) {
            this.sendGoogleAnalytics({
                event: 'productClick',
                ecommerce: {
                    click: {
                        products: this.mapProductToProductData(product)
                    }
                }
            });

            this.sendGoogleAnalytics({
                event: 'productDetail',
                ecommerce: {
                    detail: {
                        products: this.mapProductToProductData(product)
                    }
                }
            });
        }
    }

    // view_item_list items
    public viewItems(products: Product[] ) {
        if (!isEmptyArray(products)) {
            let impressionArray;
            impressionArray = (products as Product[]).map(p => this.mapProductToProductData(p, true));
            this.sendGoogleAnalytics({
                event: 'productImpressions',
                ecommerce: {
                    currencyCode: 'US',
                    impressions: flatternArray(impressionArray)
                }
            });
        }
    }

    // old fashion way to send navigation path
    // in modern way should be screen_view	screen_name	a user loads a new screen or new content.
    public navigationTo(path: string, title = '') {
        if (!this.gtmDefined) {
            return;
        }

        if (path) {
            this.sendGoogleAnalytics(
                {
                    event: 'virtualPageView',
                    page: {
                        'url': path,
                        title: this.miltiTenantService.getMappedTitle(title)
                    }
            });
        }
    }



    // user submits checkout
    public checkout(order: Order) {
        if (!this.gtmDefined) {
            return;
        }

        if (order && order.items && order.items.length > 0) {
            const data = {
                event: 'checkout',
                ecommerce: {
                    checkout: {
                        actionField: {
                            id: order.id,
                            shipping: order.shippingTotal,
                            tax: order.taxes,
                            revenue: order.totalCost,
                            option: order.creditCard ? 'Credit Card' : 'Terms',
                            step: 1
                        },
                        products: flatternArray(order.items.map(oi => this.mapOrderItemToProductData(order, oi)))
                    }
                }
            };
            if (order.promoCodeExists) {
                data['ecommerce']['checkout']['actionField']['coupon'] = order.promo_code.code;
            }
            this.sendGoogleAnalytics(data);
        }
    }

    // on order-review page
    public purchase(order: Order) {
        if (!this.gtmDefined) {
            return;
        }

        if (order && order.items && order.items.length > 0) {
            const data = {
                event: 'purchase',
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: order.id,
                            shipping: order.shippingTotal,
                            tax: order.taxes,
                            revenue: order.totalCost,
                        },
                        products: flatternArray(order.items.map( oi => this.mapOrderItemToProductData(order, oi)))
                    }
                }
            };
            if (order.promoCodeExists) {
                data['ecommerce']['purchase']['actionField']['coupon'] = order.promo_code.code;
            }
            this.sendGoogleAnalytics(data);
        }
    }


    private     sendGoogleAnalytics( data: any) {
        if (this.gtmDefined) {
            this.dataLayer.push(data);
        }
    }

    private mapProductToProductData(product: Product, hideVariants = false) {

        const program = this.getProductProgram(product);

        if (product.hasVariations) {
            const skus =  hideVariants ? [product.skus[0]] : product.skus;
            return skus.map( sku => {
                const item = {
                    id: sku.getDisplaySkuID(),
                    name: product.label,
                    brand: product.getBrand(),
                    category:  this.fillCategory(product, sku),
                    price: '' + sku.getPrice(),
                    variant: this.getSkuLabel(sku)
                };

                if (program) {
                    item['list'] = program;
                }

                return item;
            });

        }  else {

            let price = 0.0;
            const currentSku = product.firstSku;
            price = product.getPrice(currentSku);

            const item = {
                id: product.firstSkuId,
                name: product.label,
                brand: product.getBrand(),
                category:  this.fillCategory(product),
                price: '' + price,
            };

            if (program) {
                item['list'] = program;
            }

            return [item];
        }

    }


    private  mapOrderItemToProductData(order: Order, orderItem: OrderItem, quantity = 0, sku: OrderItemSku = null) {
        // id	string	Yes*	Unique ID/SKU for the item.
        // name	string	Yes*	Item name.
        // brand	string	No	Brand of the item
        // category	string	No	Item category
        // variant	string	No	Item variant
        // price	number	No	Purchase price of the item
        // quantity	integer	No	Item quantity
        // coupon	string	No	Coupon code for a purchasable item
        // list_position	integer	No	The position of the item in the list

        const product = orderItem.product;
        const program = this.getProductProgram(product);

        if (orderItem.product.hasVariations) {
            const result = [];
            if (sku) {

                let skuQuantity = quantity;
                if (!skuQuantity) {
                    if (sku.quantity === sku.init_quantity) {
                        skuQuantity = sku.quantity;
                    } else {
                        skuQuantity = Math.abs( sku.quantity - sku.init_quantity);
                    }
                }

                const productSku =  product.getSKUbyID( sku.sku_id);
                if (productSku) {
                    const item = {
                        id: productSku.getDisplaySkuID(),
                        name: product.label,
                        brand: product.getBrand(),
                        category: this.fillCategory(orderItem, productSku),
                        price: '' + orderItem.getProductPrice(productSku),
                        quantity: '' + skuQuantity,
                        variant: this.getSkuLabel(productSku)
                    };

                    if (program) {
                        item['list'] = program;
                    }

                    result.push(item);
                }
            } else {



                const orderSkus = orderItem.skus;
                orderSkus.forEach( orderSku => {

                    let skuQuantity = orderSku.quantity;
                    if ( orderSku.quantity  !== orderSku.init_quantity) {
                        skuQuantity = Math.abs( orderSku.quantity - orderSku.init_quantity);
                    }

                    if (skuQuantity) {
                        const productSku =  product.getSKUbyID( orderSku.sku_id);
                        if (productSku) {
                            const  item = {
                                id: productSku.getDisplaySkuID(),
                                name: product.label,
                                brand: product.getBrand(),
                                category:  this.fillCategory(orderItem, productSku),
                                price: '' + orderItem.getProductPrice(productSku),
                                quantity: '' +  skuQuantity,
                                variant: this.getSkuLabel(productSku)
                            };

                            if (program) {
                                item['list'] =  program;
                            }

                            result.push( item );
                        }
                    }
                });
            }

            return result;
        } else {

            let orderItemQuantity = quantity;
            if (!orderItemQuantity) {
                if (orderItem.init_quantity === orderItem.quantity) {
                    orderItemQuantity = orderItem.quantity;
                } else {
                    orderItemQuantity = Math.abs( orderItem.quantity - orderItem.init_quantity);
                }
            }

            const item = {
                id: orderItem.productSkuId,
                name: product.label,
                brand: product.getBrand(),
                category:  this.fillCategory(orderItem),
                price: '' + orderItem.getProductPrice(),
                quantity: '' + orderItemQuantity
            }

            if (program) {
                item['list'] =  program;
            }

            return [item];
        }

    }


    private getSkuLabel(sku: SKU): string {
        const attributes: string[] = [];
        sku.attributeValues.forEach( (val, key) => {
            attributes.push(`${this.translateService.translate(key)}: ${val}`);
        });
        return attributes.join(', ');
    }

    private fillCategory ( item: OrderItem | Product, sku?: SKU): string {
        if (!item) {
            return '';
        }
        const result = [];
        let product: Product = null;

        if (item instanceof OrderItem) {
            product = item.product;
            result.push( this.getOrderCategory(item));
        }

        if (item instanceof Product) {
            product = item;
            let productSKU = null;
            if (sku) {
                productSKU = sku as SKU;
            }
            const productCategory  = this.getProductCategory(product);
            const productType = this.getProductType(product);
            const productSize = this.getProductSize(product, productSKU );
            const productPackageConfig = this.getProductPackageConfig(product, productSKU);
            if (productCategory) {
                result.push(productCategory);
            }
            if (productType) {
                result.push(productType);
            }

            if (productSize) {
                result.push(productSize);
            }
            if (productPackageConfig) {
                result.push(productPackageConfig);
            }
            result.push( this.getOrderCategory(item));
        }

        return result.join('/')
    }


    private getOrderCategory(item: OrderItem | Product): string {
        if (!item) {
            return '';
        }

        if (item instanceof  OrderItem) {
            if (item.isBuyingWindowOrder) {
                return this.miltiTenantService.orderWindowLabel;
            }

            if (item.isOnDemandOrder) {
                return this.miltiTenantService.onDemandLabel;
            }

            if (item.isCustomOrder) {
                return 'Auto Ship';
            }
        }

        if (item instanceof  Product) {
            return item.window_id ? this.miltiTenantService.orderWindowLabel : this.miltiTenantService.onDemandLabel;
        }

        return '';
    }

    private getProductProgram(product: Product): string {
        if (!product) {
            return ''
        }

        return product.getProgram();
    }

    private getProductCategory(product: Product): string {
        if (!product) {
            return '';
        }

        return product.productCategory;
    }

    private getProductType(product: Product): string {
        if (!product) {
            return '';
        }

        return product.productType;
    }

    private getProductSubType(product: Product): string {
        if (!product) {
            return '';
        }

        return product.productSubType;
    }


    private getProductSize(product: Product, sku?: SKU): string {
        if (!product) {
            return '';
        }

        return product.getProductSize(sku);
    }

    private getProductPackageConfig(product: Product, sku?: SKU): string {
        if (!product) {
            return '';
        }
        return product.getProductPackageConfig(sku);
    }

    private get dataLayer(): any[] {
        return window['dataLayer'];
    }

    get orderWindowLabel(): string {
        return this.miltiTenantService.orderWindowLabel;
    }

    get onDemandLabel(): string {
        return this.miltiTenantService.onDemandLabel;
    }

}
