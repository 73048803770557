import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import { AddressService } from './address.service';
import { AuthService } from './auth.service';
import { BudgetService } from './budget.service';
import { ConfigService } from './config.service';
import { ContentService } from './content.service';
import { EntityService } from './entity.service';
import { ImageService } from './image.service';
import { NotificationService } from './notification.service';
import { OrderService } from './order.service';
import { ProductService } from './product.service';
import { ProfileService } from './profile.service';
import { SearchService } from './search.service';
import { SortService } from './sort.service';
import { TranslateService } from './translate.service';
import { LocalStorageService } from './local-storage.service';
import { DoaService } from './doa.service';
import { PaymentService } from './payments.service';
import {ModalService} from './modal.service';
import { ApiOrderService } from './api-order.service';
import { ApiProductService } from './api-product.service';
import { PageService } from './page.service';
import { AutoShipService } from './auto-ship.service';
import { GoogleAnalytics } from './google-analytics.service';
import {OrderItemService} from './order-item.service';
import {AutoShipApiService} from './auto-ship-api.service';
import {MobileCheckServiceService} from './mobile-check-service.service';
import {HelperService} from './helper.service';
import {RepairRequestsService} from './repair-requests.service';
import {ApiRepairRequestsService} from './api-repair-requests.service';
import {ApiRecommendedProductsService} from './api-recommended-products.service';
import {RecommendedProductsService} from './recommended-products.service';
import {ApiPaymentMethodsService} from './api-payment-methods.service';
import {CurrencyService} from './currency.service';
import {MultiTenantService} from './multi-tenant.service';
import {RestrictedRouterGuardService} from './restricted-router.guard.service';
import {ClientCustomizationService} from './client-customization.service';

@NgModule()
export class ServiceModule {
    static forRoot(): ModuleWithProviders<ServiceModule> {
    return {
        ngModule: ServiceModule,
        providers: [
            AddressService,
            AuthService,
            BudgetService,
            ConfigService,
            ContentService,
            EntityService,
            ImageService,
            NotificationService,
            OrderService,
            ProductService,
            ProfileService,
            SearchService,
            SortService,
            TranslateService,
            LocalStorageService,
            DoaService,
            PaymentService,
            ModalService,
            ApiOrderService,
            ApiProductService,
            PageService,
            AutoShipService,
            GoogleAnalytics,
            OrderItemService,
            AutoShipApiService,
            MobileCheckServiceService,
            HelperService,
            RepairRequestsService,
            ApiRepairRequestsService,
            ApiRecommendedProductsService,
            RecommendedProductsService,
            ApiPaymentMethodsService,
            CurrencyService,
            RestrictedRouterGuardService,
            MultiTenantService,
            ClientCustomizationService
        ]
    };
    }
    constructor (@Optional() @SkipSelf() parentModule?: ServiceModule) {
        if (parentModule) {
            throw new Error(
                'ServiceModule is already loaded. Import it in the AppModule only');
        }
    }
}
