import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ProductOrdersInfo} from '../../../../../interfaces/product-orders-info';

@Component({
  selector: 'app-product-order-details-table',
  templateUrl: './product-order-details-table.component.html',
  styleUrls: ['./product-order-details-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductOrderDetailsTableComponent implements OnInit {

  @Input() orderDetails: ProductOrdersInfo;

  @Input() mobile = false;

  @Input() modal = false;

  @Output() exportToPdf = new EventEmitter<void>();

  @Output() exportToExcel = new EventEmitter<void>();

  @Output() showDetails = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  get hasWslr(): boolean {
    if (!this.orderDetails) {
      return false;
    }

    return this.orderDetails.info.filter( i => !!i.wslr_id).length > 0;
  }
}
