<div *ngIf="productId">
    <ng-container *ngIf="isFetching$ |async; else details">
        <div class="content-loader mt-5">
            <div class="inline-loader"></div>
        </div>
    </ng-container>
</div>
<ng-template #details>
    <ng-container *ngIf="ordersDetails$ | async as orderDetails">
        <app-product-order-details-table [orderDetails]="orderDetails" [mobile]="mobile" [modal]="modal"
             (exportToExcel)="exportToExcel()" (exportToPdf)="exportToPDF()"
             (showDetails)="showFullView()"></app-product-order-details-table>
    </ng-container>
</ng-template>


