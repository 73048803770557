import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {AppSettings} from '../app.settings';
import {Observable, of} from 'rxjs';
import {RepairRequest, RepairRequestComment} from '../interfaces/repair-request';
import {catchError, delay, map, switchMap} from 'rxjs/operators';
import {RepairRequestsResult} from '../interfaces/repair-request-result';
import {RepairFormConfig} from '../interfaces/repair-form.config';
import { Response } from '../models/response.model';
import {findDetailedErrorMessage} from '../shared/helpers';
import {ToastrService} from 'ngx-toastr';
import {convertBlobToBase64, isEmptyArray} from '../shared/utils';
import {UploadImage} from '../interfaces/upload-image';
import {AppDate} from '../models/date.model';

const BASE_URL = '/zendesk/repair-request/';



@Injectable()
export class ApiRepairRequestsService {
    readonly apiURL: string;

    constructor(private http: HttpClient, private appSettings: AppSettings, private toastr: ToastrService) {
        this.apiURL = this.appSettings.settings.apiBaseURL + BASE_URL;
    }

    public fetchRepairRequests(paging = '20-0'): Observable<RepairRequestsResult> {
        const headers: HttpHeaders = new HttpHeaders({'Range': paging});
        return this.http.get<HttpResponse<Response>>(this.apiURL, {observe: 'response', headers: headers}).pipe(
            map( httpResponse => {
                const response = new Response(httpResponse.body);
                const total = parseInt(httpResponse.headers.get('X-Count'), 10) || 0;
                if (Array.isArray(response.data)) {

                    const data = response.data.map(  item => {
                        return {
                            status: item.status,
                            subject: item.subject,
                            zendesk_id: item.zendesk_id,
                            updated_at: new AppDate(item.updated_at),
                            created_at: new AppDate(item.created_at),
                            fields: [],
                            comments: []
                        }
                    });

                    return {total, repairRequests: data};
                }

                return {total: 0, repairRequests: []};

            }),
            catchError(this.handleError('ApiRepairRequestsService::fetchRepairRequests', {total: 0, repairRequests: []}))
        )
    }


    public fetchRequestRequestDetails(id: number): Observable<RepairRequest> {
        return this.http.get<Response>(`${this.apiURL}${id}`).pipe(
            map(response => {
                if (response?.data) {
                    return response.data;
                }
                return null;
            }),
            catchError(this.handleError('ApiRepairRequestsService::fetchRequestRequestDetails', null))
        )
    }

    public submitRepairRequest(data: any): Observable<RepairRequest> {
        return this.http.post<Response>(`${this.apiURL}`, data , {headers: this.xsrfTokenHeader}).pipe(
            map(response => {
                if (response?.data) {
                    return response.data;
                }
                return null;
            }),
            catchError(this.handleError('ApiRepairRequestsService::submitRepairRequest', null))
        )
    }



    public addComment(repairRequestId: number, commentData): Observable<RepairRequest> {
        return this.http.post<Response>(`${this.apiURL}${repairRequestId}/comment`, commentData , {headers: this.xsrfTokenHeader}).pipe(
            map(response => {
                if (response?.data) {
                    return response.data;
                }
                return null;
            }),
            catchError(this.handleError('ApiRepairRequestsService::addComment', null))
        )
    }

    public getConfig(): Observable<RepairFormConfig> {

        return this.http.get<Response>(`${this.apiURL}config`).pipe(
            map(response => {
                if (!isEmptyArray(response?.data) ) {
                    // return response.data.map( item => new Order(item));
                    return {fields: response.data};
                }

                return null;
            }),
            catchError(this.handleError('ApiRepairRequestsService::getConfig', null))
        )
    }

    // upload an image to server
    public uploadImage(file: File): Observable<UploadImage> {

        const formData = new FormData();
        formData.append('files[]', file, file.name);

        return this.http.post<Response>(`${this.apiURL}upload`, formData, {headers: this.xsrfTokenHeader}).pipe(
            map((response: Response) => {
                if (response.data) {
                    return response.data;
                } else {
                    return null;
                }
            }),
            catchError(this.handleError('ApiRepairRequestsService::uploadImage', null))
        );
    }

    // return base64
    public fetchImageById(id): Observable<string> {
        return this.fetchImage(`${this.apiURL}attachment/${id}`);
    }

    public fetchThumbnailImageById(id): Observable<string> {
        return this.fetchImage(`${this.apiURL}attachment/${id}/thumb`);
    }

    private fetchImage(url: string): Observable<string> {
        return this.http.get(url, { responseType: 'blob' }).pipe(
            switchMap(response => {
                if (!response) {
                    return of('/assets/img/app/no-image.png');
                }
                return convertBlobToBase64(response);
            }),
            catchError(this.handleError('ApiRepairRequestsService::fetchImage', '/assets/img/app/no-image.png'))
        );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            const errorMessage = findDetailedErrorMessage(error);
            if (errorMessage) {
                this.toastr.error(errorMessage);
                console.log(operation + ' failed', error);
            }
            return of(result as T);
        };
    }


    private get xsrfTokenHeader() {
        return new HttpHeaders({'x-xsrf-token': ''});
    }

}
