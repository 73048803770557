import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';

@Component({
  selector: 'app-export-result-modal',
  templateUrl: './export-result-modal.component.html',
  styleUrls: ['./export-result-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExportResultModalComponent implements OnInit {

  exportType: 'pdf' | 'xlsx' =  'pdf';

  constructor(private activeModal: NgbActiveModal, private router: Router) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close(null);
  }

  get isPdf(): boolean {
    return this.exportType === 'pdf';
  }

  get title(): string {
    return this.isPdf ? 'Your PDF is Being Generated' : 'Your Excel is Being Generated';
  }

  navigateToNotifications() {
    this.close();
    this.router.navigate(['/notifications']);
  }
}
