import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Order} from '../../../../models/order.model';
import {OrderService} from '../../../../services/order.service';
import {Router} from '@angular/router';
import {AuthService} from '../../../../services/auth.service';
import {MultiTenantService} from '../../../../services/multi-tenant.service';

@Component({
  selector: 'app-mobile-cart-menu',
  templateUrl: './mobile-cart-menu.component.html',
  styleUrls: ['./mobile-cart-menu.component.scss'],
})
export class MobileCartMenuComponent implements OnInit {

  @Input() visible = false;
  @Output() onBWProductsClicked = new EventEmitter<void>();
  @Output() onBWProgramsClicked = new EventEmitter<void>();
  @Output() onODProductsClicked = new EventEmitter<void>();
  @Output() onODProgramsClicked = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();

  buyingWindowMenuExpanded = true;
  onDemandMenuExpanded = true;
  accountMenuExpanded = true;
  constructor(private orderService: OrderService, private router: Router,
              private authService: AuthService,
              private multiTenantService: MultiTenantService) { }

  ngOnInit(): void {
  }

  bwProductsClicked() {
    this.onBWProductsClicked.emit();
    this.onClosed();
    return false;
  }

  bwProgramsClicked() {
    this.onBWProductsClicked.emit();
    this.onClosed();
    return false;
  }

  odProductsClicked() {
    this.onODProductsClicked.emit();
    this.onClosed();
    return false;
  }

  odProgramsClicked() {
    this.onODProgramsClicked.emit();
    this.onClosed();
    return false;
  }

  onClosed() {
    this.closed.emit();
  }

  get activeODOrder(): Order {
    return this.orderService.activeOrder;
  }

  get activeBWOrder(): Order {
    if (this.orderService.activeOrderWindow?.active) {
      return this.orderService.activeBWOrder;
    }
    return null;
  }

  navigateToOrder(order: Order = null) {
    if (!order) {
      this.router.navigate(['/cart/']);
    } else {
      this.router.navigate(['/cart/', order.id]);
    }

    this.onClosed();
    return false;
  }

  get canSeeBuyingWindows(): boolean {
    return this.authService.canBuyingWindow;
  }

  get orderWindowLabel(): string {
    return this.multiTenantService.orderWindowLabel;
  }

  get onDemandLabel(): string {
    return this.multiTenantService.onDemandLabel;
  }
}
