import {BaseService} from './base.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

import {AppSettings} from '../app.settings';
import {ToastrService} from 'ngx-toastr';
import {Response} from '../models/response.model';
import {API_URL} from '../constants/api-urls';


@Injectable()
export class ContentService extends BaseService {

    public clearanceUrl = '';
    public truckGraphicsUrl = '';

    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService
    ) {
        super('/content', http, appSettings, toastr);
    }

    get(key: string, force = false): Observable<any> {
        // if (this.cache.has(key) && !force) {
        //     return of(this.cache.get(key));
        // }

        // let url = `${API_URL}/content?uri=${key}`;
        // let url = `${this.apiURL}/content?uri=${key}`;
        // if (key === 'latest-news') {
        //     // url  = `${API_URL}/latest-news`;
        //     url  = `${this.apiURL}/latest-news`;
        // }

        let url = `${this.apiURL}?uri=${key}`;
        if (key === 'latest-news') {
            url  = `${this.baseUrl}/latest-news`;
        }


        return this.http.get<Response>(url)
            .pipe(
                map(response => {
                    const data = response.data;
                    // this.cache.set(key, data);
                    return data;
                }),
                catchError(this.handleError('ContentService::get', null))
            );
    }

    public getTerms(): Observable<string> {
        return this.get('terms').pipe( map( result => {
            if (!result) {
                return ''
            }
            return result.data || '';
        }));
    }

    public getLatestNews(): Observable<string> {
        return this.get('latest-news', true).pipe( map( result => {
            if (!result) {
                return '';
            }
            return result || '';
        }));
    }

    public getFAQ(): Observable<any> {
        return this.get('faq').pipe( map (response => {
            if (response?.data) {
                return JSON.parse(response.data);
            }
            return '';
        }))
    }

    public getClearanceUrl() {
        this.get('navlink-clearance').subscribe( response => {
            this.clearanceUrl = response.data || '';
        })
    }

    public getTruckGraphicsUrl() {
        this.get('navlink-truck-graphics').subscribe( response => {
            this.truckGraphicsUrl = response.data || '';
        })
    }
}
