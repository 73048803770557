export class Errors {

    // HTTP Errors
    static readonly NOT_FOUND                 = 404;
    static readonly RANGE_REQUIRED            = 411;
    static readonly APP_EXCEPTION             = 500;
    
    // General App Errors
    static readonly DB_READ_ERROR             = 1000;
    static readonly DB_WRITE_ERROR            = 1001;
    static readonly ID_REQUIRED               = 1002;
    static readonly ID_NOT_FOUND              = 1003;
    static readonly ENTITY_NOT_FOUND          = 1004;
    static readonly ENTITY_ACCESS_ERROR       = 1005;
    static readonly INVALID_REQUEST           = 1006;
    static readonly UNKNOWN_TYPE              = 1007;
    static readonly FILE_NOT_FOUND            = 1008;    
    static readonly WRONG_HASH                = 1009;
    static readonly WRONG_FILE_NAME           = 1010;
    static readonly STATE_NOT_SAVED           = 1011;
    
    // User Errors
    static readonly NOT_AUTHENTICATED         = 2000;
    static readonly SSO_AUTH_ERROR            = 2001;
    static readonly ACCESS_DENIED             = 2002;
    static readonly INVALID_ACCESS_KEY        = 2003;
    static readonly INVALID_VENDOR_KEY        = 2004;
    static readonly IMPERSONATE_NO_ACCESS     = 2005;
    static readonly SSO_USER_REDIRECT         = 2006;
    static readonly USER_NOT_FOUND            = 2007;
    static readonly USER_INCORRECT_PASSWORD   = 2008;
    static readonly USER_DEACTIVATED          = 2009;
    static readonly USER_ID_REQUIRED          = 2010;
    static readonly USER_FIRST_NAME_REQUIRED  = 2100;
    static readonly USER_LAST_NAME_REQUIRED   = 2101;
    static readonly USER_EMAIL_REQUIRED       = 2102;
    static readonly USER_VALID_EMAIL          = 2103;
    static readonly USER_UNIQUE_EMAIL         = 2104;
    static readonly USER_PASSWORD_REQUIRED    = 2105;
    static readonly USER_STRONG_PASSWORD      = 2106;
    static readonly USER_SSO_BOOL             = 2107;
    static readonly USER_ACTIVE_BOOL          = 2108;
    static readonly LABEL_REQUIRED            = 2200;
    static readonly IS_DEFAULT_BOOL           = 2201;
    static readonly ADDR_ATTN_REQUIRED        = 2202;
    static readonly ADDR_STREET_REQUIRED      = 0;
    static readonly ADDR_CITY_REQUIRED        = 0;
    static readonly ADDR_STATE_REQUIRED       = 0;
    static readonly ADDR_ZIP_REQUIRED         = 0;
    static readonly ADDR_COUNTRY_REQUIRED     = 0;
    static readonly ADDR_STATE_LEN            = 0;
    static readonly ADDR_COUNTRY_LEN          = 0;
    static readonly ADDR_ZIP_PATTERN          = 0;
    static readonly CC_LAST_FOUR_REQUIRED     = 0;
    static readonly CC_TOKEN_REQUIRED         = 0;
    
    // Product Errors
    static readonly PRODUCT_GROUP_NOT_FOUND   = 0;
    
    // Order Errors
    static readonly ORDER_WRONG_ID            = 0;
    static readonly TRACKING_NOT_SAVED        = 0;
    
    
    // Validation Errors
    static readonly FILE_REQUIRED             = 0;
    static readonly TZ_NUMBER                 = 0;

    constructor() { }
}
