import {NgModule, APP_INITIALIZER, LOCALE_ID} from '@angular/core';
import { NgbModule }                    from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule }                from '@angular/platform-browser';
import { BrowserAnimationsModule }      from '@angular/platform-browser/animations';
import { ToastrModule }                 from 'ngx-toastr';
import { AppInit }                      from './app.init';
import { Routing }                      from './app.routing';
import { AppSettings }                  from './app.settings';
import { AppComponent }                 from './app.component';
import { httpInterceptorProviders }     from './interceptors/interceptors';

import { HttpClientModule } from '@angular/common/http';
import { ServiceModule }                from './services/service.module';

import { BlockUIModule }                from 'ng-block-ui';
import { CalendarModule, DateAdapter }  from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ThemeModule }                  from './theme/theme.module';
import { PipesModule }                  from './pipes/pipes.module';
import { PublicApiComponent }           from './components/public-api.component';
import { NotFoundComponent }            from './components/not-found/not-found.component';
import { SharedProductsModule }         from './modules/shared/products.module';
import { NgSelectModule }               from '@ng-select/ng-select';
import { FormsModule }                  from '@angular/forms';
import { MobxAngularModule }            from 'mobx-angular';
import { NotSupportedComponent } from './components/not-supported/not-supported.component';

import {StoreModule} from '@ngrx/store';
import {reducers} from './app.reducers';
// import { registerLocaleData } from '@angular/common';
// import localeCa from '@angular/common/locales/en-CA';
// import localeUS from '@angular/common/locales/en';
// import localeDe from '@angular/common/locales/de';
// import localeColumbia from '@angular/common/locales/es-CO';
// registerLocaleData(localeCa);
// registerLocaleData(localeUS);
// registerLocaleData(localeDe);
// registerLocaleData(localeColumbia);
export function initApp(appInit: AppInit) {
    return async () => {
        try {
            await appInit.init();
        } catch (e) {
            console.error('Failed to initialize app', e);
            window.document.body.classList.add('failed');
            window.location.href = '/assets/html/error.html';
            const forever = new Promise(() => { }); // will never resolve
            await forever;

        }
    }
};




@NgModule({
    declarations: [
        AppComponent,
        PublicApiComponent,
        NotFoundComponent,
        NotSupportedComponent,
        // SearchResultsComponent
    ],
    imports: [
        SharedProductsModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ServiceModule.forRoot(),
        BlockUIModule.forRoot(),
        ThemeModule,
        PipesModule,
        NgbModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        ToastrModule.forRoot({
            preventDuplicates: true,
        }),
        Routing,
        NgSelectModule,
        FormsModule,
        MobxAngularModule,
        StoreModule.forRoot(reducers),
    ],
    exports: [
        ThemeModule,
        PipesModule,
    ],
    providers: [
        httpInterceptorProviders,
        AppSettings,
        AppInit,
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [AppInit],
            multi: true
        },
    ],
    bootstrap: [ AppComponent ]
})

export class        AppModule { }
