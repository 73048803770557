import { observable, computed, action } from 'mobx';
import { Injectable } from '@angular/core';

@Injectable()
export class NotificationStore {
   @observable maxItemCount: any = 0;

   @action updateMaxItemCount(data) {
     this.maxItemCount = data;
   }
}
