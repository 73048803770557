import {BaseModel} from './base.model';
import {AutoShipItem} from './auto-ship.model';
import {AppDate} from './date.model';
import {generateUUID, updateItemByUUID} from '../shared/utils';
import {Order} from './order.model';
import {AutoShipState, stateColor, stateDescription} from '../enums/auto-ship-state.enum';


export class AutoShipGroup extends BaseModel {
    id: number
    order_id: number;
    order_label: string;
    order: Order;
    created_at: AppDate;
    state: AutoShipState;
    description: string;
    uuid: string;
    selected: boolean;
    quantity: number;

    custom_product_requests_ids: number[];
    items: AutoShipItem[];

    static createNewAutoship(order: Order): AutoShipGroup {
        return new AutoShipGroup({order_id: order.id, order: order, order_label: order.attr_cart_name});
    }

    constructor(data) {
        super(data, {
            num: ['id', 'order_id', 'quantity', 'state'],
            date: ['created_at']
        });

        this.custom_product_requests_ids = data.custom_product_requests_ids || [];
        this.items = data.items || [];
        this.state = data.state || AutoShipState.CREATED;
        this.quantity = data.quantity ? data.quantity : 0;
        this.created_at = data.created_at || new AppDate(new Date());

        this.uuid = data.uuid ? data.uuid : generateUUID();
    }


    updateItem(item: AutoShipItem | string, option: { insert?: boolean } = {}) {
        this.items = updateItemByUUID<AutoShipItem>(this.items, item, option);
        this.custom_product_requests_ids = this.items.map(i => i.id);
        this.quantity = this.items.reduce((total, i) => total += i.order_quantity, 0);

        // change group state if all items have different state
        if (item instanceof  AutoShipItem) {
            const count = this.items.filter(i => i.state === item.state).length;
            if (count === this.items.length && item.state !== this.state) {
                this.state =  item.state;
            }
        }
    }


    get stateDescription(): string {
        return stateDescription(this.state);
    }

    get stateColor(): string {
        return stateColor(this.state);
    }

    get canSubmit(): boolean {
        if (!this.order || !this.order.canEdit) {
            return false;
        }

        // it should be at least one item
        if (this.items.length === 0) {
            return false;
        }
        if (this.custom_product_requests_ids.length !== this.items.length) {
            return false;
        }

        // if (this.order.items.length !== this.items.length) {
        //     return false;
        // }

        return this.items.filter( i => i.canModify).length === this.items.length;

        // const readyItems = this.items.filter(item => {
        //     if (!item.canSubmit) {
        //         return false;
        //     }
        //
        //     const orderItem = this.order.items.find(i => i.id === item.item_id);
        //     if (!orderItem) {
        //         return null;
        //     }
        //
        //     return orderItem.quantity >= item.order_quantity;
        // }).length;
        //
        //
        // return readyItems === this.items.length;
    }

    get isCancelled(): boolean {
        return this.state === AutoShipState.CANCELLED;
    }

    get canDelete(): boolean {
        return this.state === AutoShipState.CREATED;
    }

    get canCancel(): boolean {
        return this.state === AutoShipState.PENDING
            || this.state === AutoShipState.SUBMITTED;
    }

    get canAddItem(): boolean {
        return this.state === AutoShipState.CREATED;
    }

    get canCheckout(): boolean {
        return this.state === AutoShipState.READY && this.order && this.order.canEdit;
    }

    get canExport(): boolean {
        return this.state !== AutoShipState.CREATED && !!this.order && this.order.totalShipments > 0;
    }

    get requestedQuantityMatch(): boolean {
        if (!this.order) {
            return false;
        }

        // exclude cancelled items
        const items = this.items.filter( i  => !i.isCancelled);
        if (items.length === 0) {
            return false;
        }

        const matched = items.filter( item => {
            if (!item.isPurchasable) {
                return false;
            }

            if (!item.item_id) {
                return false;
            }

            const orderItem = this.order.items.find( i => i.id === item.item_id);
            if (!orderItem) {
                return false;
            }

            if (!orderItem.isEditable) {
                return false;
            }

            return orderItem.quantity === item.order_quantity;
        }).length;


        return matched === items.length;
    }



    // get canAddDelivery(): boolean {
    //     return this.state === AutoShipState.SUBMITTED;
    // }

    public findItemById(id: number): AutoShipItem {
        const item = this.items.find(i => i.id === id);
        this.selectItem(item);
        return item;
    }

    public get selectedItem(): AutoShipItem {
        return this.items.find(i => i.selected);
    }

    public selectItem(item: AutoShipItem) {
        this.items.forEach(i => {
            if (!item) {
                i.selected = false;
            } else {
                i.selected = i.uuid === item.uuid;
            }
        });
    }





}
