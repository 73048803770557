import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {action, observable, computed} from 'mobx';
import {ToastrService} from 'ngx-toastr';
import {forkJoin, from, Observable, of} from 'rxjs';
import {catchError, filter, finalize, map, switchMap, tap} from 'rxjs/operators';

import {BaseService} from './base.service';
import {AppSettings} from '../app.settings';
import {AutoShipItem} from '../models/auto-ship.model';
import {Product} from '../models/product.model';
import {ProductService} from './product.service';
import {Order} from '../models/order.model';
import {OrderService} from './order.service';
import {OrderItem} from '../models/order-item.model';
import {AuthService} from './auth.service';
import {OrderItemDelivery} from '../models/order-item-delivery.model';
import {Address} from '../models/address.model';
import {AddressService} from './address.service';
import {SKU} from '../models/sku.model';
import {ConfigService} from './config.service';
import {Response} from '../models/response.model';
import {ModalService} from './modal.service';
import {EntityService} from './entity.service';
import {isEmptyArray, updateItemByUUID} from '../shared/utils';
import {OrderItemService} from './order-item.service';
import {AutoShipGroup} from '../models/auto-ship-group.model';
import {AutoShipApiService} from './auto-ship-api.service';
import {AlertType} from '../enums/alert-type';
import {SportTeam} from '../models/sport-team.model';
import {computeAvailableAddresses} from '../shared/helpers';
import {ICategory} from '../models/category-model';
import {Agency} from '../interfaces/agency';


const DEFAULT_FETCH_ITEMS = 20;


@Injectable()
export class AutoShipService extends BaseService {


    @observable autoShips: AutoShipGroup[] = [];
    @observable fetching = false;

    @observable fetchingAutoshipByOrderId = false;
    @observable removingAutoshipUUID = null;
    @observable removingAutoshipItemUUID = null;
    @observable fetchingAutoshipItem = false;

    @observable loadingMore = false;
    @observable uploading = false;
    @observable uploadingCSV = false;
    @observable savingAutoshipItem = false;
    @observable updatingInstructions = false;

    @observable updatingOrder = false;

    @observable orderItem: OrderItem;
    @observable editingOrderItem: OrderItem;


    private productTypesContent: any = null; // { type1 : [ subtype1, substype2], ... }
    private categories: ICategory[] =  null;

    private productTypes: {name: string; category_ids: number[]}[] = [];

    @observable public brands: string[] = [];
    public sportTeams: SportTeam[] = [];
    public agencies: Agency[] = [];
    delivery: OrderItemDelivery;

    private availableItems = 0;
    private fetchedAll = false;

    @observable deletingAddressDeliveries = false;
    @observable deletingWSLRDeliveries = false;
    @observable submittingRequest = false;
    @observable changingCostCenterDeliveryUUID: string = null;

    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService,
        protected router: Router,
        private productService: ProductService,
        private orderService: OrderService,
        private authService: AuthService,
        private addressService: AddressService,
        private entityService: EntityService,
        private configService: ConfigService,
        private modalService: ModalService,
        private orderItemService: OrderItemService,
        private api: AutoShipApiService
    ) {
        super('/custom-products-requests', http, appSettings, toastr);
        if (this.authService.canAutoship) {
            // this.api.fetchBrands().subscribe( brands => this.brands = brands);
            // this.getTypes();
            // this.getSportTeams().subscribe( ()  => { });
            // this.getAgencies();
            this.initWatcher();
        }

    }


    // public methods

    public createAutoShip(): Observable<AutoShipGroup> {

        return this.createOrder()
            .pipe(
                map(retOrder => {
                    if (!retOrder) {
                        return null;
                    }
                    return AutoShipGroup.createNewAutoship(retOrder);
                }),
                tap( autoship => {
                    if (autoship) {
                        this.updateAutoShips(autoship, true); // add to list
                    }
                })
            );
    }

    // create new CUSTOM_PRODUCT order
    public createOrder(): Observable<Order> {

        // this.setUpdatingOrder(true);
        return this.orderService.createAutoShipOrder()
            .pipe(
                switchMap( order => {
                   if (!order) {
                       return of(order);
                   }

                   return this.getOrderAddresses(order);
                }),
                switchMap(order => {
                    if (order) {
                        // it should call edit order settings dialog after creation
                        return this.editOrderSettings(order, true);
                    }
                    return of(order);
                })
            );

    }

    public editOrderSettings(order: Order, skipAutoshipUpdate = false): Observable<Order> {
        return from(this.modalService.editOrderSettings(order, {headerLabel: 'Auto-Ship Request'})).pipe(
            tap(retOrder => {
                if (!skipAutoshipUpdate && retOrder && this.selectedAutoShip) {
                    this.selectedAutoShip.order = new Order(retOrder);
                    this.selectedAutoShip.order_label = retOrder.attr_cart_name;
                }
            })
        )
    }

    public getAutoShipGroupByOrderId(orderId: number, force = false): Observable<AutoShipGroup> {
        // check if autoship has been fetched already

        this.fetchingAutoshipByOrderId = true;
        if (!force) {
            const autoShip = this.autoShips.find(a => a.order_id === orderId);
            if (autoShip) {
                return this.getAutoShipDetails(autoShip).pipe( finalize( () => this.fetchingAutoshipByOrderId = false));
            }
        }

        // fetch from api
        return this.api.fetchAutoShipGroupByOrderId(orderId).pipe(
            switchMap(retAutoShip => this.getAutoShipDetails(retAutoShip)),
            finalize( () => this.fetchingAutoshipByOrderId = false)
        )

    }


    public getAutoShipItem(orderId: number, itemId: number): Observable<AutoShipItem> {
        this.fetchingAutoshipItem = true;
        return this.getAutoShipGroupByOrderId(orderId).pipe(
            switchMap(autoShipGroup => {
                if (!autoShipGroup) {
                    return of(null);
                }

                const autoShipItem = autoShipGroup.findItemById(itemId);
                if (!autoShipGroup) {
                    return of(null);
                }

                if (autoShipItem.product_id && autoShipItem.product) {
                    // product has been fetched already
                    return of(autoShipItem);
                }

                return this.getProductForAutoship(autoShipItem).pipe(
                    tap( retItem => {
                        autoShipGroup.updateItem(retItem);
                        this.updateAutoShips(autoShipGroup);
                    })
                )

            }),
            finalize( () => this.fetchingAutoshipItem = false)
        );
    }


    public getOrderItem(autoShipGroup: AutoShipGroup, autoShipItem: AutoShipItem): OrderItem {

        this.editingOrderItem =  null;
        this.delivery = null;
        const orderItem = this.orderItemService.setupOrderItem(autoShipGroup.order, autoShipItem.product);
        if (orderItem) {
            orderItem.cleanNewDeliveries();
            this.editingOrderItem = new OrderItem(orderItem);
            this.setupDelivery(autoShipItem.product);
        }

        return this.editingOrderItem;
    }

    public assignOrderToAutoship(autoShipGroup: AutoShipGroup): Observable<AutoShipGroup> {

        if (!autoShipGroup) {
            return of(autoShipGroup);
        }

        return this.getAutoShipDetails(autoShipGroup).pipe(
            switchMap(retAutoShipGroup => {
                return this.createOrder().pipe(
                    switchMap(order => {
                        if (!order) {
                            return of(retAutoShipGroup);
                        }

                        const updatedAutoship = new AutoShipGroup(retAutoShipGroup);
                        updatedAutoship.order_id = order.id;
                        updatedAutoship.order = order;
                        updatedAutoship.order_label = order.attr_cart_name;
                        if (updatedAutoship.items) {
                            return forkJoin(updatedAutoship.items.map(i => {
                                i.order_id = order.id;
                                return this.saveAutoShipItem(i);
                            })).pipe(map(() => {
                                this.updateAutoShips(updatedAutoship);
                                return updatedAutoship;
                            }));
                        }
                    })
                );
            })
        );


    }


    public removeAutoShipGroup(autoshipGroup: AutoShipGroup): Observable<boolean> {
        if (!autoshipGroup) {
            return of(false);
        }


        if (!autoshipGroup.canCancel && !autoshipGroup.canDelete) {
            let msg = 'The item cannot be deleted.';
            if (!autoshipGroup.canCancel) {
                msg = 'Request cannot be cancelled at this stage. Please contact Procurement to cancel or modify your request.'
            }

            this.modalService.showAlert({
                type: AlertType.Prompt,
                title: 'The order cannot be cancelled',
                message: msg,
                actionLabel: 'OK'
            }, 'md');
            return of(false);
        }

        const options = {
            label: autoshipGroup.canDelete ? 'ARE YOU SURE YOU WANT TO REMOVE THIS AUTO-SHIP?' : 'ARE YOU SURE YOU WANT TO CANCEL THIS AUTO-SHIP?',
            removeButton: autoshipGroup.canDelete ? 'Remove Auto-ship' : 'Cancel Auto-ship'
        };

        let operation = 'removing';

        return this.getAutoShipDetails(autoshipGroup).pipe(
            switchMap ( autoshipWithDetails => {

                return from(this.modalService.showRemoveItemModal(options)).pipe(
                    filter(result => result),
                    switchMap(() => {
                        this.removingAutoshipUUID = autoshipGroup.uuid;
                        if (autoshipGroup.canDelete) {
                            if (autoshipGroup.order_id) {
                                return this.api.removeAutoshipGroup(autoshipGroup);
                            }
                            if (autoshipGroup.items && autoshipGroup.items.length === 1) {
                                return this.api.removeAutoshipItem(autoshipGroup.items[0]);
                            }
                            return of(false);

                        } else {
                            if (autoshipGroup.order_id) {
                                operation = 'cancelling';
                                return this.api.cancelAutoshipGroup(autoshipGroup);
                            }
                            if (autoshipGroup.items && autoshipGroup.items.length === 1) {
                                operation = 'cancelling';
                                return this.api.cancelAutoshipItem(autoshipGroup.items[0]);
                            }

                            return of(false);

                        }
                    }),
                    map(result => {
                        if (result) {
                            if (result instanceof AutoShipGroup) {
                                this.updateAutoShips(result);
                            } else if (result instanceof AutoShipItem) {
                                autoshipGroup.updateItem(result);
                            } else {
                                // remove autoship
                                this.updateAutoShips(autoshipGroup.uuid);
                            }

                            this.toastr.success(operation === 'cancelling' ? 'Autoship has been cancelled.' : 'Autoship has been removed.');
                            return true;
                        } else {
                            this.toastr.error(operation === 'cancelling'  ? 'Cannot cancel the Autoship.' : 'Cannot remove the Autoship.');
                            return false;
                        }
                    }),
                    finalize(() => {
                        this.removingAutoshipUUID = null;
                    })
                );

            })
        )

    }

    public removeOrCancelAutoShipItem(autoshipGroup: AutoShipGroup, autoshipItem: AutoShipItem): Observable<boolean> {
        if (!autoshipItem) {
            return of(false);
        }

        if (!autoshipItem.canCancel && !autoshipItem.canDelete) {
            let msg = 'The item cannot be deleted.';
            if (!autoshipItem.canCancel) {
                msg = 'Request cannot be cancelled at this stage. Please contact Procurement to cancel or modify your request.'
            }

            this.modalService.showAlert({
                type: AlertType.Prompt,
                title: 'The item cannot be cancelled',
                message: msg,
                actionLabel: 'OK'
            }, 'md');
            return of(false);
        }


        const options = {
            label: autoshipItem.canDelete ? 'ARE YOU SURE YOU WANT TO REMOVE THIS ITEM?' : 'ARE YOU SURE YOU WANT TO CANCEL THIS ITEM?',
            removeButton: autoshipItem.canDelete ? 'Remove Item' : 'Cancel Item'
        };

        return from(this.modalService.showRemoveItemModal(options)).pipe(
            filter(result => result),
            switchMap(() => {
                this.removingAutoshipItemUUID = autoshipItem.uuid;
                if (autoshipItem.canDelete) {
                    return this.api.removeAutoshipItem(autoshipItem);
                } else {
                    return this.api.cancelAutoshipItem(autoshipItem);
                }
            }),
            map(result => {
                if (result) {
                    if (result instanceof AutoShipItem) {
                        // cancel
                        autoshipGroup.updateItem(result);
                    } else {
                        // remove autoship
                        autoshipGroup.updateItem(this.removingAutoshipItemUUID);

                    }
                    this.updateAutoShips(autoshipGroup);


                    this.toastr.success(autoshipItem.canCancel ? 'The item has been cancelled.' : 'The item has been removed.');
                    return true;
                } else {
                    return false;
                }
            }),
            finalize(() => {
                this.removingAutoshipItemUUID = null;
            })
        );

    }

    public submitAutoShip( autoShipGroup: AutoShipGroup): Observable<AutoShipGroup> {
        if (!autoShipGroup) {
            return of(autoShipGroup);
        }

        this.submittingRequest = true;
        return this.api.submit(autoShipGroup).pipe(
            switchMap( retAutoShipGroup => {
               if (!retAutoShipGroup) {
                   return of(null);
               }
               return this.getAutoShipDetails(retAutoShipGroup);
            }),
            map( retAutoShip => {
                if (retAutoShip) {
                    this.updateAutoShips(retAutoShip);
                    this.toastr.success('The request has been submitted.');
                }
                return retAutoShip;
            }),
            finalize( () => {
                this.submittingRequest = false;
            })
        );
    }


    private getAutoShipDetails(autoShip: AutoShipGroup): Observable<AutoShipGroup> {
        if (!autoShip) {
            this.selectAutoShip(null); // de-select all
            return of(null);
        }

        return forkJoin([this.getAutoShipItems(autoShip), this.getAutoShipOrder(autoShip)]).pipe(
            map(([items, order]) => {
                autoShip.items = items;
                autoShip.order = order;
                return autoShip;
            }),
            tap((retAutoShip) => {
                this.updateAutoShips(retAutoShip);
                this.fetchingAutoshipByOrderId = false;
            })
        );
    }


    private getAutoShipOrder(autoShip: AutoShipGroup): Observable<Order> {
        if (autoShip.order) {
            return of(autoShip.order);
        }
        if (!autoShip.order_id) {
            return of(null);
        }

        return this.orderService.getOrder(autoShip.order_id);
    }

    private getAutoShipItems(autoShip: AutoShipGroup): Observable<AutoShipItem[]> {
        if (autoShip.items.length > 0) {
            autoShip.selectItem(null); // un-select all
            return of(autoShip.items);
        }

        return this.api.fetchAutoShipItems(autoShip.custom_product_requests_ids);
    }


    private getOrderAddresses(order: Order): Observable<Order> {
        if (!order || !order.entity_id) {
            return of(order);
        }
        return this.entityService.getAutoShipLocations(order.entity_id).pipe(
            map( locations => {
                order.autoshipLocations = locations;
                return order;
            })
        );
    }


    private getProductForAutoship(autoShipItem: AutoShipItem): Observable<AutoShipItem> {
        if (!autoShipItem || autoShipItem.product || !autoShipItem.product_id ) {
            return of(autoShipItem);
        }

        return this.productService.getProduct(autoShipItem.product_id, 0, true).pipe(
            map( product => {
                if (product) {
                    autoShipItem.product = product;
                }
                return autoShipItem;
            })
        );
    }


    // fetch list of auto-ships
    public fetchAutoShips(loadingMore = false) {

        if (this.fetchedAll && !loadingMore) {
            return; // avoid of repeating requests
        }

        if (!loadingMore) {
            this.resetAutoShips(); // reset already loaded
        }

        this.fetching = true;
        const paging = `${DEFAULT_FETCH_ITEMS} - ${this.autoShips.length}`;
        return this.api.fetchAll(paging).subscribe(({items, total}) => {
            this.availableItems = total;
            if (Array.isArray(items) && items.length > 0) {
                items.forEach(a => this.updateAutoShips(a));
            }
            this.fetchedAll = true;
            this.fetching = false;
        });
    }

    // re-fetch all  autoships
    public refetchAutoShips() {
        this.fetching = true;
        this.resetAutoShips();
        const paging = `${Math.max(this.autoShips.length, DEFAULT_FETCH_ITEMS)} - 0`;
        return this.api.fetchAll(paging).subscribe(({items, total}) => {
            this.availableItems = total;

            if (Array.isArray(items) && items.length > 0) {
                items.forEach(a => this.updateAutoShips(a));
            }
            this.fetchedAll = true;
            this.fetching = false;
        });

    }


    public getSportTeams(): Observable<SportTeam[]> {
        if (this.sportTeams.length > 0) {
            return of(this.sportTeams);
        }
        return this.api.fetchSportTeams().pipe(
            tap(teams => {
                this.sportTeams = teams;
            })
        );
    }

    public updateDeliveryAddress(address: Address) {
        if (!this.canOrder) {
            return;
        }

        if (address) {
            this.delivery.setAddress(address, this.authService.user, true,
                {canOrder: this.authService.canOrder, isEmployee: this.authService.isEmployee});
        }

    }


    public addDelivery() {

        // check if order has cost center
        if (this.canAutoship && !(this.order.hasCostCenter() || this.order.isWSLR)) {
            this.modalService.editOrderSettings(this.order);
            return;
        }

        this.editingOrderItem.updateDelivery(new OrderItemDelivery(this.delivery));
        this.editingOrderItem = new OrderItem(this.editingOrderItem);
        // this.orderItemService.computeAvailableAddresses(this.editingOrderItem, this.order);
        this.setupDelivery(this.editingOrderItem.product);
    }


    public removeDelivery(deliveryId: number | string) {
        this.editingOrderItem.removeDelivery(deliveryId);
        this.editingOrderItem = new OrderItem(this.editingOrderItem);
        this.setupDelivery(this.editingOrderItem.product);
        // this.orderItemService.computeAvailableAddresses(this.editingOrderItem, this.order);
    }

    public computeDeliveryPrice(delivery: OrderItemDelivery) {
        return ProductService.computeDiscountPrice(this.product.getPrice(), this.product) * delivery.quantity;
        // console.log('price', this.selectedAutoShip.requested_price, delivery.quantity);
        // return this.selectedAutoShip.requested_price * delivery.quantity;
    }


    public saveAutoShipItem(autoShip: AutoShipItem): Observable<boolean> {
        this.savingAutoshipItem = true;

        return this.api.saveAutoShip(autoShip).pipe(
            switchMap( retItem => {
                if (retItem) {
                    return this.getProductForAutoship(retItem);
                }
                return of(null);
            }),
            map(retAutoShipItem => {
                if (retAutoShipItem) {
                    this.selectedAutoShip.updateItem(retAutoShipItem);
                    this.selectAutoShipItem(retAutoShipItem);
                    return true;
                }
                return false;
            }),
            finalize(() => this.savingAutoshipItem = false)
        );
    }

    // upload an image to server
    public uploadImage(file: File): Observable<string> {

        this.uploading = true;
        return this.api.uploadImage(file).pipe(
            finalize(() => this.uploading = false)
        );

    }

    public changeDeliverySap(delivery: OrderItemDelivery): Observable<any> {
        if (!this.selectedAutoShip || !this.selectedAutoShipItem || !this.editingOrderItem) {
            return of(null);
        }

        const costCenter = delivery.cost_center ||  this.selectedAutoShip.order.costCenter;
        return from(this.modalService.showSelectSAPModal(costCenter)).pipe(
            switchMap( retCostCenter => {
                if (!retCostCenter) {
                    return of(false);
                }
                this.changingCostCenterDeliveryUUID = delivery.uuid;
                delivery.cost_center = retCostCenter;
                return this.saveOrderItem();
            }),
            finalize( () => this.changingCostCenterDeliveryUUID = null)
        );
    }

    public getTypes() {
        if (!this.productTypesContent) {
            this.api.fetchProductTypes().subscribe(data => {
                this.categories = data?.categories || [];
                this.setProjectTypes(data?.item_mappings || []);
            });
        }
    }

    public getSubTypes(category: ICategory, type_name: string, term: string = '') {
        if (!category || !type_name) {
            return [];
        }
        const productType = this.productTypesContent.find(i => (i.item_type_name === type_name));
        if (productType && productType.sub_type_configurations) {
            const subtypes = productType.sub_type_configurations.filter( s => {
                if (term) {
                    return s.category_id === category.category_id && s.item_subtype_name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
                }
                return s.category_id === category.category_id;
            });

            if (subtypes) {
                if (Array.isArray(subtypes)) {
                    return subtypes;
                } else {
                    return [subtypes];
                }
            }
        }

        return [];
    }


    public getCategoryById(id: number): ICategory {
        if (isEmptyArray(this.categories)) {
            return null;
        }

        return this.categories.find( c => c.category_id === id);
    }

    public getCategoryByName(name: string): ICategory {
        if (!name || isEmptyArray(this.categories)) {
            return null;
        }
        const lowerCaserName = name.toLowerCase();
        return this.categories.find( c => c.category_name.toLowerCase() === lowerCaserName);
    }


    public getCategories(): ICategory[] {
        return this.categories || [];
    }

    // get addresses(): Address[] {
    //     return this.addressService.addresses;
    // }


    @computed
    public get selectedAutoShip(): AutoShipGroup {
        return this.autoShips.find(a => a.selected);
    }

    @computed
    public get selectedAutoShipItem(): AutoShipItem {
        if (!this.selectedAutoShip) {
            return null;
        }

        return this.selectedAutoShip.items.find( i => i.selected);
    }

    @computed get order(): Order {
        if (!this.selectedAutoShip) {
            return null;
        }

        return this.selectedAutoShip.order;
    }


    @computed get product(): Product {
        if (!this.selectedAutoShipItem) {
            return null;
        }

        return this.selectedAutoShipItem.product;
    }

    public get moreItemsAvailable(): boolean {
        return this.availableItems > this.autoShips.length;
    }

    public updateOrderItem(): Observable<boolean> {

        if (!this.editingOrderItem.id && isEmptyArray(this.editingOrderItem.deliveries)) {
            return of(true);  // skip saving order item when no deliveries are applied
        }

        this.setUpdatingOrder(true);
        return this.saveOrderItem().pipe(
            finalize( () =>  this.setUpdatingOrder(false))
        );
    }

    public saveOrderItem(): Observable<boolean> {

        this.editingOrderItem.computeDeliveryTotalAmount();
        this.editingOrderItem.autoship_item_id = this.selectedAutoShipItem.id;

        return this.orderItemService.saveOrderItem(this.order, this.editingOrderItem, false, this.orderItem).pipe(
            map(result => {
                if (result) {
                    if (result.orderItem) {
                        this.setOrderItem(result.orderItem);
                        return this.orderItem;
                    }
                }
                return null;
            }),
            switchMap( orderItem => {
                if (this.selectedAutoShipItem && orderItem) {
                    if (this.selectedAutoShipItem.item_id !== orderItem.id) {
                        const item = new AutoShipItem(this.selectedAutoShipItem);
                        item.item_id = orderItem.id  ? orderItem.id : null;
                        return this.saveAutoShipItem(item);
                    }
                    return of(true);
                }
                return of(false);

            }),
        );
    }


    public fetchByIds(ids: number[]): Observable<AutoShipItem[]> {
        if (!ids || ids.length === 0) {
            return of([]);
        }
        return this.http.post<Response>(`${this.apiURL}/for-ids`, ids).pipe(
            map(response => {
                if (Array.isArray(response.data)) {
                    return response.data.map(i => new AutoShipItem(i));
                } else {
                    return [];
                }
            }),
            catchError(this.handleError('AutoShipService::fetchByIds', [])),
        )
    }


    public getSportTeamByCode(code: string): string {
        if (!code) {
            return '';
        }

        const team = this.sportTeams.find( t => t.id === code);
        return team ? team.label : '';
    }

    // private methods
    @action private selectAutoShip(autoShip: AutoShipGroup | string) {
        this.autoShips.forEach(a => {
            if (autoShip instanceof  AutoShipGroup) {
                a.selected = (a.uuid === autoShip.uuid);
            } else {
                a.selected = false;
            }
        });
    }

    @action private selectAutoShipItem(item: AutoShipItem) {
        if (!this.selectedAutoShip) {
            return;
        }

        this.selectedAutoShip.selectItem(item);
    }

    @action private setUpdatingOrder(val: boolean) {
        this.updatingOrder = val;
    }

    @action
    private setAutoShips(val: AutoShipGroup[]) {
        this.autoShips = val;
    }

    @action
    private resetAutoShips() {
        this.autoShips = [];
    }

    private setupOrderItem() {
        if (!this.product) {
            return;
        }

        this.orderItem = this.order.getItemForProduct(this.product, {});
        this.orderItem.deliveries = this.orderItem.deliveries.filter(d => d.id > 0); // keep only actual deliveries

        if (this.orderItem.skus.length === 0) {
            this.product.skus.forEach((sku: SKU) => {
                this.orderItem.getVariationSKU(sku.id);
            });
        }
        this.editingOrderItem = new OrderItem(this.orderItem);
        this.setupDelivery(this.product);

    }


    private setupDelivery(product: Product, address?: Address) {

        this.delivery = new OrderItemDelivery({
            type: 'SHIPPING',
            method: '',
            quantity: 0,
            data: {},
        });

        if (address) {
            this.updateDeliveryAddress(address);
        } else {
            // take first available if any
            const availableAddresses = computeAvailableAddresses(this.addressService.addresses, this.order, this.editingOrderItem);


            if (!isEmptyArray(availableAddresses)) {
                this.updateDeliveryAddress(availableAddresses[0]);
            }
        }

    }

    private findAutoShipById(id: number): Observable<AutoShipGroup> {

        const autoShip = this.autoShips.find(a => a.id === id);
        if (autoShip) {
            return of(autoShip);
        } else {
            return this.fetchById(id);
        }
    }


    private fetchById(id: number): Observable<AutoShipGroup> {
        return this.http.get<Response>(`${this.apiURL}/${id}`).pipe(
            map(response => {
                if (response.data) {
                    return new AutoShipGroup(response.data);
                } else {
                    return null;
                }
            }),
            catchError(this.handleError('AutoShipService::fetchById', null)),
        )
    }




    private setProjectTypes(val) {
        this.productTypesContent = val;
        if (!isEmptyArray(this.productTypesContent)) {
            this.productTypes = this.productTypesContent.map( c => ({name: c.item_type_name, category_ids: c.category_ids}));
        } else {
            this.productTypes = [];
        }
    }

    @action setOrderItem(orderItem: OrderItem) {
        this.orderItem = orderItem;
        this.editingOrderItem = new OrderItem(orderItem);
        this.editingOrderItem.changed = false;
    }


    private updateAutoShips(autoShip: AutoShipGroup | string, insertFirst = false) {

        if (!autoShip) {
            return;
        }
        const autoships = updateItemByUUID(this.autoShips, autoShip, {insert: insertFirst});
        this.setAutoShips(autoships);
        this.selectAutoShip(autoShip);
    }





    public deleteDeliveries(delivery_type: string): Observable<boolean> {
        if (!this.selectedAutoShipItem) {
            return;
        }

        if (delivery_type === 'wslr') {
            this.deletingWSLRDeliveries = true;
        } else {
            this.deletingAddressDeliveries = true;
        }

        return this.updateOrderItem().pipe(
            switchMap( (updateResult) => {
                if (!updateResult) {
                    return null;
                }
                return this.api.deleteDeliveries(delivery_type, this.selectedAutoShipItem.id)
            }),
            map(orderItem => {
                if (orderItem) {
                    const autoShip = new AutoShipGroup(this.selectedAutoShip);
                    autoShip.order.updateOrderItems(orderItem);
                    this.updateAutoShips(autoShip);
                    this.orderItem = orderItem;
                    this.editingOrderItem = new OrderItem(this.orderItem);

                    this.toastr.success('Shipments have been removed');
                } else {
                    this.setupOrderItem();
                }
                this.deletingWSLRDeliveries = false;
                this.deletingAddressDeliveries = false;
                return true;
            })
        );
    }

    public populateAuto(autoship: AutoShipItem) {
        this.api.populateAuto(autoship);
    }


    public uploadCSV(file: File): Observable<any> {
        if (!this.selectedAutoShip) {
            return;
        }

        if (!this.selectedAutoShipItem) {
            return;
        }

        const formData = new FormData();
        formData.append('files[]', file, file.name);

        this.uploadingCSV = true;

        return this.updateOrderItem().pipe(
            switchMap( (updateResult) => {
                if (!updateResult) {
                    return of(null);
                }
                return this.api.uploadCSV(file, this.selectedAutoShipItem.id).pipe(
                    switchMap( retVal => {

                        if (retVal) {
                            const {orderItem, uploadResult} = retVal

                            if (orderItem) {
                                const updatedOrder = this.selectedAutoShip.order;
                                updatedOrder.updateOrderItems(orderItem);

                                const autoShip = new AutoShipGroup(this.selectedAutoShip);
                                autoShip.order = updatedOrder;
                                this.updateAutoShips(autoShip);
                                this.setOrderItem(orderItem);

                                // update autoship item_id if required
                                if (this.selectedAutoShipItem.item_id !== orderItem.id) {
                                    this.selectedAutoShipItem.item_id = orderItem.id;
                                    return this.saveAutoShipItem(this.selectedAutoShipItem).pipe(
                                        map( () => {
                                            return uploadResult;
                                        })
                                    );
                                } else {
                                    return of(uploadResult)
                                }
                            } else {
                                return  of(uploadResult);
                            }

                        } else {
                            return of(null);
                        }
                    }),

                    map( (uploadResult) => {

                        if (uploadResult) {
                            this.modalService.showCsvUploadResult(uploadResult).then(result => {
                                if (result.downloadResults === true) {
                                    window.open(`${this.api.getApiUrl()}/${this.selectedAutoShipItem.id}/shipment-csv-results`, '_blank');
                                }
                            });
                        }

                        return uploadResult;

                    }),
                )
            }),
            finalize( () => this.uploadingCSV = false)
        )


    }
    
    public getApiUrl(): string {
        return this.api.getApiUrl();
    }

    public getTypeNameByCategoryId(category_id: number): string[] {
        let result = [];
        if (!category_id) {
            result = this.productTypes;
        } else {
            result = this.productTypes.filter( type => type.category_ids.includes(category_id));
        }

        return result.map( type => type.name);
    }

    private  initWatcher() {
        this.orderService.autoshipOrderCheckouted.pipe(
            switchMap( orderId => {
                if (!Number.isNaN(orderId)) {
                    return this.getAutoShipGroupByOrderId(orderId)
                }
            })
        ).subscribe( (result) => {
        });

    }


    private getAgencies() {
        this.api.fetchAgencies().subscribe(  result => this.agencies =  result);
    }

    public get canOrder(): boolean {
        return this.authService.canOrder;
    }

    public get canAutoship(): boolean {
        return this.authService.canAutoship
    }

}




