import { BaseModel } from './base.model';

export abstract class PaymentMethod extends BaseModel {

    public id: number;
    public type: number;
    public active: boolean;

    abstract isCard(): boolean;
    abstract isAccount(): boolean;


    constructor(data) {
        super(data);
        if (!data  || (data && !data.active)) {
            this.active = false;
        }
    }
}
