import {Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {Store} from '@ngrx/store';

import {ToastrService} from 'ngx-toastr';
import * as actions from '../actions/repairt-requests.actions';
import * as fromRepairRequestReducer from '../reducers/repair-requests.reducer' ;
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {isEmptyArray, updateItemInArray} from '../shared/utils';
import {ApiRepairRequestsService} from './api-repair-requests.service';
import {UploadImage} from '../interfaces/upload-image';
import {RepairRequest, RepairRequestComment} from '../interfaces/repair-request';
import {RepairFormConfig} from '../interfaces/repair-form.config';
import {AppDate} from '../models/date.model';

const PAGING_OFFSET = 20;

@Injectable()
export class RepairRequestsService {

    constructor(
        private store: Store<fromRepairRequestReducer.IRepairRequestsState>,
        private api: ApiRepairRequestsService,
        private toastr: ToastrService
    ) {
    }


    public getRequests(reload = false) {
        this.store.select(fromRepairRequestReducer.repairRequestsResult).pipe(
            take(1),
            switchMap(retVal => {
                const listReceived = retVal.listReceived;
                const hasRequests = !isEmptyArray(retVal.repairRequests);

                if (reload || !listReceived) {
                    let paging: string;
                    if (reload && hasRequests) {
                        // paging only if there are already some requests
                        paging = `${retVal.repairRequests.length}-0`;
                    }
                    this.store.dispatch(new actions.FetchingRepairRequests());
                    return this.api.fetchRepairRequests(paging);
                }

                return of(retVal);
            })
        ).subscribe(retResult => {
            this.store.dispatch(new actions.FetchedRepairRequests(retResult.repairRequests, retResult.total));
        });
    }

    public loadMoreRequests(): void {

        this.store.select(fromRepairRequestReducer.isLoadingMore).pipe(
            take(1),
            switchMap(isLoading => {
                if (isLoading) {
                    return of(null);
                }
                return this.store.select(fromRepairRequestReducer.repairRequestsResult).pipe(
                    take(1)
                )
            }),
            switchMap(storeResult => {
                if (!storeResult) {
                    return of(null);
                }

                const {repairRequests, total} = storeResult;
                if (total === 0 || isEmptyArray(repairRequests) || total <= repairRequests.length) {
                    return of(null);
                }
                const length = repairRequests.length;
                const paging = `${length + PAGING_OFFSET}-${length}`;
                this.store.dispatch(new actions.LoadingMoreRepairRequests());
                return this.api.fetchRepairRequests(paging);
            })
        ).subscribe(retResult => {
            if (retResult) {
                this.store.dispatch(new actions.LoadedMoreRepairRequests(retResult.repairRequests));
            }
        });

    }

    public getConfig() {
        this.getConfig$().subscribe(config => {});
    }

    public getConfig$(): Observable<RepairFormConfig> {
        return this.store.select(fromRepairRequestReducer.repairFormConfig).pipe(
            take(1),
            switchMap(config => {
                if (config) {
                    return of(config);
                }
                return this.api.getConfig();
            }),
            tap( config => {
                if (config) {
                    this.store.dispatch(new actions.FetchedRepairFormConfig(config));
                }
            })
        );
    }


    // upload an image to server
    public uploadImage(file: File) {

        this.store.dispatch(new actions.UploadingRepairRequestImage());
        this.api.uploadImage(file).pipe(
            switchMap(result => {
                if (!result) {
                    return of(result);
                }
                return this.getImageData(result).pipe(
                    map(retVal => {
                        return result;
                    })
                );
            })
        ).subscribe(retVal => {
            if (retVal) {
                this.toastr.success('Image has been uploaded');
            } else {
                this.toastr.warning('Failed to upload the image');
            }
            this.store.dispatch(new actions.UploadedRepairRequestImage(retVal));
        });
    }

    public getImageData(img: UploadImage): Observable<UploadImage> {
        // get from cache
        return this.store.select(fromRepairRequestReducer.getImageFromCacheById, {id: img.id}).pipe(
            take(1),
            switchMap( retImage => {
                if (retImage) {
                    return of(retImage);
                }
                return this.getImagesFromServer(img);
            })
        );
    }

    private getImagesFromServer(img: UploadImage): Observable<UploadImage> {
        return this.api.fetchThumbnailImageById(img.id).pipe(
            map( result => {
                img.thumbnail_url = result;
                this.store.dispatch( new actions.AddImageToCache(img));
                return img;
            })
        )
    }

    private getOriginalImagesFromServer(img: UploadImage): Observable<UploadImage> {
        this.store.dispatch(new actions.FetchingOriginalImage(img.id));

        return this.api.fetchImageById(img.id).pipe(
            map( result => {
                const retImage  = {...img};
                retImage['url'] = result;
                this.store.dispatch( new actions.FetchedOriginalImage(retImage));
                return retImage;
            })
        )
    }

    public getRepairRequestDetails(id: number) {
        if (!id) {
            return;
        }

        // need to get config date before repair request details
        this.getConfig$().pipe(
            switchMap( config => {
                if (!config) {
                    return of(null);
                }
                this.store.dispatch(new actions.FetchingRepairRequestDetails(id));
                return this.api.fetchRequestRequestDetails(id);
            }),
            switchMap( retRequest => {
                if (!retRequest) {
                    return of(retRequest);
                }

                retRequest.updated_at = new AppDate(retRequest.updated_at);
                retRequest.created_at = new AppDate(retRequest.created_at);

                return this.fetchImages(retRequest);
            })
        ).subscribe(retVal => {
            this.store.dispatch(new actions.FetchedRepairRequestDetails(retVal));
        })
    }


    public submitRequest(data: any): Observable<boolean> {
        if (!data) {
            return of(false);
        }

        this.store.dispatch(new actions.SubmittingRepairRequest());

        return this.api.submitRepairRequest(data).pipe(
            switchMap(retVal => {
                if (!retVal) {
                    return of(retVal);
                }
               return this.fetchImages(retVal);

            }),
            map(retVal => {

                if (retVal.updated_at) {
                    retVal['updated_at'] = new AppDate(retVal.updated_at);
                }
                if (retVal.created_at) {
                    retVal['created_at'] = new AppDate(retVal.created_at);
                }

                if (!retVal.subject) {
                    retVal['subject'] = retVal.fields['subject'] || '';
                }

                this.store.dispatch(new actions.SubmittedRepairRequest(retVal));
                if (retVal) {
                    this.toastr.success('Repair Request has been submitted');
                    return true;
                } else {
                    return false;
                }
            }),
            catchError((error: any): Observable<any> => {
                this.store.dispatch(new actions.SubmittedRepairRequest(null));
                return of(false);
            })
        );

    }


    public addComment(requestId, comment: any): Observable<boolean> {
        if (!requestId || !comment) {
            return of(false);
        }

        this.store.dispatch(new actions.AddingRepairRequestComment(requestId));
        return this.api.addComment(requestId, comment).pipe(
            switchMap( retVal => {
                if (!retVal) {
                    return  of(retVal);
                }

                if (retVal.updated_at) {
                    retVal['updated_at'] = new AppDate(retVal.updated_at);
                }
                if (retVal.created_at) {
                    retVal['created_at'] = new AppDate(retVal.created_at);
                }

                return this.fetchImages(retVal);
            }),
            tap( result => {
              this.store.dispatch(new actions.AddedRepairRequestComment(result));
            }),
            map( retVal => {
                return !!retVal;
            }),
            catchError((error: any): Observable<any> => {
                this.store.dispatch(new actions.AddedRepairRequestComment(null));
                return of(false);
            })

        );
    }

    public getOriginalImage(img: UploadImage): Observable<UploadImage> {
        return this.store.select(fromRepairRequestReducer.originalImageById, {id: img.id}).pipe(
            take(1),
            switchMap( image => {
                if (image) {
                    return of(image);
                }

                return this.getOriginalImagesFromServer(img);
            })
        )
    }

    private fetchImages(repairRequest: RepairRequest): Observable<RepairRequest> {
        if (isEmptyArray(repairRequest?.comments)) {
            return of(repairRequest);
        }

        const images: UploadImage[] = [];

        repairRequest.comments.forEach( commment => {
            if (!isEmptyArray(commment.attachments)) {
              commment.attachments.forEach( attachment => images.push(attachment));
            }
        });

        if (isEmptyArray(images)) {
            return of(repairRequest);
        }

        return forkJoin( images.map(img => {
            return this.getImageData(img)
        })).pipe( map(retVal => {
            if (!isEmptyArray(retVal)) {
                retVal.forEach( img => this.updateRepairRequestImage(repairRequest, img));
            }
            return repairRequest;
        }));

    }


    private updateRepairRequestImage(repairRequest: RepairRequest, image: UploadImage) {
        repairRequest.comments.forEach( comment => {
            if (!isEmptyArray(comment.attachments)) {
               const toUpdate = comment.attachments.find(a => a.id === image.id);
               if (toUpdate) {
                   comment.attachments = updateItemInArray<UploadImage>(comment.attachments, image);
               }
            }
        })
    }

}
