<div class="modal-md address">
    <div class="modal-header state">
        <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label">Make Default Address</h1>
        <button type="button"  class="btn btn-icon close" (click)="close()" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
        <p>Make this address as the default for following branches:</p>
        <div class="mx-auto mb-2" style="max-width: 160px">
            <div *ngFor="let item of wlsr" class="d-flex align-items-start">
                <span class="custom-control-description mr-3">WLSR # {{ item.id }} </span>
                <label class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input"
                           [(ngModel)]="item.checked"
                    >
                    <span class="custom-control-indicator"></span>
                </label>
            </div>
        </div>

        <div class="mt-3 text-align-center" >
            <button class="btn btn-primary" (click)="onUpdate()" [disabled]="updating"><ng-container *ngIf="updating">
                <i class="fa fa-sync rotate"></i>&nbsp;
            </ng-container>Update</button>
        </div>
    </div>
</div>
