import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';

import {OrderService} from '../../../services/order.service';
import {IDecisionPointData} from '../../../interfaces/decision-point.data';

@Component({
    selector: 'app-decision-point-modal',
    templateUrl: './decision-point-modal.component.html',
    styleUrls: ['./decision-point-modal.component.scss']
})
export class DecisionPointModalComponent implements OnInit {

    orderDecisionPointData: IDecisionPointData;
    productDecisionPointData: IDecisionPointData;
    storeCount = 0;

    constructor(private orderService: OrderService, private activeModal: NgbActiveModal, private router: Router) {
    }

    ngOnInit(): void {
        this.storeCount = this.productDecisionPointData.orderItemQuantity || this.productDecisionPointData.dpQuantity;
    }

    close(val?: number | boolean) {
        this.activeModal.close(val);
    }

    validateDPQuantity() {
        if (this.productDecisionPointData.availableQty > 0) {
            if (this.storeCount > this.productDecisionPointData.availableQty) {
                this.storeCount = this.productDecisionPointData.availableQty;
            }
        }
    }

    redirectToFAQ() {
        this.close(true);
        this.router.navigate(['/faq']);
        return false;
    }

    get canSubmit(): boolean {
        return this.storeCount > 0;
    }

    submit() {
        this.close(this.storeCount);
    }
}
