import { Directive, HostListener, Provider } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
    selector: 'input[trim]',
    providers: [NgModel],
    host: {
        '(ngModelChange)': 'onInputChange($event)',
        '(blur)': 'onBlur($event)'
    }
})

export class TrimValueAccessor {
    onChange = (_) => { };

    private el: any;
    private newValue: any;

    constructor(private model: NgModel) {
        this.el = model;
    }
    
    onInputChange(event) {
        this.newValue = event;
    }
    
    onBlur(event) {
        if (this.newValue) {
            this.model.control.setValue(this.newValue.trim());
        }
    }
}