<ng-container *ngIf="visible">
    <div class="backdrop" (click)="onClosed()"></div>
    <div class="uppercase mobile__menu" >
        <ng-container *ngIf="canSeeBuyingWindows">
            <div class="menu__header font-weight-bold">{{orderWindowLabel}}
            </div>
            <div *ngIf="activeBWOrder" class="menu__item">
                <span class="price"><app-currency-price [price]="activeBWOrder.totalCost" [currency]="activeBWOrder.currency"></app-currency-price></span>
                <a routerLink="" class="link link-underline font-weight-bold" (click)="navigateToOrder(activeBWOrder)">View Order</a>
            </div>
            <div *ngIf="!activeBWOrder" class="menu__item">
                Order Empty
            </div>
        </ng-container>
        <div class="menu__header  font-weight-bold">{{onDemandLabel}}</div>
        <div *ngIf="activeODOrder" class="menu__item">
            <span class="price"><app-currency-price [price]="activeODOrder.totalCost" [currency]="activeODOrder.currency"></app-currency-price></span>
            <a href="#" class="link link-underline font-weight-bold" (click)="navigateToOrder(activeODOrder)">Checkout</a>
        </div>
        <div *ngIf="!activeODOrder" class="menu__item">
            Order Empty
        </div>
        <div class="menu__header mt-2" (click)="navigateToOrder()">View all open orders</div>
    </div>
</ng-container>

