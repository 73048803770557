import {Component, ViewEncapsulation} from '@angular/core';
import { UserGuide } from '../../../../models/user-guide';
import {getSiblings, isEmptyArray} from '../../../../shared/utils';
import {ConfigService} from '../../../../services/config.service';
import {MobileCheckServiceService} from '../../../../services/mobile-check-service.service';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserGuideComponent {

  private dropdownTimer: any;

  userGuideMenuExpanded = false;

  constructor(private configService: ConfigService,
              private mobileCheck: MobileCheckServiceService) {
  }

  dropdownMouseEnter(event): void {
    clearTimeout(this.dropdownTimer);
    const siblings = getSiblings(event.target);
    for (const el of siblings) {
      el.classList.remove('is-active');
    }
    event.target.classList.add('is-active');
  }

  dropdownMouseLeave(event): void {
    this.dropdownTimer = setTimeout(() => {
      event.target.classList.remove('is-active');
    }, 200);
  }

  get userGuides(): UserGuide[] {
    return this.configService.features.userGuides;
  }

  get isMobile(): boolean {
    return this.mobileCheck.mobile;
  }

  get isDesktop(): boolean {
    return !this.isMobile;
  }

  get hasUserGuides(): boolean {
    return !isEmptyArray(this.userGuides);
  }

  get hasMultipleUSerGuides(): boolean {
    return this.userGuides?.length > 1;
  }
}
