<div class="modal-md">
    <div class="modal-header state">
        <h2 class="modal-title text-truncate full-width text-align-center text-transformation-none" id="modal-label">{{ title }}</h2>
        <button type="button" (click)="close()" class="btn btn-icon close" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body text-align-center">
        <p class="mt-3">You will receive an email notification with a link to your notification page when it is ready.</p>
        <button class="btn btn-outline-primary mt-3" (click)="navigateToNotifications()">GO TO NOTIFICATIONS PAGE</button>
    </div>
</div>
