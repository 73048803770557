import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Order} from '../../../models/order.model';
import {OrderItem} from '../../../models/order-item.model';

@Component({
    selector: 'remove-order-item-modal',
    templateUrl: './remove-order-item-modal.component.html'
})
export class RemoveOrderItemModalComponent {

    order: Order;
    orderItem: OrderItem;

    constructor(public activeModal: NgbActiveModal) {}

    close(result) {
        this.activeModal.close(result)
    }

    delete() {
        this.activeModal.close(this.orderItem);
    }

}
