import {Component, EventEmitter, Output, OnDestroy, OnInit, Input} from '@angular/core';
import { debounceTime, distinctUntilChanged, takeWhile, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { CostCenter } from '../../../models/cost-center.model';
import { PaymentService } from '../../../services/payments.service';
import { PaymentMethod } from '../../../models/payment-method.model';
import { ConfigService } from '../../../services/config.service';
import { AuthService } from '../../../services/auth.service';
import { ModalService } from '../../../services/modal.service';



@Component({
    selector: 'app-sap-selector',
    templateUrl: './sap-selector.component.html',
    styleUrls: ['./sap-selector.component.scss']
})
export class SapSelectorComponent implements OnInit, OnDestroy {

    private alive = true;


    @Output() selectedPaymentMethod = new EventEmitter<PaymentMethod>();

    selectedCostCenter: CostCenter;
    selectedCostCenterId: number;

    // sap search
    sapSearchResults: CostCenter[] = [];
    sapSearchLoading = false;
    sapSearchSubject: Subject<string> = new Subject<string>();
    private sapSearchTerm: string;


    costCenters: CostCenter[];

    @Input() set costCenterId(val: number) {
        if (val) {
            this.selectedCostCenterId = val;
        }
    };

    constructor(private paymentService: PaymentService,
                private config: ConfigService,
                private authService: AuthService,
                private modalService: ModalService) {
    }

    ngOnInit(): void {
        this.costCenters = [...this.paymentService.sapAccounts];
        this.sapSearchResults = this.costCenters;
        this.initSAPSearch();

    }

    ngOnDestroy(): void {
        this.alive = false;
    }


    // add new sap
    addSap() {
        const newCostCenter = new CostCenter({
            company_billing_entity: this.userBillingEntity,
            vendor_number: this.userVendorNumber
        });



        this.modalService.showSapAccountDialog(newCostCenter).then(costCenter => {
            // add new cost center to dropdown
            if (costCenter) {
                this.costCenters.push(costCenter);
                this.sapSearchResults = this.costCenters.slice();

                this.setPaymentMethod(costCenter);

            }
        })
    }



    // init CostCenter dropdown
    private initSAPSearch() {
        this.sapSearchSubject.pipe(
            takeWhile(() => this.alive),
            debounceTime(400),
            distinctUntilChanged(),
            tap(() => {
                this.sapSearchLoading = true;
            }),
        ).subscribe(term => {
            this.sapSearchTerm = term;
            this.sapSearchResults = this.paymentService.findPaymentMethodsByTerm<CostCenter>(term, this.paymentService.sapAccounts);
            this.sapSearchLoading = false;
        });

    }

    onSelectPaymentMethod(paymentMethod: PaymentMethod | any) {
        if (!paymentMethod) {
            return;
        }

        this.setPaymentMethod(paymentMethod);

    }


    private setPaymentMethod(paymentMethod: PaymentMethod) {
        if (!paymentMethod) {
            return;
        }
        if (paymentMethod.isAccount()) {
            this.selectedCostCenter = new CostCenter(paymentMethod as CostCenter);
            this.selectedCostCenterId = this.selectedCostCenter.id;
            this.selectedPaymentMethod.emit(paymentMethod);
        }

    }


    private get userBillingEntity(): string {
        return this.authService.user?.attr_company_billing_entity || '';
    }

    private get userVendorNumber(): string {
        return this.authService.user?.attr_vendor_number || '';
    }

    get canAddPaymentMethod(): boolean {
        return this.authService.canManagePaymentMethods;
    }

}
