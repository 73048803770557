import { Pipe, PipeTransform } from '@angular/core';
import { ProductMedia } from 'app/models/product-media.model';
import {ProductCatalogMedia} from '../models/product-catalog-media.model';

@Pipe({
  name: 'thumbnail'
})

export class ThumbnailPipe implements PipeTransform {
    constructor() {}

    transform(media, args?): string {
        const thumbMedia = media.find(response => response.type === 'THUMB_MEDIA') ||
                           media.find(response => response.type === 'IMAGE_MEDIA');
        return thumbMedia?.url || '';
    }
}
