import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../../services/auth.service';
import {PageService} from '../../../../services/page.service';
import {ECOMMERCE_TYPE, PREORDER_TYPE} from '../../../../constants/order-types';
import {Router} from '@angular/router';
import {MultiTenantService} from '../../../../services/multi-tenant.service';
import {ConfigService} from '../../../../services/config.service';
import { UserGuide } from '../../../../models/user-guide';

@Component({
  selector: 'app-mobile-navigation-menu',
  templateUrl: './mobile-navigation-menu.component.html',
  styleUrls: ['./mobile-navigation-menu.component.scss'],
})
export class MobileNavigationMenuComponent implements  OnInit {
  @Input() visible = false;
  @Output() onBWProductsClicked = new EventEmitter<void>();
  @Output() onBWProgramsClicked = new EventEmitter<void>();
  @Output() onODProductsClicked = new EventEmitter<void>();
  @Output() onODProgramsClicked = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();

  buyingWindowMenuExpanded = false;
  onDemandMenuExpanded = false;
  accountMenuExpanded = false;
  supportMenuExpanded = false;
  supportSubMenuExpanded = false;
  userGuides: UserGuide[];

  constructor(private authService: AuthService,
              private pageService: PageService,
              private router: Router,
              private configService: ConfigService,
              private multiTenantService: MultiTenantService) {
  }


  ngOnInit() {
    this.userGuides = this.configService.features.userGuides;
  }


  bwProductsClicked() {
    this.onBWProductsClicked.emit();
    this.onClosed();
    return false;
  }

  navigateToODQuickOrder() {
    this.pageService.setQuickOrderTab(ECOMMERCE_TYPE);
    this.router.navigate(['/quick-order']);
    this.onClosed();
    return false;
  }

  navigateToBWQuickOrder() {
    this.pageService.setQuickOrderTab(PREORDER_TYPE);
    this.router.navigate(['/quick-order']);
    this.onClosed();
    return false;
  }

  bwProgramsClicked() {
    this.onBWProgramsClicked.emit();
    this.onClosed();
    return false;
  }

  odProductsClicked() {
    this.onODProductsClicked.emit();
    this.onClosed();
    return false;
  }

  odProgramsClicked() {
    this.onODProgramsClicked.emit();
    this.onClosed();
    return false;
  }

  onClosed(): void {
    this.closed.emit();
  }

  toggleBuyingWindowMenu(): void {
    if (this.isUserRestricted) {
      this.buyingWindowMenuExpanded = false;
      return;
    }
    this.buyingWindowMenuExpanded = !this.buyingWindowMenuExpanded;
  }

  toggleOnDemandMenu(): void {
    if (this.isUserRestricted) {
      this.onDemandMenuExpanded = false;
      return;
    }
    this.onDemandMenuExpanded = !this.onDemandMenuExpanded;
  }

  get canOrder(): boolean {
    return this.authService.canOrder;
  }

  get canSeeBuyingWindows(): boolean {
    return this.authService.canBuyingWindow && this.canOrder;
  }
  get canShowTransaction(): boolean {
    return this.authService.canSeeTransactions;
  }

  get canShowPaymentPage(): boolean {
    return this.authService.canSeePaymentMethods && this.configService.showBillingInfo;
  }

  get canShowBudgets(): boolean {
    return this.authService.canSeeBudgets;
  }

  get canShowAutoshipPage(): boolean {
    return this.authService.canAutoship;
  }

  get canSeeOrders(): boolean {
    return this.authService.canSeeOrders;
  }

  get canSeeAddresses(): boolean {
    return this.authService.canSeeAddresses;
  }

  get canSeeRepairRequests(): boolean {
    return this.authService.canSeeRepairRequests;
  }


  get isUserRestricted(): boolean {
    return this.authService.isRestricted;
  }

  get orderWindowLabel(): string {
    return this.multiTenantService.orderWindowLabel;
  }

  get onDemandLabel(): string {
    return this.multiTenantService.onDemandLabel;
  }
}
