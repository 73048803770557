import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, finalize, map, switchMap, take, tap} from 'rxjs/operators';
import {action, computed, observable} from 'mobx';
import { ToastrService } from 'ngx-toastr';
import {Observable, of} from 'rxjs';

import { BaseService } from './base.service';
import { AppSettings } from '../app.settings';
import { Response } from '../models/response.model';
import { Address } from '../models/address.model';
import {isEmptyArray, moveElementInArray, updateItemInArray} from '../shared/utils';
import {AuthService} from './auth.service';
import {WslrAddress} from '../interfaces/wslr-address';
import {flatternSortedAddressesByWslr, getSortedAddressesByWslr, getSortedWslrIds} from '../shared/helpers';
import {API_URL} from '../constants/api-urls';
import {Order} from '../models/order.model';
import {PresetAddress} from '../interfaces/preset-address';

export interface AddressResult {
    is_valid: boolean,
    exists: boolean,
    addresses: Address[],
    savedAddress: Address
}



@Injectable()
export class AddressService extends BaseService {

    @observable userAddresses: Address[] = [];

    @observable sortedAddresses: WslrAddress[] = [];

    @observable updatingAddressId = null;

    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService,
        private authService: AuthService
    ) {
        super('/account/addresses', http, appSettings, toastr);
    }


    /**
     * requested once -  right after user is logged in
     * promise is require to ensure addresses are loaded
     */
    fetchUserAddresses(): Promise<void> {
        return new Promise<void>( (resolve) => {

            if (!this.authService.canSeeAddresses) {
                this.setAddresses([]);
                resolve();
                return;
            }

            this.fetchAddresses().subscribe( addresses => {
                this.setAddresses(addresses);
                resolve();

            });

        });
    }


    private fetchAddresses(): Observable<Address[]> {
        const url = `${API_URL}/account/addresses`;
        return this.http.get<Response>(url).pipe(
            map( response => {
                if (!isEmptyArray(response.data)) {
                    return response.data.map( item => new Address(item));
                }
                return [];
            }),
            catchError(this.handleError('AddressService::get', []))
        )
    }


    // get(userID: number = null): Observable<Address[]> {
    //     let key: string = this.cacheKey;
    //     let url: string = this.apiURL;
    //
    //     // Impersonation
    //     if (userID > 0) {
    //         key += '-' + userID.toString();
    //         url = this.apiURL.replace('account', 'account/user/' + userID);
    //     }
    //
    //     if (this.cache.has(key)) {
    //         return of(this.cache.getItems(key));
    //     }
    //
    //     return this.http.get<Response>(url)
    //         .pipe(
    //             map(response => {
    //                 if (response.data) {
    //
    //                 } else {
    //
    //                 }
    //
    //
    //                 const result = response.data;
    //
    //                 _.each(result, (item, i) => {
    //                     result[i] = new Address(item);
    //                 });
    //
    //                 this.cache.setItems(key, result);
    //
    //                 return result;
    //             }),
    //             catchError(this.handleError('AddressService::get', []))
    //         );
    // }

    @action public setDefault(address: Address, wslrIds: number[] = []): Observable<boolean> {

        address.is_default = true;

        const requestBody = {
            ...address
        };

        if (!isEmptyArray(wslrIds)) {
            requestBody['wslr_ids'] = wslrIds;
        }
        this.updatingAddressId = address.id;
        const url = `${API_URL}/account/addresses/${address.id}`;
        return this.http.put<Response>(url, requestBody,
            {headers: this.xsrfTokenHeader})
        .pipe(
            switchMap( () => this.fetchAddresses()),
            tap((addresses) => {
                if (!isEmptyArray(addresses)) {
                    this.setAddresses(addresses);
                    this.toastr.success('Default address has been changed');
                }
            }),
            catchError(this.handleError('AddressService::setDefault', null)),
            finalize(() => this.updatingAddressId = null)
        );
    }

    save(address: Address): Observable<AddressResult> {


        // always validate address before save
        return this.validate(address).pipe(
            switchMap ( result => {
                if (!result) {
                    // validation failed
                    return of(null);
                }


                if (!result.is_valid) {
                    // address is not valid
                    return of(result);
                }

                // check if returned address is the same
                if (result.addresses.length === 1 && address.isTheSame(result.addresses[0]) !== true) {
                    // returned address is not the same -- show to user
                    return of(result);
                }

                address.is_valid = true;

                // store validated result
                return this.store(address).pipe(
                     map( savedAddress => {
                         result.savedAddress = savedAddress;
                         return result;
                     })
                );
            })
        );
    }

    public store(address: Address): Observable<Address> {
        let request = null;
        if (!address) {
            return of (null);
        }

        // save address
        if (!address.id) {
            request = this.http.post<Response>(this.apiURL, address, {headers: this.xsrfTokenHeader});
        } else {
            const url = `${API_URL}/account/addresses/${address.id}`;
            request = this.http.put<Response>(url, address, {headers: this.xsrfTokenHeader});
        }

        return request.pipe(
            map( (response: Response) => {
                if (response && response.data) {
                    return new Address(response.data);
                } else {
                    return null;
                }
            }),
            catchError(this.handleError('AddressService::save', null)),
            tap( (result: Address) => {
                if (result) {
                    this.setAddresses(updateItemInArray<Address>(this.userAddresses, result));
                    this.toastr.success('Your address has been saved');
                }
            })
        );

    }

    private validate(address: Address): Observable<AddressResult> {
        if (!address) {
            return of(null);
        }

        if (address.is_valid) {
            // it's already valid - no need to validate again
            return of({
                is_valid: true,
                exists: false,
                addresses: [],
                savedAddress: null
            });
        }


        const validatedAddress = {
            country_code: address.country_code,
            state: address.state,
            zip_code: address.zip_code,
            city: address.city,
            street_1: address.street_1,
            street_2: address.street_2 || ''
        }

        // send address id for already exist address
        if (address.id) {
            validatedAddress['id'] = address.id;
        }

        return this.http.post<Response>(`${this.apiURL}/validate`, validatedAddress).pipe(
            map( response => {
                if (response && response.data) {
                    let exists = false;
                    let valid = false;
                    let addresses: Address[] = [];

                    if (response.data.existing_address) {
                        exists = true;
                        addresses = [ new Address({...response.data.existing_address, wslr_ids: address.wslr_ids})];
                    } else {
                        valid = response.data.is_valid;
                        if (!response.data.is_valid && !response.data.addresses.length) {
                            const errorMsg  = response.data.error_message || 'No valid addresses found. Please update and try again.';
                            this.toastr.error(errorMsg);
                        }
                        addresses = Array.isArray(response.data.addresses) ?
                            response.data.addresses.map(a => new Address({...a,
                                label: address.label,
                                is_valid: true,
                                wslr_ids: address.wslr_ids}) ) : [];
                    }


                    return {
                        is_valid: valid,
                        exists,
                        addresses,
                        saved: false
                    }
                }
                return null;
            }),
            catchError(this.handleError('AddressService::save', null))
        );

    }

    async delete(address: Address) {
        const response = await this.http.delete<Response>(this.apiURL + '/' + address.id)
            .pipe(
                catchError(this.handleError('AddressService::delete', null))
            ).toPromise();
        if (response) {
            const addresses  = updateItemInArray<Address>(this.userAddresses, address.id);
            this.setAddresses(addresses);
            this.toastr.success('Your address has been deleted');
        }

    }

    search(term: string, productID?: number): Observable<Address[]> {
        if (!term || term.replace(/ /g, '' ) === '' || term.length < 2) {
            return of([]);
        }
        if (this.cache.has(term + '-' + productID)) {
            return of(this.cache.getItems(term + '-' + productID));
        }

        const params = { search: term };
        if (productID) {
            params['product_id'] = productID;
        }

        const url = `${API_URL}/entities/locations`;
        return this.http.post<Response>(url, params, {headers: this.xsrfTokenHeader})
            .pipe(
                map(response => {
                    let result = [];
                    if (response.data) {
                        result = response.data.map( item => new Address({...item, isWSLR: this.authService.isEmployee}));
                    }

                    this.cache.setItems(term + '-' + productID, result);

                    return result;
                }),
                catchError(this.handleError('AddressService::search', []))
            );
    }

    private sortAddressesByWLSR(addresses: Address[]): WslrAddress[] {
        return  getSortedAddressesByWslr(addresses, this.authService.user.entity_id);
    }

    public sortUserAddresses() {
        this.sortedAddresses = this.sortAddressesByWLSR(this.userAddresses || []);
    }

    @action public setAddresses(addresses: Address[]) {
        this.userAddresses = addresses;
        this.sortedAddresses = this.sortAddressesByWLSR(this.userAddresses);
    }

    // sorted user address - default is always first
    @computed public get addresses(): Address[] {

        return flatternSortedAddressesByWslr([...this.validatedAddressed], this.authService.user.entity_id);

        // return this.validatedAddressed;
        // return this.validatedAddressed.sort( (a: Address, b: Address) => {
        //     if (a.is_default) {
        //         return -1;
        //     }
        //     if (b.is_default) {
        //         return 1;
        //     }
        //     return 0;
        // });
    }

    @computed public get validatedAddressed(): Address[] {
        return this.userAddresses.filter(a => a.is_valid);
    }

    @computed public get wlsrIds(): number[] {
        return getSortedWslrIds(this.userAddresses, this.authService.user.entity_id);
    }




    @action public moveAddress(from: number, to: number, wslrId: number) {

        const wslrAddress = this.sortedAddresses.find( a => a.wslrId === wslrId);
        if (wslrAddress) {

            const fromAddress =  wslrAddress.addresses[from];
            if (!this.authService.canMoveAddress(fromAddress)) {
                return;
            }

            wslrAddress.addresses = moveElementInArray<Address>(wslrAddress.addresses, from, to);
            const sorted = [];
            let ord = 0;
            if (!isEmptyArray(wslrAddress.default)) {
                wslrAddress.default.forEach( (address) => {
                    sorted.push({id: address.id, ord});
                    ord++;
                });
            }
            wslrAddress.addresses.forEach( (address) => {
                sorted.push({id: address.id, ord});
                ord++;
            });

            this.http.post<Response>(`${this.apiURL}/sort`, sorted).subscribe( () => {});

        }
        // this.setAddresses(moveElementInArray<Address>(this.userAddresses, from, to));
    }



    // public getPresetAddresses(order: Order): Address[] {
    //     if (isEmptyArray(this.addresses)) {
    //         return [];
    //     }
    //     if (isEmptyArray(order?.attr_preset_addresses)) {
    //         return [];
    //     }
    //
    //     return this.addresses.filter( a => order.preset_addresses.includes(a.id));
    // }


    private  fetchAddressesByIds(ids: PresetAddress[]): Observable<Address[]> {
        if (isEmptyArray(ids)) {
            return of([]);
        }
        const url = `${API_URL}/account/addresses/for-ids`;
        return this.http.post<Response>(url, ids).pipe(
            map( response => {
                if (!isEmptyArray(response.data)) {
                    return response.data.map( item => new Address({...item, isWSLR: this.authService.isEmployee}));
                }
                return [];
            }),
            catchError(this.handleError('AddressService::fetchAddressesByIds', []))
        )
    }


    public getPresetAddressesForOrder(order: Order): Observable<Address[]> {
        if (!order) {
            return of([]);
        }

        if (isEmptyArray(order.attr_preset_addresses)) {
            return of([]);
        }

        // user addresses don't have entity id
        const userAddressesIds = this.addresses.map( a => a.id);
        const missingAddresses  =  order.attr_preset_addresses.filter( i => {
            if (!!i.entity_id) {
                return true;
            }
            return !userAddressesIds.includes(i.id);
        });

        return this.fetchAddressesByIds(missingAddresses).pipe(
          map( retAddresses => {
            const result: Address[] = [];

            order.attr_preset_addresses.forEach( a => {
                if (!a.entity_id) {
                    // user address use case
                    let address = this.addresses.find( i => i.id === a.id);
                    if (!address) {
                        // look up in returned addresses
                        address = retAddresses.find( i => (i.id === a.id && i.entity_id === undefined));
                    }

                    if (!!address) {
                        result.push(address);
                    }
                } else {
                    // location use case
                    const address = retAddresses.find( i => (i.id === a.id && i.entity_id === a.entity_id));
                    if (!!address) {
                        result.push(address);
                    }
                }
            })

            return result;
          })
        );
    }

}
