<div class="modal-md transaction-result-modal">
    <div class="modal-header state">
        <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label"> Order #{{transactionGroup.totals.order_id}} </h1>
        <button type="button" (click)="close()" class="btn btn-icon close" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
        <div class="fix-transaction--container mt-4">
            <h1 *ngIf="isSuccessful" class="color-green uppercase line-separator--light-grey pb-4 text-align-center">Your Card Was Successfully Charged</h1>
            <h1 *ngIf="!isSuccessful" class="color-red uppercase line-separator--light-grey pb-4  text-align-center">Your Card Was Unable To Be Processed</h1>

            <div class="transaction--info">
                <div class="d-flex mt-4">
                    <label class="label">Transaction #</label>
                    <span class="value">{{transactionGroup.totals.transaction_tag}}</span>
                </div>

                <div  class="d-flex mt-4 pb-4 line-separator--light-grey">
                    <label class="label">Payment Method</label>
                    <span class="value">{{displayCreditCardLabel(transactionGroup.totals.last_four)}}</span>
                </div>


                <div class="d-flex flex-column align-content-center mt-4 text-center">
                    <span *ngIf="isSuccessful" class="uppercase">Total Amount Charged</span>
                    <span *ngIf="!isSuccessful" class="uppercase color-red">Total Amount Attempted</span>
                    <span class="price value mt-2 font-weight-bold" [ngClass]="{'color-red': !isSuccessful}" ><app-currency-price [price]="transactionGroup.totals.amount"></app-currency-price></span>
                </div>

            </div>

            <button  *ngIf="!isSuccessful" class="btn btn-outline-primary d-block mx-auto" (click)="startOver()">
                <span class="uppercase">Start Over</span>
            </button>
    </div>
</div>
