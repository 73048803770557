import { BaseModel } from './base.model';
import { AppDate } from './date.model';
import { Attribute } from './attribute.model';
import { ProductAttribute } from './product-attribute.model';

import * as _ from 'lodash';
import {isEmptyArray} from '../shared/utils';

export class SKU extends BaseModel {
    public id: number;
    public product_id: number;
    public pricing_type: number;
    public weight_per_m: number;
    public cartons_per_m: number;
    public external_id: string;
    public warehouse_id: string;
    public created_at: AppDate;
    public updated_at: AppDate;
    public active: boolean;

    public attrs: Array<Attribute>;

    // derived properties
    public attributes: Array<ProductAttribute>;
    public prices: any;
    public delivery_type: string;
    public default_quantity: number;
    public min_quantity: number;
    public max_quantity: number;
    public available_quantity: number;
    public aggregated_quantity: number;
    public group_aggregated_quantity: number;
    public client_sku_id: string;
    public client_sku_number: string;
    public parent_sku_id: string;
    public pending_quantity: number;
    public in_stock_at: AppDate;
    public urn: string;

    public combined_available_quantity: number;
    public combined_pending_quantity: number;

    // we have to manually create these psuedo properties for the compiler
    public attr_pkg_qty: any;
    public vendor: string;

    // -- short info
    public pkg_qty: string;
    public parent_sku: string;
    public price: number;
    public original_price: number
    public originalPrice: number;

    public group_min_quantity: number;

    public product_price: string;  // this price should be calculated on BE using aggregated quantity

    public supplier_part_id: string;
    public parent_supplier_part_id;

    public variations_sku_number: string;
    public variations_supplier_id: string;

    constructor(data) {

        super(data, {
            num: ['id', 'product_id', 'weight_per_m', 'cartons_per_m', 'default_quantity', 'min_quantity', 'available_quantity', 'aggregated_quantity', 'group_aggregated_quantity',
                'pending_quantity', 'combined_available_quantity', 'combined_pending_quantity', 'pricing_type'],
            bool: ['active'],
            date: ['created_at', 'updated_at', 'in_stock_at']
        }, ['pkg_qty']);

        if (!this.pricing_type) {
            this.pricing_type = 1;
        }

        this.originalPrice = this.findOriginalPrice();

        if (!this.product_price) {
            this.product_price  = '0.00';
        }
    }

    public getPriceTiers() {
        let result = [];
        if (this.hasPricingTiers) {
            for (const [key, index] of Object.entries(this.prices)) {
                /*if (result.length === 1 && result[0].value === this.prices[key]) {
                    continue;
                }*/
                result.push({
                    key: key,
                    value: this.prices[key],
                });
            }

            /*if (result.length > 1) {
                // check if last price tier has the same value as previous
                if (result[result.length - 1].value === result[result.length - 2].value) {
                    // remove last price tier
                    result.pop();
                }
            }*/
        } else {
            // run on range price
            result = [...this.prices];
        }

        return result;
    }

    // public getPrice(quantity: number) {
    //     const itemVolumes = Object.keys(this.prices).map(i => Number(i));
    //     if (quantity) {
    //         const tierQty = _.find(itemVolumes, qty => {
    //             return quantity < qty;
    //         });
    //         if (tierQty) {
    //             return Number(this.prices[tierQty]);
    //         }
    //         // hit more than tiers
    //         return Number(this.prices[_.max(itemVolumes)]);
    //     } else {
    //         return Number(this.prices[itemVolumes[0]]);
    //     }
    // }
    //


    private get priceFromFirstTier(): number {
        if (!this.prices) {
            return 0;
        }

        if (this.hasPricingTiers) {
            const itemVolumes = Object.keys(this.prices).map(i => Number(i));
            if (isEmptyArray(itemVolumes)) {
                return 0;
            }

            return Number(this.prices[itemVolumes[0]]);
        }

        if (isEmptyArray(this.prices)) {
            return 0
        }
        return Number(this.prices[0].pricing);
    }


    public getPrice(): number {
        const price = Number(this.product_price);
        return price ? price : this.priceFromFirstTier;
    }

    // get attribute GROUP_MIN_QTY
    public get groupMinQTY(): number {
        if (this.group_min_quantity > 0) {
            return this.group_min_quantity;
        }

        return this.hasAttr('group_min_qty') ? parseInt(this.getAttrValue('group_min_qty'), 10) : 0;
    }

    public getDisplaySkuID() {
        if (this.variations_sku_number) {
            return this.variations_sku_number;
        }

        return (this.parentSkuID) ? this.removeBWSuffux(this.parentSkuID)
            : this.clientSkuId;
    }

    public get clientSkuId(): string {
        return this.removeBWSuffux(this.client_sku_number);
    }

    // returns pending quantities or 0
    public get pendingQuantity(): number {
        return this.pending_quantity || 0;
    }

    public get availableQuantity(): number {
        return this.available_quantity || 0;
    }

    public get aggregatedQuantity(): number {
        return this.aggregated_quantity > 0 ?  this.aggregated_quantity : 0;
    }

    public get combinedPendingQuantity(): number {
        return this.combined_pending_quantity || 0;
    }

    public get combinedAvailableQuantity(): number {
        return this.combined_available_quantity || 0;
    }

    // returns in stock quantity or 0
    public get inStockQuantity(): number {
        return Math.max(this.availableQuantity - this.pendingQuantity, 0);
    }

    public get inStockCombinedQuantity(): number {
        return Math.max(this.combinedAvailableQuantity - this.combinedPendingQuantity, 0);
    }

    public get inStock() {
        return (this.availableQuantity > 0 || this.combinedAvailableQuantity > 0);
    }

    public get maxQuantity(): number {
        return this.max_quantity || 0;
    }

    public get minQuantity(): number {
        return this.min_quantity || 0;
    }

    // remove -BW suffix in sku client or parent id
    removeBWSuffux(val: string) {
        if (val) {
            const idx = val.indexOf('-BW');
            if (idx > -1) {
                return val.slice(0, idx);
            }
        }
        return val;
    }


    public get sortedAttributes(): ProductAttribute [] {
        if (Array.isArray(this.attributes)) {
            return this.attributes.sort( (a, b) => {
                return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);
            })
        }
        return [];
    }

    public get attributeValues(): Map<string, string> {
        const result = new Map<string, string>();

        this.sortedAttributes.forEach( a => {
            if (a.label && a.options && a.options.length > 0) {
                result.set(a.label, a.options[0].label);
            }
        });

        return result;
    }

    // short info

    public get packageQuantity(): number {
        const  qty = this.pkg_qty || this.attr_pkg_qty;
        if (!qty) {
            return 0 ;
        }
        if (typeof qty === 'string') {
            return parseInt(qty, 10);
        }

        return qty;
    }


    public get parentSkuID(): string {
        return this.parent_sku_id || this.parent_sku;
    }

    private findOriginalPrice(): number {
        if (this.original_price > 0) {
            return this.original_price;
        }

        // get from attrs
        const origPrice = this.getAttrValue('original_price');
        try {
            return origPrice ? parseFloat(origPrice) : 0;
        } catch (e) {
            return 0;
        }
    }


    public get  supplierId(): string {

        const skuId =  this.getDisplaySkuID();
        if (this.variations_supplier_id) {
            return this.variations_supplier_id !== skuId ? this.variations_supplier_id : undefined;
        }

        if (this.supplier_part_id) {
            return this.supplier_part_id !== skuId ? this.supplier_part_id : undefined;
        }

        if (this.parent_supplier_part_id) {
            return this.parent_supplier_part_id !== skuId ? this.parent_supplier_part_id : undefined;
        }

        return this.client_sku_id !== skuId ? this.client_sku_id : undefined;
    }


    public get hasPriceRunOnRange(): boolean {
        return this.pricing_type === 2;
    }

    public get hasPricingTiers(): boolean {
        return this.pricing_type === 1;
    }



}
