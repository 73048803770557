<div class="user-menu">
    <button class="no-caret btn btn-icon" id="user-menu" aria-haspopup="true" aria-expanded="false">
        <i class="fal fa-user"></i>
    </button>

    <div class="nav__utility__dropdown">
        <div class="arrow"></div>
        <div class="column col-12 text-align-center">
            <ul class="nav__utility__dropdown__links">
                <li><a class="link small font-weight-normal" routerLink="/account">Account Information</a></li>
                <li *ngIf="canSeeAddresses"><a class="link small font-weight-normal" routerLink="/account/addresses">Addresses</a></li>
                <li *ngIf="canShowPaymentPage"><a class="link small font-weight-normal" routerLink="/account/payment-methods">Payment Methods</a></li>
                <li *ngIf="canShowTransaction"><a class="link small font-weight-normal" routerLink="/account/transactions">Credit Transactions</a></li>
                <li *ngIf="canShowBudgets"><a class="link small font-weight-normal" routerLink="/account/budgets">Budgets</a></li>
                <li *ngIf="canSeeOrders"><a class="link small font-weight-normal" routerLink="/account/orders">Order History</a></li>
                <li *ngIf="canShowAutoshipPage"><a class="link small font-weight-normal" routerLink="/account/auto-ship">Auto-Ship</a></li>
                <li *ngIf="false"><a class="link small font-weight-normal" routerLink="/account/news">News</a></li>
                <li *ngIf="canSeeRepairRequests"><a class="link small font-weight-normal" routerLink="/account/repair-requests">Repair Request</a></li>
            </ul>
            <button class="btn btn-primary sign-out mt-2" (click)="logoutWarning()">Signout</button>
        </div>
    </div>
</div>
