import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {AppSettings} from '../app.settings';
import { Response } from '../models/response.model';
import {Observable, of} from 'rxjs';
import {catchError, delay, map, switchMap, take} from 'rxjs/operators';
import {findDetailedErrorMessage} from '../shared/helpers';
import {ToastrService} from 'ngx-toastr';
import {ProductOrdersInfo, ProductOrdersInfoItem} from '../interfaces/product-orders-info';
import {PaymentMethodType} from '../enums/payment-method';
import {CostCenter} from '../models/cost-center.model';
import {CreditCard} from '../models/credit-card.model';
import {isEmptyArray} from '../shared/utils';
import {PaymentMethod} from '../models/payment-method.model';
import {API_URL} from '../constants/api-urls';

const BASE_URL = '/account/payment-methods';



@Injectable()
export class ApiPaymentMethodsService {
    readonly apiURL: string;

    constructor(private http: HttpClient, private appSettings: AppSettings, private toastr: ToastrService) {
        this.apiURL = this.appSettings.settings.apiBaseURL + BASE_URL;
    }

    public getPaymentMethods(): Observable<PaymentMethod[]> {
        const url = `${API_URL}${BASE_URL}`;
        return this.http.get<Response>(url)
            .pipe(
                map( response => {
                   if (isEmptyArray(response.data)) {
                       return [];
                   }

                    const paymentsMethods = [];
                    response.data.forEach(i => {
                        if (i.type === PaymentMethodType.CostCenter) {
                            paymentsMethods.push(new CostCenter(i));
                        } else if ( (i as CreditCard)) {
                            const creditCard = new CreditCard(i);
                            if (creditCard.canDisplay) {
                                paymentsMethods.push(creditCard);
                            }
                        }
                    });

                    return paymentsMethods;
                }),
                catchError(this.handleError('PaymentService::fetchUserPaymentMethods', []))
            );
    }


    public updatePayment(payment: PaymentMethod): Observable<PaymentMethod> {
        return this.http.put<Response>(`${this.apiURL}/${payment.id}`, payment, {headers: this.xsrfTokenHeader}).pipe(
            map( response => {
                if (response.data) {
                    if (payment.isCard()) {
                        return  new CreditCard(response.data);
                    }
                    return new CostCenter(response.data);
                }
                return null;
            }),
            catchError(this.handleError('PaymentService::updatePaymentMethod', null))
        );
    }

    public setActivePayment(payment: PaymentMethod): Observable<PaymentMethod> {
        return this.http.put<Response>(`${this.apiURL}/${payment.id}/active`, null).pipe(
            map( response => {
                if (response.data) {
                    if (payment.isCard()) {
                        return  new CreditCard(response.data);
                    }
                    return new CostCenter(response.data);
                }
                return null;
            }),
            catchError(this.handleError('PaymentService::setActivePaymentMethod', null))
        );
    }


    public addPaymentMethod(payment: PaymentMethod): Observable<PaymentMethod>  {
        return this.http.post<Response>(this.apiURL, payment, {headers: this.xsrfTokenHeader}).pipe(
            map( response => {
                if (response.data) {
                    if (payment.isCard()) {
                        return  new CreditCard(response.data);
                    }
                    return new CostCenter(response.data);
                }
                return null;
            }),
            catchError(this.handleError('PaymentService::addPaymentMethod', null))
        );
    }

    public deletePaymentMethod(paymentId: number): Observable<boolean>  {
        return this.http.delete<Response>(`${this.apiURL}/${paymentId}`).pipe(
            map( response => {
                return !response.error;
            }),
            catchError(this.handleError('PaymentService::deletePaymentMethod', false))
        )
    }


    public getPaymentMethod(paymentMethodId: number): Observable<PaymentMethod> {
        return this.http.get<Response>(`${this.apiURL}/${paymentMethodId}`).pipe(
            map( response => {
                if (response.data) {
                    if (response.data.type === PaymentMethodType.CostCenter) {
                        return new CostCenter(response.data);
                    } else {
                        return new CreditCard(response.data);
                    }
                }
                return null;
            }),
            catchError(this.handleError('PaymentService::getPaymentMethod', null))
        );
    }


    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            const errorMessage = findDetailedErrorMessage(error);
            if (errorMessage) {
                this.toastr.error(errorMessage);
                console.log(operation + ' failed', error);
            }
            return of(result as T);
        };
    }


    private get xsrfTokenHeader() {
        return new HttpHeaders({'x-xsrf-token': ''});
    }

}
