<ng-container *ngIf='isQuickBuyProduct'>

  <ng-container  *ngIf="viewType === 'grid-item'; else detailsContainer" >
    <div class="min-max--container" [ngClass]="fontSize">
        <div *ngIf="productHasMinQuantity"  [ngClass]="classes">
          <strong>{{ productMinQuantity }}</strong><span>&nbsp;Minimum Order</span>
        </div>
      <div *ngIf="false && productHasMaxQuantity" [ngClass]="classes">
        <strong>{{ productMaxQuantity }}</strong>
        <span>&nbsp;Maximum Quantity</span>
      </div>
    </div>
  </ng-container>

  <ng-template #detailsContainer>
    <div class="min-max--container vertical" *ngIf="product" [ngClass]="fontSize">
        <div *ngIf="productHasMinQuantity"  [ngClass]="classes">
          <span>Minimum Order Quantity: </span><strong class="font-weight-normal">{{ productMinQuantity }}</strong>
        </div>
      <div *ngIf="false && productHasMaxQuantity" [ngClass]="classes">
        <span>Maximum Order Quantity: </span><strong class="font-weight-normal">{{ productMaxQuantity }}</strong>
      </div>
    </div>
  </ng-template>



</ng-container>
