import { observable, computed, action } from 'mobx';
import { Injectable } from '@angular/core';
import { BRANDS, SearchItem } from '../interfaces/search';

@Injectable()
export class SearchStore {

    @observable globalSearchIsActive = false;
    @observable searchLabel = '';
    searchKey = '';
    @observable maxItemCount: any;

    @observable brands: SearchItem[] = [];
    @observable programs: SearchItem[] = [];
    @observable products: SearchItem[] = [];
    @observable productTypes: SearchItem[] = [];
    @observable customProducts: SearchItem[] = [];

    @observable foundBrands = 0;
    @observable foundPrograms = 0;
    @observable foundProducts = 0;
    @observable foundProductTypes = 0;
    @observable foundCustomProducts = 0;

    @observable offsetBrands = 0;
    @observable offsetProducts = 0;
    @observable offsetPrograms = 0;
    @observable offsetProductTypes = 0;

    @observable selectedCategory = BRANDS;

    @action updateMaxItemCount(data) {
        this.maxItemCount = data;
    }

    @action toggleGlobalSearch() {
        this.globalSearchIsActive = !this.globalSearchIsActive;
        if (!this.globalSearchIsActive) {
            //this.searchLabel = '';
            this.clearSearchResults();
        }
    }

    @action setBrands( items: SearchItem[], found: number) {
        this.brands = items;
        this.foundBrands = found;
    }
    @action setPrograms( items: SearchItem[], found: number) {
        this.programs = items;
        this.foundPrograms = found;
    }
    @action setProductTypes( items: SearchItem[], found: number) {
        this.productTypes = items;
        this.foundProductTypes = found;
    }
    @action setProducts( items: SearchItem[], found: number) {
        this.products = items;
        this.foundProducts = found;
    }

    @action setCustomProducts( items: SearchItem[], found: number) {
        this.customProducts = items;
        this.foundCustomProducts = found;
    }

    @action clearSearchResults() {
        this.brands = [];
        this.products = [];
        this.programs = [];
        this.productTypes = [];

        this.foundBrands = 0;
        this.foundProducts = 0;
        this.foundPrograms = 0;
        this.foundProductTypes = 0;

        this.offsetBrands = 0;
        this.offsetProducts = 0;
        this.offsetPrograms = 0;
        this.offsetProductTypes = 0;
    }

    @computed get isFound() {
        return this.foundProductTypes > 0 || this.foundPrograms > 0 || this.foundProducts > 0 || this.foundBrands > 0;
    }


    @action setSelectedCategory(val: string) {
        this.selectedCategory = val;
    }




}

export const SearchStoreInstance = new SearchStore();
