<div>
    <div class="modal-header state">
        <button type="button" (click)="close(null)" class="btn btn-icon close" aria-label="Close"><i class="fal fa-times" aria-hidden="true"></i></button>
    </div>
    <div class="modal-body">

        <h1 class="pb-4 text-center">ARE YOU SURE YOU WANT TO REMOVE THIS ITEM FROM YOUR CART?</h1>
        <hr class="pb-4"/>

        <div class="d-flex justify-content-between">
            <button (click)="close(null)" class="btn btn-outline-primary" type="button" name="Remove Item">Keep Item In Cart</button>
            <button  class="btn btn-primary" type="button" name="Remove Item" (click)="delete()">Remove Item</button>
        </div>
    </div>
</div>
