<div class="modal-content alert-dialog" *ngIf="data">
    <div class="modal-header {{ data.headerClass }}">
        <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label"><i *ngIf="data.headerIcon" class="fal {{ data.headerIcon }}"></i>{{ data.title }}</h1>
        <button type="button" (click)="close()" class="btn btn-icon close" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body mt-3">
        {{ data.message }}
    </div>
    <div class="modal-footer">
        <button *ngIf="data.type === types.Confirm" type="button" class="btn btn-outline-primary"  (click)="close()">{{ data.cancelLabel }}</button>
        <button type="button" class="btn btn-primary {{ data.actionClass }}"  (click)="handler()">{{ data.actionLabel }}</button>
    </div>
</div>
