import { BaseModel } from './base.model';

export class Response extends BaseModel {
    public error: boolean;
    public error_code: number;
    public message: string;
    public data: any;
    public eTag: string;

    constructor(data) {
        super(data);
    }
}
