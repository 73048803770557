import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {of, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap, takeWhile, tap} from 'rxjs/operators';

import {Address} from '../../../models/address.model';
import {AddressService} from '../../../services/address.service';
import { AuthService } from '../../../services/auth.service';
import { OrderItemDelivery } from '../../../models/order-item-delivery.model';
import { Entity } from '../../../models/entity.model';
import {ModalService} from '../../../services/modal.service';
import {
    computeAvailableAddresses,
    computeShipmentLabel,
    flatternSortedAddressesByWslr,
} from '../../../shared/helpers';
import {isEmptyArray} from '../../../shared/utils';
import {OrderItem} from '../../../models/order-item.model';
import {Order} from '../../../models/order.model';
import {Product} from '../../../models/product.model';
import {MobileCheckServiceService} from '../../../services/mobile-check-service.service';


const DROPDOWN_WIDTH = 300;

@Component({
    selector: 'app-ship-to-address',
    templateUrl: './ship-to-address.component.html',
    styleUrls: ['./ship-to-address.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ShipToAddressComponent implements OnInit, OnDestroy, AfterViewInit {
    // private _selectedAddresses: string[] = [];
    // private searchSubscription: Subscription;
    private accountAddresses: Address[] = [];
    private alive = true;

    addr_id: number;
    addressesSubject: Subject<string> = new Subject<string>();
    addressesLoading = false;
    computedAddressList: Address[] = [];
    itemsTemplateHeader = '';

    private initialized;
    private _orderItem: OrderItem;

    @ViewChild('select', { static: false }) addressSelector;

    @Input() disabled = false;

    @Input() delivery: OrderItemDelivery;

    @Input() isInline = false;

    @Input() className = '';

    _entity: Entity;
    entityLabel: string;

    // output event when address has been selected
    @Output() addressSelected = new EventEmitter<Address>();


    @Input() order: Order;

    @Input() set orderItem(val: OrderItem) {
        this._orderItem = val;
        this.accountAddresses = computeAvailableAddresses(this.addressService.addresses,
            this.order,
            this._orderItem,
            this.isInline ? this.delivery : null);
        this.computeAddressList(this.accountAddresses);
    }

    // // already selected  addresses in other deliveries
    // @Input() set selectedAddresses( val: string[]) {
    //     this._selectedAddresses = val;
    //     this.computeAddressList(this.accountAddresses, false);
    // };
    //
    // @Input() set availableAddresses(val: Address[]) {
    //     this.accountAddresses = (val && val.length) > 0 ? val : this.addressService.addresses;
    //     this.computeAddressList(this.accountAddresses, false);
    // };

    constructor(private addressService: AddressService, private authService: AuthService,
                private modalService: ModalService, private mobileCheck: MobileCheckServiceService) {

    }

    onSelect(data) {
        if (data instanceof Event) {
            return;
        }
        this.addressSelected.emit(data);
    }

    onCloseDropdown() {
        // reset search items to default state
        this.computeAddressList(this.accountAddresses, false);
    }

    computeAddressList(addresses: Address[] = [], isWholesalerAddresses = false) {
        if (this.isEmployee) {
            this.itemsTemplateHeader = isWholesalerAddresses ? 'Wholesalers Addresses' : 'Your Addresses';

        } else {
            this.itemsTemplateHeader = 'Your Addresses';
        }

        // this.computedAddressList = [...addresses];

        this.computedAddressList = flatternSortedAddressesByWslr([...addresses], this.authService.user.entity_id);

        // this.computedAddressList = [...addresses].sort( (a1, a2) => {
        //     // if (a1.is_default) {
        //     //     return -1;
        //     // }
        //     // if (a2.is_default) {
        //     //     return 1;
        //     // }
        //
        //     // sort by wslr
        //     if (a1.entity_id !== a2.entity_id) {
        //         return a1.entity_id < a2.entity_id ? -1 : 1;
        //     }
        //     // in case wslr is the same sort by ord
        //     return a1.ord < a2.ord ? -1 : 1;
        // });

        if (this.delivery) {
            this.addr_id = this.delivery.addr_id;
        } else {
            if (this.computedAddressList.length > 0) {
                this.addr_id = this.computedAddressList[0].id;
            } else {
                this.addr_id = null;
            }
        }
    }

    ngOnInit() {

        if (isEmptyArray(this.accountAddresses)) {
            this.accountAddresses = this.addressService.addresses;
        }

        this.computeAddressList(this.accountAddresses);

        this.addressesSubject.pipe(
            takeWhile( () => this.alive),
            debounceTime(200),
            distinctUntilChanged(),
            tap(() => this.addressesLoading = true),
            switchMap(term => {
                if (!term) {
                    return of(this.accountAddresses);
                }
                if (this.isEmployee) {
                    return this.addressService.search(term, this.productId);
                } else {
                    return of(this.accountAddresses.filter( address => (address.searchKey && address.searchKey.indexOf(term) > -1)));
                }
            })
        ).subscribe( (addresses: Address[]) => {
            this.addressesLoading = false;
            this.computeAddressList(addresses || [], true);
        });

        if (this.delivery) {
            this.addr_id = this.delivery.addr_id;
        }
    }

    ngAfterViewInit() {
        setTimeout(() => this.initialized = true);
    }

    ngOnDestroy(): void {
        this.alive = false;
    }

    get addressLabel() {
        if (!this.delivery) {
            return '';
        }

        return computeShipmentLabel(this.delivery, this.attention);
    }

    getAddressInfo(delivery: OrderItemDelivery): string {
        if (!delivery) {
            return '';
        }

        return `${delivery.street_1}, ${delivery.getCSZ()}`;

    }


    addAddress() {
        this.modalService.editAddress(null, true).then( result => {
            if (result) {
                this.accountAddresses =
                    computeAvailableAddresses( this.addressService.addresses, this.order, this._orderItem, this.delivery);
                this.computeAddressList(this.accountAddresses);
                this.addr_id = result.id;
                this.addressSelected.emit(result);
            }
        })
        this.addressSelector.close();
        return false;

    }

    get product(): Product {
        return this._orderItem?.product;
    }

    get productId(): number {
        return this.product?.id;
    }

    // get shipmentMethods(): any {
    //     if (this.product && this.product.shipping_options) {
    //         return this.product.shipping_options || {};
    //     }
    //
    //     return {};
    // }


    get isEmployee(): boolean {
        return this.authService.isEmployee;
    };

    get isAutoship(): boolean {
        return this.order.isCustom;
    };

    get isDecisionPoint(): boolean {
        return this.order.isDecisionPoint
    };

    get attention(): string {
        return this.order?.attr_attention || '';
    };

    get wslrEntity(): Entity {
        return this.order.isWSLR ? this.order?.entity : null;
    }

    get deliveries(): OrderItemDelivery[] {
        return this._orderItem?.deliveries || [];
    }

    get canAddAddresses(): boolean {
        return this.authService.canAddAddress;
    }

    get mobile(): boolean {
        return this.mobileCheck.mobile;
    }

    get  dropDownClasses(): string {
        if (!this.mobile || !this.initialized) {
            return  `${this.className}`;
        }

        let leftAligned = false;
        if (this.addressSelector ) {
            const rect = this.addressSelector.element.getBoundingClientRect();
            leftAligned = rect.right >= DROPDOWN_WIDTH;
        }

        return `${this.className} ${this.mobile ? 'mobile ' : 0} ${leftAligned ? 'left-aligned' : ''}`;
    }
}
