import {AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';

import { PaymentService } from '../../../services/payments.service';
import { CreditCard } from '../../../models/credit-card.model';
import {OrderService} from '../../../services/order.service';
import {ToastrService} from 'ngx-toastr';
import {IPaymentJsToken} from '../../../interfaces/payment-js.token';
import {Country} from '../../../models/country.model';
import {ConfigService} from '../../../services/config.service';
import {MultiTenantService} from '../../../services/multi-tenant.service';

// pattern="^\d{5}(?:[\-]\d{4})?$" required
const ZIP_PATTERN = /^\d{5}(?:[\-]\d{4})?$/;

/**
 * zip validator only for US
 */
function zipCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

        const countryCode = control.root.value['country'];
        if (!countryCode) {
            return null;
        } else if (countryCode === 'US') {
            if (!control.value) {
                return {required: 'required'};
            } else if (!ZIP_PATTERN.test(control.value)) {
                return {pattern: 'invalid'}
            }
        }
        return null;

    };
}

const config = {
    fields: {
        card: {
            selector: '[data-cc-card]',
            allowedBrands: ['visa', 'mastercard', 'american-express']
        },
        cvv: {
            selector: '[data-cc-cvv]',
        },
        exp: {
            selector: '[data-cc-exp]',
        },
        name: {
            selector: '[data-cardholder-name]',
            placeholder: 'The Name as it Appears on the Card',
        },
    },

    styles: {
        input: {
            'font-size': '18px',
            color: '#000',
            'font-family': '"Gilroy", Helvetica, Arial, sans-serif',
        },
        '.card': {
            'font-family': '"Gilroy", Helvetica, Arial, sans-serif',
        },
        ':focus': {
            // outline: -webkit-focus-ring-color auto 1px;
        },
        '.valid': {
            // color: '#43B02A',
        },
        '.invalid': {
            color: '#000',
        },
        'input:focus' : {
            outline: 'initial'
        },
        'input:-webkit-autofill': {
//            '-webkit-box-shadow': '0 0 0 50px white inset',
        },
        'input:focus:-webkit-autofill': {
//            '-webkit-text-fill-color': '#00a9e0',
        },
        'input.valid:-webkit-autofill': {
//            '-webkit-text-fill-color': '#43B02A',
        },
        'input.invalid:-webkit-autofill': {
            '-webkit-text-fill-color': '#000',
        },
        'input::placeholder': {
//            color: '#aaa',
        },
    },

    classes: {
        empty: 'empty',
        focus: 'focus',
        invalid: 'invalid',
        valid: 'valid',
    },
};


@Component({
    selector: 'app-credit-card-modal',
    templateUrl: './credit-card-modal.component.html',
    styleUrls: ['./credit-card-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CreditCardModalComponent  implements OnInit, OnDestroy, AfterViewInit {

    selectedCountry: string
    card: CreditCard;
    orderId: number;
    token: IPaymentJsToken;
    saveToPaymentMethods = false;

    isSaving = false;

    private paymentJSForm;

    creditCardNumberIsInvalid = false;
    creditCardTypeIsInvalid = false;
    creditCardExpIsInvalid = false;
    creditCardCVVIsInvalid = false;
    creditCardHolderNameIsInvalid = false;


    cardForm = new UntypedFormGroup({
        label: new UntypedFormControl('', [Validators.required]),
        street: new UntypedFormControl('', [Validators.required]),
        city: new UntypedFormControl('', [Validators.required]),
        state: new UntypedFormControl(''),
        zip_code: new UntypedFormControl('', [zipCodeValidator()]),
        country: new UntypedFormControl(''),

    });

    hooks: any;


    constructor(private activeModal: NgbActiveModal,
                private paymentService: PaymentService,
                private configService: ConfigService,
                private orderService: OrderService,
                private toastr: ToastrService,
                private multiTenantService: MultiTenantService,
                ) {}

    ngOnInit(): void {
        this.initPaymentForm();
    }

    ngAfterViewInit() {

    }

    ngOnDestroy(): void {
        // this.removeIFrameListener();
        this.destroyForm();
    }

    onCreate( paymentForm ) {
        this.paymentJSForm = paymentForm;
    }

    hideDialog(result: CreditCard = null) {
        this.activeModal.close(result);
    }



    get isCardValid(): boolean {
        return this.card && this.card.isValid;
    }


    cancel() {
        this.hideDialog();
    }

    selectCountry(event: any) {
        if (event instanceof Country) {
            this.selectedCountry = event.isoCode;
        }
    }

    get countries(): Country[] {
        return this.configService.countries;
    }

    get label() {
        return this.cardForm.get('label');
    }

    get street() {
        return this.cardForm.get('street');
    }

    get city() {
        return this.cardForm.get('city');
    }

    get zip_code() {
        return this.cardForm.get('zip_code');
    }

    get state() {
        return this.cardForm.get('state');
    }

    get country() {
        return this.cardForm.get('country');
    }


    saveCreditCard(e: Event) {
        e.preventDefault();

        if (!this.paymentJSForm) {
            this.toastr.warning('Credit card initialization is failed. Please contact the support.')
        }

        if (!this.selectedCountry) {
            this.selectedCountry = '';
        }

        this.cardForm.markAllAsTouched();

        const cardNumberIsInvalid  = !this.paymentJSForm.state.data.fields.card.validity.valid;
        this.creditCardTypeIsInvalid = false;
        this.creditCardNumberIsInvalid = false;
        if (cardNumberIsInvalid) {
            // check if card type is supported
            const supportedTypes = this.paymentJSForm.state.data.fields.card.config.allowedBrands;
            const type = this.paymentJSForm.state.data.fields.card.validity.brand;
            if (!supportedTypes.includes(type)) {
                this.creditCardTypeIsInvalid = true;
            } else {
                this.creditCardNumberIsInvalid = true;
            }
        }

        // this.creditCardNumberIsInvalid = !this.paymentJSForm.state.data.fields.card.validity.valid;
        this.creditCardHolderNameIsInvalid = !this.paymentJSForm.state.data.fields.name.validity.valid;
        this.creditCardCVVIsInvalid = !this.paymentJSForm.state.data.fields.cvv.validity.valid;
        this.creditCardExpIsInvalid = !this.paymentJSForm.state.data.fields.exp.validity.valid;

        if (this.cardForm.invalid || !this.selectedCountry
            || this.creditCardNumberIsInvalid
            || this.creditCardHolderNameIsInvalid
            || this.creditCardCVVIsInvalid
            || this.creditCardExpIsInvalid
        ) {
            return;
        }

        const onSuccess = () => {

            const cardId = this.card ? this.card.id : null
            this.paymentService.saveCreditCard(cardId, this.orderId, this.label.value, this.saveToPaymentMethods).subscribe(
                (result) => {
                    if (result) {
                        this.hideDialog(result);
                    }
                    this.paymentJSForm.reset(() => {});
                }
            );
            this.isSaving = false;
        };

        const onError = (error) => {
            this.isSaving = false;
            console.log('Tokenize Error: ' + error.message);
            this.toastr.error('Credit card verification has been failed. Please try again or contact the support.');
        };


        this.isSaving = true;
        this.paymentJSForm.onSubmit(onSuccess, onError);
    }


    validationClassName(control: AbstractControl) {
        if (control.touched || control.dirty) {
            return control.invalid ? 'input-danger' : '';
        }
        return '';
    }

    validationLabelStyle(val: string | boolean | AbstractControl) {
        if (typeof val === 'boolean' && val === true) {
           return {'display': 'inline'};
        }

        if (typeof val === 'string' && val === '') {
            return {'visibility': 'inline'};
        }

        if (val instanceof  AbstractControl) {
            if ( (val.touched || val.dirty) && val.invalid) {
                return {'visibility': 'inline'};
            }
        }


        return {'display': 'none'};
    }


    get savingCreditCard(): boolean {
        return this.isSaving || this.paymentService.savingNewCreditCard;
    }


    private initPaymentForm() {
        this.hooks = {
            preFlowHook: (callbackFn) => {
                // values come from authorize-session endpoint
                callbackFn(this.token);
            },

            submitFormHook: (callbackFn) => {
                const address = {
                    address1: this.street.value,
                    city: this.city.value,
                    country: this.selectedCountry,
                    postalCode: this.zip_code.value,
                    region: this.state.value,
                }
                callbackFn(address);
            }
        };

        window['firstdata'].createPaymentForm(config, this.hooks, (paymentForm) => this.onCreate(paymentForm));
    }

    private destroyForm(): void {
        this.paymentJSForm = null;
    }


    get projectLabel(): string {
        return this.multiTenantService.projectLabel;
    }
}

