import {BaseModel} from './base.model';

export class SortItem {
    public name: string
    public label: string;
    public selected: boolean;
    public moduleType: string;
    public intentType: string;

    public direction: string; // direction of sorting  ASC | DESC
    public directionType: string; // type of direction alpha | amount
    public prefix: boolean; // if true  - or + should be used before label

    constructor(data) {
        Object.assign(this, data);
        // super(data, {
        //     bool: ['selected', 'prefix']
        // });
    }

    get id(): string {
        if (this.prefix) {
            return (this.direction === 'ASC' ? '+' : '-') + this.name;
        }
        return this.direction ? `${this.name} ${this.direction}` : this.name;
    }

    get directionDescription(): string {
        if (this.direction && this.directionType === 'date') {
            return this.direction === 'ASC' ? 'Oldest First' : 'Newest First';
        }

        return '';
    }
    public toggleDirection() {
        this.direction = this.direction === 'ASC' ? 'DESC' : 'ASC';
    }
}
