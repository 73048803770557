<div class="logout-modal">
    <div class="modal-header state">
        <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label"></h1>
        <button type="button" (click)="hideDialog()" class="btn btn-icon close" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
        <h1>Are you sure you want to log out?</h1>
        <p>Your changes and orders will be saved for the next time you want to access your account.</p>
    </div>
    <div class="modal-footer">
        <div class="app-modal-footer">
            <button type="button" class="btn btn-warning" (click)="hideDialog(true)">Log Out</button>
            <a (click)="hideDialog()">No, keep me logged in</a>
        </div>
    </div>
</div>
