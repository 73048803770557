import { BaseModel } from './base.model';

export class ProductMedia extends BaseModel {
    public id: number;
    public product_id: number;
    public type: string;
    public url: string;
    public ord: number;

    constructor(data) {
        super(data, {
            num: ['id','product_id','ord']
        });
    }
}
