<ng-container *ngIf="!hasAddresses">
  <div class='empty--container'>
    <a class="link link-underline font-5" (click)="onAddAddressHandler()" title="Add Default Address" href="javascript:void(0)">Add Default Address <i class="fas fa-plus"></i></a>
  </div>
</ng-container>
<ng-container *ngIf="hasAddresses">
  <div class='preset--scroll--container' #scrollContainer>
    <app-preset-address-item *ngFor='let address of addresses; index as i;'
                             [address]='address' [showAddButton]="i === addresses.length - 1"
                             [disabled]="disabled"
                             (anotherAddressAdded)="onAddAddressHandler()"
                             (addressUpdated)="onAddressUpdatedHandler($event,i)"
                             (addressRemoved)="onRemoveAddressHandler(i)"></app-preset-address-item>
  </div>
</ng-container>
