<div class="product-order-item" *ngIf="_orderItem && product && _order && initialized"
     [ngClass]="containerCSSClass">
    <ng-container *ngTemplateOutlet="modal ? modalView: (readonly && mobileDevice) ? mobileReadOnlyView: desktopView">
    </ng-container>
</div>

<ng-template #desktopView>
    <div class="product-order-item__row">
        <div class="image" *ngIf="product.media && viewType === 'list-item'">
            <img  [src]="product.media | thumbnail" [alt]="product.label">
        </div>

        <div>
            <!--header-->
            <app-product-order-item-header [orderItem]="_orderItem"
                                           [order]="_order"
                                           [viewType]="viewType"
                                           [showHideItemButton]="showHideItemButton"
                                           [showReorderButton]="showReorderButton"
                                           [readonly]="readonly" [modal]="modal"
                                           (changedVisibility)="onOrderItemChangeVisibility($event)"
                                           (editted)="onUpdate.emit()" (reorderItem)="reorderItem()">
            </app-product-order-item-header>

            <ng-container *ngTemplateOutlet="mobileDevice ? placeOrderMobileView : placeOrderView"></ng-container>
        </div>

    </div>
</ng-template>

<ng-template #modalView>
    <div class="modal-container">
        <div class="product--container">

            <div class="product-details--mobile">
                <ng-container *ngTemplateOutlet="productDetailsView"></ng-container>
            </div>

            <div class="product-gallery bottom_line">
                <div class="product-gallery--box">
                    <product-gallery [product]="product" [modalView]="true"></product-gallery>
                </div>


            </div>
            <div class="product-description py-3 bottom_line" [innerHTML]="product.content.description | truncate: 300 | safeContent ">
            </div>
        </div>
        <div class="order-item--container">

            <div class="product-details--desktop">
                <ng-container *ngTemplateOutlet="productDetailsView"></ng-container>
            </div>

            <div>
                <ng-container *ngTemplateOutlet="placeOrderView"></ng-container>
            </div>

        </div>
    </div>
    <a *ngIf="!readonly" class="d-block link link-underline small text-align-center my-3" (click)="redirectToFullProduct()" href="javascript:void(0)">View Full Item Details</a>
</ng-template>

<ng-template #productDetailsView>
    <div class="bottom_line pb-2 px-3">
        <h1 class="product-label my-0" [innerHTML]="product.label | safeContent "></h1>
        <div class="mb-2">
            <app-product-item-number [itemNumber]="product.skus[0].getDisplaySkuID()"
                                     [supplierId]="product.skus[0].supplierId"></app-product-item-number>
        </div>
        <div class="product-meta">
            <div>

              <app-product-order-item-min-max *ngIf="showMinOrderInHeader"
                                              [shouldShowAvailableQuantity]="!product.hasVariations"
                                              [isRecommended]="isProductRecommended"
                                              [headerPosition]="true"
                                              [orderItem]="_orderItem"
                                              [viewType]="viewType" fontSize="font-4">
              </app-product-order-item-min-max>
              <app-on-demand-min-max [orderItem]="_orderItem" [viewType]="viewType"></app-on-demand-min-max>

                <app-product-recommened-quantity *ngIf="isProductRecommended" [recommendedQty]="product.recommended_qty_expected" [currentQty]="product.recommended_qty_current"></app-product-recommened-quantity>
                <div *ngIf="!this.readonly && !product.hasVariations && !product.isBuyingWindow" class="availability--container">
                    <app-product-order-item-in-stock [product]="product" [quantity]="_orderItem.quantity"
                                                      [viewType]="viewType"  fontSize="font-4" class="availability--container"></app-product-order-item-in-stock>
                </div>


                <div class="d-flex" *ngIf="product.isCustomizable">
                    <span>Custom Min Required:</span>
                    <span class="font-weight-bold ml-2">{{product.customization.custom_min_qty }}</span>
                </div>

            </div>
            <div class="price--container pr-3">
                <app-product-order-item-price *ngIf="!product.hasVariations" [orderItem]="_orderItem" [ngClass]="'list-item'" [modal]="modal" fontSize="font-3">
                </app-product-order-item-price>
            </div>
        </div>


    </div>

</ng-template>


<ng-template #placeOrderView>
    <div class="order-item__decision-point bottom_line mt-3" *ngIf="!readonly && isDecisionPointOrder && !product.hasVariations && (product.window_id > 0 || !product.inventoryType || product.inStock || _orderItem.deliveries.length > 0)">
        <div *ngIf="dpSummaryData" class="d-flex flex-column align-items-center">
            <button (click)="showDpModal()" type="button" class="btn btn-outline-primary" [disabled]="!canShowDPModal" >
                <span>Update Decision Point Quantity</span>
            </button>
            <p class="text-center mt-2 color--primary-grey font-4">{{ dpSummaryData.eligible_wslr_count }} Wholesalers and {{ dpSummaryData.store_count }} Stores&nbsp;<button class="btn btn-icon" (click)="showDPFaqDialog()"><i class="fas fa-exclamation-circle"></i></button></p>
        </div>
        <div *ngIf="loadingSummaryData">
            <app-infinite-progress [inProgress]="true"></app-infinite-progress>
        </div>
    </div>

    <div class="order-item__decision-point bottom_line" *ngIf="!readonly && isDecisionPointOrder && product.hasVariations">
        <p class="mt-3 color-red">Currently you cannot order by Decision Point for items with sizes or variations. Thank you for your patience.</p>
    </div>


    <div *ngIf="product.hasVariations && canOrder && !showVariations" class="pb-3">
        <button class="btn btn-primary float-right" (click)="redirectToFullProduct()"  type="button" name="update">Product Detail</button>
        <p class="variation-message">This product has variations. Please use the product detail to order this item</p>
    </div>



    <div  *ngIf="!product.hasVariations || showVariations">
        <!-- delivery items -->
        <ng-container *ngIf="delivery && _orderItem.hidden === false">
            <app-product-order-item-delivery [delivery]="delivery"
                                             [product]="product"
                                             [viewType]="viewType"
                                             [order]="_order"
                                             [orderItem]="_orderItem"
                                             [readonly]="readonly"
                                             [showEditControls]="showEditControls"
                                             [totals]="totalCost"
                                             [showTotalInline]="!showTotals"
                                             [isFirstShipment]= "true"
                                             [isLastShipment]="true"
                                             [showTrackingInfo]="showTrackingInfo"
                                             [canRemoveAddress]="!isDecisionPointOrder"
                                             [paidQuantity]="paidQuantity"
                                             (customizationChanged)="onCustomizationChanged()"
                                             (deliverToChanged)="onDeliveryChanged()"
                                             (changed)="onOrderItemChanged($event)"
                                             [refreshingTrackingStatus]="refreshingTrackingStatus"
                                             (refreshStatus)="refreshTrackingStatus()"
            >
            </app-product-order-item-delivery>
        </ng-container>
        <ng-container *ngIf="!delivery &&  _orderItem.hidden === false">
            <app-product-order-item-delivery *ngFor="let delivery of getActualDeliveries(); let i = index"
                                             [delivery]="delivery"
                                             [product]="product"
                                             [viewType]="viewType"
                                             [order]="_order"
                                             [orderItem]="_orderItem"
                                             [readonly]="readonly"
                                             [showEditControls]="showEditControls"
                                             [totals]="totalCost"
                                             [showTotalInline]="!showTotals"
                                             [showTrackingInfo]="showTrackingInfo"
                                             [isFirstShipment]= "i === 0"
                                             [isLastShipment]="getActualDeliveries().length == i + 1"
                                             [canRemoveAddress]="getActualDeliveries().length > 1 && !isDecisionPointOrder"
                                             (customizationChanged)="onCustomizationChanged()"
                                             (changed)="onOrderItemChanged($event)"
                                             (refreshStatus)="refreshTrackingStatus()"
                                             [refreshingTrackingStatus]="refreshingTrackingStatus"
                                             (deliverToChanged)="onDeliveryChanged()"></app-product-order-item-delivery>

        </ng-container>

    </div>

    <ng-container *ngIf="(!readonly && canOrder); else readonlyContainer">
        <!--  add another address-->
        <div class="order-item__add-address"
             *ngIf="canAddAddress">
            <a class="link link-underline" (click)="addAnotherAddress()" href="javascript:void(0)">
                {{addressLabel}} <i class="fas fa-plus"></i>
            </a>
        </div>

        <!-- product variations-->
        <div *ngIf="product.hasVariations && showVariations && !isDecisionPointOrder" class="order-item__variations">
            <div *ngFor="let sku of product.skus" class="align-items-center order-item__variations--item ">
                <div class="col-2 order-item__variations--input">
                    <input min="0" digitOnly type="number" (change)="updateOrderItemVariation(sku)"  [(ngModel)]="_orderItem.getVariationSKU(sku.id).quantity" [disabled]="!canAddQuantity" >
                </div>
                <div class="col-3 order-item__variations--description font-4 font-weight-bold">
                    <ul class="my-0">
                        <li *ngFor="let label of getVariationLabel(sku)">{{label}}</li>
                        <li *ngIf="sku.urn" class="font-weight-normal">URN: {{sku.urn}}</li>
                    </ul>
                </div>
                <div class="col-4 order-item__variations--price text-align-right">
                    <app-product-order-item-in-stock *ngIf="!product.isBuyingWindow"
                            [product]="product" [sku]="sku" fontSize="font-5" viewType="sku-item" class="mb-2"></app-product-order-item-in-stock>
                    <app-product-order-item-min-max *ngIf="showMinOrderInBody(sku)" [orderItem]="_orderItem" [product]="product"
                                                    [showQuantityInSeparateLine]="true"
                                                    [headerPosition]="false"
                                                    [sku]="sku" viewType="detailed" fontSize="font-5">
                    </app-product-order-item-min-max>
                    <app-on-demand-sku-available-qty [orderItem]="_orderItem" [product]="product"

                                                     [sku]="sku" viewType="detailed" fontSize="font-5"></app-on-demand-sku-available-qty>
                </div>
                <div class="col-3 order-item__variations--price" style="margin-left: .5rem">
                    <app-product-order-item-price [sku]="sku" [orderItem]="_orderItem"  fontSize="font-2"></app-product-order-item-price>
                </div>
            </div>
        </div>

        <ng-container *ngIf="product.isCustomizable && product.hasVariations">
            <div class="mt-3 d-flex align-items-center">
                <button (click)="addVariationsCustomization()" [disabled]="!canCustomize" class="btn btn-outline-primary mr-2 ml-3">Customize</button>
                <customization-info [customizations]="variationCustomizations"></customization-info>
                <i *ngIf="!canCustomize && _order.isDecisionPoint" class="fal fa-exclamation-triangle yellow"
                   title="This product can be customized but Decision Point Order does not support it. Please use other order."></i>
            </div>


        </ng-container>




        <!--  total quantity and cost-->
        <div class="order-item__total mt-3" *ngIf="showTotals">
            <div class="col-3 order-item__total--quantity">
                <div>
                    <label class="label">Total Qty</label>
                    <p class="font-weight-bold">{{ _originalOrderItem.quantity }}</p>
                </div>
            </div>
            <div class="col-6">
                <!--    budgets-->
                <ng-container *ngIf="showBudget && budgets && budgets.length > 0 && canSeeBudgets">
                    <label class="label text-left">Budget Available</label>
                    <ng-select class="ng-select--budgets"
                            [items]="budgets"
                            placeholder="Choose a budget"
                            [readonly]="budgets.length === 1"
                            dropdownPosition="bottom"
                            [searchable]="false"
                            [clearable]="false"
                            bindValue="id"
                            (change)="changeBudget($event)"
                            name="budget"
                            [(ngModel)]="selectedBudgetId">
                        <ng-template ng-label-tmp let-item="item">
                            <div  class="font-5" *ngIf="selectedBudget">
                                <div class="font-weight-bold text-left">{{ selectedBudget.label }}</div>
                                <div class="text-left" *ngIf="selectedBudget.id > 0">
                                    <span class="price d-inline font-weight-normal"><app-currency-price [price]="selectedBudget.balance" [currency]="orderCurrency"></app-currency-price></span> (Expires {{ selectedBudget.end_at | dateFormat }})
                                </div>
                            </div>

                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                            <div class="font-6" >
                                <div class="font-weight-bold text-left">{{ item.label }}</div>
                                <div class="text-left"  *ngIf="item.id > 0">
                                    <span class="price d-inline font-weight-normal"><app-currency-price [price]="item.balance" [currency]="orderCurrency"></app-currency-price></span> (Expires {{ item.end_at | dateFormat }})
                                </div>
                            </div>
                        </ng-template>
                    </ng-select>
                    <p *ngIf="isBudgetExhausted" class="font-5 color-red ng-star-inserted text-align-left mt-2">
                        {{ budgets[0].label }} is exhausted. Please alter existing order quantities to add more.
                    </p>
                </ng-container>

            </div>
            <div class="col-3 order-item__total--cost">
                <div class="">
                    <label class="label">Total Cost</label>
                    <span class="font-1 font-weight-bold price"><app-currency-price [price]="_orderItem.totalCost" [currency]="orderCurrency"></app-currency-price></span>
                    <a *ngIf="!readonly" class="link link-underline font-5  font-weight-normal btn-price-details" (click)="viewPriceDetails()" href="javascript:void(0)">View Details</a>
                </div>
            </div>
        </div>

        <!--    add to cart-->
        <div class="order-item__update" *ngIf="!product.hasVariations || showVariations">
            <div *ngIf="product.window_id > 0 && product.in_stock" class="order-item__update--buy-now mt-2">
                <div>
                    <span>Need it sooner?</span>
                    <a class="link underline" routerLink="/on-demand/products/{{ product.in_stock_slug }}">Buy It Now</a>
                </div>
            </div>

            <div class="order-item__update--cta">

                <div class="cta--container" [ngStyle]="{'align-items': showLeaseWarning ? 'flex-start' : 'center'}">
                    <div *ngIf="showLeaseWarning" class="mr-2">
                        <div class="text-center">
                            <p class="p-0 m-0">A Lease Agreement is required to order this item.</p>
                            <a  class="link small link-underline" href="{{ leaseAgreementUrl }}" target="_blank">Click here to Apply</a>
                            <div class="text-center mt-3">
                                <p class="m-0 p-0">Already applied?</p>
                                <a class="link small link-underline" href="#" (click)="checkLeaseStatus()">Check your status
                                    <i class="fal fa-sync rotate ml-2" *ngIf="checkingLease"></i>
                                </a>
                                <ng-container *ngIf="leaseResult">
                                    <p class="m-0 p-0 red" *ngIf="leaseResult !== product.attr_capex_type">A Lease Agreement has not been approved for this product.</p>
                                    <p class="m-0 p-0 green" *ngIf="leaseResult === product.attr_capex_type">A Lease Agreement has been approved for this product.</p>
                                </ng-container>
                            </div>
                        </div>


                    </div>
                    <div *ngIf="purchaseResult.added" class="purchase-result mr-2">Your item was successfully added to your <a class="link link-underline small" (click)="redirectToShoppingCart()" href="javascript:void(0)">shopping cart</a> </div>
                    <div *ngIf="purchaseResult.updated" class="purchase-result mr-2">Your item was successfully updated in your <a class="link link-underline small" (click)="redirectToShoppingCart()" href="javascript:void(0)">shopping cart</a> </div>
                    <div *ngIf="purchaseResult.deleted" class="purchase-result  mr-2">Your item has been removed from your <a class="link link-underline small" (click)="redirectToShoppingCart()" href="javascript:void(0)">Shopping cart</a> </div>

                    <button *ngIf="canOrder"
                            [disabled]="!canPurchase  ||  isNotSameCurrency || orderItemUpdating"
                            (click)="saveWithDelay()"
                            [title]="purchaseButtonTitle"
                            type="button"
                            class="btn btn-primary btn-purchase ml-auto mr-3">
                        <i *ngIf="orderItemUpdating" class="fal fa-sync rotate mr-2"></i>
                        <span>{{_orderItem.id ? 'Update': 'Add To Cart'}}</span>
                    </button>

                </div>


                <div class="mt-3 pr-3">
                    <app-favorite-toggle [product]="product"  [isUpdating]="updatingFavorites" (toggleFavorite)="onToggleFavorites($event)" (close)="onClose()"></app-favorite-toggle>
                </div>

            </div>
        </div>
        <div class="mt-3 text-align-left" *ngIf="canShowProductPurchaseDetails">
            <app-product-orders-details [productId]="product.id" [modal]="modal" (showFull)="redirectToFullProduct()" ></app-product-orders-details>
        </div>
    </ng-container>


    <div *ngIf="cancelled" class="extra-opacity"></div>
    <div *ngIf="cancelled" class="product-order-item--cancelled">Item has been canceled</div>



</ng-template>


<ng-template #placeOrderMobileView>
    <div class="order-item__decision-point bottom_line mt-3" *ngIf="!readonly && isDecisionPointOrder && !product.hasVariations && (product.window_id > 0 || !product.inventoryType || product.inStock || _orderItem.deliveries.length > 0)">
        <div *ngIf="dpSummaryData" class="d-flex flex-column align-items-center">
            <button (click)="showDpModal()" type="button" class="btn btn-outline-primary" [disabled]="!canShowDPModal" >
                <span>Update Decision Point Quantity</span>
            </button>
            <p class="text-center mt-2 color--primary-grey font-4">{{ dpSummaryData.eligible_wslr_count }} Wholesalers and {{ dpSummaryData.store_count }} Stores&nbsp;<button class="btn btn-icon" (click)="showDPFaqDialog()"><i class="fas fa-exclamation-circle"></i></button></p>
        </div>
        <div *ngIf="loadingSummaryData">
            <app-infinite-progress [inProgress]="true"></app-infinite-progress>
        </div>
    </div>

    <div class="order-item__decision-point bottom_line" *ngIf="!readonly && isDecisionPointOrder && product.hasVariations">
        <p class="mt-3 color-red">Currently you cannot order by Decision Point for items with sizes or variations. Thank you for your patience.</p>
    </div>


    <div *ngIf="product.hasVariations && canOrder && !showVariations" class="pb-3">
        <button class="btn btn-primary float-right" (click)="redirectToFullProduct()"  type="button" name="update">Product Detail</button>
        <p class="variation-message">This product has variations. Please use the product detail to order this item</p>
    </div>



    <div  *ngIf="!product.hasVariations || showVariations">
        <!--    delivery items-->
        <ng-container *ngIf="delivery && _orderItem.hidden === false">
            <app-product-order-item-delivery [delivery]="delivery"
                                             [product]="product"
                                             [viewType]="viewType"
                                             [order]="_order"
                                             [orderItem]="_orderItem"
                                             [readonly]="readonly"
                                             [showEditControls]="showEditControls"
                                             [totals]="totalCost"
                                             [showTotalInline]="!showTotals"
                                             [isFirstShipment]= "true"
                                             [isLastShipment]="true"
                                             [showTrackingInfo]="showTrackingInfo"
                                             [canRemoveAddress]="!isDecisionPointOrder"
                                             [paidQuantity]="paidQuantity"
                                             (customizationChanged)="onCustomizationChanged()"
                                             (deliverToChanged)="onDeliveryChanged()"
                                             (changed)="onOrderItemChanged($event)"
                                             [refreshingTrackingStatus]="refreshingTrackingStatus"
                                             (refreshStatus)="refreshTrackingStatus()"
            >
            </app-product-order-item-delivery>

        </ng-container>
        <ng-container *ngIf="!delivery &&  _orderItem.hidden === false">
            <app-product-order-item-delivery *ngFor="let delivery of getActualDeliveries(); let i = index"
                                             [delivery]="delivery"
                                             [product]="product"
                                             [viewType]="viewType"
                                             [order]="_order"
                                             [orderItem]="_orderItem"
                                             [readonly]="readonly"
                                             [showEditControls]="showEditControls"
                                             [totals]="totalCost"
                                             [showTotalInline]="!showTotals"
                                             [showTrackingInfo]="showTrackingInfo"
                                             [isFirstShipment]= "i === 0"
                                             [isLastShipment]="getActualDeliveries().length == i + 1"
                                             [canRemoveAddress]="getActualDeliveries().length > 1 && !isDecisionPointOrder"
                                             (customizationChanged)="onCustomizationChanged()"
                                             (changed)="onOrderItemChanged($event)"
                                             (refreshStatus)="refreshTrackingStatus()"
                                             [refreshingTrackingStatus]="refreshingTrackingStatus"
                                             (deliverToChanged)="onDeliveryChanged()"></app-product-order-item-delivery>

        </ng-container>

    </div>

    <ng-container *ngIf="(!readonly && canOrder); else readonlyContainer">
        <!--  add another address-->
        <div class="order-item__add-address"
             *ngIf="canAddAddress">
            <a class="link link-underline" (click)="addAnotherAddress()" href="javascript:void(0)">
                {{addressLabel}}&nbsp;<i class="fas fa-plus"></i>
            </a>
        </div>

        <!-- product variations-->
        <div *ngIf="product.hasVariations && showVariations && !isDecisionPointOrder" class="order-item__variations full-width">
            <ng-container *ngFor="let sku of product.skus">
                <div class="mt-3 font-weight-bold">
                    <ul class="sku--label my-0">
                        <li *ngFor="let label of getVariationLabel(sku)">{{label}}</li>
                        <li *ngIf="sku.urn" class="font-weight-normal">URN: {{sku.urn}}</li>
                    </ul>
                </div>

                <div  class="align-items-center order-item__variations--item ">
                    <div class="col-4 order-item__variations--input px-0 pr-3">
                        <input min="0" digitOnly type="number" (change)="updateOrderItemVariation(sku)"  [(ngModel)]="_orderItem.getVariationSKU(sku.id).quantity" [disabled]="!canAddQuantity" >
                    </div>
                    <div class="col-4 order-item__variations--price text-align-right  px-0 pr-3">
                        <app-product-order-item-in-stock *ngIf="!product.isBuyingWindow"
                                [product]="product" [sku]="sku" fontSize="font-5" viewType="sku-item" class="mb-2"></app-product-order-item-in-stock>
                        <app-product-order-item-min-max *ngIf="showMinOrderInBody(sku)" [orderItem]="_orderItem" [product]="product"
                                                        [showQuantityInSeparateLine]="true"
                                                        [headerPosition]="false"
                                                        [sku]="sku" viewType="detailed" fontSize="font-5">
                        </app-product-order-item-min-max>
                        <app-on-demand-sku-available-qty  [orderItem]="_orderItem" [product]="product"
                                                        [sku]="sku" viewType="detailed" fontSize="font-5">
                        </app-on-demand-sku-available-qty>

                    </div>
                    <div class="col-4 order-item__variations--price px-0">
                        <app-product-order-item-price [sku]="sku" [orderItem]="_orderItem"  fontSize="font-2"></app-product-order-item-price>
                    </div>
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="product.isCustomizable && product.hasVariations">
            <div class="mt-3 d-flex align-items-center">
                <button (click)="addVariationsCustomization()" [disabled]="!canCustomize" class="btn btn-outline-primary">Customize</button>
                <customization-info [customizations]="variationCustomizations"></customization-info>
                <i *ngIf="!canCustomize && _order.isDecisionPoint" class="fal fa-exclamation-triangle yellow"
                   title="This product can be customized but Decision Point Order does not support it. Please use other order."></i>
            </div>


        </ng-container>




        <!--  total quantity and cost-->
        <div class="order-item__total mt-3" *ngIf="showTotals">
            <div class="col-3 order-item__total--quantity px-0 pr-3">
                <div>
                    <label class="label">Total Qty</label>
                    <p class="font-weight-bold">{{ _originalOrderItem.quantity }}</p>
                </div>
            </div>
            <div class="col-6 px-0 pr-3">
                <!--    budgets-->
                <ng-container *ngIf="showBudget && budgets && budgets.length > 0 && canSeeBudgets">
                    <label class="label text-left">Budget Available</label>
                    <ng-select class="ng-select--budgets"
                               [items]="budgets"
                               placeholder="Choose a budget"
                               [readonly]="budgets.length === 1"
                               dropdownPosition="bottom"
                               [searchable]="false"
                               [clearable]="false"
                               bindValue="id"
                               (change)="changeBudget($event)"
                               name="budget"
                               [(ngModel)]="selectedBudgetId">
                        <ng-template ng-label-tmp let-item="item">
                            <div  class="font-5" *ngIf="selectedBudget">
                                <div class="font-weight-bold text-left">{{ selectedBudget.label }}</div>
                                <div class="text-left" *ngIf="selectedBudget.id > 0">
                                    <span class="price d-inline font-weight-normal"><app-currency-price [price]="selectedBudget.balance" [currency]="orderCurrency"></app-currency-price></span> (Expires {{ selectedBudget.end_at | dateFormat }})
                                </div>
                            </div>

                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                            <div class="font-6" >
                                <div class="font-weight-bold text-left">{{ item.label }}</div>
                                <div class="text-left"  *ngIf="item.id > 0">
                                    <span class="price d-inline font-weight-normal"><app-currency-price [price]="item.balance" [currency]="orderCurrency"></app-currency-price></span> (Expires {{ item.end_at | dateFormat }})
                                </div>
                            </div>
                        </ng-template>
                    </ng-select>
                    <p *ngIf="isBudgetExhausted" class="font-5 color-red ng-star-inserted text-align-left mt-2">
                        {{ budgets[0].label }} is exhausted. Please alter existing order quantities to add more.
                    </p>
                </ng-container>

            </div>
            <div class="col-3 order-item__total--cost px-0">
                <div>
                    <label class="label">Total Cost</label>
                    <span class="font-1 font-weight-bold price"><app-currency-price [price]="_orderItem.totalCost" [currency]="orderCurrency"></app-currency-price></span>
                    <a *ngIf="!readonly" class="link link-underline font-5  font-weight-normal btn-price-details" (click)="viewPriceDetails()" href="javascript:void(0)">View Details</a>
                </div>
            </div>
        </div>

        <!--    add to cart-->
        <div class="order-item__update" *ngIf="!product.hasVariations || showVariations">
            <div *ngIf="product.window_id > 0 && product.in_stock" class="order-item__update--buy-now mt-2">
                <div>
                    <span>Need it sooner?</span>
                    <a class="link underline" routerLink="/on-demand/products/{{ product.in_stock_slug }}">Buy It Now</a>
                </div>
            </div>

            <div class="order-item__update--cta">

                <div *ngIf="showLeaseWarning" class="mt-3">
                    <div class="text-center">
                        <p class="p-0 m-0">A Lease Agreement is required to order this item.</p>
                        <a  class="link small link-underline" href="{{ leaseAgreementUrl }}" target="_blank">Click here to Apply</a>
                        <div class="text-center mt-3">
                            <p class="m-0 p-0">Already applied?</p>
                            <a class="link small link-underline" href="#" (click)="checkLeaseStatus()">Check your status
                                <i class="fal fa-sync rotate ml-2" *ngIf="checkingLease"></i>
                            </a>
                            <ng-container *ngIf="leaseResult">
                                <p class="m-0 p-0 red" *ngIf="leaseResult !== product.attr_capex_type">A Lease Agreement has not been approved for this product.</p>
                                <p class="m-0 p-0 green" *ngIf="leaseResult === product.attr_capex_type">A Lease Agreement has been approved for this product.</p>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="cta--container">
                    <div *ngIf="purchaseResult.added" class="purchase-result mr-2">Your item was successfully added to your <a class="link link-underline small" (click)="redirectToShoppingCart()" href="javascript:void(0)">shopping cart</a> </div>
                    <div *ngIf="purchaseResult.updated" class="purchase-result mr-2">Your item was successfully updated in your <a class="link link-underline small" (click)="redirectToShoppingCart()" href="javascript:void(0)">shopping cart</a> </div>
                    <div *ngIf="purchaseResult.deleted" class="purchase-result  mr-2">Your item has been removed from your <a class="link link-underline small" (click)="redirectToShoppingCart()" href="javascript:void(0)">Shopping cart</a> </div>

                    <button *ngIf="canOrder"
                            [disabled]="!canPurchase || isNotSameCurrency || orderItemUpdating"
                            (click)="saveWithDelay()"
                            [title]="purchaseButtonTitle"
                            type="button"
                            class="btn btn-primary btn-purchase ml-auto mr-0">
                        <i *ngIf="orderItemUpdating" class="fal fa-sync rotate mr-2"></i>
                        <span>{{_orderItem.id ? 'Update': 'Add To Cart'}}</span>
                    </button>

                </div>


                <div class="mt-3 pr-0">
                    <app-favorite-toggle [product]="product"  [isUpdating]="updatingFavorites" (toggleFavorite)="onToggleFavorites($event)" (close)="onClose()"></app-favorite-toggle>
                </div>
            </div>
        </div>

        <div class="mt-3 text-align-left" *ngIf="canShowProductPurchaseDetails">
            <app-product-orders-details [productId]="product.id"></app-product-orders-details>
        </div>
    </ng-container>


    <div *ngIf="cancelled" class="extra-opacity"></div>
    <div *ngIf="cancelled" class="product-order-item--cancelled">Item has been canceled</div>



</ng-template>

<ng-template #readonlyContainer>
    <ng-container *ngIf="canOrder">
        <ng-container *ngTemplateOutlet="mobileDevice ? mobileTotalsContainer : desktopTotalsContainer"></ng-container>
    </ng-container>
</ng-template>

<ng-template #desktopTotalsContainer>
    <div class="mt-3 ml-2" [ngStyle]="{'visibility': hideFinancials ? 'hidden': 'initial'}"  *ngIf="_orderItem.hidden === false">
        <div class="col-12 totals font-weight-normal font-5">

            <div class="totals--cell">
                <div>
                    <label class="text-align-center mb-2">total quantity</label>
                    <span>{{_orderItem.quantity}}</span>
                </div>
            </div>

            <div class="totals--cell">
                <div>
                    <label class="mb-2">subtotal</label>
                    <span class="price font-weight-normal"><app-currency-price [price]="_orderItem.subTotal" [currency]="orderCurrency" [solelySymbol]="true"></app-currency-price></span>
                </div>
            </div>

            <div class="totals--cell">
                <div>
                    <label class="mb-2">Estimated Shipping</label>
                    <span class="price font-weight-normal"><app-currency-price [price]="_orderItem.shippingTotal" [currency]="orderCurrency" [solelySymbol]="true"></app-currency-price></span>
                </div>
            </div>

            <div class="totals--cell">
                <div [ngClass]="{'color-red' : taxExemptOrderHasTaxes}">
                    <label class="mb-2">
                        taxes
                        <i *ngIf="taxExemptOrderHasTaxes" class="fas fa-info-circle" placement="right" tooltipClass="tooltips"
                           ngbTooltip="{{ taxExemptReason }}"></i>
                    </label>
                    <span class="price font-weight-normal"><app-currency-price [price]="taxes" [currency]="orderCurrency" [solelySymbol]="true"></app-currency-price></span>
                </div>
            </div>


            <div class="totals--cell">
                <div  *ngIf="_orderItem.totalDiscount > 0">
                    <label class="mb-2">discount</label>
                    <div class="green">
                        <span>-&nbsp;</span><span class="price d-inline-block font-weight-normal"><app-currency-price [price]="_orderItem.totalDiscount" [currency]="orderCurrency"></app-currency-price></span>
                    </div>
                </div>
            </div>


            <div class="totals--cell">
                <div  *ngIf="_orderItem.budget > 0">
                    <label class="mb-2">budget</label>
                    <div class="green">
                        <span>-&nbsp;</span><span class="price d-inline-block font-weight-normal"><app-currency-price [price]="_orderItem.budget" [currency]="orderCurrency"></app-currency-price></span>
                    </div>
                </div>
            </div>

            <div class="totals--cell">
                <div>
                    <label class="font-weight-bold mb-2 text-center">total cost</label>
                    <span class="price"><app-currency-price [price]="financialTotalCost" [currency]="orderCurrency"></app-currency-price></span>
                </div>
            </div>

        </div>

    </div>
    <div class="col col-lg-3 col-12 order-item--cost px-3">
        <div *ngIf="canDisplayEditControls" class="order-item--controls">
            <ng-container *ngIf="!restricted">
                <button *ngIf="canEditOrder" class="btn btn-outline-primary" (click)="handleEdit()" [disabled] = "_orderItem.product.active !== true">Edit</button>
                <a class="link link-underline small" (click)="onHandleRemove()" href="javascript:void(0)">Remove</a>
            </ng-container>
            <ng-container *ngIf="restricted">
                <button class="btn btn-primary mb-3" (click)="onHandleRemove(true)"
                        [disabled]="removing">
                    <i *ngIf="removing" class="fal fa-sync rotate"></i>&nbsp;
                    Remove</button>
            </ng-container>
        </div>

    </div>

</ng-template>

<ng-template #mobileTotalsContainer>
    <div class="mt-3" [ngStyle]="{'visibility': hideFinancials ? 'hidden': 'initial'}"  *ngIf="_orderItem.hidden === false">
        <div class="totals font-weight-normal font-5">
            <div class="col-2 px-0 text-align-center">
                <label class="d-block text-align-center mb-2">QTY</label>
                <span class="font-weight-bold">{{_orderItem.quantity}}</span>
            </div>
            <div class="col-4 offset-6 px-0 text-align-center">
                <label class="d-block mb-2 uppercase">total cost</label>
                <span class="price font-3"><app-currency-price [price]="financialTotalCost" [currency]="orderCurrency"></app-currency-price></span>
            </div>

        </div>

    </div>
    <div class="col col-lg-3 col-12 order-item--cost px-3">
        <div *ngIf="canDisplayEditControls" class="order-item--controls">
            <ng-container *ngIf="!restricted">
                <button *ngIf="canEditOrder" class="btn btn-outline-primary" (click)="handleEdit()" [disabled] = "_orderItem.product.active !== true">Edit</button>
                <a class="link link-underline small" (click)="onHandleRemove()" href="javascript:void(0)">Remove</a>
            </ng-container>

            <ng-container *ngIf="restricted">
                <button class="btn btn-primary mb-3" (click)="onHandleRemove(true)"
                        [disabled]="removing">
                    <i *ngIf="removing" class="fal fa-sync rotate"></i>&nbsp;
                    Remove</button>
            </ng-container>

        </div>

    </div>

</ng-template>


<ng-template #mobileReadOnlyView>
    <ng-container *ngIf="viewType === 'list-item'; else mobileReadonlyDetailedView">
        <div class="product-order-item__row" >

            <div>
                <div class="image" *ngIf="product.media">
                    <img  [src]="product.media | thumbnail" [alt]="product.label">
                </div>
                <div>
                    <app-product-order-item-price *ngIf="!product.hasVariations" [orderItem]="_orderItem" [ngClass]="'list-item'" [modal]="modal" fontSize="font-3">
                    </app-product-order-item-price>
                </div>
            </div>

            <div>
                <!--header-->
                <app-product-order-item-header [orderItem]="_orderItem"
                                               [order]="_order"
                                               [viewType]="viewType"
                                               [showHideItemButton]="showHideItemButton"
                                               [showReorderButton]="showReorderButton"
                                               [readonly]="readonly" [modal]="modal"
                                               (changedVisibility)="onOrderItemChangeVisibility($event)"
                                               (editted)="onUpdate.emit()" (reorderItem)="reorderItem()">
                </app-product-order-item-header>
            </div>

        </div>

        <div>
            <ng-container *ngTemplateOutlet="mobileDevice ? placeOrderMobileView : placeOrderView"></ng-container>
        </div>
    </ng-container>


</ng-template>


<ng-template #mobileReadonlyDetailedView>
    <div class="d-flex align-items-start justify-content-between">
        <div>
            <app-product-order-item-price *ngIf="!product.hasVariations" [orderItem]="_orderItem" [ngClass]="'list-item'" [modal]="modal" fontSize="font-2">
            </app-product-order-item-price>
        </div>

        <div>
            <!--header-->
            <app-product-order-item-header [orderItem]="_orderItem"
                                           [order]="_order"
                                           [viewType]="viewType"
                                           [showHideItemButton]="showHideItemButton"
                                           [showReorderButton]="showReorderButton"
                                           [readonly]="readonly" [modal]="modal"
                                           (changedVisibility)="onOrderItemChangeVisibility($event)"
                                           (editted)="onUpdate.emit()" (reorderItem)="reorderItem()">
            </app-product-order-item-header>
        </div>

    </div>

    <div>
        <ng-container *ngTemplateOutlet="mobileDevice ? placeOrderMobileView : placeOrderView"></ng-container>
    </div>

</ng-template>
