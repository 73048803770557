import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {TrackingNumber} from '../../../interfaces/tracking-number';
import {normalizeTrackingData} from '../../../shared/utils';

@Component({
    selector: 'app-tracking-data',
    templateUrl: './tracking.component.html',
    styleUrls: ['./tracking.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackingComponent implements OnInit {

    @Input()
    trackingData: any = {};

    trackingNumbers: TrackingNumber[] = [];

    ngOnInit(): void {
        this.trackingNumbers = normalizeTrackingData(this.trackingData);
    }

}
