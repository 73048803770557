import {BaseService} from './base.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {AppSettings} from '../app.settings';
import {ToastrService} from 'ngx-toastr';

import * as _ from 'lodash';
import {Response} from '../models/response.model';
import {CacheModel} from '../models/cache.model';
import {API_URL} from '../constants/api-urls';


function transformKey( value: string): string {
    if (typeof value === 'string') {
        return value.replace(/[^a-z0-9]/gi, '').toUpperCase();
    }
    return value;
}

@Injectable()
export class TranslateService extends BaseService {

    // private cacheKey: string = 'translations';
    private translations: CacheModel;

    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService
    ) {
        super('/config/translations', http, appSettings, toastr);

        this.cache.persistent = true;
        this.cache.prefix = 'Translate';

        this.translations = new CacheModel();
    }

    translate(value: string): string {
        if (!value) {
            return '';
        }

        const key = transformKey(value);
        return  this.translations.get(key) || value;
    }

    // If the language changes, that needs to be sent to the server to 
    // update the user account + session and refetch all the translations
    getAll() {
        // const eTag: string = this.cache.getETag(this.cacheKey);
        // const httpOptions: Object = {headers: new HttpHeaders({'If-Match': eTag})};
        const url = `${API_URL}/config/translations`;
        return this.http.get<Response>(url)
            .pipe(
                map(response => {
                    const result = response.data;

                    // if (result.length === 0 && response.eTag === eTag) {
                    //     result = this.cache.getItems(this.cacheKey);
                    // }
                    //
                    // if (response.eTag !== eTag) {
                    //     this.cache.deleteItems(this.cacheKey);
                    //     this.cache.setItems(this.cacheKey, result);
                    //     this.cache.setETag(this.cacheKey, response.eTag);
                    // }

                    result.forEach ( item => {
                        if (typeof item === 'string') {
                            item = JSON.parse(item);
                        }
                        const key = transformKey(item.key)
                        this.translations.set(key, item.value);
                    })

                    return result;
                }),
                catchError(this.handleError('ConfigService::getNavItems', []))
            );
    }
}
