import { NgModule }                 from '@angular/core';
import { CommonModule }             from '@angular/common';

// Common Application Pipes
import { TranslatePipe }            from './translate.pipe';
import { TruncatePipe }             from './truncate.pipe';
import { DateFormatPipe }           from './dateFormat.pipe';
import { CleanTextPipe }            from './cleanText.pipe';
import { SafeContentPipe }          from './safeContent.pipe';
import { BackorderedDatePipe }      from './backorderedDate.pipe';
import { ThumbnailPipe }          from './thumbnail.pipe';
import {NewLineReplacePipe} from './newLineReplace.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        TranslatePipe,
        TruncatePipe,
        DateFormatPipe,
        CleanTextPipe,
        BackorderedDatePipe,
        SafeContentPipe,
        ThumbnailPipe,
        NewLineReplacePipe
    ],
    exports: [
        TranslatePipe,
        TruncatePipe,
        DateFormatPipe,
        BackorderedDatePipe,
        CleanTextPipe,
        SafeContentPipe,
        ThumbnailPipe,
        NewLineReplacePipe
    ],
    providers: [
        DateFormatPipe
    ]
})
export class PipesModule { }
