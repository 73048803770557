import {BaseModel} from './base.model';
import {EntityLocation} from './entity-location.model';

export class Entity extends BaseModel {
    public id: number;
    public parent_id: number;
    public external_id: string;
    public label: string;
    public type: string;
    public role: number;
    public has_children: boolean;
    public locations: EntityLocation[] = [];
    public allow_tax_exempt: boolean;
    public capex: 'none' | 'alcohol' | 'non-alcohol'

    constructor(data) {
        super(data, {
            num: ['id', 'role'],
            bool: ['has_children', 'allow_tax_exempt']
        });

        if (!this.capex) {
            this.capex = 'none';
        }
    }


    getDefaultShippingLocation() {

        const shippingLocations = this.shippingLocations;
        const defaultLocation = shippingLocations.find( l =>  l.is_default);
        if (defaultLocation) {
            return defaultLocation;
        }

        // if default location does not exist take first one
        if (shippingLocations.length > 0) {
            return shippingLocations[0];
        }
        return null;
    }

    get isTaxExemptAllowed(): boolean {
        return this.allow_tax_exempt  === true;
    }

    get shippingLocations(): EntityLocation[] {
        return this.locations.filter( l => l.type === 'SHIPPING_LOCATION');
    }

    get hasLease(): boolean {
        return this.capex === 'alcohol' || this.capex === 'non-alcohol';
    }

}
