import { BaseModel } from './base.model';
import { AppDate } from './date.model';
import { Entity } from './entity.model';
import { Attribute } from './attribute.model';
import {Address} from './address.model';
import {UserCountry} from "../interfaces/user-country";
import {isEmptyArray} from "../shared/utils";


export class User extends BaseModel {
    public id: number;
    public guid: string;
    public store_id: number;
    public entity_id: number;
    public role: Array<string>;
    public first_name: string;
    public last_name: string;
    public email: string;
    public timezone_id: number;
    public lang: string;
    public sso: boolean;
    public created_at: AppDate;
    public active: boolean;

    public token: string;
    public attrs: Array<Attribute>;
    public timezone: string;
    public timezone_offset: number;
    public hash: string;
    public entity: Entity;
    public avatar: string;
    public current_storage_usage: number;
    public max_storage_usage: number;
    public sso_url: string;
    public address: Address;
    public location: string;
    public external_id: any;
    public terms_approved: boolean;
    public latest_news_approved: boolean;

    // we have to manually create these psuedo properties for the compiler
    public attr_phone_number: any;
    public attr_mobile_number: any;
    public attr_fax_number: any;
    public attr_direct_number: any;
    public attr_website: any;
    public attr_title: any;
    public attr_twitter: any;
    public attr_facebook: any;
    public attr_linkedin: any;
    public attr_region_number: string;

    public allow_tax_exempt: boolean;
    public role_label: string;
    public attr_vendor_number: string;
    public attr_company_billing_entity: string;
    public attr_business_unit: string;

    public countries: UserCountry[];

    public user_types: string[];

    constructor(data) {
        super(data, {
            num: ['id', 'store_id', 'entity_id', 'timezone_id', 'timezone_offset', 'current_storage_usage', 'max_storage_usage'],
            bool: ['sso', 'active', 'terms_approved', 'allow_tax_exempt'],
            date: ['created_at']
        }, ['vendor_number', 'company_billing_entity', 'business_unit', 'phone_number', 'mobile_number',
            'fax_number', 'direct_number', 'website', 'title', 'twitter', 'facebook', 'linkedin', 'region_number']);

        this.entity = new Entity(this.entity);

        // this.setUserRoles();

        if (data?.address) {
            this.address = new Address(data.address);
        }

        if (!isEmptyArray(data?.countries)) {
            this.countries = data.countries.map(c => ({...c}));
        } else {
            this.countries = [];
        }

        this.user_types = data?.user_types || [];
    }

    fullName(): string {
        return `${this.first_name} ${this.last_name}`;
    }

    // onlyRole(role: string): boolean {
    //     return (this.role.length === 1 && this.hasRole(role));
    // }
    //
    // hasRole(role: string): boolean {
    //     return (this.role.indexOf(role) > -1);
    // }

    get isTaxExemptAllowed(): boolean {
        return this.allow_tax_exempt  === true;
    }


    // private setUserRoles() {
    //     if (!this.role) {
    //         return;
    //     }
    //
    //     if (this.onlyRole('LIMITED_USER_ROLE') === true) {
    //         this.isLimited = true;
    //     } else if (this.onlyRole('ORDERING_USER_ROLE') === true) {
    //         this.isWholeSeller = true;
    //     } else if (this.hasRole('ORDER_ADMIN_ROLE') === true) {
    //         this.isEmployee = true;
    //     } else if (this.hasRole('IMP_ORDER_VIEW_ROLE') === true) {
    //         this.isWholeSellerMultiple = true;
    //     }
    // }

}
