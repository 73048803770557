import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

import {AuthService} from './auth.service';


@Injectable()
export class RestrictedRouterGuardService implements CanActivate {


    constructor(private authService: AuthService, private router: Router) {}


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
     boolean {
        if (!this.authService.isRestricted) {
            return true;
        }
        this.router.navigate(['/']);
    }
}
