import {Injectable} from '@angular/core';
import {Settings} from './app.settings.model';

@Injectable()
export class AppSettings {
    public static APP_VERSION = '1.44.0';

    public settings = new Settings(
        'Diageo',
        'Purchase/Ecom Platform',
        '/api',
        '/report-system/',
        {
            menu: 'horizontal', // horizontal , vertical
            menuType: 'default', // default, compact, mini
            showMenu: true,
            navbarIsFixed: true,
            footerIsFixed: false,
            sidebarIsFixed: true,
            showSideChat: false,
            sideChatIsHoverable: true,
            canLockScreen: false,  // SSO users...
            skin: 'combined',  // light , dark, blue, green, combined, purple, orange, brown, grey, pink
        },
        null,
        { fixedHeader: true, singleCreditCard: true},
        null
    );


}
