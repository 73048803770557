<ng-container *ngIf="orderWindow; else noActiveOrderWindow">

    <ng-container *ngIf="orderWindow.active; else historicalMode">
        <div class="header__callout order-window">
            <span>{{orderWindow.label}} &nbsp; | &nbsp; open &nbsp; | &nbsp; {{formattedDates}}</span>
            <!-- <a routerLink="/"><i class="fal fa-exchange"></i> Switch</a> -->
        </div>
    </ng-container>

</ng-container>

<ng-template #noActiveOrderWindow>
    <div *ngIf="statistics?.ow_upcoming" class="header__callout" >
        Countdown To {{ statistics.ow_upcoming.label }} &nbsp; | &nbsp; Opens {{ statistics.ow_upcoming.start_at.format('MMMM D, Y') }}
    </div>
</ng-template>

<ng-template #historicalMode>
    <div class="header__callout order-window inactive">
        <span>viewing historical mode: {{orderWindow.label}} &nbsp; | &nbsp; closed &nbsp; | &nbsp; {{formattedDates}}</span>
        <a (click)="selectActiveWindow()"><i class="far fa-backward"></i> return to active window</a>
    </div>
</ng-template>
