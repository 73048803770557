<!--min order-->
<ng-container  *ngIf="viewType === 'grid-item'; else detailsContainer" >
    <div class="min-max--container" *ngIf="product" [ngClass]="fontSize">
        <ng-container *ngIf="!showAvailableQuantity && !isRecommended">
            <div *ngIf="showMinQuantity" [class.min-order__met]="moqMet" class="min-order__not-met" [ngClass]="classes">
                <strong>{{ totalQuantity }}/{{ product.skus[0].min_quantity }}</strong>
                <span>&nbsp;Min Order Qty</span>
            </div>
            <div *ngIf="groupQuantityVisible" [class.min-order__met]="groupMoqMet" class="group-min-order min-order__not-met" [ngClass]="classes">
                <strong>{{ groupQuantity }}/{{ groupMinQuantity }}</strong>
                <span>&nbsp;{{ groupMinOrderTitle }}</span>
            </div>
        </ng-container>
        <div *ngIf="showAvailableQuantity && shouldShowAvailableQuantity" [class.max-order__met]="maxQtyMet" class="max-order__not-met" [ngClass]="classes">
            <strong>{{ availableQuantity }}</strong>
            <span>&nbsp;Available Quantity</span>
        </div>
    </div>
</ng-container>

<ng-template #detailsContainer>
    <div class="min-max--container vertical" *ngIf="product" [ngClass]="fontSize">
        <ng-container *ngIf="!showAvailableQuantity && !isRecommended">
            <div *ngIf="showMinQuantity" [class.min-order__met]="moqMet" class="min-order__not-met" [ngClass]="classes">
                <span>Minimum Order Quantity: </span><br *ngIf="showQuantityInSeparateLine"/>
                <strong class="font-weight-normal">{{ totalQuantity }}/{{ minQuantity }}</strong>
            </div>
            <div *ngIf="groupQuantityVisible" [class.min-order__met]="groupMoqMet" class="group-min-order min-order__not-met" [ngClass]="classes">
                <span>{{groupMinOrderTitle}}: </span><br *ngIf="showQuantityInSeparateLine"/>
                <strong class="font-weight-normal">{{ groupQuantity }}/{{ groupMinQuantity }}</strong>
            </div>
        </ng-container>
        <div *ngIf="showAvailableQuantity && shouldShowAvailableQuantity" [class.max-order__met]="maxQtyMet" class="max-order__not-met" [ngClass]="classes">
                <span>Available Quantity: </span><br *ngIf="showQuantityInSeparateLine"/>
                <strong class="font-weight-normal">{{ availableQuantity }}</strong>
        </div>
    </div>
</ng-template>
