import {BaseModel} from './base.model';

export class OrderItemSku extends BaseModel {
    public id: number;
    public sku_id: number;
    public quantity: number;
    public price: number;
    public weight: number;
    public cartons: number;
    public init_quantity: number;
    public product_price: string;
    public urn: string;
    // public product_total: string; // price * qty


    constructor(data) {
        super(data, {
            num: ['id', 'sku_id', 'quantity', 'price', 'weight', 'cartons']
        });

        if (!this.quantity || this.quantity < 0) {
            this.quantity = 0;
        }
        this.init_quantity = data.quantity || 0;

        if (!this.product_price) {
            this.product_price = '0.00';
        }
    }

    public get quantityDelta() {
        return Math.max(this.quantity - this.init_quantity, 0);
    }
}
