import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, filter, map, switchMap, take, tap} from 'rxjs/operators';
import {from, Observable, of} from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { BaseService } from './base.service';
import { AppSettings } from '../app.settings';
import { AuthService } from './auth.service';
import { Response } from '../models/response.model';
import { User } from '../models/user.model';
import {NotificationSettings} from '../models/notification-settings.model';
import {ModalService} from './modal.service';
import {ContentService} from './content.service';
import {OrderService} from './order.service';
import {PaymentService} from './payments.service';
import {ProductService} from './product.service';
import {Router} from '@angular/router';
import {ORDERS_DEADLINE} from '../constants/texts';
import {MultiTenantService} from './multi-tenant.service';

@Injectable()
export class ProfileService extends BaseService {

    // public user: User;
    // private cacheKey: string = 'profile';

    public fetchingNotificationPreferences = false;
    public savingNotificationPreferences = false;

    public notificationSettings: NotificationSettings = null;

    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService,
        protected authService: AuthService,
        private modalService: ModalService,
        private contentService: ContentService,
        private orderService: OrderService,
        private paymentService: PaymentService,
        private productService: ProductService,
        private router: Router,
        private multiTenantService: MultiTenantService
    ) {
        super('/account/profile', http, appSettings, toastr);
    }

    // current user should be taken from auth service
    get(userID: number = null): Observable<User> {
        let url: string = this.apiURL;

        // Impersonation
        if (userID > 0) {
            url = this.apiURL.replace('account', 'account/user/' + userID);
        }

        // if (!userID && this.user) {
        //     return of(this.user);
        // }
        // if (userID > 0 && this.cache.has(key)) {
        //     return of(new User(this.cache.get(key)));
        // }
        //
        return this.http.get<Response>(url)
            .pipe(
                map(response => {
                    const result = response.data;
                    if (userID > 0) {
                        return new User(result);
                    }
                    return null;
                }),
                catchError(this.handleError('ProfileService::get', null))
            );
    }

    save(userData: any): Observable<User> {
        const url: string = this.apiURL;

        return this.http.put<Response>(url, userData, {headers: this.xsrfTokenHeader})
            .pipe(
                map(response => {
                    if (response.data) {
                        return new User(response.data);
                    }

                    return null;
                }),
                catchError(this.handleError('ProfileService::save', null)),
                tap( newUser => {
                    this.updateUser(newUser)
                })
            );
    }



    // email-preferences
    public getNotificationSettings(): Observable<NotificationSettings> {

        if (this.notificationSettings) {
            return of(this.notificationSettings);
        }

        if (this.fetchingNotificationPreferences) {
            return of(null);
        }

        this.fetchingNotificationPreferences = true;

        const url = this.apiURL.replace('profile', 'email-preferences');
        return this.http.get<Response>(url)
            .pipe(
                map(response => {
                    const result =  response.data ? new NotificationSettings(response.data) : null;
                    this.setNotificationSetting(result);
                    return result;
                }),
                catchError(this.handleError('ProfileService::getNotificationPreferences', null)),
                tap( () => this.fetchingNotificationPreferences = false)
            );


    }


    public saveNotificationNotificationSettings( val: NotificationSettings): Observable<NotificationSettings> {

        if (!val) {
            return of(null);
        }

        if (this.savingNotificationPreferences) {
            return of(null);
        }
        this.savingNotificationPreferences = true;
        const url = this.apiURL.replace('profile', 'email-preferences');
        return this.http.post<Response>(url, val)
            .pipe(
                map(response => {
                    const notification = response.data ? new NotificationSettings(response.data) : null;
                    this.setNotificationSetting(notification);

                    return notification;
                }),
                tap(  notification => {
                    if (notification) {
                        this.toastr.success('Notification Settings have been updated');
                    }
                }),
                catchError(this.handleError('ProfileService::saveNotificationNotificationSettings', null)),
                tap( () => this.savingNotificationPreferences = false)
            );


    }

    private setNotificationSetting(val: NotificationSettings) {
        this.notificationSettings = val;
    }

    private updateUser(user: User) {
        if (user) {
            this.authService.user = user;
        }
    }


    public showTermsAndConditions(withAcceptance = false) {
        if (withAcceptance && this.authService.user.terms_approved) {
            // already approved;
            return;
        }

        this.contentService.getTerms().pipe(
            switchMap( terms => {
                return from(this.modalService.showTermsAndConditions(terms, withAcceptance));
            })
        ).subscribe( (result) => {
            if (result) {
                if (result.accept) {
                    // accept terms and conditions
                    this.acceptTerms();
                } else if (result.logout) {
                    // logout
                    this.logout();
                }
            }
        });

    }

    public showLatestNews() {
        if (this.authService.user.latest_news_approved) {
            return;
        }

        this.contentService.getLatestNews().pipe(
            filter( content => !!content),
            switchMap(  content => {
                return this.modalService.showLatestNews(content);
            })
        ).subscribe(() => {
            this.acceptLatestNews();
        });
    }

    // public showOrderDeadline() {
    //     if (this.multiTenantService.isRemy) {
    //         return;
    //     }
    //     const currentDate = new Date();
    //
    //     // end of year  date
    //     const endOfYear = new Date(currentDate.getFullYear(), 11, 31);
    //
    //     if ( currentDate.getTime() < endOfYear.getTime() ) {
    //         this.modalService.showLatestNews(ORDERS_DEADLINE, 'md').subscribe( () => {});
    //     }
    // }


    public showLogout() {
        this.modalService.showLogout().then( result => {
            if (result) {
                this.logout();
            }
        });
    }

    private logout() {
        this.authService.logout().subscribe((result) => {
            // reset order service and products caches
            if (result) {
                this.orderService.reset();
                this.productService.reset();
                this.paymentService.reset();

                this.router.navigate(['/login']);
            }
        });
    }


    private acceptTerms() {
        const url = this.apiURL.replace('profile', 'accept-terms');
        this.http.post<Response>(url, {})
        .pipe(
            catchError(this.handleError('ProfileService::acceptTerms', false))
        ).subscribe( result => {
            if (result) {
                this.authService.user.terms_approved = true;
            }
        });
    }

    private acceptLatestNews() {
        const url = this.apiURL.replace('profile', 'accept-latest-news');
        this.http.post<Response>(url, {})
            .pipe(
                catchError(this.handleError('ProfileService::acceptLatestNews', false))
            ).subscribe( result => {
            if (result) {
                this.authService.user.latest_news_approved = true;
            }
        });
    }

}
