import { Injectable }                                                           from '@angular/core';
import { Router }                                                               from '@angular/router';
import { Observable,ObservableInput }                                           from 'rxjs';
import { catchError, map }                                                      from 'rxjs/operators';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse }   from '@angular/common/http';
import { Errors }                                                               from '../models/errors.model';
import { Response }                                                             from '../models/response.model';
import {LocalStorageService, LOGIN_ERROR, REDIRECT_POST_LOGIN, U_SSO, U_SSO_URL, U_TOKEN} from '../services/local-storage.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private router: Router, private localStorageService: LocalStorageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                map((response: any) => {
                    if (response instanceof HttpResponse) {
                        if (response.body.error) {

                            // check for auth errors, redirect
                            switch (response.body.code) {

                                // use codes from a static model
                                case Errors.NOT_AUTHENTICATED:
                                    this.localStorageService.setItem(REDIRECT_POST_LOGIN, window.location.pathname);
                                    this.localStorageService.setItem(LOGIN_ERROR, response.body.message);
                                    this.localStorageService.removeItem(U_TOKEN);

                                    if (this.localStorageService.getItem(U_SSO)) {
                                        this.localStorageService.removeItem(U_SSO);
                                        document.cookie = 'sso_user_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                                        console.log('Redirect to external SSO removed.');
                                        this.router.navigate(['/login']);
                                        return;
                                    }

                                    if (window.location.pathname !== '/password-reset' &&
                                        window.location.pathname !== '/not-supported') {
                                        this.router.navigate(['/login']);
                                    }

                                    break;

                                case Errors.SSO_USER_REDIRECT:
                                    const url = this.localStorageService.getItem(U_SSO_URL);

                                    if (!url) {
                                        this.router.navigate(['/errors/sso-gateway']);
                                        return;
                                    }

                                    window.location.href = url;
                                    break;

                                default:
                                    //! we want the caller to handle non-specified errors...
                            }
                        }
                    }

                    return response;
                })
            );
    }
}
