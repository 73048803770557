<ng-container *ngIf="orderItem">
    <ng-container *ngTemplateOutlet="mobileDevice ?  mobileView : desktopView"></ng-container>
</ng-container>

<ng-template #desktopView>
    <ng-container *ngTemplateOutlet="viewType === 'list-item' ? listItemView : detailedItemView"></ng-container>
</ng-template>

<ng-template #listItemView>
    <div class="product-order-item--header" [ngClass]="classes">
        <div class="header--row">

            <div class="description">
                <div class="description__body d-flex flex-column full-width">

                    <a  href="#" (click)="showProductDetails()"  class="link">
                        <h1 class="product-label" [innerHTML]="product.label | safeContent "></h1>
                    </a>
                    <div class="d-block font-5 font-weight-normal mb-2">
                        <app-product-item-number [itemNumber]="product.skus[0].getDisplaySkuID()"
                                                 [supplierId]="product.skus[0].supplierId"></app-product-item-number>
                        <ng-container *ngIf="!product.hasVariations">
                            <ng-container *ngIf="product.skus[0]?.urn as urn">URN: {{urn}}</ng-container>
                        </ng-container>
                    </div>
                    <app-product-recommened-quantity
                            *ngIf="isProductRecommended"
                            [currentQty]="product.recommended_qty_current"
                            [recommendedQty]="product.recommended_qty_expected"></app-product-recommened-quantity>
                    <div class="d-flex align-items-baseline">
                        <div *ngIf="readonly && !showHideItemButton">
                            <span *ngIf="orderItem.shipment_timing" class="font-weight-bold mr-3">{{ orderItem.shipment_timing }}&nbsp;</span>
                            <div *ngIf="orderItem.backorderedMessage && orderItem.estimatedDates" class=" mr-3">
                                <span class="font-weight-bold">{{orderItem.backorderedMessage}} </span>{{ orderItem.estimatedDates }}
                            </div>
                            <div *ngIf="orderItem.expected_ship_at" class="mr-3">
                              <span class="font-weight-bold">Shipment Update: </span> Estimated Ship Date is {{ orderItem.expected_ship_at | dateFormat: 'MMM D, YYYY'}}
                            </div>
                        </div>

<!--                        <app-vendor-modal [vendorLabel]="orderItem.vendor" class="description__modal"-->
<!--                                          *ngIf="canShowVendor"></app-vendor-modal>-->

                    </div>
                    <div *ngIf="product.isApprovalRequired" class="mb-2">
                        {{productRequiresApprovalMessage}}
                    </div>


                    <ng-container *ngIf="showHideItemButton ">

                        <div class="d-flex align-items-center">
                            <div class="mr-4" *ngIf="showProductPrice">
                                <app-product-order-item-price
                                                              [orderItem]="orderItem"
                                                              viewType="grid-item"
                                                              [ngClass]="viewType"
                                                              fontSize="font-2">
                                </app-product-order-item-price>
                            </div>
                            <ng-container *ngIf="showReorderButton">
                                <div class="" *ngIf="orderItem.product.replacedProductId">
                                    <a class="link link-underline" (click)="showProduct(orderItem.product)" href="javascript:void(0)">
                                        Order Replacement <i class="fas fa-bolt"></i>
                                    </a>
                                </div>
                                <div class="" *ngIf="!orderItem.product.replacedProductId && order.isOnDemand && !orderItem.isCancelled">
                                    <a class="link link-underline" *ngIf="orderItem.product.productActive"
                                       (click)="reorderItem.emit()" href="javascript:void(0)">Reorder Item <i class="fas fa-redo"></i></a>
                                    <span class="color-gray" *ngIf="!orderItem.product.productActive">This Item cannot be reordered</span>

                                </div>
                            </ng-container>
                            <div *ngIf="readonly">
                                <span *ngIf="orderItem.shipment_timing" class="font-weight-bold">{{ orderItem.shipment_timing }}&nbsp;</span>
                                <div *ngIf="orderItem.backorderedMessage && orderItem.estimatedDates" class=" ml-3">
                                    <span class="font-weight-bold uppercase">{{orderItem.backorderedMessage}} </span>{{ orderItem.estimatedDates }}
                                </div>
                                <div *ngIf="orderItem.expected_ship_at" class="ml-3">
                                    <span class="font-weight-bold uppercase">Shipment Update: </span> Estimated Ship Date is {{ orderItem.expected_ship_at | dateFormat: 'MMM D, YYYY'}}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <ng-container *ngIf="!showHideItemButton">
                <!--in stock-->
                <app-product-order-item-in-stock *ngIf="showInStock && !product.hasVariations && !product.isBuyingWindow"
                                                 [product]="product" [quantity]="orderItem.quantity"
                                                 [viewType]="viewType"  fontSize="font-4" label="Availability:" class="availability--container"></app-product-order-item-in-stock>


                <!--min order-->
                <app-product-order-item-min-max *ngIf="showMinOrder"
                                                [shouldShowAvailableQuantity]="!product.hasVariations"
                                                [isRecommended]="isProductRecommended"
                                                [headerPosition] = true
                                                [orderItem]="orderItem" [viewType]="viewType"
                                                class="mr-3"></app-product-order-item-min-max>
              <app-on-demand-min-max [orderItem]="orderItem" [viewType]="viewType"
                                              class="mr-3"></app-on-demand-min-max>

                <!--price-->
                <app-product-order-item-price *ngIf="showProductPrice" [orderItem]="orderItem" [ngClass]="viewType" fontSize="font-2">
                </app-product-order-item-price>
            </ng-container>

            <div *ngIf="showHideItemButton">
                <button class="btn btn-icon"
                        (click)="toggleItemVisibility()"
                        [title]="orderItem.hidden ? 'Show Details' : 'Hide Details'">
                    <img [src]="orderItem.hidden ? '/assets/img/icons/plus-circle.svg' : '/assets/img/icons/minus-circle.svg'">
                </button>
            </div>
        </div>
        <div class="text-center" *ngIf="isProductRestricted">
            <p class="color--alert">{{productRestrictedMessage}}</p>
        </div>
    </div>
</ng-template>

<ng-template #detailedItemView>
    <div class="product-order-item--header" [ngClass]="classes">
        <div class="px-3">
            <div class="header--row">
                <h1 class="product-label color--primary-blue my-0" [innerHTML]="product.label | safeContent "></h1>
                <div class="mb-2">
                    <app-product-item-number [itemNumber]="product.skus[0].getDisplaySkuID()"
                                             [supplierId]="product.skus[0].supplierId"></app-product-item-number>
                    <ng-container *ngIf="!product.hasVariations">
                        <ng-container *ngIf="product.skus[0]?.urn as urn">URN: {{urn}}</ng-container>
                    </ng-container>
                </div>

                <div class="product--details mb-1" [ngClass]="{'pb-3' : product.hasVariations}">
                    <div class="d-flex flex-column">
                        <app-product-recommened-quantity *ngIf="isProductRecommended"
                                [recommendedQty]="product.recommended_qty_expected"
                                [currentQty]="product.recommended_qty_current"></app-product-recommened-quantity>

                        <app-product-order-item-min-max *ngIf="showMinOrder"
                                                        [shouldShowAvailableQuantity]="!product.hasVariations"
                                                        [orderItem]="orderItem"
                                                        [headerPosition]="true"
                                                        [isRecommended]="isProductRecommended" [viewType]="viewType" fontSize="font-4">
                        </app-product-order-item-min-max>
                        <app-on-demand-min-max [orderItem]="orderItem"
                                               [viewType]="viewType" fontSize="font-4"></app-on-demand-min-max>

                        <app-product-order-item-in-stock  *ngIf="showInStock && !product.hasVariations && !product.isBuyingWindow"
                                                          [product]="product" [quantity]="orderItem.quantity"
                                                          [viewType]="viewType"  fontSize="font-4" label="Availability:" class="availability--container"></app-product-order-item-in-stock>

                        <div *ngIf="product.isApprovalRequired" class="mb-2">
                            {{productRequiresApprovalMessage}}
                        </div>

                        <app-vendor-modal [vendorLabel]="orderItem.vendor" class="description__modal"
                                          *ngIf="canShowVendor"></app-vendor-modal>

                        <div class="header--row subheader justify-content-start" *ngIf="product.isCustomizable">
                            <span>Custom Min Required:</span>
                            <span class="font-weight-bold ml-2">{{product.customization.custom_min_qty }}</span>
                        </div>

                        <ng-container *ngIf="showReorderButton && readonly">
                            <div class="" *ngIf="orderItem.product.replacedProductId">
                                <a class="link link-underline" (click)="showProduct(orderItem.product)" href="javascript:void(0)">
                                    Order Replacement <i class="fas fa-bolt"></i>
                                </a>
                            </div>
                            <div class="" *ngIf="!orderItem.product.replacedProductId && order.isOnDemand && !orderItem.isCancelled">
                                <a class="link link-underline" *ngIf="orderItem.product.productActive"
                                   (click)="reorderItem.emit()" href="javascript:void(0)">Reorder Item <i class="fas fa-redo"></i></a>
                                <span class="color-gray" *ngIf="!orderItem.product.productActive">This Item cannot be reordered</span>

                            </div>
                        </ng-container>
                    </div>
                    <div class="description__product-price">
                        <app-product-order-item-price *ngIf="showProductPrice" [orderItem]="orderItem" [viewType]="viewType"
                                                      fontSize="font-2" class="pr-3">
                        </app-product-order-item-price>
                    </div>
                </div>
            </div>


        </div>
    </div>
</ng-template>

<ng-template #mobileView>
    <ng-container *ngTemplateOutlet="readonly ? mobileReadOnlyView : mobileDetailedView"></ng-container>
</ng-template>



<ng-template #mobileDetailedView>
    <div class="product-order-item--header" [ngClass]="classes">
        <div>
            <div class="header--row">
                <div class="product--details" [ngClass]="{'pb-3' : product.hasVariations}">
                    <div class="d-flex flex-column">
                        <div>
                            <app-product-item-number [itemNumber]="product.skus[0].getDisplaySkuID()"
                                                     [supplierId]="product.skus[0].supplierId"></app-product-item-number>
                        </div>
                        <app-product-recommened-quantity *ngIf="isProductRecommended" [recommendedQty]="product.recommended_qty_expected" [currentQty]="product.recommended_qty_current"></app-product-recommened-quantity>
                        <app-product-order-item-min-max *ngIf="showMinOrder" [orderItem]="orderItem"
                                                        [shouldShowAvailableQuantity]="!product.hasVariations"
                                                        [headerPosition]="true"
                                                        [isRecommended]="isProductRecommended" [viewType]="viewType" fontSize="font-4">
                        </app-product-order-item-min-max>
                      <app-on-demand-min-max  [orderItem]="orderItem" [viewType]="viewType" fontSize="font-4"></app-on-demand-min-max>

                        <app-product-order-item-in-stock  *ngIf="showInStock && !product.hasVariations && !product.isBuyingWindow"
                                                          [product]="product" [quantity]="orderItem.quantity"
                                                          [viewType]="viewType"  fontSize="font-4" label="Availability:" class="availability--container"></app-product-order-item-in-stock>

                        <div *ngIf="product.isApprovalRequired" class="mb-2">
                            {{productRequiresApprovalMessage}}
                        </div>

                        <app-vendor-modal [vendorLabel]="orderItem.vendor" class="description__modal"
                                          *ngIf="canShowVendor"></app-vendor-modal>

                        <div class="header--row subheader justify-content-start" *ngIf="product.isCustomizable">
                            <span>Custom Min Required:</span>
                            <span class="font-weight-bold ml-2">{{product.customization.custom_min_qty }}</span>
                        </div>

                    </div>
                    <div class="description__product-price">
                        <app-product-order-item-price *ngIf="showProductPrice" [orderItem]="orderItem" [viewType]="viewType"
                                                      fontSize="font-2" class="pr-3">
                        </app-product-order-item-price>
                    </div>
                </div>
            </div>


        </div>
    </div>
</ng-template>


<ng-template #mobileReadOnlyView>
    <div class="product-order-item--header p-0 ml-3 list-item" *ngIf="viewType === 'list-item'; else mobileReaonlyDetaileView">
        <div class="header--row" style="max-width: calc(100% - 55px)">

            <div class="description full-width" >
                <div class="description__body d-flex flex-column full-width">

                    <a  href="#" class="link"  (click)="showProductDetails()">
                        <h1 class="product-label text-hidden-ellipsis" [innerHTML]="product.label | safeContent "></h1>
                    </a>
                    <div class="d-block mb-2 font-5 font-weight-normal mb-2">
                        <app-product-item-number [itemNumber]="product.skus[0].getDisplaySkuID()"
                                                 [supplierId]="product.skus[0].supplierId"></app-product-item-number>
                    </div>

                    <div>
                        <span *ngIf="orderItem.shipment_timing" class="font-weight-bold mt-3">{{ orderItem.shipment_timing }}&nbsp;</span>
                        <div *ngIf="orderItem.backorderedMessage && orderItem.estimatedDates" class="mt-3">
                            <span class="font-weight-bold uppercase">{{orderItem.backorderedMessage}} </span>{{ orderItem.estimatedDates }}
                        </div>
                        <div *ngIf="orderItem.expected_ship_at" class="mt-3">
                            <span class="font-weight-bold uppercase">Shipment Update: </span> Estimated Ship Date is {{ orderItem.expected_ship_at | dateFormat: 'MMM D, YYYY'}}
                        </div>
                    </div>
                    <div *ngIf="product.isApprovalRequired" class="mb-2">
                        {{productRequiresApprovalMessage}}
                    </div>


                    <app-vendor-modal [vendorLabel]="orderItem.vendor" class="description__modal"
                                      *ngIf="canShowVendor"></app-vendor-modal>

                    <div class="d-flex align-items-center">
                        <ng-container *ngIf="showReorderButton">
                            <div class="mt-3" *ngIf="orderItem.product.replacedProductId">
                                <a class="link link-underline" (click)="showProduct(orderItem.product)" href="javascript:void(0)">
                                    Order Replacement <i class="fas fa-bolt"></i>
                                </a>
                            </div>
                            <div class="mt-3" *ngIf="!orderItem.product.replacedProductId && order.isOnDemand && !orderItem.isCancelled">
                                <a class="link link-underline" *ngIf="orderItem.product.productActive"
                                   (click)="reorderItem.emit()" href="javascript:void(0)">Reorder Item <i class="fas fa-redo"></i></a>
                                <span class="color-gray" *ngIf="!orderItem.product.productActive">This Item cannot be reordered</span>

                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div *ngIf="showHideItemButton">
                <button class="btn btn-icon"
                        (click)="toggleItemVisibility()"
                        [title]="orderItem.hidden ? 'Show Details' : 'Hide Details'">
                    <img [src]="orderItem.hidden ? '/assets/img/icons/plus-circle.svg' : '/assets/img/icons/minus-circle.svg'">
                </button>

            </div>

        </div>
        <div  *ngIf="isProductRestricted">
            <p class="color--alert">{{productRestrictedMessage}}</p>
        </div>
    </div>
</ng-template>


<ng-template #mobileReaonlyDetaileView>
    <div class="product-order-item--header p-0 ml-3 detailed" >
        <div class="header--row" >

            <div class="description full-width" >
                <div class="description__body d-flex flex-column full-width">

                    <div class="d-block mb-2 font-5 font-weight-normal mb-2">
                        <app-product-item-number [itemNumber]="product.skus[0].getDisplaySkuID()"
                                                 [supplierId]="product.skus[0].supplierId"></app-product-item-number>
                    </div>

                    <div>
                        <span *ngIf="orderItem.shipment_timing" class="font-weight-bold mt-3">{{ orderItem.shipment_timing }}&nbsp;</span>
                        <div *ngIf="orderItem.backorderedMessage && orderItem.estimatedDates" class="mt-3">
                            <span class="font-weight-bold uppercase">{{orderItem.backorderedMessage}} </span>{{ orderItem.estimatedDates }}
                        </div>
                        <div *ngIf="orderItem.expected_ship_at" class="mt-3">
                            <span class="font-weight-bold uppercase">Shipment Update: </span> Estimated Ship Date is {{ orderItem.expected_ship_at | dateFormat: 'MMM D, YYYY'}}
                        </div>
                    </div>
                    <div *ngIf="product.isApprovalRequired" class="mb-2">
                        {{productRequiresApprovalMessage}}
                    </div>

                    <app-vendor-modal [vendorLabel]="orderItem.vendor" class="description__modal"
                                      *ngIf="canShowVendor"></app-vendor-modal>


                    <div class="d-flex align-items-center">
                        <ng-container *ngIf="showReorderButton">
                            <div class="mt-3" *ngIf="orderItem.product.replacedProductId">
                                <a class="link link-underline" (click)="showProduct(orderItem.product)" href="javascript:void(0)">
                                    Order Replacement <i class="fas fa-bolt"></i>
                                </a>
                            </div>
                            <div class="mt-3" *ngIf="!orderItem.product.replacedProductId && order.isOnDemand && !orderItem.isCancelled">
                                <a class="link link-underline" *ngIf="orderItem.product.productActive"
                                   (click)="reorderItem.emit()" href="javascript:void(0)">Reorder Item <i class="fas fa-redo"></i></a>
                                <span class="color-gray" *ngIf="!orderItem.product.productActive">This Item cannot be reordered</span>

                            </div>
                        </ng-container>
                    </div>

                </div>
            </div>

            <div *ngIf="showHideItemButton">
                <button class="btn btn-icon"
                        (click)="toggleItemVisibility()"
                        [title]="orderItem.hidden ? 'Show Details' : 'Hide Details'">
                    <img [src]="orderItem.hidden ? '/assets/img/icons/plus-circle.svg' : '/assets/img/icons/minus-circle.svg'">
                </button>

            </div>

        </div>
    </div>
</ng-template>
