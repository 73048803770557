import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {Address} from '../../../models/address.model';
import {ConfigService} from '../../../services/config.service';
import {AddressService} from '../../../services/address.service';
import {isEmptyArray} from '../../../shared/utils';
import {AuthService} from '../../../services/auth.service';
import {ToastrService} from 'ngx-toastr';


interface WlsrCheckbox {
    id: number;
    checked: boolean
};

@Component({
    selector: 'app-default-address-modal',
    templateUrl: './default-address-modal.component.html',
    styleUrls: ['./default-address-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DefaultAddressModalComponent implements OnInit {

    updating = false;
    public address: Address;
    public wlsrIdList: number[];

    wlsr: WlsrCheckbox[] = [];

    constructor(private configService: ConfigService,
                private addressService: AddressService,
                private activeModal: NgbActiveModal,
                private toastr: ToastrService,
                private authService: AuthService) {
    }

    ngOnInit(): void {
        // this.initAddressFields();
        this.wlsr = this.initWslr();
    }

    close(address?: Address) {
        this.activeModal.close(address);
    }

    private initWslr(): WlsrCheckbox[] {
        if (isEmptyArray(this.wlsrIdList)) {
            return [];
        }

        return this.wlsrIdList.map(id => {
            const checked = !isEmptyArray(this.address.wslrIds) && this.address.wslrIds.includes(id);
            return {
                id, checked
            };
        });
    }

    onUpdate() {

        const checked = this.wlsr.filter(w => w.checked);

        if (isEmptyArray(checked)) {
            this.toastr.warning('At least one branch should be selected.');
            return;
        }

        this.updating = true;
        this.addressService.setDefault(this.address, checked.map(i => i.id)).subscribe((result) => {
                if (result) {
                    this.close();
                }
                this.updating = false;
            }
        );
    }
}
