import { Routes, RouterModule, PreloadAllModules }  from '@angular/router';
import { ModuleWithProviders }                      from '@angular/core';
import { NotFoundComponent }                        from './components/not-found/not-found.component';
import { NotSupportedComponent } from './components/not-supported/not-supported.component';
// import {SearchResultsComponent} from './modules/search-results/search-results.component';
// import {environment} from '../environments/environment';
export const routes: Routes = [
    // Needs to redirect to a landing page, that module can display
    // content appropriate to the user
    { path: '', loadChildren: () => import('app/modules/home/home.module').then(m => m.HomeModule) },

    // Feaure Modulesnom
    { path: 'login',    loadChildren: () => import('app/modules/account/login/login.module').then(m => m.LoginModule) },
    { path: 'password-reset',    loadChildren: () => import('app/modules/account/password-reset/password-reset.module').then(m => m.PasswordResetModule) },
    { path: 'errors',   loadChildren: () => import('app/modules/errors/errors.module').then(m => m.ErrorsModule) },
    { path: 'account',  loadChildren: () => import('app/modules/account/account.module').then(m => m.AccountModule),       data: { breadcrumb: 'Account'} },
    { path: 'buying-window', loadChildren: () => import('app/modules/buying-window/buying-window.module').then(m => m.BuyingWindowModule),    data: { breadcrumb: '__BW__' , orderType: 'PREORDER_TYPE'} },
    { path: 'on-demand', loadChildren: () => import('app/modules/on-demand/on-demand.module').then(m => m.OnDemandModule),    data: { breadcrumb: '__OD__', orderType: 'ECOMMERCE_TYPE'} },
    { path: 'search-results', loadChildren: () => import('app/modules/search-results/search-results.module').then(m => m.SearchResultsModule),    data: { breadcrumb: 'Search Results'} },
    // { path: 'search-results', component: SearchResultsComponent,    data: { breadcrumb: 'Search Results'} },
    { path: 'cart', loadChildren: () => import('app/modules/carts/carts.module').then(m => m.CartsModule),    data: { breadcrumb: 'Order Summaries & Carts'} },
    { path: 'checkout', loadChildren: () => import('app/modules/checkout/checkout.module').then(m => m.CheckoutModule),    data: { breadcrumb: 'Checkout'} },
    { path: 'notifications', loadChildren: () => import('app/modules/notifications/notifications.module').then(m => m.NotificationsModule),    data: { breadcrumb: 'Notifications'} },
    { path: 'reports', loadChildren: () => import('app/modules/reports/reports.module').then(m => m.ReportsModule),    data: { breadcrumb: 'Reports'} },
    { path: 'faq', loadChildren: () => import('app/modules/faq/faq.module').then(m => m.FaqModule) },
    { path: 'quick-order', loadChildren: () => import('app/modules/quick-order/quick-order.module').then(m => m.QuickOrderModule), data: { breadcrumb: 'Easy Find'} },
    { path: 'favorites', loadChildren: () => import('app/modules/favorites/favorites.module').then(m => m.FavoritesModule), data: { breadcrumb: 'Favorites'} },
    { path: 'not-supported', component: NotSupportedComponent, data: {title: 'Browser Not Supported'} },
    { path: '**', component: NotFoundComponent, data: {title: 'Page Not Found'} },
];

export const Routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: 'reload'
    // enableTracing: true
});
