<div class="vendor-modal-container">
  <ng-container *ngIf="vendorLabel">
    <div class="vendor-modal-container__button" *ngIf="vendorLabel !== 'InnerTrak'">
      <span class="button-label">{{ buttonLabel ? buttonLabel : 'Ships from '}}</span>
      <a class="link link-underline font-weight-normal" href="javascript:void(0);" (click)="toggleModal()">{{vendorLabel}}</a>
    </div>
    <div class="vendor-modal-container__button" *ngIf="vendorLabel === 'InnerTrak'">
      <span class="button-label">Shipping Directly from Vendor</span>
    </div>
  </ng-container>
  <a class="link extra-small font-weight-normal" href="javascript:void(0);" (click)="toggleModal()" *ngIf="!vendorLabel">Vendor Info</a>
  <app-modal #vendorModal title="Vendor Information" class="vendor-modal-container__body">
    <div class="app-modal-body">
      <span class="app-modal-body__line"></span>
      <ng-container *ngFor="let vendor of vendors">
        <h2>{{vendor.label}}</h2>
        <div class="app-modal-body__info">
          <p class="mr-2">
            Phone #:<span class="ml-2">{{vendor.phone}}</span>
          </p>
          <p class="mr-2">
            Email:<span class="ml-2">{{vendor.email}}</span>
          </p>
        </div>
        <span class="app-modal-body__line"></span>
      </ng-container>
    </div>
  </app-modal>
</div>
