import { Component, OnInit, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';

import { Pages } from '../../../enums/pages.enum';
import { Router } from '@angular/router';
import {ProfileService} from '../../../services/profile.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FooterComponent implements OnInit, OnDestroy {

    @Input() hideLinks = false;

    currentPage: Pages;
    allPages = Pages;

    public settings: Settings;
    private alive = true;
    year: any;

    constructor(
        public appSettings: AppSettings,
        private profileService: ProfileService,
        private router: Router
    ) {
        this.settings = this.appSettings.settings;

        const d = new Date();
        this.year = d.getFullYear();
    }

    ngOnInit() {
        if (this.router.url.indexOf('/search-results') !== -1) {
            this.currentPage = Pages.Search;
        } else {
            this.currentPage = null;
        }
    }


    ngOnDestroy() {
        this.alive = false;
    }


    showTermsAndConditions() {
        this.profileService.showTermsAndConditions(false);
        return false;
    }
}
