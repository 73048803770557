<div class="container main-navigation p-3" [class.hide]="!_visible">
    <div class="navigation--container">
        <div>
            <ng-container *ngTemplateOutlet="type === onDemandType ? featureProductsView : featureProgramsView"></ng-container>
        </div>
        <div>
            <h3 class="mb-3">Programs</h3>
            <div class="navigation__programs default-scroll" >
                <ng-container *ngIf="!isProgramsLoading; else loading">
                    <div *ngFor="let program of programs">
                        <a href="#" class="d-inline-block full-width mb-3 link color-black font-weight-normal text-transformation-none font-4"
                           (click)="navigateToProgram(program)">
                            <span class="d-block text-hidden-ellipsis" [innerHTML]="program.label | safeContent"></span>
                        </a>
                    </div>
                </ng-container>
            </div>
            <button class="btn btn-outline-primary d-block mx-auto mt-3" (click)="navigateToProgram()">All Programs</button>
        </div>
        <div>
            <h3 class="mb-3">Brands</h3>
            <div class="navigation__brands default-scroll">
                <ng-container *ngIf="!isBrandsLoading; else loading">
                    <div *ngFor="let brand of filteredBrands">
                        <a href="#" class="d-inline-block full-width mb-3 link color-black font-weight-normal text-transformation-none font-4"
                           (click)="navigateToFilteredProducts('BRAND' , brand)">
                            <span class="d-block text-hidden-ellipsis" [innerHTML]="brand | safeContent"></span>
                        </a>
                    </div>
                </ng-container>
            </div>
            <div class="mt-3">
                <div class="mx-auto" style="width: 90%; max-width: 210px">
                    <input type="text" class="form-control" [(ngModel)]="searchBrand" placeholder="Type to Search Brands" (input)="findBrands()" [disabled]="!brands.length" >
                </div>
            </div>
        </div>
        <div>
            <h3 class="mb-3">Categories</h3>
            <div class="navigation__categories default-scroll">
                <ng-container *ngIf="!isCategoriesLoading; else loading">
                    <div *ngFor="let category of categories" >
                        <a href="#" class="d-inline-block full-width mb-3 link color-black font-weight-normal text-transformation-none font-4"
                           (click)="navigateToFilteredProducts('CATEGORY', category)">
                            <span class="d-block text-hidden-ellipsis" [innerHTML]="category | safeContent"></span>
                        </a>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="navigation--footer mt-3">
        <ng-container *ngIf="type === onDemandType; else buyingWindowFooterView">
            <div class="navigation--footer__label font-weight-bold uppercase">
            <span>{{onDemandLabel}}</span>
            </div>
        </ng-container>

<!--        <div class="navigation&#45;&#45;footer__quick-order">-->
<!--            <span>Need to place an order quickly?</span>-->
<!--            <button class="btn btn-outline-primary" (click)="navigationToQuickOrder()" [disabled]="!availableQuickOrder">Easy Find</button>-->
<!--        </div>-->
    </div>
</div>

<ng-template #buyingWindowFooterView>
    <div class="navigation--footer__bw-label font-weight-bold uppercase">
        <a *ngIf="!isHomePage" href="#" (click)="navigateToHomePage()"><i class="far fa-backward"></i> Change Window</a>
        <span>{{ buyingWindowLabel }}</span>
        <button class="btn btn-outline-primary" (click)="navigateToProduct()">All Products</button>
    </div>
</ng-template>


<ng-template #featureProductsView>
    <h3 class="mb-3">Featured Products</h3>
    <div class="navigation__products default-scroll">
        <ng-container *ngIf="!isProductsLoading; else loading">
            <div *ngFor="let product of featureProducts" class="product-item d-flex mb-3" role="link"  (click)="navigateToProduct(product)">
                <img class="product-image" [src]="product.media | thumbnail" alt="Product Image" />
                <div class="ml-3">
                    <h4 [innerHTML]="product.label | safeContent" style="word-break: break-word"></h4>
                    <div class="font-5">
                        <app-product-item-number [itemNumber]="product.firstSkuId"
                                                 [supplierId]="product.firstSku.supplierId"></app-product-item-number>
                    </div>
                </div>

            </div>
        </ng-container>
    </div>
    <button class="btn btn-outline-primary d-block mx-auto mt-3" (click)="navigateToProduct()">All Products</button>

</ng-template>


<ng-template #featureProgramsView>
    <h3 class="mb-3">Featured Programs</h3>
    <div class="navigation__feature_programs default-scroll">
        <ng-container *ngIf="!isProgramsLoading; else loading">
            <div *ngFor="let program of featurePrograms" class="product-item d-flex mb-3 align-items-center" role="link"  (click)="navigateToProgram(program)">
                <img class="program-image" [src]="program.media | thumbnail" alt="Product Image" />
                <div class="ml-3">
                    <h4 [innerHTML]="program.label | safeContent" style="word-break: break-word" class="m-0"></h4>
                </div>

            </div>
        </ng-container>
    </div>
</ng-template>


<ng-template #loading>
    <div class="loader-inner" style="margin-top: 30px">
        <div class="loader"></div>
    </div>
</ng-template>
