<ng-container *ngIf="!showInternalOrderCodes; else internalCodesTmpl">
  <app-clearable-input [defaultValue]="order.internalOrder"
                       [value]="delivery.internalOrder"
                       (valueChange)="onInternalOrderChanged($event)"
                       (clear)="onInternalOrderReset()"></app-clearable-input>
</ng-container>

<ng-template #internalCodesTmpl>
  <ng-select
    #select
    class="ng-select--custom searchable codes--selector"
    [ngClass]="dropDownClasses"
    [items]="filteredCodes"
    placeholder="Internal Order Code"
    [searchable]="true"
    [typeahead]="searchSubject"
    (change)="onSelect($event)"
    [clearable]="false"
    dropdownPosition="bottom"
    [(ngModel)]="selectedCode">
    <ng-template ng-label-tmp let-item="item">
      <span class="d-block  font-5">{{item}}</span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
        <span class="font-6">
          {{ item }}
        </span>
    </ng-template>
  </ng-select>
</ng-template>
