import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService }    from '../services/translate.service';

@Pipe({
  name: 'translate'
})

export class TranslatePipe implements PipeTransform {
    constructor(public translateService: TranslateService) {}
  
    transform(key: string, args?) : string {
        return this.translateService.translate(key);
    }
}