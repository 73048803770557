import {Component, Input, OnDestroy, OnInit, Output, ViewEncapsulation, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';

import {OrderService} from '../../../../services/order.service';
import {isEmptyArray} from '../../../../shared/utils';
import {ProductService} from '../../../../services/product.service';
import {Product} from '../../../../models/product.model';
import {ProductCatalog} from '../../../../models/product-catalog.model';

import {ECOMMERCE_TYPE, PREORDER_TYPE} from '../../../../constants/order-types';
import {PageService} from '../../../../services/page.service';
import {AuthService} from '../../../../services/auth.service';
import {takeWhile} from 'rxjs/operators';
import {MultiTenantService} from '../../../../services/multi-tenant.service';

@Component({
    selector: 'app-header-navigation',
    templateUrl: './header-navigation.component.html',
    styleUrls: ['./header-navigation.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderNavigationComponent implements OnInit, OnDestroy {
    private alive = true;
    initialized = false;

    isBrandsLoading = true;
    isCategoriesLoading = true;
    isProgramsLoading = true;
    isProductsLoading = true;

    filteredBrands: string[] = [];
    searchBrand = '';
    brands: string[] = [];
    categories: string[] = [];
    programs: ProductCatalog[] = []
    featureProducts: Product[] = [];
    featurePrograms: ProductCatalog[] = [];

    _visible = false;

    @Input() type: string;

    @Output() close = new EventEmitter<void>();

    @Input() set visible(val) {
        this._visible = val;
        this.searchBrand = ''; // reset brands filter when visibility changed
        this.findBrands();
    };

    constructor(private orderService: OrderService,
                private productService: ProductService,
                private router: Router,
                private pageService: PageService,
                private authService: AuthService,
                private multiTenantService: MultiTenantService
                ) {
    }

    ngOnInit(): void {
        this.brands = [];
        this.categories = []

        this.orderService.isInitialized().subscribe(data => {
            if (data) {
                this.initialized = true;
                this.fetchOnDemandData();
                this.fetchBuyingWindowsData();
            }
        });

    }

    ngOnDestroy() {
        this.alive = false;
    }


    navigateToProduct(product: Product = null) {
        let url = '/on-demand/products';
        if (!product) {
            if ( this.type === PREORDER_TYPE) {
                url = `/buying-window/${this.orderService.activeOrderWindowID}/products`
            }
        } else {
            url = product.productURL;
        }
        this.router.navigate([url]);
        this.close.emit();
        return false;
    }

    navigateToProgram(program: ProductCatalog = null) {
        let url = '/on-demand/programs';
        if (!program) {
            // navigate to all programs
            if (this.type === PREORDER_TYPE) {
                url = `/buying-window/${this.orderService.activeOrderWindowID}/programs`;
            }
            this.router.navigate([ url]);
        } else {
            url = program.programDetailsUrl;
        }

        this.router.navigate([url]);
        this.close.emit();
        return false;
    }

    navigateToFilteredProducts(type: string, filter: string) {
        if (!filter) {
            return false;
        }


        let path = `/on-demand/products`;
        if (this.type === PREORDER_TYPE) {
            path = `/buying-window/${this.orderService.activeOrderWindowID}/products`;
        }
        this.pageService.preselectedFilter = {path, value: filter, type};
        this.router.navigate([path]);
        this.close.emit();
        return false;
    }

    navigationToQuickOrder() {
        this.pageService.setQuickOrderTab(this.type);
        this.router.navigate(['/quick-order']);
        this.close.emit();
        return false;
    }

    navigateToHomePage() {
        this.close.emit();
        setTimeout(() => {
            this.router.navigate(['/']);
        }, 200);
        return false;
    }

    findBrands() {
        const val = this.searchBrand.trim();
        if (!val || val.length < 1) {
            this.filteredBrands = [...this.brands];
        } else {

            const searchVal = val.toLowerCase();
            this.filteredBrands = this.brands.filter( b => b.toLowerCase().includes(searchVal));
        }
    }

    get availableQuickOrder(): boolean {
        return this.orderService.canDoQuickOrder(this.type);
    }

    get canSeeBuyingWindows(): boolean {
        return this.authService.canBuyingWindow
    }

    get onDemandType(): string {
        return ECOMMERCE_TYPE;
    }

    get BuyingWindowType(): string {
        return PREORDER_TYPE;
    }

    get buyingWindowLabel(): string {
        return this.orderService.activeOrderWindow?.label;
    }

    get isHomePage(): boolean {
        return this.router.url === '/';
    }


    get canOrder(): boolean {
        return this.authService.canOrder;
    }

    private fetchOnDemandData() {
        if (this.type !== ECOMMERCE_TYPE) {
            this.resetLoadingIndicator();
            return;
        }

        this.isProductsLoading = true;
        this.productService.getEcomProducts(1)
            .subscribe(({products}) => {
                this.featureProducts = products.filter( p => p.isFeatured);

                this.featureProducts.forEach((product: any) => {
                    product.uniqueSlug = product.getSlug();
                });

                this.isProductsLoading = false;
            });

        this.fetchBrandAndCategories();
        this.fetchPrograms();


    }

    private fetchBuyingWindowsData() {
        if (!this.canSeeBuyingWindows) {
            this.resetLoadingIndicator();
            return;
        }

        if (this.type !== PREORDER_TYPE) {
            this.resetLoadingIndicator();
            return;

        }

        // this.fetchBrandAndCategories();
        // this.fetchPrograms();

        this.orderService.buyingWindowChanged
            .pipe( takeWhile(() => this.alive))
            .subscribe( (buywinWindow) => {
                this.fetchBrandAndCategories();
                this.fetchPrograms();
            })
    }

    private fetchPrograms() {
        this.isProgramsLoading = true;
        const id = this.type === ECOMMERCE_TYPE ? 0 : this.orderService.activeOrderWindowID;
        this.featurePrograms = [];
        this.programs = [];
        this.productService.getCatalogs(id).subscribe( programsResult => {
            if (!isEmptyArray(programsResult?.catalogs)) {
                this.featurePrograms = programsResult.catalogs.filter( c => c.isFeatureType);
                this.programs = programsResult.catalogs.sort( (c1, c2) => c1.label.localeCompare(c2.label));
            }
            this.isProgramsLoading = false;
        });
    }

    private fetchBrandAndCategories() {
        this.isBrandsLoading = true;
        this.isCategoriesLoading = true;

        const groupId = this.type === ECOMMERCE_TYPE ? 1 : this.orderService.activeOrderWindowID;
        const type = this.type === ECOMMERCE_TYPE ? 'product' : 'all-product';
        this.productService.getFilters(groupId, type).subscribe(filters => {

            const brands: string[] = [];
            const categories: string[] = [];
            if (!isEmptyArray(filters)) {

                const brandFilters = filters.find(f => f.label === 'BRAND');
                if (brandFilters) {
                    brandFilters.items.forEach(i => {
                        i.children.forEach(c => {
                            if (!brands.includes(c.label)) {
                                brands.push(c.label);
                            }
                        });
                    });

                }

                const categoriesFilter = filters.find(f => f.label === 'CATEGORY');
                if (categoriesFilter ) {
                    categoriesFilter.items.forEach( i => {
                        if (!categories.includes(i.label)) {
                            categories.push(i.label);
                        }
                    });
                    // categories = categoriesFilter.items.map(i => i.label);
                }
            }

            this.brands = brands.sort();
            this.filteredBrands = [...this.brands];
            this.categories = categories;

            this.isBrandsLoading = false;
            this.isCategoriesLoading = false;
        });

    }

    private resetLoadingIndicator() {
        this.isProductsLoading = false;
        this.isProgramsLoading = false;
        this.isBrandsLoading = false;
        this.isCategoriesLoading = false;

    }

    get onDemandLabel(): string {
        return this.multiTenantService.onDemandLabel;
    }

}
