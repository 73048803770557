<div  class="credit-card--label--modal">
    <div class="modal-header state">
        <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label"> <i class="fal fa-credit-card"></i> Edit Credit Card Label </h1>
        <button type="button" (click)="close()" class="btn btn-icon close" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="input-group">
                        <label for="cardLabel">Label<span class="lowercase">(Required)</span>
                        </label>
                        <input type="text" name="cardLabel" id="cardLabel"  placeholder="Enter Credit Card Label" [(ngModel)]="label">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="buttons-container">
                        <button (click)="close()" class="btn btn-outline-primary">Cancel</button>
                        <button [disabled]="!canSave || isSaving" class="btn btn-primary submit" (click)="save()">
                            <i *ngIf="isSaving" class="fal fa-sync rotate"></i>&nbsp;Save</button>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <p class="font-weight-bold">
                        Editing credit card information is not supported. To change card information, please delete the card and create a new card with the new details.
                    </p>
                </div>
            </div>

        </div>
    </div>
</div>
