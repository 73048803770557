<section class="global-search" [class.is-active]="true">
  <div class="global-search__container">
    <div class="container">
      <div class="global-search__form">
        <input type="text" placeholder="I am searching for" autocomplete="off" #searchInput
               [(ngModel)]="searchText">
        <button *ngIf="searchText" class="btn-close" (click)="clearSearch()"><i class="fal fa-times"></i></button>
        <button class="btn-search" (click)="search()"><i class="far fa-search"></i></button>
      </div>
    </div>
  </div>
</section>

<div *ngIf="showBackdrop" class="global-search__cover" [class.is-active]="true" (click)="close()">
</div>
