import { BaseModel } from './base.model';
import { FilterItem } from './filter-item.model';

export class FilterGroup extends BaseModel {
    public id: any;
    public ord: number;
    public label: string;
    public items: FilterItem[];
    public selected: boolean;
    public searchTerm = '';
    public expanded: boolean;
    public changed: boolean;

    constructor(data) {
        super(data, {
            num: ['ord'],
        });

        if (!this.items) {
            this.items = [];
        } else {
            this.items = this.items.map( i => new FilterItem(i));
        }

        if (!this.ord) {
            this.ord = 0;
        }

    }

    public getSelectedItemIDs() {
        const selectedItems = [];

        this.getFilterValue(this.items, selectedItems);

        return selectedItems;
    }

    public clearSelection() {
        this.items.forEach( item => item.clearSelection());
        this.searchTerm = '';
    }

    public clearDisplay() {
        this.items.forEach(item => item.clearDisplay());
    }

    public get selectedFilters(): FilterItem[] {
        const result: FilterItem[] = [];
        this.items.forEach( item => {
            if (item.id !== 0 && item.selected) {
                result.push(item);
            }
            item.children.forEach( child => {
                if (child.id !== 0 && child.selected) {
                    result.push(child);
                }
            })

        });
        return result;
    }

    private getFilterValue(items, selected) {

        items.forEach( item => {
            if (item.children.length > 0) {
                this.getFilterValue(item.children, selected);
            } else {
                if (item.selected) {
                    selected.push(`${item.attr_id}:${item.id}`);
                }
            }
        });
    }

    public get visible(): boolean {
        return this.label !== 'ITEM_SUBTYPE';
    }

}
